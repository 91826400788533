import MaterialTable, { Column } from 'material-table'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Reducers, ReducerGraphic } from '../../types/reducers'
import { getLangTable } from '../../utility/functions'
import { Languages } from '../../utility/Languages'
import CheckboxInput from '../CheckboxInput/CheckboxInput'
import FixedTableTheme from '../FixedTableTheme'
import { RadioToAddToBundle } from '../Modals/AddRadioToBundleModal'
import SelectInput, { Option } from '../SelectInput/SelectInput'
import TextInput from '../TextInput/TextInput'

interface Props {
    radios: RadioToAddToBundle[]
    maxPos: number
    updatePosition: (pos: number, updated: number) => void
    updateWeight: (weight: number, updated: number) => void
    updateSponsor: (status: boolean, updated: number) => void
    deletePosition: (radio: number) => void
}

const RadioToAddToBundleTable: React.FC<Props> = (props) => {
    const graphic = useSelector<Reducers, ReducerGraphic>((state) => state.graphic)
    const tableRef = React.useRef<any>()

    const orderedPositions: RadioToAddToBundle[] = useMemo(() => {
        return props.radios
    }, [props.radios])

    const columns: Column<RadioToAddToBundle>[] = useMemo(() => {
        return [
            {
                title: 'Posizione',
                field: 'position',
                render: (radio: RadioToAddToBundle) => {
                    return (
                        <TextInput
                            value={radio.position.toString()}
                            onTextChange={(v) => {
                                const number = parseInt(v, 10)
                                if (number > 0) {
                                    if (number > props.maxPos) {
                                        props.updatePosition(props.maxPos, radio.id)
                                    } else {
                                        props.updatePosition(number, radio.id)
                                    }
                                } else {
                                    props.updatePosition(1, radio.id)
                                }
                            }}
                        />
                    )
                },
                width: '12%',
                headerStyle: { width: 160 },
                cellStyle: {
                    width: 160,
                    maxWidth: 160,
                    cellWidth: '12%',
                },
            },
            {
                title: 'Radio',
                field: 'radios',
                render: (radio: RadioToAddToBundle) => {
                    return (
                        <div style={{ display: 'flex', gap: 15, justifyContent: 'flex-start', alignItems: 'center' }}>
                            <p>{radio.name}</p>
                        </div>
                    )
                },
                width: '75%',
                cellStyle: {
                    cellWidth: '75%',
                },
            },
            {
                title: 'Peso',
                field: 'weight',
                render: (radio: RadioToAddToBundle) => {
                    return (
                        <SelectInput
                            value={{
                                id: radio.weight,
                                value: radio.weight,
                                label: radio.weight.toString(),
                            }}
                            options={[
                                { id: 1, value: 1, label: '1' },
                                { id: 2, value: 2, label: '2' },
                                { id: 3, value: 3, label: '3' },
                                { id: 4, value: 4, label: '4' },
                            ]}
                            onChange={(e) => {
                                const v = e as Option
                                props.updateWeight(v.id as number, radio.id)
                            }}
                        />
                    )
                },
                width: '7%',
                headerStyle: { width: 120 },
                cellStyle: {
                    width: 120,
                    maxWidth: 120,
                    cellWidth: '7%',
                },
            },
            {
                title: 'Sponsorizzata',
                field: 'sponsored',
                render: (radio: RadioToAddToBundle) => {
                    return (
                        <CheckboxInput
                            values={[{ label: '', status: radio.isSponsored }]}
                            onClick={() => {
                                props.updateSponsor(!radio.isSponsored, radio.id)
                            }}
                        />
                    )
                },
                width: '6%',
                headerStyle: { width: 100, maxWidth: 100 },
                cellStyle: {
                    width: 100,
                    maxWidth: 100,
                    cellWidth: '6%',
                },
            },
        ]
    }, [orderedPositions])

    const actions = useMemo(() => {
        const actions = [
            (radio: RadioToAddToBundle) => ({
                icon: 'do_disturb_on',
                iconProps: {
                    color: 'error',
                },
                onClick: () => {
                    props.deletePosition(radio.id)
                },
            }),
        ]

        return actions
    }, [props.radios])

    return (
        <FixedTableTheme>
            <MaterialTable
                columns={columns}
                data={orderedPositions}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                actions={actions}
                ref={tableRef}
                title=""
                localization={getLangTable(graphic.currentLanguage as Languages)}
                options={{
                    actionsColumnIndex: -1,
                    search: false,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 50],
                    actionsCellStyle: {
                        border: '0',
                    },
                    toolbar: false,
                    tableLayout: 'auto',
                }}
                style={{
                    boxShadow: 'none',
                }}
            />
        </FixedTableTheme>
    )
}

export default RadioToAddToBundleTable
