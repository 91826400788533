import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getRadios } from '../../resources/api-constants'
import { COLORS } from '../../resources/colors'
import { PLACEHOLDER } from '../../resources/constants'
import { ROUTES } from '../../resources/routes-constants'
import { setLastSearchRadio } from '../../store/actions/graphic'
import { Radio } from '../../types/data'
import { ReducerData, ReducerGraphic, Reducers, ReducerUser } from '../../types/reducers'
import FmWorldAxios from '../../utility/FmWorldAxios'
import { RadioFilterObject } from '../Filters/RadioFilter'
import Loader from '../Loader/Loader'
import './Grid.sass'
import GridItem from './GridItem/GridItem'
import PageController from './PageController/PageController'

interface Props {
    filter: RadioFilterObject
}

const RadioGrid: any = forwardRef((props: Props, ref: any) => {
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const data = useSelector<Reducers, ReducerData>((state) => state.data)
    const graphic = useSelector<Reducers, ReducerGraphic>((state) => state.graphic)
    const [pageSize, setPageSize] = useState(graphic.lastRadioSearch ? graphic.lastRadioSearch.pageSize : 21)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [currentPage, setCurrentPage] = useState(graphic.lastRadioSearch ? graphic.lastRadioSearch.page : 1)
    const [renderedRadios, setRenderedRadios] = useState<Radio[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [totalPages, setTotalPages] = useState(data.totalRadios ? Math.ceil(data.totalRadios / pageSize) : 1)
    const [totalRadios, setTotalRadios] = useState(data.totalRadios ? data.totalRadios : 0)

    useImperativeHandle(ref, () => ({
        reloadData: () => {
            setCurrentPage(1)
        },
    }))

    const getParamsObject = () => {
        const params: any = {
            limit: pageSize,
            offset: pageSize * (currentPage - 1),
            return_empty_streams: props.filter.returnEmptyStreams,
            return_offline_streams: props.filter.returnOfflineStreams,
            only_verified: props.filter.returnVerifiedStreams,
            video_only: props.filter.videoOnly,
        }
        if (props.filter.query && props.filter.query.length > 2) {
            params.query = props.filter.query
        }
        if (props.filter.countries.length > 0) {
            params.countries = props.filter.countries.toString()
        }
        if (props.filter.types.length > 0) {
            params.types = props.filter.types.toString()
        }
        if (props.filter.categories.length > 0) {
            params.categories = props.filter.categories.toString()
        }
        if (props.filter.networks.length > 0) {
            params.networks = props.filter.networks.toString()
        }
        if (!props.filter.returnEmptyStreams) {
            params.return_empty_streams = props.filter.returnEmptyStreams
        }
        if (!props.filter.returnOfflineStreams) {
            params.return_offline_streams = props.filter.returnOfflineStreams
        }
        if (props.filter.returnVerifiedStreams) {
            params.only_verified = props.filter.returnVerifiedStreams
        }
        if (props.filter.videoOnly) {
            params.video_only = props.filter.videoOnly
        }
        return params
    }

    const fetchNewRadios = async () => {
        setIsLoading(true)
        // console.log(currentPage, props.search)
        const params: any = getParamsObject()
        const radios = await FmWorldAxios.get(getRadios(), {
            params: { ...params },
        }).then((res) => {
            setTotalRadios(res.data.count)
            setTotalPages(Math.ceil(res.data.count / pageSize))
            return res.data.items
        })
        setRenderedRadios(radios)
        setIsLoading(false)
    }

    useEffect(() => {
        if (currentPage <= totalPages && props.filter) {
            void fetchNewRadios()
        }
    }, [currentPage])

    useEffect(() => {
        if (currentPage > 1) {
            setCurrentPage(1)
        } else {
            void fetchNewRadios()
        }
    }, [props.filter, pageSize])

    useEffect(() => {
        if (user.user) {
            if (graphic.lastRadioSearch) {
                setCurrentPage(graphic.lastRadioSearch.page)
            } else {
                void fetchNewRadios()
            }
        }
    }, [user.user])

    // console.log(currentPage)

    if (isLoading) {
        return (
            <div className="loadingGrid">
                <Loader />
            </div>
        )
    }

    if (props.filter.query && props.filter.query.length > 2 && renderedRadios.length === 0) {
        return (
            <div className="loadingGrid">
                <p className="title">Nessuna radio trovata</p>
            </div>
        )
    }

    if (renderedRadios.length === 0) {
        return (
            <div className="loadingGrid">
                <p className="title">
                    Premi su <span style={{ color: COLORS.purple, fontWeight: 700 }}>Aggiungi</span> per aggiungere la
                    tua prima radio
                </p>
            </div>
        )
    }

    return (
        <div>
            <div className="grid">
                {renderedRadios.map((r) => {
                    return (
                        <GridItem
                            name={r.name}
                            key={r.id?.toString()}
                            image={r.logo ? (r.logo as string) : PLACEHOLDER}
                            onClick={() => {
                                navigate(`${ROUTES.RADIO_DETAIL_ROUTE}${r.id}`, {
                                    state: { name: r.name, idNetwork: r.idNetwork },
                                })
                                dispatch(
                                    setLastSearchRadio({
                                        pageSize,
                                        page: currentPage,
                                        query: props.filter.query ? props.filter.query : '',
                                        countries: props.filter.countries,
                                        categories: props.filter.categories,
                                        types: props.filter.types,
                                        videoOnly: props.filter.videoOnly,
                                        returnEmptyStreams: props.filter.returnEmptyStreams,
                                        returnOfflineStreams: props.filter.returnOfflineStreams,
                                        networks: props.filter.networks,
                                        returnVerifiedStreams: props.filter.returnVerifiedStreams,
                                    })
                                )
                            }}
                        />
                    )
                })}
            </div>
            {renderedRadios.length > 0 ? (
                <div className="grid-controller">
                    <PageController
                        minNumber={pageSize * currentPage - pageSize + 1}
                        maxNumber={pageSize * currentPage > totalRadios ? totalRadios : pageSize * currentPage}
                        totalNumber={totalRadios}
                        pageSize={pageSize}
                        onFirstPageClick={() => setCurrentPage(1)}
                        onPrevPageClick={() => setCurrentPage(currentPage - 1)}
                        onNextPageClick={() => setCurrentPage(currentPage + 1)}
                        onLastPageClick={() => setCurrentPage(totalPages)}
                        onChangePageSize={(v) => setPageSize(v)}
                    />
                </div>
            ) : null}
        </div>
    )
})
RadioGrid.displayName = 'RadioGrid'
export default RadioGrid
