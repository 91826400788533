import React, { useEffect, useMemo, useState } from 'react'
import './Modals.sass'
import Modal from '@mui/material/Modal'
import { MuxServiceAssociation, Radio } from '../../types/data'
import Button from '../Button/Button'
import { COLORS } from '../../resources/colors'
import SelectInput, { Option } from '../SelectInput/SelectInput'
import { useTranslation } from 'react-i18next'
import AsyncSelectInput from '../SelectInput/AsyncSelectInput'
import axios from 'axios'

interface Props {
    isVisible: boolean
    radios: Radio[]
    onClose: () => void
    onSave: (radio: Option<number>, channel: number) => void
    service: MuxServiceAssociation
}

interface TXControlDeviceVariable {
    ID: number
    custom_name: string
    description: string
    name: string
    oid: string
}

const DabTxControlRadioModal: React.FC<Props> = (props) => {
    const [selectedRadio, setSelectedRadio] = useState<Option<number> | null>(null)
    const [selectedChannel, setSelectedChannel] = useState<number | undefined>(undefined)
    const [channels, setChannels] = useState<number[]>([])
    const { t } = useTranslation()

    useEffect(() => {
        if (props.isVisible) {
            setSelectedRadio(null)
            setSelectedChannel(undefined)
        }
    }, [props.isVisible])

    useEffect(() => {
        if (props.service) {
            void getChannels()
        }
    }, [props.service])

    const getChannels = async () => {
        try {
            const fetchedVariables = await axios
                .get<{ data: TXControlDeviceVariable[]; count: number }>(
                    `https://${props.service.configuration.instanceUrl}/api/device-vars`,
                    {
                        params: {
                            id_device: props.service.configuration.deviceId,
                        },
                        headers: {
                            Authorization: `Bearer ${props.service.configuration.userToken}`,
                        },
                    }
                )
                .then((res) => res.data.data)

            setChannels([...new Set(fetchedVariables.map((item) => parseInt(item.name.replace(/[^\d.]/g, ''), 10)))])
        } catch (e) {
            console.log(e)
        }
    }

    const unusedChannels = useMemo(() => {
        const settedChannels = props.service.configuration.radios.flatMap((radio) => radio.channel)

        return channels.filter((channel) => {
            const isAlreadySelected = settedChannels.find((settedChannel) => settedChannel === channel) !== undefined

            return !settedChannels.includes(channel) && !isAlreadySelected
        })
    }, [channels, props.service])

    const unusedRadios = useMemo(() => {
        const settedRadios = props.service.configuration.radios

        return props.radios.filter((radio) => {
            if (selectedRadio) {
                return selectedRadio.value !== radio.id
            }

            return settedRadios.find((settedRadio) => settedRadio.id === radio.id) === undefined
        })
    }, [props.radios, props.service])

    return (
        <Modal
            open={props.isVisible}
            onClose={() => props.onClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className="modalBox actionBox">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 15 }}>
                    <h2 className="modalBox-title" style={{ flex: 2 }}>
                        {t('dabs.add_radio_configuration')}
                    </h2>
                </div>
                <div>
                    <SelectInput
                        label={`${t('radios.radio')}*`}
                        value={selectedRadio}
                        placeholder={t('placeholders.write_to_select_radio')}
                        options={unusedRadios.map((c: Radio) => {
                            return {
                                id: c.id,
                                value: c.id,
                                label: c.name,
                            }
                        })}
                        onChange={(value) => {
                            const v = value as Option<number>
                            setSelectedRadio(v)
                        }}
                    />
                    <div style={{ margin: '20px 0' }}>
                        <AsyncSelectInput
                            label={`${t('dabs.channel')}*`}
                            placeholder={t('placeholders.select_channel')}
                            flex={1}
                            value={{
                                value: selectedChannel ?? -1,
                                label: selectedChannel?.toString() ?? '',
                                id: selectedChannel ?? -1,
                            }}
                            fetchOptions={async (input: string) => {
                                let filteredChannels = unusedChannels

                                if (input.length > 0) {
                                    filteredChannels = filteredChannels.filter((channel) =>
                                        channel.toString().toLowerCase().trim().includes(input.toLowerCase().trim())
                                    )
                                }

                                return filteredChannels.map((channel) => {
                                    return {
                                        id: channel,
                                        value: channel.toString(),
                                        label: channel.toString(),
                                    }
                                })
                            }}
                            onChange={(value) => {
                                const v = value as Option<number>
                                setSelectedChannel(v.value)
                            }}
                            bordered
                        />
                    </div>
                    <div className="modalBox-buttonsRow">
                        <div style={{ flex: '1 1 100%' }} />
                        <Button
                            label={t('general.cancel')}
                            border={'1px solid rgba(0,0,0,0.07)'}
                            onPress={() => props.onClose()}
                            type="secondary"
                            color={COLORS.darkGrey}
                            background="#fff"
                        />
                        <Button
                            disabled={!selectedRadio}
                            label={t('general.add')}
                            onPress={() => props.onSave(selectedRadio as Option<number>, selectedChannel ?? -1)}
                            type="submit"
                            color="#fff"
                            background={COLORS.purple}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default DabTxControlRadioModal
