import React, { useEffect, useState } from 'react'
import './Modals.sass'
import Modal from '@mui/material/Modal'
import { Mux } from '../../types/data'
import TextInput from '../TextInput/TextInput'
import Button from '../Button/Button'
import { COLORS } from '../../resources/colors'
import { VoidMux } from '../../utility/voidConstants'
import { useTranslation } from 'react-i18next'

interface Props {
    isVisible: boolean
    onClose: () => void
    onSave: (newElement: Mux | VoidMux) => void
    mux: Mux | VoidMux | null
}

const MuxModal: React.FC<Props> = (props) => {
    const [muxToEdit, setMuxToEdit] = useState<Mux | VoidMux | null>(props.mux)
    const { t } = useTranslation()

    useEffect(() => {
        if (props.isVisible) {
            setMuxToEdit(props.mux)
        }
    }, [props.isVisible])

    return (
        <Modal
            open={props.isVisible}
            onClose={() => props.onClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className="modalBox actionBox">
                <h2 className="modalBox-title">
                    {muxToEdit && (muxToEdit as Mux).id ? t('dabs.edit_mux') : t('dabs.create_mux')}
                </h2>
                {muxToEdit ? (
                    <div>
                        <TextInput
                            value={muxToEdit.name}
                            label={t('general.name')}
                            onTextChange={(e) =>
                                setMuxToEdit({
                                    ...muxToEdit,
                                    name: e,
                                })
                            }
                        />
                        <div style={{ margin: '20px 0' }}>
                            <TextInput
                                value={muxToEdit.code}
                                label={t('dabs.code')}
                                onTextChange={(e) =>
                                    setMuxToEdit({
                                        ...muxToEdit,
                                        code: e,
                                    })
                                }
                            />
                        </div>
                    </div>
                ) : null}
                <div className="modalBox-buttonsRow">
                    <div style={{ flex: '1 1 100%' }} />
                    <Button
                        label={t('general.cancel')}
                        border={'1px solid rgba(0,0,0,0.07)'}
                        onPress={() => props.onClose()}
                        type="secondary"
                        color={COLORS.darkGrey}
                        background="#fff"
                    />
                    <Button
                        disabled={!muxToEdit || !muxToEdit?.name}
                        label={muxToEdit && (muxToEdit as Mux).id ? t('general.update') : t('general.add')}
                        onPress={() => props.onSave(muxToEdit as Mux | VoidMux)}
                        type="submit"
                        color="#fff"
                        background={COLORS.purple}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default MuxModal
