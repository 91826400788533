import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../../components/Button/Button'
import Loader from '../../../components/Loader/Loader'
import ActionsModal from '../../../components/Modals/ActionsModal'
import DeleteModal from '../../../components/Modals/DeleteModal'
import LoadingModal from '../../../components/Modals/LoadingModal'
import TextInput from '../../../components/TextInput/TextInput'
import { editMuxService, getRadioDetail } from '../../../resources/api-constants'
import { COLORS } from '../../../resources/colors'
import { MuxServiceAssociation, Radio } from '../../../types/data'
import FmWorldAxios from '../../../utility/FmWorldAxios'
import DabTxControlRadiosTable, {
    TxControlCompleteRadioConfiguration,
} from '../../../components/Tables/DabTxControlRadiosTable'
import DabTxControlRadioModal from '../../../components/Modals/DabTxControlRadioModal'
import { Option } from '../../../components/SelectInput/SelectInput'
import { deepClone } from '../../../utility/functions'

interface Props {
    muxId: number
    muxRadios: Radio[]
    service: MuxServiceAssociation
    onRefreshConfiguration: () => void
}

const DabTXControlTab: React.FC<Props> = (props) => {
    const [search, setSearch] = useState('')
    const [selectedRadio, setSelectedRadio] = useState<Radio | null>(null)
    const [radioToDelete, setRadioToDelete] = useState<Radio | null>(null)
    const [showAddRadio, setShowAddRadio] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isCreating, setIsCreating] = useState(false)
    const [error, setError] = useState('')
    const [radios, setRadios] = useState<Radio[]>([])
    const { t } = useTranslation()

    useEffect(() => {
        void fetchRadios(props.service.configuration.radios.map((item) => item.id))
    }, [props.service])

    const fetchRadios = async (radioIds: number[]) => {
        setError('')
        setIsLoading(true)
        try {
            const fetchedRadios: Radio[] = []
            for (let i = 0; i < radioIds.length; i++) {
                const fetchedRadio = await FmWorldAxios.get(getRadioDetail(radioIds[i])).then((res) => {
                    return res.data
                })
                fetchedRadios.push(fetchedRadio)
            }

            setRadios(fetchedRadios)
        } catch (error: any) {
            console.log('ERROR', error)
            setError(error.toString())
        }
        setIsLoading(false)
    }

    const handleDeleteAssoc = async () => {
        if (radioToDelete) {
            setError('')
            setIsLoading(true)
            try {
                await FmWorldAxios.put(editMuxService(props.muxId, props.service.id), {
                    configuration: {
                        ...props.service.configuration,
                        radios: props.service.configuration.radios.filter(
                            (currentRadio) => currentRadio.id !== selectedRadio?.id
                        ),
                    },
                })
                setRadioToDelete(null)
                setSelectedRadio(null)
                void props.onRefreshConfiguration()
            } catch (error: any) {
                console.log('ERROR', error)
                setError(error.toString())
                setIsLoading(false)
            }
        }
    }

    const handleCreateAssoc = async (radio: Option<number>, channel: number) => {
        setError('')
        setIsCreating(true)
        try {
            const copiedConfiguration = deepClone(props.service.configuration)

            if (copiedConfiguration.radios.find((item) => item.id === radio.id)) {
                copiedConfiguration.radios = copiedConfiguration.radios.map((currentRadio) => {
                    if (currentRadio.id !== radio.id) {
                        return currentRadio
                    }

                    return {
                        id: radio.id,
                        channel,
                    }
                })
            } else {
                copiedConfiguration.radios.push({
                    id: radio.id,
                    channel,
                })
            }

            await FmWorldAxios.put(editMuxService(props.muxId, props.service.id), {
                configuration: {
                    ...copiedConfiguration,
                    radios: copiedConfiguration.radios,
                },
            })
            setIsCreating(false)
            setSelectedRadio(null)
            setShowAddRadio(false)
            void props.onRefreshConfiguration()
        } catch (error: any) {
            console.log('ERROR', error)
            setError(error.toString())
        }
    }

    const actionsModal = [
        {
            text: t('dabs.delete_radio_association'),
            color: COLORS.redDelete,
            onClick: () => setRadioToDelete(selectedRadio),
        },
    ]

    const dataToRender = useMemo(() => {
        const serviceRadios = props.service.configuration.radios

        return serviceRadios
            .map((item) => {
                const foundRadio = radios.find((radio) => {
                    return radio.id === item.id
                })

                if (!foundRadio) {
                    return null
                }

                return {
                    ...foundRadio,
                    channel: item.channel,
                }
            })
            .filter((item) => item !== null) as TxControlCompleteRadioConfiguration[]
    }, [props.service, radios])

    return (
        <div>
            <div className="titleRow">
                <div style={{ flex: 1 }}>
                    <h2 className="title">{t('dabs.radios_txcontrol_configurations')}</h2>
                    <h3 className="subtitle" style={{ fontWeight: 400 }}>
                        {t('dabs.manage_radios_txcontrol_configurations')}
                    </h3>
                    {error && <p className="u-normal-text u-bold-text u-error-text">{error}</p>}
                </div>
                <div style={{ display: 'flex', flex: 2, gap: 10, alignItems: 'center', justifyContent: 'flex-end' }}>
                    <div style={{ flex: 1 }} />
                    <div style={{ flex: 2 }}>
                        <TextInput
                            value={search}
                            placeholder={t('general.search')}
                            icon="search"
                            iconPosition="right"
                            onTextChange={(v) => setSearch(v)}
                        />
                    </div>
                    <div style={{ flex: 1 }}>
                        <Button
                            label={t('general.refresh')}
                            onPress={() => {
                                void props.onRefreshConfiguration()
                            }}
                            type="submit"
                            background="rgba(100,38,119,0.14)"
                            border={`2px solid ${COLORS.purple}`}
                            color={COLORS.purple}
                        />
                    </div>
                    <div style={{ flex: 1 }}>
                        <Button
                            label={t('general.add')}
                            onPress={() => {
                                setShowAddRadio(true)
                            }}
                            type="submit"
                        />
                    </div>
                </div>
            </div>
            {isLoading ? (
                <div className="loadingGrid">
                    <Loader />
                </div>
            ) : (
                <div className="u-elements-row" style={{ alignItems: 'flex-start', marginTop: 20 }}>
                    <div style={{ flex: 1 }}>
                        <DabTxControlRadiosTable data={dataToRender} selectRadio={(f) => setSelectedRadio(f)} />
                    </div>
                </div>
            )}

            <ActionsModal
                isVisible={selectedRadio !== null}
                title={t('general.what_to_do')}
                onClose={() => setSelectedRadio(null)}
                actions={actionsModal}
            />
            <LoadingModal isVisible={isCreating} text={t('loadings.updating_mux_radio_association')} />
            <DabTxControlRadioModal
                isVisible={showAddRadio}
                radios={props.muxRadios}
                onClose={() => setShowAddRadio(false)}
                onSave={(radio, channel) => void handleCreateAssoc(radio, channel)}
                service={props.service}
            />
            <DeleteModal
                onDelete={() => {
                    void handleDeleteAssoc()
                }}
                title={`${t('dabs.delete_txcontrol_configuration_radios')} ${radioToDelete?.name}`}
                text={`${t('dabs.want_delete_txcontrol_configuration_radios')} ${radioToDelete?.name}?`}
                onClose={() => {
                    setRadioToDelete(null)
                }}
                isVisible={radioToDelete !== null}
            />
        </div>
    )
}

export default DabTXControlTab
