import React from 'react'
import { useNavigate } from 'react-router-dom'
import './Link.sass'

interface InternalLink {
    url: string
    params?: any
}

interface Props {
    icon?: string
    label: string
    underline?: 'none' | 'hover' | 'always'
    type: 'accent' | 'hover' | 'button' | 'text-colored'
    disabled?: boolean
    targetUrl?: InternalLink
    href?: string
    target?: 'internal' | 'external'
    onPress?: () => void
    color?: string
}

const Link: React.FC<Props> = (props) => {
    const navigate = useNavigate()

    const navigateToTarget = () => {
        const url = props.targetUrl && props.targetUrl.url ? props.targetUrl.url : undefined
        const params = props.targetUrl && props.targetUrl.params ? props.targetUrl.params : undefined
        if (url) {
            navigate(url, params)
        }
    }

    return (
        <>
            {props.target && props.target === 'external' ? (
                <a
                    href={props.href ? props.href : '#'}
                    className={`o-link o-${props.type ? props.type : 'hover'}-link u-underline-${
                        props.underline ? props.underline : 'always'
                    }`}
                    aria-label={props.label}
                >
                    {props.label}
                </a>
            ) : (
                <div
                    className={`o-link o-${props.type ? props.type : 'hover'}-link u-underline-${
                        props.underline ? props.underline : 'always'
                    }`}
                    aria-label={props.label}
                    style={{ color: props.color ? props.color : '' }}
                    onClick={() => navigateToTarget()}
                >
                    {props.label}
                </div>
            )}
        </>
    )
}

export default Link
