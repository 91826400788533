export const cron_locale_enEN = {
    everyText: 'every',
    emptyMonths: 'every month',
    emptyMonthDays: 'every day of the month',
    emptyMonthDaysShort: 'day of the month',
    emptyWeekDays: 'every day of the week',
    emptyWeekDaysShort: 'day of the week',
    emptyHours: 'every hour',
    emptyMinutes: 'every minute',
    emptyMinutesForHourPeriod: 'every',
    yearOption: 'year',
    monthOption: 'month',
    weekOption: 'week',
    dayOption: 'day',
    hourOption: 'hour',
    minuteOption: 'minute',
    rebootOption: 'restart',
    prefixPeriod: 'Every',
    prefixMonths: 'on',
    prefixMonthDays: 'the',
    prefixWeekDays: 'the',
    prefixWeekDaysForMonthAndYearPeriod: 'and',
    prefixHours: 'at',
    prefixMinutes: ':',
    prefixMinutesForHourPeriod: 'at',
    suffixMinutesForHourPeriod: 'minute(s)',
    errorInvalidCron: 'Expression not valid',
    clearButtonText: 'Clear',
    weekDays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ],
    altWeekDays: ['SUN', 'MON', 'TUE', 'THU', 'WED', 'FRI', 'SAT'],
    altMonths: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
}
