import React from 'react'
import './CardContainer.sass'

interface Props {
    children: any
    flex?: number
}

const CardContainer: React.FC<Props> = (props) => {
    return (
        <div
            className="cardContainer"
            style={{ flex: props.flex ? props.flex : 1, display: 'flex', flexDirection: 'column' }}
        >
            {props.children}
        </div>
    )
}

export default CardContainer
