import React from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { setCollapsedBar } from '../../store/actions/graphic'
import { Reducers, ReducerGraphic } from '../../types/reducers'
import Icon from '../Icon'
import AudioPlayer from '../MediaPlayer/AudioPlayer'
import './CollapsableSidebar.sass'

const CollapsableSidebar: React.FC = () => {
    const graphic = useSelector<Reducers, ReducerGraphic>((state) => state.graphic)
    const dispatch = useDispatch()

    if (!graphic.collapsedBar) {
        return (
            <div className="collapsableSidebar">
                <div className="collapsableSidebar-container">
                    <div style={{ flex: 1, padding: 10, width: '100%' }}>
                        <AudioPlayer />
                    </div>
                    <div className="collapsableSidebar-container_controller">
                        <div
                            className="collapsableSidebar-container_controller--control"
                            onClick={() => dispatch(setCollapsedBar(true))}
                        >
                            <Icon name="collapse" size={20} color={'#fff'} />
                        </div>
                        <div className="collapsableSidebar-container_controller--line" />
                        <div className="collapsableSidebar-container_controller--circle" />
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className="floatButton" onClick={() => dispatch(setCollapsedBar(false))}>
            <Icon name="expand" size={20} color={'#fff'} />
        </div>
    )
}

export default CollapsableSidebar
