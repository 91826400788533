import MaterialTable, { Column } from 'material-table'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { COLORS } from '../../resources/colors'
import { ROUTES } from '../../resources/routes-constants'
import { PlayerNetwork } from '../../types/data'
import { Reducers, ReducerGraphic } from '../../types/reducers'
import { getLangTable } from '../../utility/functions'
import { Languages } from '../../utility/Languages'
import FixedTableTheme from '../FixedTableTheme'

interface Props {
    data: PlayerNetwork[]
    idNetwork: number
    nameNetwork: string
    selectNetwork: (player: PlayerNetwork) => void
}

const PlayersTable: React.FC<Props> = (props) => {
    const graphic = useSelector<Reducers, ReducerGraphic>((state) => state.graphic)
    const navigate = useNavigate()
    const { t } = useTranslation()

    const columns: Column<PlayerNetwork>[] = [
        {
            title: t('networks.player') as string,
            field: 'name',
            render: (player: PlayerNetwork) => {
                return (
                    <p
                        onClick={() => navigate(`${ROUTES.PLAYER_DETAIL_ROUTE}${player.id}`)}
                        style={{ fontWeight: 'bold', color: COLORS.purple, cursor: 'pointer' }}
                    >
                        {player.name}
                    </p>
                )
            },
        },
    ]

    const data = useMemo(() => {
        return props.data
    }, [props.data])

    const actions = useMemo(() => {
        const actions = [
            (player: PlayerNetwork) => ({
                icon: 'more_vert',
                onClick: () => {
                    props.selectNetwork(player)
                },
            }),
        ]

        return actions
    }, [props.data])

    return (
        <FixedTableTheme>
            <MaterialTable
                columns={columns}
                data={data}
                actions={actions}
                title=""
                localization={getLangTable(graphic.currentLanguage as Languages)}
                options={{
                    actionsColumnIndex: -1,
                    search: false,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 50],
                    actionsCellStyle: {
                        border: '0',
                    },
                    toolbar: false,
                }}
                style={{
                    boxShadow: 'none',
                }}
            />
        </FixedTableTheme>
    )
}

export default PlayersTable
