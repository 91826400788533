import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Reducers, ReducerData } from '../../types/reducers'
import Icon from '../Icon'
import NotificationPopover from './NotificationPopover/NotificationPopover'

const NotificationIcon: React.FC = () => {
    const data = useSelector<Reducers, ReducerData>((state) => state.data)
    const [anchorNotifEl, setAnchorNotifEl] = useState<HTMLElement | null>(null)
    const [timeoutAlert, setTimeoutAlert] = useState(false)

    const startTimeoutAlert = async () => {
        setAnchorNotifEl(document.getElementById('notification-icon'))
        setTimeoutAlert(true)
        await setTimeout(() => {
            setAnchorNotifEl(null)
            setTimeoutAlert(false)
        }, 2000)
    }

    useEffect(() => {
        if (!anchorNotifEl && data.notifications.length > 0) {
            void startTimeoutAlert()
        }
    }, [data.notifications])

    const handlePopover = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorNotifEl(event.currentTarget)
    }

    return (
        <div
            className="sidebar-container_profile--notification"
            id="notification-icon"
            onClick={(e) => handlePopover(e)}
        >
            {data.notifications.length > 0 && !anchorNotifEl ? (
                <div className="sidebar-container_profile--notification-number">{data.notifications.length}</div>
            ) : null}
            <Icon name="notifications" color="rgba(82,86,90,0.5)" size={30} />
            {anchorNotifEl ? (
                <NotificationPopover
                    isTimeOut={timeoutAlert}
                    anchorElem={anchorNotifEl}
                    onClose={(e) => {
                        e.stopPropagation()
                        setAnchorNotifEl(null)
                    }}
                />
            ) : null}
        </div>
    )
}

export default NotificationIcon
