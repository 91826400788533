import React, { useEffect, useState } from 'react'
import Button from '../../../components/Button/Button'
import Loader from '../../../components/Loader/Loader'
import DeleteModal from '../../../components/Modals/DeleteModal'
import ActionsModal from '../../../components/Modals/ActionsModal'
import StreamsTable from '../../../components/Tables/StreamsTable'
import TextInput from '../../../components/TextInput/TextInput'
import {
    associateRadioStream,
    getRadioDetail,
    getStreamDetail,
    getStreams,
    getStreamsOffline,
} from '../../../resources/api-constants'
import { COLORS } from '../../../resources/colors'
import { Stream, StreamOffline } from '../../../types/data'
import FmWorldAxios from '../../../utility/FmWorldAxios'
import StreamModal from '../../../components/Modals/StreamModal'
import { VoidStream, voidStream } from '../../../utility/voidConstants'
import LoadingModal from '../../../components/Modals/LoadingModal'
import { Grants } from '../../../utility/Grants'
import StreamFilter, { FilterStreams } from '../../../components/Filters/StreamFilter'
import { useTranslation } from 'react-i18next'

interface Props {
    idRadio: number
    radioName: string
    permissions: Grants[]
}

const initialFilter = {
    query: '',
    offlineOnly: false,
}

const StreamsTab: React.FC<Props> = (props) => {
    const [filter, setFilter] = useState<FilterStreams>(initialFilter)
    const [isLoading, setIsLoading] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)
    const [isCreating, setIsCreating] = useState(false)
    const [error, setError] = useState('')
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const [streams, setStreams] = useState<Stream[]>([])
    const [filteredStreams, setFilteredStreams] = useState<Stream[]>([])
    const [selectedStream, setSelectedStream] = useState<Stream | null>(null)
    const [streamToDelete, setStreamToDelete] = useState<Stream | null>(null)
    const [streamToEdit, setStreamToEdit] = useState<Stream | VoidStream | null>(null)
    const { t } = useTranslation()

    const fetchRadioDetail = async () => {
        setError('')
        setIsLoading(true)
        try {
            let streamsFetch
            if (!filter.offlineOnly) {
                streamsFetch = await FmWorldAxios.get(getRadioDetail(props.idRadio)).then((res) => {
                    return res.data.streams
                })
            } else {
                streamsFetch = await FmWorldAxios.get(getStreamsOffline(), {
                    params: { id_radio: props.idRadio, offline: true },
                }).then((res) => {
                    return res.data.items.map((s: StreamOffline) => {
                        return {
                            id: s.stream.id,
                            idLanguage: s.stream.idLanguage,
                            url: s.stream.url,
                            isVideo: s.stream.isVideo,
                            serverType: s.stream.serverType,
                            isOnline: s.stream.isOnline,
                            bitrate: s.stream.bitrate,
                            createdOn: s.stream.createdOn,
                            updatedOn: s.stream.updatedOn,
                        }
                    })
                })
            }
            setStreams(streamsFetch)
        } catch (error: any) {
            console.log('ERROR', error)
            setError(error.toString())
        }
        setIsLoading(false)
    }

    useEffect(() => {
        void fetchRadioDetail()
    }, [props.idRadio])

    useEffect(() => {
        if (filter.query) {
            const filtered = streams.filter((s) => s.url.includes(filter.query))
            setFilteredStreams(filtered)
        } else {
            setFilteredStreams(streams)
        }
    }, [filter.query, streams])

    useEffect(() => {
        void fetchRadioDetail()
    }, [filter.offlineOnly])

    const actionsModal = () => {
        const actions = []
        if (props.permissions.includes(Grants.EDIT_RADIO)) {
            actions.push({
                text: t('radios.edit_stream'),
                color: COLORS.purple,
                onClick: () => setStreamToEdit(selectedStream),
            })
            actions.push({
                text: t('radios.delete_stream'),
                color: COLORS.redDelete,
                onClick: () => setStreamToDelete(selectedStream),
            })
        }
        return actions
    }

    if (isLoading) {
        return (
            <div className="loadingGrid">
                <Loader />
            </div>
        )
    }

    const handleDeleteStream = async () => {
        if (streamToDelete) {
            setError('')
            setIsLoading(true)
            try {
                await FmWorldAxios.delete(getStreamDetail(streamToDelete.id))
                await fetchRadioDetail()
                setStreamToDelete(null)
                setSelectedStream(null)
            } catch (error: any) {
                console.log('ERROR', error)
                setError(error.toString())
                setIsLoading(false)
            }
        }
    }

    const handleUpdateStream = async (newStream: Stream) => {
        if (streamToEdit) {
            setError('')
            setIsUpdating(true)
            try {
                const streamToPass: any = { ...newStream }
                delete streamToPass.id
                delete streamToPass.updatedOn
                delete streamToPass.createdOn
                delete streamToPass.tableData
                await FmWorldAxios.put(getStreamDetail((streamToEdit as Stream).id), streamToPass)
                setIsUpdating(false)
                setStreamToEdit(null)
                setSelectedStream(null)
                await fetchRadioDetail()
            } catch (error: any) {
                console.log('ERROR', error)
                setError(error.toString())
                setIsUpdating(false)
            }
        }
    }

    const handleCreateStream = async (newStream: VoidStream) => {
        if (streamToEdit) {
            setError('')
            setIsCreating(true)
            try {
                const newId: number = await FmWorldAxios.post(getStreams(), newStream).then((res) => {
                    return res.data.id
                })
                await FmWorldAxios.post(associateRadioStream(props.idRadio, newId))
                setIsCreating(false)
                setStreamToEdit(null)
                await fetchRadioDetail()
            } catch (error: any) {
                console.log('ERROR', error)
                setError(error.toString())
                setIsCreating(false)
            }
        }
    }

    const handleFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    return (
        <div>
            <div className="titleRow">
                <div style={{ flex: 1 }}>
                    <h2 className="title">{t('radios.streams')}</h2>
                    <h3 className="subtitle" style={{ fontWeight: 400 }}>
                        {t('radios.manage_streams')}
                    </h3>
                    {error && <p className="u-normal-text u-bold-text u-error-text">{error}</p>}
                </div>
                <div style={{ display: 'flex', flex: 2, gap: 10, alignItems: 'center', justifyContent: 'flex-end' }}>
                    <div style={{ flex: 2 }}>
                        <TextInput
                            value={filter.query}
                            placeholder={t('general.search')}
                            icon="search"
                            iconPosition="right"
                            onTextChange={(v) => setFilter({ ...filter, query: v })}
                        />
                    </div>
                    <div style={{ flex: 1 }}>
                        <Button
                            label={t('general.filter')}
                            onPress={(event) => {
                                handleFilter(event)
                            }}
                            type="submit"
                            background="#fff"
                            border={`2px solid ${
                                JSON.stringify(filter) !== JSON.stringify(initialFilter) ? COLORS.green : COLORS.purple
                            }`}
                            color={
                                JSON.stringify(filter) !== JSON.stringify(initialFilter) ? COLORS.green : COLORS.purple
                            }
                        />
                        <StreamFilter
                            filter={filter}
                            anchorEl={anchorEl}
                            onClose={() => setAnchorEl(null)}
                            onReset={() => setFilter(initialFilter)}
                            onUpdate={(f) => setFilter({ ...f, offlineOnly: f.offlineOnly })}
                        />
                    </div>
                    <div style={{ flex: 1 }}>
                        <Button
                            label={t('general.refresh')}
                            onPress={() => {
                                setFilter({ ...filter, query: '' })
                                void fetchRadioDetail()
                            }}
                            type="submit"
                            background="rgba(100,38,119,0.14)"
                            border={`2px solid ${COLORS.purple}`}
                            color={COLORS.purple}
                        />
                    </div>
                    {props.permissions.includes(Grants.EDIT_RADIO) ? (
                        <div style={{ flex: 1 }}>
                            <Button
                                label={t('general.add')}
                                onPress={() => {
                                    setStreamToEdit(voidStream)
                                }}
                                type="submit"
                            />
                        </div>
                    ) : null}
                </div>
            </div>
            <StreamsTable
                data={filteredStreams}
                selectStream={(stream) => setSelectedStream(stream)}
                showActions={actionsModal().length > 0}
                radioName={props.radioName}
            />
            <ActionsModal
                isVisible={selectedStream !== null}
                title={t('general.what_to_do')}
                onClose={() => setSelectedStream(null)}
                actions={actionsModal()}
            />
            <StreamModal
                isVisible={streamToEdit !== null}
                onClose={() => setStreamToEdit(null)}
                stream={streamToEdit as Stream}
                onSave={(newElement: Stream | VoidStream) => {
                    if ((newElement as Stream).id) {
                        void handleUpdateStream(newElement as Stream)
                    } else {
                        void handleCreateStream(newElement)
                    }
                }}
            />
            <LoadingModal
                isVisible={isCreating || isUpdating}
                text={isUpdating ? t('loadings.updating_stream') : t('loadings.creating_stream')}
            />
            <DeleteModal
                onDelete={() => {
                    void handleDeleteStream()
                }}
                title={`${t('radios.delete_stream')} ${streamToDelete?.url}`}
                text={`${t('radios.want_delete_stream')} ${streamToDelete?.url}?`}
                onClose={() => {
                    setStreamToDelete(null)
                }}
                isVisible={streamToDelete !== null}
            />
        </div>
    )
}

export default StreamsTab
