import React, { useEffect, useState } from 'react'
import './Modals.sass'
import Modal from '@mui/material/Modal'
import { Mux } from '../../types/data'
import Button from '../Button/Button'
import { COLORS } from '../../resources/colors'
import SelectInput, { Option } from '../SelectInput/SelectInput'
import MuxFilter, { MuxFilterObject } from '../Filters/MuxFilter'
import FmWorldAxios from '../../utility/FmWorldAxios'
import { getMuxList } from '../../resources/api-constants'
import { useTranslation } from 'react-i18next'
import LoadingModal from './LoadingModal'

interface Props {
    isVisible: boolean
    selectedMuxs: Mux[]
    onClose: () => void
    onSave: (newElement: Option) => void
}

const initialFilter = {
    query: '',
    countries: [],
    cities: [],
    radios: [],
}

const RadioDabModal: React.FC<Props> = (props) => {
    const [selectedMux, setSelectedMux] = useState<Option | null>(null)
    const [muxs, setMuxs] = useState<Option[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [filter, setFilter] = useState<MuxFilterObject>(initialFilter)
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const { t } = useTranslation()

    const getParams = () => {
        const params: any = {}
        if (filter.cities.length > 0) {
            params.cities = filter.cities
                .map((c) => {
                    return c.id
                })
                .toString()
        }
        if (filter.radios.length > 0) {
            params.radios = filter.radios
                .map((r) => {
                    return r.id
                })
                .toString()
        }
        if (filter.countries.length > 0) {
            params.countries = filter.countries.toString()
        }
        return params
    }

    const fetchMuxList = async () => {
        setIsLoading(true)
        try {
            const params = getParams()
            const muxFetch = await FmWorldAxios.get(getMuxList(), { params: { ...params } }).then((res) => {
                return res.data.items
            })
            console.log(muxFetch)
            const filtered = muxFetch
                .filter((m: Mux) => props.selectedMuxs.find((p) => p.id === m.id) === undefined)
                .map((m: Mux) => {
                    return {
                        id: m.id,
                        value: m.id,
                        label: m.name,
                    }
                })

            setMuxs(filtered)
        } catch (error: any) {
            console.log('ERROR', error)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        if (props.isVisible) {
            setSelectedMux(null)
            void fetchMuxList()
        }
    }, [props.isVisible])

    const handleFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    return (
        <Modal
            open={props.isVisible}
            onClose={() => props.onClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className="modalBox actionBox">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 15 }}>
                    <h2 className="modalBox-title" style={{ flex: 2 }}>
                        {t('radios.associate_mux')}
                    </h2>
                    <div style={{ flex: 1 }}>
                        <Button
                            label={t('dabs.filter_muxs')}
                            onPress={(event) => {
                                handleFilter(event)
                            }}
                            type="submit"
                            background="#fff"
                            border={`2px solid ${
                                JSON.stringify(filter) !== JSON.stringify(initialFilter) ? COLORS.green : COLORS.purple
                            }`}
                            color={
                                JSON.stringify(filter) !== JSON.stringify(initialFilter) ? COLORS.green : COLORS.purple
                            }
                        />
                        <MuxFilter
                            filter={filter}
                            anchorEl={anchorEl}
                            hideRadio
                            onClose={() => setAnchorEl(null)}
                            onReset={() => {
                                setFilter(initialFilter)
                            }}
                            onUpdate={(f) => setFilter(f)}
                            position={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                        />
                    </div>
                </div>
                <div>
                    <SelectInput
                        label={t('dabs.mux')}
                        placeholder={t('placeholders.select_mux')}
                        value={selectedMux}
                        options={muxs}
                        onChange={(value) => {
                            const v = value as Option
                            setSelectedMux(v)
                        }}
                    />
                    <div className="modalBox-buttonsRow">
                        <div style={{ flex: '1 1 100%' }} />
                        <Button
                            label="Annulla"
                            border={'1px solid rgba(0,0,0,0.07)'}
                            onPress={() => props.onClose()}
                            type="secondary"
                            color={COLORS.darkGrey}
                            background="#fff"
                        />
                        <Button
                            disabled={!selectedMux}
                            label={t('general.add')}
                            onPress={() => props.onSave(selectedMux as Option)}
                            type="submit"
                            color="#fff"
                            background={COLORS.purple}
                        />
                    </div>
                </div>
                <LoadingModal isVisible={isLoading} text={t('loadings.generic_loading')} />
            </div>
        </Modal>
    )
}

export default RadioDabModal
