import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Button from '../../../components/Button/Button'
import RadioNetworksGrid from '../../../components/Grids/RadioNetworksGrid'
import { COLORS } from '../../../resources/colors'
import { ROUTES } from '../../../resources/routes-constants'
import { Grants } from '../../../utility/Grants'
import { useSelector } from 'react-redux'
import { Reducers, ReducerData } from '../../../types/reducers'

interface Props {
    permissions: Grants[]
}

const RadiosTab: React.FC<Props> = (props) => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const data = useSelector<Reducers, ReducerData>((state) => state.data)
    const selectedNetwork = data.selectedNetwork

    return (
        <div style={{ flex: 1 }}>
            <div className="titleRow">
                <div style={{ flex: 1 }}>
                    <h2 className="title">{t('radios.your_radios')}</h2>
                    <h3 className="subtitle" style={{ fontWeight: 400 }}>
                        {t('radios.manage_radios')}
                    </h3>
                </div>
                <div style={{ display: 'flex', flex: 2, gap: 10, alignItems: 'center', justifyContent: 'flex-end' }}>
                    <div style={{ flex: 2 }}>
                        {/* <TextInput
                            value={searchInput}
                            placeholder={t('general.search')}
                            icon="search"
                            iconPosition="right"
                            onTextChange={(v) => {
                                setSearchInput(v)
                            }}
                        /> */}
                    </div>
                    <div style={{ flex: 1 }}>
                        <Button
                            label={t('general.refresh')}
                            onPress={() => {
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                radioGridRef.current?.reloadData()
                            }}
                            type="submit"
                            background="rgba(100,38,119,0.14)"
                            border={`2px solid ${COLORS.purple}`}
                            color={COLORS.purple}
                        />
                    </div>
                    {props.permissions.includes(Grants.CREATE_RADIO) ? (
                        <div style={{ flex: 1 }}>
                            <Button
                                label={t('general.add')}
                                onPress={() => {
                                    navigate(`${ROUTES.CREATE_NEW_RADIO_ROUTE}`, {
                                        state: { idNetwork: selectedNetwork?.id },
                                    })
                                }}
                                type="submit"
                                hidden
                            />
                        </div>
                    ) : null}
                </div>
            </div>
            <RadioNetworksGrid idNetwork={selectedNetwork?.id} permissions={props.permissions} />
        </div>
    )
}

export default RadiosTab
