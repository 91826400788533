import { FmWorldBaseUrl, refreshToken } from '../resources/api-constants'
import axios, { AxiosError } from 'axios'
import { toCamelCase, toSnackCase } from './functions'
import { setLoggedUserData } from '../store/actions/user'
import { store } from '../store/reducers'
import { ReducerUser } from '../types/reducers'

const FmWorldAxios = axios.create({
    baseURL: FmWorldBaseUrl,
    headers: { 'Content-Type': 'application/json' },
})

FmWorldAxios.interceptors.response.use(
    (response) => {
        response.data = toCamelCase(response.data)
        return response
    },
    async (responseError) => {
        const error = responseError as AxiosError<{ message?: string }>
        const message = error.response?.data?.message
        if (message === 'auth/id-token-expired') {
            try {
                const user = store.getState().user as ReducerUser
                console.log('user', { refresh_token: user.user?.refreshToken })
                const tokenData: { access_token: string; refresh_token: string } = await axios
                    .post(refreshToken(), { refresh_token: user.user?.refreshToken })
                    .then((res) => res.data)

                if (user.user)
                    // eslint-disable-next-line nonblock-statement-body-position
                    store.dispatch(
                        setLoggedUserData({
                            ...user.user,
                            authToken: tokenData.access_token,
                            refreshToken: tokenData.refresh_token,
                        })
                    )

                return axios.request({
                    ...error.config,
                    headers: {
                        ...(error.config?.headers || {}),
                        Authorization: `Bearer ${tokenData.access_token}`,
                    },
                })
            } catch (tokenError) {
                console.error('Error on token refresh:', tokenError)
                return Promise.reject(tokenError)
            }
        }

        return Promise.reject(error)
    }
)

FmWorldAxios.interceptors.request.use(
    (config) => {
        config.data = toSnackCase(config.data)

        const user = store.getState().user as ReducerUser
        const token = user.user?.authToken
        if (token && config.headers) {
            config.headers.Authorization = `Bearer ${token}`
        }
        return config
    },
    async (error) => {
        return Promise.reject(error)
    }
)

export default FmWorldAxios
