import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Button from '../../components/Button/Button'
import CardContainer from '../../components/CardContainer/CardContainer'
import MuxFilter, { MuxFilterObject } from '../../components/Filters/MuxFilter'
import Loader from '../../components/Loader/Loader'
import ActionsModal from '../../components/Modals/ActionsModal'
import DeleteModal from '../../components/Modals/DeleteModal'
import LoadingModal from '../../components/Modals/LoadingModal'
import MuxModal from '../../components/Modals/MuxModal'
import PageSidebarContainer from '../../components/PageSidebarContainer'
import MuxTable from '../../components/Tables/MuxTable'
import TextInput from '../../components/TextInput/TextInput'
import { getMuxDetail, getMuxList } from '../../resources/api-constants'
import { COLORS } from '../../resources/colors'
import { ROUTES } from '../../resources/routes-constants'
import { setLastSearchMux, setPageHeader } from '../../store/actions/graphic'
import { fetchDab } from '../../store/actions/thunk_actions'
import { Mux } from '../../types/data'
import { Reducers, ReducerGraphic, ReducerUser } from '../../types/reducers'
import FmWorldAxios from '../../utility/FmWorldAxios'
import { voidMux, VoidMux } from '../../utility/voidConstants'

const initialFilter = {
    query: '',
    countries: [],
    cities: [],
    radios: [],
}

const DabListPage: React.FC = () => {
    const graphic = useSelector<Reducers, ReducerGraphic>((state) => state.graphic)
    const [filter, setFilter] = useState<MuxFilterObject>(graphic.lastMuxSearch ? graphic.lastMuxSearch : initialFilter)
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const [isLoading, setIsLoading] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)
    const [isCreating, setIsCreating] = useState(false)
    const [error, setError] = useState('')
    const [muxs, setMuxs] = useState<Mux[]>([])
    const [filteredMuxs, setFilteredMuxs] = useState<Mux[]>([])
    const [selectedMux, setSelectedMux] = useState<Mux | null>(null)
    const [muxToDelete, setMuxToDelete] = useState<Mux | null>(null)
    const [muxToEdit, setMuxToEdit] = useState<Mux | VoidMux | null>(null)
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation()

    useEffect(() => {
        dispatch(
            setPageHeader({
                parent: null,
                name: t('headers.dabs'),
            })
        )
    }, [])

    const getParams = () => {
        const params: any = {}
        if (filter.cities.length > 0) {
            params.cities = filter.cities
                .map((c) => {
                    return c.id
                })
                .toString()
        }
        if (filter.radios.length > 0) {
            params.radios = filter.radios
                .map((r) => {
                    return r.id
                })
                .toString()
        }
        if (filter.countries.length > 0) {
            params.countries = filter.countries.toString()
        }
        return params
    }

    const fetchMuxList = async () => {
        setError('')
        setIsLoading(true)
        try {
            const params = getParams()
            const muxFetch = await FmWorldAxios.get(getMuxList(), { params: { ...params } }).then((res) => {
                return res.data.items
            })
            setMuxs(muxFetch)
        } catch (error: any) {
            console.log('ERROR', error)
            setError(error.toString())
        }
        setIsLoading(false)
    }

    useEffect(() => {
        if (user.user) {
            void fetchMuxList()
        }
    }, [user.user])

    useEffect(() => {
        if (filter.query.length > 2) {
            const filtered = muxs.filter(
                (n) =>
                    n.name.toLowerCase().includes(filter.query.toLowerCase()) ||
                    n.code.toLowerCase().includes(filter.query.toLowerCase())
            )
            setFilteredMuxs(filtered)
        } else {
            setFilteredMuxs(muxs)
        }
        dispatch(setLastSearchMux(filter))
    }, [filter.query, muxs])

    useEffect(() => {
        void fetchMuxList()
        dispatch(setLastSearchMux(filter))
    }, [filter.cities, filter.radios, filter.countries])

    const actionsModal = [
        {
            text: t('dabs.view_mux'),
            color: COLORS.blueGrey,
            onClick: () =>
                navigate(`${ROUTES.DAB_DETAIL_ROUTE}${selectedMux?.id}`, {
                    state: { name: selectedMux?.name },
                }),
        },
        {
            text: t('dabs.edit_mux'),
            color: COLORS.purple,
            onClick: () => {
                setSelectedMux(null)
                setMuxToEdit(selectedMux)
            },
        },
        {
            text: t('dabs.delete_mux'),
            color: COLORS.redDelete,
            onClick: () => {
                setSelectedMux(null)
                setMuxToDelete(selectedMux)
            },
        },
    ]

    const handleUpdateMux = async (mux: Mux) => {
        setError('')
        setIsUpdating(true)
        try {
            const updated = {
                name: mux.name,
                code: mux.code,
            }
            await FmWorldAxios.put(getMuxDetail(mux.id), updated)
            setSelectedMux(null)
            setMuxToEdit(null)
            await fetchMuxList()
        } catch (error: any) {
            console.log('ERROR', error)
            setError(error.toString())
        }
        setIsUpdating(false)
    }

    const handleCreateMux = async (mux: VoidMux) => {
        setError('')
        setIsCreating(true)
        try {
            await FmWorldAxios.post(getMuxList(), mux)
            dispatch(fetchDab())
            setSelectedMux(null)
            setMuxToDelete(null)
            await fetchMuxList()
        } catch (error: any) {
            console.log('ERROR', error)
            setError(error.toString())
        }
        setIsCreating(false)
    }

    const handleDeleteNetwork = async () => {
        if (muxToDelete) {
            setError('')
            setIsLoading(true)
            try {
                await FmWorldAxios.delete(getMuxDetail(muxToDelete.id))
                dispatch(fetchDab())
                setMuxToDelete(null)
                setSelectedMux(null)
                await fetchMuxList()
            } catch (error: any) {
                console.log('ERROR', error)
                setError(error.toString())
            }
            setIsLoading(false)
        }
    }

    const handleFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    return (
        <PageSidebarContainer>
            <CardContainer>
                <div className="titleRow">
                    <div style={{ flex: 1 }}>
                        <h2 className="title">{t('dabs.your_muxs')}</h2>
                        <h3 className="subtitle" style={{ fontWeight: 400 }}>
                            {t('dabs.manage_muxs')}
                        </h3>
                        {error && <p className="u-normal-text u-bold-text u-error-text">{error}</p>}
                    </div>
                    <div
                        style={{ display: 'flex', flex: 2, gap: 10, alignItems: 'center', justifyContent: 'flex-end' }}
                    >
                        <div style={{ flex: 2 }}>
                            <TextInput
                                value={filter.query}
                                placeholder={t('general.search')}
                                icon="search"
                                iconPosition="right"
                                onTextChange={(v) => setFilter({ ...filter, query: v })}
                            />
                        </div>
                        <div style={{ flex: 1 }}>
                            <Button
                                label={t('general.filter')}
                                onPress={(e) => {
                                    handleFilter(e)
                                }}
                                type="submit"
                                background="#fff"
                                border={`2px solid ${
                                    JSON.stringify(filter) !== JSON.stringify(initialFilter)
                                        ? COLORS.green
                                        : COLORS.purple
                                }`}
                                color={
                                    JSON.stringify(filter) !== JSON.stringify(initialFilter)
                                        ? COLORS.green
                                        : COLORS.purple
                                }
                            />
                            <MuxFilter
                                filter={filter}
                                anchorEl={anchorEl}
                                onClose={() => setAnchorEl(null)}
                                onReset={() => setFilter(initialFilter)}
                                onUpdate={(f) =>
                                    setFilter({
                                        ...filter,
                                        radios: f.radios,
                                        cities: f.cities,
                                        countries: f.countries,
                                    })
                                }
                            />
                        </div>
                        <div style={{ flex: 1 }}>
                            <Button
                                label={t('general.refresh')}
                                onPress={() => {
                                    return
                                }}
                                type="submit"
                                background="rgba(100,38,119,0.14)"
                                border={`2px solid ${COLORS.purple}`}
                                color={COLORS.purple}
                            />
                        </div>
                        <div style={{ flex: 1 }}>
                            <Button
                                label={t('general.add')}
                                onPress={() => {
                                    setMuxToEdit(voidMux)
                                }}
                                type="submit"
                            />
                        </div>
                    </div>
                </div>
                {isLoading ? (
                    <div className="loadingGrid">
                        <Loader />
                    </div>
                ) : (
                    <div style={{ alignItems: 'flex-start', marginTop: 20 }}>
                        <MuxTable data={filteredMuxs} selectMux={(m) => setSelectedMux(m)} />
                    </div>
                )}
            </CardContainer>
            <ActionsModal
                isVisible={selectedMux !== null}
                title={t('general.what_to_do')}
                onClose={() => setSelectedMux(null)}
                actions={actionsModal}
            />
            <MuxModal
                isVisible={muxToEdit !== null}
                onClose={() => setMuxToEdit(null)}
                onSave={(mux) => {
                    if ((mux as Mux).id) {
                        void handleUpdateMux(mux as Mux)
                    } else {
                        void handleCreateMux(mux as VoidMux)
                    }
                }}
                mux={muxToEdit}
            />
            <DeleteModal
                onDelete={() => {
                    void handleDeleteNetwork()
                }}
                title={`${t('muxs.delete_this_mux')} ${muxToDelete?.name}`}
                text={`${t('muxs.want_delete_mux')} ${muxToDelete?.name}?`}
                onClose={() => {
                    setMuxToDelete(null)
                }}
                isVisible={muxToDelete !== null}
            />
            <LoadingModal isVisible={isCreating || isUpdating} text={t('loadings.generic_loading')} />
        </PageSidebarContainer>
    )
}

export default DabListPage
