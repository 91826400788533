import React, { useEffect, useState } from 'react'
import './Modals.sass'
import Modal from '@mui/material/Modal'
import { Stream } from '../../types/data'
import TextInput from '../TextInput/TextInput'
import Button from '../Button/Button'
import { COLORS } from '../../resources/colors'
import SelectInput, { Option } from '../SelectInput/SelectInput'
import CheckboxInput from '../CheckboxInput/CheckboxInput'
import { VoidStream } from '../../utility/voidConstants'
import { useTranslation } from 'react-i18next'
import { isValidUrl } from '../../utility/functions'

const ServerTypes = ['Icecast', 'Shoutcast', 'HLS', 'MPEG-DASH']

interface Props {
    isVisible: boolean
    onClose: () => void
    onSave: (newElement: Stream | VoidStream) => void
    stream: Stream | VoidStream | null
}

const StreamModal: React.FC<Props> = (props) => {
    const [streamToEdit, setStreamToEdit] = useState<Stream | VoidStream | null>(props.stream)
    const { t } = useTranslation()

    useEffect(() => {
        if (props.isVisible) {
            setStreamToEdit(props.stream)
        }
    }, [props.isVisible])

    return (
        <Modal
            open={props.isVisible}
            onClose={() => props.onClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className="modalBox actionBox">
                <h2 className="modalBox-title">
                    {streamToEdit && (streamToEdit as Stream).id ? t('radios.edit_stream') : t('radios.create_stream')}
                </h2>
                {streamToEdit && (
                    <div>
                        <TextInput
                            value={streamToEdit.url}
                            label={t('general.url')}
                            onTextChange={(e) => {
                                setStreamToEdit({
                                    ...streamToEdit,
                                    url: e,
                                })
                            }}
                        />
                        <div style={{ margin: '20px 0' }} className="u-elements-row">
                            <SelectInput
                                label={t('radios.server')}
                                value={{
                                    id: streamToEdit.serverType,
                                    value: streamToEdit.serverType,
                                    label: streamToEdit.serverType,
                                }}
                                options={ServerTypes.map((s) => ({
                                    id: s,
                                    value: s,
                                    label: s,
                                }))}
                                onChange={(value) => {
                                    const v = value as Option
                                    setStreamToEdit({
                                        ...streamToEdit,
                                        serverType: v.label,
                                    })
                                }}
                            />
                            <TextInput
                                value={streamToEdit.priority.toString() || '0'}
                                label={t('radios.priority')}
                                type="number"
                                onTextChange={(e) => {
                                    setStreamToEdit({
                                        ...streamToEdit,
                                        priority: e ? parseInt(e, 10) : 0,
                                    })
                                }}
                                isHidden
                            />
                        </div>
                        <CheckboxInput
                            values={[
                                {
                                    label: t('radios.audio'),
                                    status: !streamToEdit.isVideo,
                                },
                                {
                                    label: t('radios.video'),
                                    status: streamToEdit.isVideo,
                                },
                            ]}
                            onClick={(value) => {
                                if (value.label === (t('radios.audio') as string)) {
                                    setStreamToEdit({
                                        ...streamToEdit,
                                        isVideo: false,
                                    })
                                } else {
                                    setStreamToEdit({
                                        ...streamToEdit,
                                        isVideo: true,
                                    })
                                }
                            }}
                            label={t('radios.typology_stream')}
                        />
                    </div>
                )}
                <div className="modalBox-buttonsRow">
                    <div style={{ flex: '1 1 100%' }} />
                    <Button
                        label={t('general.cancel')}
                        border={'1px solid rgba(0,0,0,0.07)'}
                        onPress={() => props.onClose()}
                        type="secondary"
                        color={COLORS.darkGrey}
                        background="#fff"
                    />
                    <Button
                        disabled={
                            !streamToEdit ||
                            !streamToEdit?.url ||
                            (streamToEdit.url !== undefined && !isValidUrl(streamToEdit.url))
                        }
                        label={streamToEdit && (streamToEdit as Stream).id ? t('general.update') : t('general.add')}
                        onPress={() => {
                            if (streamToEdit) {
                                props.onSave({ ...streamToEdit, url: streamToEdit.url.trim() } as Stream)
                            }
                        }}
                        type="submit"
                        color="#fff"
                        background={COLORS.purple}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default StreamModal
