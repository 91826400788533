import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../../components/Button/Button'
import Loader from '../../../components/Loader/Loader'
import LoadingModal from '../../../components/Modals/LoadingModal'
import OnAirMeta from '../../../components/ServiceTabs/OnAirMeta'
import Xperi from '../../../components/ServiceTabs/Xperi'
import { getRadioDetail } from '../../../resources/api-constants'
import { COLORS } from '../../../resources/colors'
import { Radio } from '../../../types/data'
import FmWorldAxios from '../../../utility/FmWorldAxios'
import FmWorldMultipartAxios from '../../../utility/FmWorldMultipartAxios'
import { Grants } from '../../../utility/Grants'

interface Props {
    idRadio: number
    permissions: Grants[]
}

const ServicesTab: React.FC<Props> = (props) => {
    const [error, setError] = useState('')
    const [currentRadio, setCurrentRadio] = useState<Radio | null>(null)
    const [isLoading, setIsLoading] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)
    const { t } = useTranslation()

    const fetchRadioDetail = async () => {
        setError('')
        setIsLoading(true)
        try {
            const radioName = await FmWorldAxios.get(getRadioDetail(props.idRadio)).then((res) => {
                return res.data
            })
            setCurrentRadio(radioName)
        } catch (error: any) {
            console.log('ERROR', error)
            setError(error.toString())
        }
        setIsLoading(false)
    }

    useEffect(() => {
        void fetchRadioDetail()
    }, [props.idRadio])

    if (isLoading) {
        return (
            <div className="loadingGrid">
                <Loader />
            </div>
        )
    }

    const updateRadio = async (r: Radio) => {
        if (currentRadio) {
            setError('')
            setIsUpdating(true)
            try {
                const updatedRadio = {
                    ...currentRadio,
                    categories: currentRadio?.categories.map((c) => {
                        return c.id
                    }),
                    tags: currentRadio?.tags?.map((t) => {
                        // Add the nullish coalescing operator here
                        return t.id
                    }),
                    metadataUrl: r.metadataUrl,
                    metadataParser: r.metadataParser,
                }
                const formData = new FormData()
                Object.keys(updatedRadio).map((key) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    const currentField = updatedRadio[key as any]
                    formData.append(key, Array.isArray(currentField) ? JSON.stringify(currentField) : currentField)
                    return
                })
                await FmWorldMultipartAxios.put(getRadioDetail(props.idRadio), formData)
            } catch (error: any) {
                console.log('ERROR', error)
                setError(error.toString())
            }
            setIsUpdating(false)
        }
    }

    const handleLinkRadio = async () => {
        if (currentRadio) {
            setError('')
            setIsUpdating(true)
            try {
                const updatedRadio = {
                    ...currentRadio,
                    metadataMonitor: !currentRadio.metadataMonitor,
                    categories: currentRadio?.categories.map((c) => {
                        return c.id
                    }),
                    tags: currentRadio?.tags?.map((t) => {
                        return t.id
                    }),
                }
                const formData = new FormData()
                Object.keys(updatedRadio).map((key) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    const currentField = updatedRadio[key as any]
                    formData.append(key, Array.isArray(currentField) ? JSON.stringify(currentField) : currentField)
                    return
                })
                await FmWorldMultipartAxios.put(getRadioDetail(props.idRadio), formData)
            } catch (error: any) {
                console.log('ERROR', error)
                setError(error.toString())
            }
            setIsUpdating(false)
        }
    }

    return (
        <div>
            <div className="titleRow">
                <div style={{ flex: 1 }}>
                    <h2 className="title">{t('radios.services')}</h2>
                    <h3 className="subtitle" style={{ fontWeight: 400 }}>
                        {t('radios.manage_services')}
                    </h3>
                    {error && <p className="u-normal-text u-bold-text u-error-text">{error}</p>}
                </div>
                <div style={{ display: 'flex', flex: 2, gap: 10, alignItems: 'center', justifyContent: 'flex-end' }}>
                    <div style={{ flex: 3 }} />
                    <div style={{ flex: 1 }}>
                        <Button
                            label={t('general.refresh')}
                            onPress={() => {
                                void fetchRadioDetail()
                            }}
                            type="submit"
                            background="rgba(100,38,119,0.14)"
                            border={`2px solid ${COLORS.purple}`}
                            color={COLORS.purple}
                        />
                    </div>
                </div>
            </div>
            <div className="servicesGrid">
                <Xperi idRadio={props.idRadio} disabled={!props.permissions.includes(Grants.EDIT_RADIO)} />
                <OnAirMeta
                    radio={currentRadio}
                    onUpdate={(r) => {
                        void updateRadio(r)
                    }}
                    onLinking={() => {
                        void handleLinkRadio()
                    }}
                    disabled={!props.permissions.includes(Grants.EDIT_RADIO)}
                />
            </div>
            <LoadingModal isVisible={isUpdating} text={t('loadings.updating_services')} />
        </div>
    )
}

export default ServicesTab
