import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getCities } from '../../resources/api-constants'
import { City } from '../../types/data'
import FmWorldAxios from '../../utility/FmWorldAxios'
import AsyncSelectInput from '../SelectInput/AsyncSelectInput'
import { Option } from '../SelectInput/SelectInput'
import FilterPopover from './FilterPopover'

export interface FilterMuxFrequencies {
    query: string
    provinces: Option[]
}

interface Props {
    filter: FilterMuxFrequencies
    anchorEl: HTMLButtonElement | null
    onClose: () => void
    onReset: () => void
    onUpdate: (f: FilterMuxFrequencies) => void
}

const MuxFrequenciesFilter: React.FC<Props> = (props) => {
    const [filter, setFilter] = useState<FilterMuxFrequencies>(props.filter)
    const { t } = useTranslation()

    useEffect(() => {
        if (props.anchorEl) {
            setFilter(props.filter)
        }
    }, [props.anchorEl])

    return (
        <FilterPopover
            anchorElem={props.anchorEl}
            onResetClick={() => props.onReset()}
            onFilterClick={() => props.onUpdate(filter)}
            onClose={() => props.onClose()}
        >
            <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
                <AsyncSelectInput
                    label={t('general.city')}
                    value={filter.provinces}
                    placeholder={t('placeholders.write_to_select_city')}
                    fetchOptions={async (input: string) => {
                        if (input.length > 2) {
                            const cities = await FmWorldAxios.get(getCities(), {
                                params: { query: input },
                            }).then((res) => {
                                return res.data.items
                            })
                            return cities.map((c: City) => {
                                return {
                                    id: c.id,
                                    value: c.id,
                                    label: c.name,
                                }
                            })
                        }
                        return []
                    }}
                    isMulti
                    onChange={(value) => {
                        const v = value as Option[]
                        setFilter({ ...filter, provinces: v })
                    }}
                />
            </div>
        </FilterPopover>
    )
}

export default MuxFrequenciesFilter
