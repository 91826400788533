import React from 'react'
import '../TextInput/TextInput.sass'
import './CheckboxInput.sass'

interface CheckboxOption {
    label: string
    status: boolean
}

interface Props {
    values: CheckboxOption[]
    onClick: (check: CheckboxOption) => void
    label?: string
    flex?: number
    disabled?: boolean
    isHidden?: boolean
}

const CheckboxInput: React.FC<Props> = (props) => {
    if (props.isHidden) return null
    return (
        <div
            className="c-text-input"
            style={{
                flex: props.flex ? props.flex : 1,
                flexDirection: props.values.length === 1 && !props.values[0].label ? 'row' : 'column',
                alignItems: props.values.length === 1 && !props.values[0].label ? 'center' : 'flex-start',
            }}
        >
            {props.label && <span className="o-label">{props.label}</span>}
            <div className="optionsList">
                {props.values.map((v) => {
                    return (
                        <div className="optionsList-option" key={v.label}>
                            <input
                                type="checkbox"
                                checked={v.status}
                                onChange={() => props.onClick(v)}
                                disabled={props.disabled}
                            />
                            <label>{v.label}</label>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default CheckboxInput
