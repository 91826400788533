import React from 'react'
import { ShowSchedule } from '../../../types/data'
import './Items.sass'

interface Props {
    event: ShowSchedule
}

const ShowScheduleItem: React.FC<Props> = (props) => {
    const event = props.event

    return (
        <div
            className="scheduleItem"
            style={{
                backgroundImage: `linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%), url(${event.image})`,
            }}
        >
            <div className="scheduleItem-content">
                <p className="scheduleItem-content_title">{event.title}</p>
                <p className="scheduleItem-content_subtitle">{event.subtitle}</p>
            </div>
        </div>
    )
}

export default ShowScheduleItem
