import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    createRadioInteractiveMUXItems,
    deleteRadioInteractiveMUXItem,
    editRadioInteractiveMUXItems,
    getRadioInteractiveMUXItems,
} from '../resources/api-constants'
import { InteractiveMuxItem } from '../types/data'
import Loader from '../components/Loader/Loader'
import InteractiveMuxLicensesTable from '../components/Tables/InteractiveMuxLicensesTable'
import PageSidebarContainer from '../components/PageSidebarContainer'
import Button from '../components/Button/Button'
import InteractiveMUXModal from '../components/Modals/InteractiveMUXModal'
import Axios from 'axios'
import { COLORS } from '../resources/colors'
import ActionsModal from '../components/Modals/ActionsModal'
import { setPageHeader } from '../store/actions/graphic'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import FmWorldAxios from '../utility/FmWorldAxios'
const InteractiveMUXPage: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [licenses, setLicenses] = useState<InteractiveMuxItem[]>([])
    const [itemToEdit, setItemToEdit] = useState<InteractiveMuxItem | null>(null)
    const [selectedItem, setSelectedItem] = useState<InteractiveMuxItem | null>(null)
    const [itemModalVisible, setItemModalVisible] = useState<boolean>(false)
    const dispatch = useDispatch()

    const { t } = useTranslation()

    const actionsModal = [
        {
            text: t('interactiveMux.edit'),
            color: COLORS.purple,
            onClick: () => {
                setSelectedItem(null)
                setItemToEdit(selectedItem)
                setItemModalVisible(true)
            },
        },
        {
            text: t('interactiveMux.delete'),
            color: COLORS.redDelete,
            onClick: async () => {
                setSelectedItem(null)
                await deleteLicense(selectedItem)
                setItemToEdit(null)
                setItemModalVisible(false)
            },
        },
    ]

    useEffect(() => {
        dispatch(
            setPageHeader({
                parent: null,
                name: t('headers.interactiveMux'),
            })
        )
        void fetchLicenses()
    }, [])

    const fetchLicenses = async () => {
        setIsLoading(true)
        try {
            console.log('ciaoooo')
            const licenses: InteractiveMuxItem[] = await FmWorldAxios.get(getRadioInteractiveMUXItems()).then((res) => {
                return res.data
            })

            setLicenses(licenses)
        } catch (error: any) {
            console.log('ERROR', error)
        }
        setIsLoading(false)
    }

    const createLicense = async (item: InteractiveMuxItem | null) => {
        setIsLoading(true)
        try {
            await Axios.post(createRadioInteractiveMUXItems(), item)
            setItemModalVisible(false)
            setItemToEdit(null)
            await fetchLicenses()
            setIsLoading(false)
        } catch (error: any) {
            console.log('ERROR', error)
            setIsLoading(false)
        }
    }

    const editLicense = async (item: InteractiveMuxItem | null) => {
        setIsLoading(true)
        try {
            console.log('ITEM', {
                ...item,
                configuration: { ...item?.configuration, LastUpdate: moment().format('YYYYMMDD-HH:mm') },
            })

            await Axios.patch(
                editRadioInteractiveMUXItems(item?.id_radio ?? 0),
                { ...item, configuration: { ...item?.configuration, LastUpdate: moment().format('YYYYMMDD-HH:mm') } },
                {
                    headers: { Authorization: item?.license_code ?? '' },
                }
            )
            setItemModalVisible(false)
            setItemToEdit(null)
            await fetchLicenses()
            setIsLoading(false)
        } catch (error: any) {
            console.log('ERROR', error)
            setIsLoading(false)
        }
    }

    const deleteLicense = async (item: InteractiveMuxItem | null) => {
        setIsLoading(true)
        try {
            await Axios.delete(deleteRadioInteractiveMUXItem(item?.id_radio ?? 0))
            setItemModalVisible(false)
            setItemToEdit(null)
            await fetchLicenses()
            setIsLoading(false)
        } catch (error: any) {
            console.log('ERROR', error)
            setIsLoading(false)
        }
    }

    return (
        <>
            <PageSidebarContainer>
                <ActionsModal
                    isVisible={selectedItem !== null}
                    title={t('general.what_to_do')}
                    onClose={() => setSelectedItem(null)}
                    actions={actionsModal}
                />
                <InteractiveMUXModal
                    isVisible={itemModalVisible}
                    onClose={() => {
                        setItemToEdit(null)
                        setItemModalVisible(false)
                    }}
                    onSave={async (item) => {
                        if (itemToEdit) {
                            await editLicense(item)
                        } else {
                            await createLicense(item)
                        }
                    }}
                    item={itemToEdit}
                />
                <div style={{ flex: 1 }}>
                    <Button
                        label={t('general.add')}
                        onPress={() => {
                            setItemModalVisible(true)
                        }}
                        type="submit"
                    />
                </div>
                {isLoading ? (
                    <div className="loadingGrid">
                        <Loader />
                    </div>
                ) : (
                    <div style={{ alignItems: 'flex-start', marginTop: 20 }}>
                        <InteractiveMuxLicensesTable data={licenses} selectitem={(m) => setSelectedItem(m)} />
                    </div>
                )}
            </PageSidebarContainer>
        </>
    )
}

export default InteractiveMUXPage
