import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../../components/Button/Button'
import Loader from '../../../components/Loader/Loader'
import LoadingModal from '../../../components/Modals/LoadingModal'
import { getRadioInteractiveMUXItem, patchInteractiveMUXConfiguration } from '../../../resources/api-constants'
import { COLORS } from '../../../resources/colors'
import { InteractiveMuxItem, InteractiveMuxItemConfiguration } from '../../../types/data'
import { Grants } from '../../../utility/Grants'
import TextInput from '../../../components/TextInput/TextInput'
import moment from 'moment'
import CheckboxInput from '../../../components/CheckboxInput/CheckboxInput'
import Axios from 'axios'
import { Divider } from '@mui/material'
import { CompactPicker } from 'react-color'
import MUXPreview from '../../../components/MUXPreview/MUXPreview'

interface Props {
    idRadio: number
    radioLogo: string
    radioName: string
    permissions: Grants[]
}

const DEFAULT_INTERACTIVEMUX_ITEM_CONFIGURATION: InteractiveMuxItemConfiguration = {
    RadioName: 'Nome della radio',
    QRData: 'Testo che compone il QR-Code',
    IsPayload: false,
    UseCenterImage: false,
    CenterImageUrl: '',
    OverloadLayout: false,
    OverloadLayoutData: {
        BackgroundColor: 'Colors in HTML notations',
        TextColor: 'Colors in HTML notations',
        CoverEnabled: false,
        CoverTop: 320,
        CoverLeft: 240,
        CoverWidth: 320,
        CoverHeight: 240,
        TextEnabled: false,
        TextWidth: 320,
        TextHeight: 20,
        TextTop: 290,
        TextLeft: 0,
        QRTop: 20,
        QRLeft: 220,
        QRWidth: 80,
        QRHeight: 80,
    },
    StartDateTime: moment().format('YYYYMMDD-HH:mm'),
    EndDateTime: '',
    LastUpdate: '',
}
const InteractiveMUXTab: React.FC<Props> = (props) => {
    const [error, setError] = useState('')
    const [currentItem, setCurrentItem] = useState<InteractiveMuxItem>()
    const [currentConfiguration, setCurrentConfiguration] = useState<InteractiveMuxItemConfiguration>(
        DEFAULT_INTERACTIVEMUX_ITEM_CONFIGURATION
    )
    const [isLoading, setIsLoading] = useState(false)
    const [isUpdating] = useState(false)
    const { t } = useTranslation()

    useEffect(() => {
        void fetchRadioDetail()
    }, [props.idRadio])

    console.log(currentItem)

    const fetchRadioDetail = async () => {
        setError('')
        setIsLoading(true)
        try {
            const interactiveMuxItem: any = await Axios.get(getRadioInteractiveMUXItem(props.idRadio)).then((res) => {
                return res.data
            })
            setCurrentItem(interactiveMuxItem)
            interactiveMuxItem.configuration && setCurrentConfiguration(interactiveMuxItem.configuration)
        } catch (error: any) {
            console.log('ERROR', error)
            setError(error.toString())
        }
        setIsLoading(false)
    }

    const updateConfiguration = async () => {
        console.log(currentItem)
        setIsLoading(true)
        await Axios.patch(
            patchInteractiveMUXConfiguration(props.idRadio),
            {
                ...currentItem,
                configuration: {
                    ...currentConfiguration,
                    LastUpdate: moment().format('YYYYMMDD-HH:mm'),
                    RadioName: props.radioName,
                },
            },
            { headers: { Authorization: currentItem && currentItem.license_code ? currentItem.license_code : '' } }
        ).then((res) => {
            setIsLoading(false)
            return res.data
        })
    }

    if (isLoading) {
        return (
            <div className="loadingGrid">
                <Loader />
            </div>
        )
    }
    return (
        <div>
            <div className="titleRow">
                <div style={{ flex: 1 }}>
                    <h2 className="title">{t('radios.interactiveMUX')}</h2>
                    <h3 className="subtitle" style={{ fontWeight: 400 }}>
                        {t('radios.manage_services')}
                    </h3>
                    {error && <p className="u-normal-text u-bold-text u-error-text">{error}</p>}
                </div>
                <div
                    style={{
                        display: 'flex',
                        flex: 2,
                        gap: 10,
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                    }}
                >
                    <div style={{ flex: 3 }} />
                    <div style={{ flex: 1 }}>
                        <Button
                            label={t('general.refresh')}
                            onPress={() => {
                                void updateConfiguration()
                            }}
                            type="submit"
                            background="rgba(100,38,119,0.14)"
                            border={`2px solid ${COLORS.purple}`}
                            color={COLORS.purple}
                        />
                    </div>
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    flex: 1,
                    gap: 20,
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                }}
            >
                <div className="serviceTab" style={{ height: 600, overflow: 'scroll' }}>
                    <div style={{ margin: '20px 0' }}>
                        <CheckboxInput
                            values={[
                                {
                                    label: '',
                                    status: currentItem && currentItem.status ? currentItem.status : false,
                                },
                            ]}
                            onClick={() => {
                                currentItem && setCurrentItem({ ...currentItem, status: !currentItem?.status })
                            }}
                            label={t('interactiveMux.status')}
                        />
                    </div>
                    <div style={{ margin: '20px 0' }}>
                        <TextInput
                            disabled
                            value={currentItem?.license_code ?? ''}
                            onTextChange={() => console.log()}
                            label={t('interactiveMux.licenseCode')}
                        />
                    </div>
                    <div style={{ margin: '20px 0' }}>
                        <TextInput
                            value={currentConfiguration.QRData ?? ''}
                            onTextChange={(e) => {
                                setCurrentConfiguration({
                                    ...currentConfiguration,
                                    QRData: e,
                                })
                            }}
                            label={t('interactiveMux.qrData')}
                        />
                    </div>

                    <Divider />
                    <div style={{ margin: '20px 0' }}>
                        <CheckboxInput
                            values={[
                                {
                                    label: '',
                                    status: currentConfiguration.UseCenterImage,
                                },
                            ]}
                            onClick={() => {
                                setCurrentConfiguration({
                                    ...currentConfiguration,
                                    UseCenterImage: !currentConfiguration.UseCenterImage,
                                })
                            }}
                            label={t('interactiveMux.useCenterImage')}
                        />
                    </div>

                    {currentConfiguration.UseCenterImage && (
                        <div style={{ margin: '20px 0' }}>
                            <TextInput
                                value={currentConfiguration.CenterImageUrl ?? ''}
                                onTextChange={(e) => {
                                    setCurrentConfiguration({
                                        ...currentConfiguration,
                                        CenterImageUrl: e,
                                    })
                                }}
                                label={t('interactiveMux.centerImageUrl')}
                            />
                        </div>
                    )}
                    <Divider />
                    <div style={{ margin: '20px 0' }}>
                        <CheckboxInput
                            values={[
                                {
                                    label: '',
                                    status: currentConfiguration.OverloadLayout,
                                },
                            ]}
                            onClick={() => {
                                setCurrentConfiguration({
                                    ...currentConfiguration,
                                    OverloadLayout: !currentConfiguration.OverloadLayout,
                                })
                            }}
                            label={t('interactiveMux.overloadLayout')}
                        />
                    </div>

                    {currentConfiguration.OverloadLayout && (
                        <>
                            <div style={{ margin: '20px 0' }}>
                                <p
                                    style={{
                                        fontWeight: 600,
                                        margin: '5.3333333333px',
                                        fontSize: '14px',
                                        color: 'rgba(104, 105, 109, 0.45)',
                                    }}
                                >
                                    {t('interactiveMux.overloadLayoutData.backgroundColor').toUpperCase()}
                                </p>
                                <CompactPicker
                                    color={currentConfiguration.OverloadLayoutData.BackgroundColor}
                                    onChangeComplete={(e: any) => {
                                        setCurrentConfiguration({
                                            ...currentConfiguration,
                                            OverloadLayoutData: {
                                                ...currentConfiguration.OverloadLayoutData,
                                                BackgroundColor: e.hex,
                                            },
                                        })
                                    }}
                                />
                            </div>
                            <div style={{ margin: '20px 0' }}>
                                <TextInput
                                    type="number"
                                    value={currentConfiguration.OverloadLayoutData.QRTop.toString() ?? '0'}
                                    onTextChange={(e) => {
                                        setCurrentConfiguration({
                                            ...currentConfiguration,
                                            OverloadLayoutData: {
                                                ...currentConfiguration.OverloadLayoutData,
                                                QRTop: parseInt(e, 10),
                                            },
                                        })
                                    }}
                                    label={t('interactiveMux.overloadLayoutData.qrTop')}
                                />
                            </div>
                            <div style={{ margin: '20px 0' }}>
                                <TextInput
                                    type="number"
                                    value={currentConfiguration.OverloadLayoutData.QRLeft.toString() ?? '0'}
                                    onTextChange={(e) => {
                                        setCurrentConfiguration({
                                            ...currentConfiguration,
                                            OverloadLayoutData: {
                                                ...currentConfiguration.OverloadLayoutData,
                                                QRLeft: parseInt(e, 10),
                                            },
                                        })
                                    }}
                                    label={t('interactiveMux.overloadLayoutData.qrLeft')}
                                />
                            </div>
                            <div style={{ margin: '20px 0' }}>
                                <TextInput
                                    type="number"
                                    value={currentConfiguration.OverloadLayoutData.QRWidth.toString() ?? '0'}
                                    onTextChange={(e) => {
                                        setCurrentConfiguration({
                                            ...currentConfiguration,
                                            OverloadLayoutData: {
                                                ...currentConfiguration.OverloadLayoutData,
                                                QRWidth: parseInt(e, 10),
                                            },
                                        })
                                    }}
                                    label={t('interactiveMux.overloadLayoutData.qrWidth')}
                                />
                            </div>
                            <div style={{ margin: '20px 0' }}>
                                <TextInput
                                    type="number"
                                    value={currentConfiguration.OverloadLayoutData.QRHeight.toString() ?? '0'}
                                    onTextChange={(e) => {
                                        setCurrentConfiguration({
                                            ...currentConfiguration,
                                            OverloadLayoutData: {
                                                ...currentConfiguration.OverloadLayoutData,
                                                QRHeight: parseInt(e, 10),
                                            },
                                        })
                                    }}
                                    label={t('interactiveMux.overloadLayoutData.qrHeight')}
                                />
                            </div>

                            <Divider />
                            <div style={{ margin: '20px 0' }}>
                                <CheckboxInput
                                    values={[
                                        {
                                            label: '',
                                            status: currentConfiguration.OverloadLayoutData.CoverEnabled,
                                        },
                                    ]}
                                    onClick={() => {
                                        setCurrentConfiguration({
                                            ...currentConfiguration,
                                            OverloadLayoutData: {
                                                ...currentConfiguration.OverloadLayoutData,
                                                CoverEnabled: !currentConfiguration.OverloadLayoutData.CoverEnabled,
                                            },
                                        })
                                    }}
                                    label={t('interactiveMux.overloadLayoutData.coverEnabled')}
                                />
                            </div>

                            {currentConfiguration.OverloadLayoutData.CoverEnabled && (
                                <>
                                    <div style={{ margin: '20px 0' }}>
                                        <TextInput
                                            type="number"
                                            value={currentConfiguration.OverloadLayoutData.CoverTop.toString() ?? '0'}
                                            onTextChange={(e) => {
                                                setCurrentConfiguration({
                                                    ...currentConfiguration,
                                                    OverloadLayoutData: {
                                                        ...currentConfiguration.OverloadLayoutData,
                                                        CoverTop: parseInt(e, 10),
                                                    },
                                                })
                                            }}
                                            label={t('interactiveMux.overloadLayoutData.coverTop')}
                                        />
                                    </div>
                                    <div style={{ margin: '20px 0' }}>
                                        <TextInput
                                            type="number"
                                            value={currentConfiguration.OverloadLayoutData.CoverLeft.toString() ?? '0'}
                                            onTextChange={(e) => {
                                                setCurrentConfiguration({
                                                    ...currentConfiguration,
                                                    OverloadLayoutData: {
                                                        ...currentConfiguration.OverloadLayoutData,
                                                        CoverLeft: parseInt(e, 10),
                                                    },
                                                })
                                            }}
                                            label={t('interactiveMux.overloadLayoutData.coverLeft')}
                                        />
                                    </div>
                                    <div style={{ margin: '20px 0' }}>
                                        <TextInput
                                            type="number"
                                            value={currentConfiguration.OverloadLayoutData.CoverWidth.toString() ?? '0'}
                                            onTextChange={(e) => {
                                                setCurrentConfiguration({
                                                    ...currentConfiguration,
                                                    OverloadLayoutData: {
                                                        ...currentConfiguration.OverloadLayoutData,
                                                        CoverWidth: parseInt(e, 10),
                                                    },
                                                })
                                            }}
                                            label={t('interactiveMux.overloadLayoutData.coverWidth')}
                                        />
                                    </div>
                                    <div style={{ margin: '20px 0' }}>
                                        <TextInput
                                            type="number"
                                            value={
                                                currentConfiguration.OverloadLayoutData.CoverHeight.toString() ?? '0'
                                            }
                                            onTextChange={(e) => {
                                                setCurrentConfiguration({
                                                    ...currentConfiguration,
                                                    OverloadLayoutData: {
                                                        ...currentConfiguration.OverloadLayoutData,
                                                        CoverHeight: parseInt(e, 10),
                                                    },
                                                })
                                            }}
                                            label={t('interactiveMux.overloadLayoutData.coverHeight')}
                                        />
                                    </div>
                                </>
                            )}
                            <Divider />
                            <div style={{ margin: '20px 0' }}>
                                <CheckboxInput
                                    values={[
                                        {
                                            label: '',
                                            status: currentConfiguration.OverloadLayoutData.TextEnabled,
                                        },
                                    ]}
                                    onClick={() => {
                                        setCurrentConfiguration({
                                            ...currentConfiguration,
                                            OverloadLayoutData: {
                                                ...currentConfiguration.OverloadLayoutData,
                                                TextEnabled: !currentConfiguration.OverloadLayoutData.TextEnabled,
                                            },
                                        })
                                    }}
                                    label={t('interactiveMux.overloadLayoutData.textEnabled')}
                                />
                                {currentConfiguration.OverloadLayoutData.TextEnabled && (
                                    <>
                                        <div style={{ margin: '20px 0' }}>
                                            <p
                                                style={{
                                                    fontWeight: 600,
                                                    margin: '5.3333333333px',
                                                    fontSize: '14px',
                                                    color: 'rgba(104, 105, 109, 0.45)',
                                                }}
                                            >
                                                {t('interactiveMux.overloadLayoutData.textColor').toUpperCase()}
                                            </p>
                                            <CompactPicker
                                                color={currentConfiguration.OverloadLayoutData.TextColor}
                                                onChangeComplete={(e: any) => {
                                                    setCurrentConfiguration({
                                                        ...currentConfiguration,
                                                        OverloadLayoutData: {
                                                            ...currentConfiguration.OverloadLayoutData,
                                                            TextColor: e.hex,
                                                        },
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div style={{ margin: '20px 0' }}>
                                            <TextInput
                                                type="number"
                                                value={
                                                    currentConfiguration.OverloadLayoutData.TextTop.toString() ?? '0'
                                                }
                                                onTextChange={(e) => {
                                                    setCurrentConfiguration({
                                                        ...currentConfiguration,
                                                        OverloadLayoutData: {
                                                            ...currentConfiguration.OverloadLayoutData,
                                                            TextTop: parseInt(e, 10),
                                                        },
                                                    })
                                                }}
                                                label={t('interactiveMux.overloadLayoutData.textTop')}
                                            />
                                        </div>
                                        <div style={{ margin: '20px 0' }}>
                                            <TextInput
                                                type="number"
                                                value={
                                                    currentConfiguration.OverloadLayoutData.TextLeft.toString() ?? '0'
                                                }
                                                onTextChange={(e) => {
                                                    setCurrentConfiguration({
                                                        ...currentConfiguration,
                                                        OverloadLayoutData: {
                                                            ...currentConfiguration.OverloadLayoutData,
                                                            TextLeft: parseInt(e, 10),
                                                        },
                                                    })
                                                }}
                                                label={t('interactiveMux.overloadLayoutData.textLeft')}
                                            />
                                        </div>
                                        <div style={{ margin: '20px 0' }}>
                                            <TextInput
                                                type="number"
                                                value={
                                                    currentConfiguration.OverloadLayoutData.TextWidth.toString() ?? '0'
                                                }
                                                onTextChange={(e) => {
                                                    setCurrentConfiguration({
                                                        ...currentConfiguration,
                                                        OverloadLayoutData: {
                                                            ...currentConfiguration.OverloadLayoutData,
                                                            TextWidth: parseInt(e, 10),
                                                        },
                                                    })
                                                }}
                                                label={t('interactiveMux.overloadLayoutData.textWidth')}
                                            />
                                        </div>
                                        <div style={{ margin: '20px 0' }}>
                                            <TextInput
                                                type="number"
                                                value={
                                                    currentConfiguration.OverloadLayoutData.TextHeight.toString() ?? '0'
                                                }
                                                onTextChange={(e) => {
                                                    setCurrentConfiguration({
                                                        ...currentConfiguration,
                                                        OverloadLayoutData: {
                                                            ...currentConfiguration.OverloadLayoutData,
                                                            TextHeight: parseInt(e, 10),
                                                        },
                                                    })
                                                }}
                                                label={t('interactiveMux.overloadLayoutData.textHeight')}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                        </>
                    )}
                </div>
                <div className="serviceTab">
                    {<MUXPreview configuration={currentConfiguration} logo={props.radioLogo ?? ''} />}
                </div>
            </div>
            <LoadingModal isVisible={isUpdating} text={t('loadings.updating_services')} />
        </div>
    )
}

export default InteractiveMUXTab
