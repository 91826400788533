import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { COLORS } from '../resources/colors'
import { ROUTES } from '../resources/routes-constants'
import { setPageHeader } from '../store/actions/graphic'

const NotFoundPage: React.FC = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(
            setPageHeader({
                parent: null,
                name: t('headers.not_found'),
            })
        )
    }, [])

    /**
     * Call this function to redirect the user to the homepage.
     */
    const redirectToHomePage = () => {
        navigate(ROUTES.HOMEPAGE_ROUTE)
    }

    return (
        <div
            style={{
                position: 'relative',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            <h1 style={{ fontSize: '4em' }}>{t('headers.not_found')}!</h1>
            <span
                style={{ cursor: 'pointer', color: COLORS.purple, fontWeight: 'bold' }}
                onClick={() => redirectToHomePage()}
            >
                {t('headers.homepage')}
            </span>
        </div>
    )
}

export default NotFoundPage
