import React, { useEffect, useMemo, useState } from 'react'
import CardContainer from '../../components/CardContainer/CardContainer'
import PageSidebarContainer from '../../components/PageSidebarContainer'
import { useLocation } from 'react-router-dom'
import '../../styles/pages/MuxPage.sass'
import DabFrequencyTab from './tabs/DabFrequencyTab'
import DabRadioTab from './tabs/DabRadioTab'
import { useTranslation } from 'react-i18next'
import LoadingModal from '../../components/Modals/LoadingModal'
import FmWorldAxios from '../../utility/FmWorldAxios'
import { getDabRadios, getMuxDetail, getMuxServices, getMuxUserPermissions } from '../../resources/api-constants'
import { useDispatch } from 'react-redux'
import { Mux, MuxServiceAssociation, Radio } from '../../types/data'
import { setPageHeader } from '../../store/actions/graphic'
import { ROUTES } from '../../resources/routes-constants'
import DabUsersTab from './tabs/DabUsersTab'
import { Grants } from '../../utility/Grants'
import DabTXControlTab from './tabs/DabTXControlTab'

enum Tab {
    Frequencies = 'frequencies',
    Radios = 'radios',
    Users = 'users',
    TXControl = 'mediaDab',
}
const DabDetailPage: React.FC = () => {
    const [selectedTab, setSelectedTab] = useState(Tab.Frequencies)
    const [muxId, setMuxId] = useState<number>(-1)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [permissions, setPermissions] = useState<Grants[]>([])
    const [services, setServices] = useState<MuxServiceAssociation[]>([])
    const [error, setError] = useState<string>('')
    const [radios, setRadios] = useState<Radio[]>([])
    const location = useLocation()
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const tabs = useMemo(() => {
        const initialTabs = [Tab.Frequencies, Tab.Radios, Tab.Users]

        if (services.find((service) => service.name === 'TX-control')) {
            console.log('new tabs')
            initialTabs.push(Tab.TXControl)
        }

        return initialTabs
    }, [services])

    const currentTab = useMemo(() => {
        const txService = services.find((service) => service.name === 'TX-control')

        if (muxId === -1) {
            return null
        }

        switch (selectedTab) {
            case Tab.Frequencies:
                return <DabFrequencyTab idMux={muxId} />
            case Tab.Radios:
                return <DabRadioTab muxId={muxId} radios={radios} onReloadData={async () => fetchRadios()} />
            case Tab.Users:
                return <DabUsersTab idMux={muxId} permissions={permissions} />
            case Tab.TXControl:
                return (
                    <DabTXControlTab
                        muxRadios={radios}
                        service={txService as any}
                        muxId={muxId}
                        onRefreshConfiguration={() => void fetchMuxData()}
                    />
                )
            default:
                return <DabFrequencyTab idMux={muxId} />
        }
    }, [selectedTab, tabs, muxId, services])

    const fetchMuxData = async () => {
        if (muxId !== -1) {
            setIsLoading(true)
            try {
                const mux = await FmWorldAxios.get<Mux>(getMuxDetail(muxId)).then((res) => res.data)
                const muxServices = await FmWorldAxios.get<MuxServiceAssociation[]>(getMuxServices(muxId)).then(
                    (res) => res.data
                )

                await fetchRadios()

                setServices(muxServices)

                dispatch(
                    setPageHeader({
                        parent: {
                            name: t('headers.dabs'),
                            route: ROUTES.DAB_LIST_ROUTE,
                        },
                        name: mux.name,
                    })
                )
            } catch (error) {
                console.log(error)
            }
            setIsLoading(false)
        }
    }

    const fetchRadios = async () => {
        try {
            const radiosFetch = await FmWorldAxios.get(getDabRadios(muxId)).then((res) => {
                return res.data.items
            })
            console.log(radiosFetch)
            setRadios(radiosFetch)
            // setFrequencies(frequenciesFetch)
        } catch (error: any) {
            console.log('ERROR', error)
            setError(error.toString())
        }
    }

    const fetchPermissions = async () => {
        try {
            const permissionsFetch = await FmWorldAxios.get(getMuxUserPermissions(muxId)).then((res) => {
                return res.data
            })

            setPermissions(permissionsFetch)
        } catch (error: any) {
            setError(error.toString())
        }
    }

    useEffect(() => {
        void fetchMuxData()
        if (muxId) {
            void fetchPermissions()
        }
    }, [muxId])

    useEffect(() => {
        const idMux = location.pathname.replace('/dab/', '')
        setMuxId(parseInt(idMux, 10))
    }, [location.pathname])

    return (
        <PageSidebarContainer>
            {muxId !== -1 ? (
                <div className="tabMenu">
                    {tabs.map((tab) => {
                        return (
                            <p
                                onClick={() => setSelectedTab(tab)}
                                className={selectedTab === tab ? 'tabMenu-tab_active' : 'tabMenu-tab'}
                                key={tab}
                            >
                                {t(`dabs.${tab}`)}
                            </p>
                        )
                    })}
                </div>
            ) : null}
            {muxId ? <CardContainer>{currentTab}</CardContainer> : null}
            {error && <p className="u-normal-text u-bold-text u-error-text">{error}</p>}
            <LoadingModal isVisible={isLoading} text={t('loadings.generic_loading')} />
        </PageSidebarContainer>
    )
}

export default DabDetailPage
