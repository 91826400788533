export const table_locale_enEN = {
    body: {
        emptyDataSourceMessage: 'No content found',
        addTooltip: 'Add',
        deleteTooltip: 'Remove',
        editTooltip: 'Edit',
        filterRow: {
            filterTooltip: 'Filter',
        },
        editRow: {
            deleteText: 'Do you want to remove this row?',
            cancelTooltip: 'Cancel',
            saveTooltip: 'Save',
        },
    },
    grouping: {
        groupedBy: 'Grouped by:',
    },
    header: {
        actions: '',
    },
    pagination: {
        labelDisplayedRows: '{from}-{to} of {count}',
        labelRowsSelect: 'rows',
        labelRowsPerPage: 'rows per page:',
        firstAriaLabel: 'First page',
        firstTooltip: 'First page',
        previousAriaLabel: 'Previous page',
        previousTooltip: 'Previous page',
        nextAriaLabel: 'Next page',
        nextTooltip: 'Next page',
        lastAriaLabel: 'Last page',
        lastTooltip: 'Last page',
    },
    toolbar: {
        addRemoveColumns: 'Add or remove columns',
        nRowsSelected: '{0} selected rows',
        showColumnsTitle: 'Show columns',
        showColumnsAriaLabel: 'Show columns',
        exportTitle: 'Export',
        exportAriaLabel: 'Export',
        exportCSVName: 'Export to CSV',
        exportPDFName: 'Export to PDF',
        searchTooltip: 'Search',
        searchPlaceholder: 'Search',
    },
}
