import React, { useMemo, useRef, useState } from 'react'
import Button from '../../../components/Button/Button'
import { associateRadioStream, getRadios, getStreams } from '../../../resources/api-constants'
import { COLORS } from '../../../resources/colors'
import { Category, Radio, Stream, Tag } from '../../../types/data'
import '../../../components/Grids/Grid.sass'
import Loader from '../../../components/Loader/Loader'
import TextInput from '../../../components/TextInput/TextInput'
import SelectInput, { Option } from '../../../components/SelectInput/SelectInput'
import { useSelector } from 'react-redux'
import { Reducers, ReducerData } from '../../../types/reducers'
import UploadInput from '../../../components/UploadInput/UploadInput'
import { voidRadio, VoidRadio, voidStream, VoidStream } from '../../../utility/voidConstants'
import LoadingModal from '../../../components/Modals/LoadingModal'
import FmWorldMultipartAxios from '../../../utility/FmWorldMultipartAxios'
import { useDispatch } from 'react-redux'
import { fetchRadios } from '../../../store/actions/thunk_actions'
import { Grants } from '../../../utility/Grants'
import CheckboxInput from '../../../components/CheckboxInput/CheckboxInput'
import { PLACEHOLDER } from '../../../resources/constants'
import { useTranslation } from 'react-i18next'
import { emailRegEx, phoneRegEx } from '../../../utility/functions'
import StreamFilter, { FilterStreams } from '../../../components/Filters/StreamFilter'
import FmWorldAxios from '../../../utility/FmWorldAxios'
import StreamsTable from '../../../components/Tables/StreamsTable'
import ActionsModal from '../../../components/Modals/ActionsModal'
import DeleteModal from '../../../components/Modals/DeleteModal'
import StreamModal from '../../../components/Modals/StreamModal'
import { ROUTES } from '../../../resources/routes-constants'
import { useNavigate } from 'react-router-dom'

interface Props {
    idNetwork: number | null
    permissions: Grants[]
}

const initialFilter = {
    query: '',
    offlineOnly: false,
}

const CreateRadioComponent: React.FC<Props> = (props) => {
    const [radioDetail, setRadioDetail] = useState<Radio | VoidRadio | null>(voidRadio)
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()
    const [isCreating, setIsCreating] = useState(false)
    const [error, setError] = useState('')
    const dispatch = useDispatch()
    const data = useSelector<Reducers, ReducerData>((state) => state.data)
    const logoRef = useRef<HTMLImageElement>(null)
    const { t } = useTranslation()
    const [filter, setFilter] = useState<FilterStreams>(initialFilter)
    const [isUpdating, setIsUpdating] = useState(false)
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const [streamArray, setStreamArray] = useState<Stream[]>([])
    const [selectedStream, setSelectedStream] = useState<Stream | null>(null)
    const [streamToDelete, setStreamToDelete] = useState<Stream | null>(null)
    const [streamToEdit, setStreamToEdit] = useState<Stream | VoidStream | null>(null)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [newIdRadio, setNewIdRadio] = useState<number | null>(null)

    const dataIsInvalid = useMemo(() => {
        if (radioDetail) {
            return (
                !radioDetail.name ||
                !radioDetail.email ||
                !radioDetail.idCountry ||
                (radioDetail.phone && !phoneRegEx.test(radioDetail.phone)) === true ||
                (radioDetail.email && !emailRegEx.test(radioDetail.email)) === true ||
                streamArray.length === 0
            )
        }
        return true
    }, [radioDetail])

    const handleInsertLogo = (e: any, ref: React.RefObject<HTMLImageElement>, onChange: (img: File) => void) => {
        if (e.target.files[0] && radioDetail) {
            const image = e.target.files[0]
            if (image) {
                onChange(image)
                const fr = new FileReader()
                fr.onload = function () {
                    if (ref && ref.current && fr.result) {
                        ref.current.src = fr.result as string
                    }
                }
                fr.readAsDataURL(image)
            }
        }
    }

    const currentRadioType = useMemo(() => {
        if (radioDetail && radioDetail.idRadioType) {
            const found = data.radioTypes.find((r) => r.id === radioDetail.idRadioType)
            if (found) {
                return {
                    id: found.id,
                    value: found.id,
                    label: found.value,
                }
            }
            return null
        }
        return null
    }, [radioDetail?.idRadioType, data.radioTypes])

    const currentCountry = useMemo(() => {
        if (radioDetail && radioDetail.idCountry) {
            const found = data.countries.find((r) => r.id === radioDetail.idCountry)
            if (found) {
                return {
                    id: found.id,
                    value: found.id,
                    label: found.name,
                }
            }
            return null
        }
        return null
    }, [radioDetail?.idCountry, data.countries])

    const currentCategories = useMemo(() => {
        if (radioDetail && radioDetail.categories) {
            return radioDetail.categories.map((c) => {
                return {
                    id: c.id,
                    value: c.id,
                    label: c.value,
                }
            })
        }
        return []
    }, [radioDetail?.categories])

    const currentTags = useMemo(() => {
        if (radioDetail && radioDetail.tags) {
            return radioDetail.tags.map((c) => {
                return {
                    id: c.id,
                    value: c.id,
                    label: c.value,
                }
            })
        }
        return []
    }, [radioDetail?.tags])

    const createRadio = async () => {
        if (radioDetail && radioDetail.name) {
            setError('')
            setIsCreating(true)

            try {
                const updatedRadio = {
                    ...radioDetail,
                    categories: radioDetail?.categories.map((c) => {
                        return c.id
                    }),
                    tags: radioDetail?.tags?.map((t) => {
                        return t.id
                    }),
                    idNetwork: data.singleNetwork
                        ? data.singleNetwork.id
                        : radioDetail.idNetwork === -1
                        ? null
                        : radioDetail.idNetwork,
                }
                const formData = new FormData()
                Object.keys(updatedRadio).map((key) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    const currentField = updatedRadio[key as any]
                    formData.append(key, Array.isArray(currentField) ? JSON.stringify(currentField) : currentField)
                    return
                })
                // console.log(formData.getAll('logo'), formData.getAll('name'))
                const newRadioId = await FmWorldMultipartAxios.post(getRadios(), formData).then((res) => {
                    return res.data.id
                })
                dispatch(fetchRadios())
                setIsCreating(false)
                setNewIdRadio(newRadioId)

                streamArray.forEach(async (s) => {
                    try {
                        const newStreamId: number = await FmWorldAxios.post(getStreams(), s).then((res) => {
                            return res.data.id
                        })
                        setStreamArray(
                            streamArray.map((stream) => {
                                return stream.id === s.id ? { ...stream, id: newStreamId } : stream
                            })
                        )

                        return await FmWorldAxios.post(associateRadioStream(newRadioId, newStreamId))
                    } catch (error: any) {
                        console.log('ERROR', error)
                        setIsCreating(false)
                        setError(error.toString())
                    }
                }, [])
                navigate(`${ROUTES.RADIO_DETAIL_ROUTE}${newRadioId}`, {
                    state: {
                        name: updatedRadio.name,
                        idNetwork: data.singleNetwork ? data.singleNetwork.id : radioDetail.idNetwork,
                    },
                })
            } catch (error: any) {
                console.log('ERROR', error)
                setIsCreating(false)
                setError(error.toString())
            }
        }
    }

    const actionsModal = () => {
        const actions = []
        if (props.permissions.includes(Grants.EDIT_RADIO)) {
            actions.push({
                text: t('radios.edit_stream'),
                color: COLORS.purple,
                onClick: () => setStreamToEdit(selectedStream),
            })
            actions.push({
                text: t('radios.delete_stream'),
                color: COLORS.redDelete,
                onClick: () => setStreamToDelete(selectedStream),
            })
        }
        return actions
    }

    if (isLoading) {
        return (
            <div className="loadingGrid">
                <Loader />
            </div>
        )
    }

    const handleDeleteStream = async () => {
        if (streamToDelete) {
            setError('')
            setIsLoading(true)
            try {
                setStreamArray(streamArray.filter((s) => s.id !== streamToDelete.id))
                setStreamToDelete(null)
                setSelectedStream(null)
                setIsLoading(false)
            } catch (error: any) {
                console.log('ERROR', error)
                setError(error.toString())
                setIsLoading(false)
            }
        }
    }

    const handleUpdateStream = async (newStream: Stream) => {
        if (streamToEdit) {
            setError('')
            setIsUpdating(true)
            try {
                const streamToPass: any = { ...newStream }
                delete streamToPass.id
                delete streamToPass.updatedOn
                delete streamToPass.createdOn
                delete streamToPass.tableData
                setStreamArray(streamArray.map((s) => (s.id === newStream.id ? newStream : s)))
                setIsUpdating(false)
                setStreamToEdit(null)
                setSelectedStream(null)
            } catch (error: any) {
                console.log('ERROR', error)
                setError(error.toString())
                setIsUpdating(false)
            }
        }
    }

    const handleCreateStream = async (newStream: VoidStream) => {
        setError('')
        setIsCreating(true)
        try {
            console.log('newStream', newStream)
            setStreamArray([
                ...streamArray,
                {
                    id: streamArray.length + 1,
                    ...newStream,
                    createdOn: '',
                    updatedOn: '',
                },
            ])
            console.log('streamArray', streamArray)
        } catch (error: any) {
            console.log('ERROR', error)
            setError(error.toString())
        }
        setIsCreating(false)
        setStreamToEdit(null)
    }

    if (isLoading) {
        return (
            <div className="loadingGrid">
                <Loader />
            </div>
        )
    }

    return (
        <div>
            <div className="titleRow">
                <div style={{ display: 'flex', flex: 1, gap: 10, alignItems: 'center', justifyContent: 'flex-end' }}>
                    {error && <p className="u-normal-text u-bold-text u-error-text">{error}</p>}
                    <Button
                        label={t('general.add')}
                        onPress={() => {
                            void createRadio()
                        }}
                        disabled={dataIsInvalid}
                        type="submit"
                    />
                </div>
            </div>
            {radioDetail ? (
                <div>
                    <div style={{ borderBottom: '2px solid rgba(0,0,0,0.07)', padding: '20px 0' }}>
                        <div className="u-elements-row">
                            <div className="uploadImage">
                                <img
                                    ref={logoRef}
                                    alt="logo1"
                                    src={radioDetail.logo ? (radioDetail.logo as string) : PLACEHOLDER}
                                    className="uploadImage-logo"
                                    onError={(e: any) => {
                                        e.currentTarget.src = PLACEHOLDER
                                    }}
                                />
                                <input
                                    className="uploadImage-upload"
                                    type="file"
                                    accept="image/*"
                                    onChange={(event) =>
                                        handleInsertLogo(event, logoRef, (img) => {
                                            setRadioDetail({
                                                ...radioDetail,
                                                logo: img,
                                            })
                                        })
                                    }
                                />
                            </div>
                            <p className="captionImg">
                                {t('radios.customize_logo_radio')}*
                                <br />({t('radios.500_500_px')}).
                            </p>
                        </div>
                        <div className="u-elements-row">
                            <TextInput
                                label={`${t('general.name')}*`}
                                value={radioDetail.name}
                                onTextChange={(text) => {
                                    setRadioDetail({
                                        ...radioDetail,
                                        name: text,
                                    })
                                }}
                                bordered
                                disabled={!props.permissions.includes(Grants.EDIT_RADIO)}
                            />
                            <TextInput
                                label={t('radios.payoff')}
                                value={radioDetail.slogan}
                                onTextChange={(text) => {
                                    setRadioDetail({
                                        ...radioDetail,
                                        slogan: text,
                                    })
                                }}
                                bordered
                                disabled={!props.permissions.includes(Grants.EDIT_RADIO)}
                            />
                            <div style={{ flex: 1 }} />
                        </div>
                    </div>
                    <div style={{ padding: '20px 0' }} className="u-elements-row">
                        <TextInput
                            label={t('radios.website')}
                            value={radioDetail.website}
                            onTextChange={(text) => {
                                setRadioDetail({
                                    ...radioDetail,
                                    website: text,
                                })
                            }}
                            bordered
                            disabled={!props.permissions.includes(Grants.EDIT_RADIO)}
                        />
                        <TextInput
                            label={`${t('auth.email')}*`}
                            value={radioDetail.email}
                            onTextChange={(text) => {
                                setRadioDetail({
                                    ...radioDetail,
                                    email: text,
                                })
                            }}
                            borderColor={
                                radioDetail.email && !emailRegEx.test(radioDetail.email) ? COLORS.redDelete : ''
                            }
                            bordered
                            disabled={!props.permissions.includes(Grants.EDIT_RADIO)}
                        />
                        <TextInput
                            label={`${t('general.telephone')}`}
                            value={radioDetail.phone || ''}
                            onTextChange={(text) => {
                                setRadioDetail({
                                    ...radioDetail,
                                    phone: text,
                                })
                            }}
                            borderColor={
                                radioDetail.phone && !phoneRegEx.test(radioDetail.phone) ? COLORS.redDelete : ''
                            }
                            bordered
                            disabled={!props.permissions.includes(Grants.EDIT_RADIO)}
                        />
                        <TextInput
                            label={t('general.facebook')}
                            value={radioDetail.socialContacts || ''}
                            onTextChange={(text) => {
                                setRadioDetail({
                                    ...radioDetail,
                                    socialContacts: text,
                                })
                            }}
                            bordered
                            disabled={!props.permissions.includes(Grants.EDIT_RADIO)}
                            isHidden
                            // hyde
                        />
                    </div>
                    <div style={{ padding: '20px 0' }} className="u-elements-row">
                        {data.radioTypes.length > 0 ? (
                            <SelectInput
                                label={`${t('radios.typology')}`}
                                placeholder={t('placeholders.select_typology')}
                                value={currentRadioType}
                                options={data.radioTypes.map((r) => {
                                    return {
                                        id: r.id,
                                        value: r.id,
                                        label: r.value,
                                    }
                                })}
                                onChange={(value) => {
                                    const v = value as Option
                                    if (v) {
                                        setRadioDetail({
                                            ...radioDetail,
                                            idRadioType: v.id as number,
                                        })
                                    }
                                }}
                                bordered
                                disabled={!props.permissions.includes(Grants.EDIT_RADIO)}
                            />
                        ) : null}
                        {data.countries.length > 0 ? (
                            <SelectInput
                                label={`${t('radios.country')}`}
                                placeholder={t('placeholders.select_country')}
                                value={currentCountry}
                                options={data.countries.map((r) => {
                                    return {
                                        id: r.id,
                                        value: r.id,
                                        label: r.name,
                                    }
                                })}
                                onChange={(value) => {
                                    const v = value as Option
                                    if (v) {
                                        setRadioDetail({
                                            ...radioDetail,
                                            idCountry: v.id as string,
                                        })
                                    }
                                }}
                                bordered
                                disabled={!props.permissions.includes(Grants.EDIT_RADIO)}
                            />
                        ) : null}
                        {data.categories.length > 0 ? (
                            <SelectInput
                                label={`${t('radios.categories')}*`}
                                placeholder={t('placeholders.select_categories')}
                                flex={1}
                                isMulti
                                value={currentCategories}
                                options={data.categories.map((r) => {
                                    return {
                                        id: r.id,
                                        value: r.id,
                                        label: r.value,
                                    }
                                })}
                                onChange={(value) => {
                                    const values = value as Option[]
                                    if (values) {
                                        const rendered: Category[] = values.map((v) => {
                                            return {
                                                id: v.id as number,
                                                value: v.label,
                                            }
                                        })
                                        setRadioDetail({
                                            ...radioDetail,
                                            categories: rendered,
                                        })
                                    }
                                }}
                                bordered
                                disabled={!props.permissions.includes(Grants.EDIT_RADIO)}
                            />
                        ) : null}
                        {data.radioTags.length > 0 ? (
                            <SelectInput
                                label={`${t('radios.tags')}`}
                                placeholder={t('placeholders.select_tags')}
                                flex={1}
                                isMulti
                                value={currentTags}
                                options={data.radioTags.map((r) => {
                                    return {
                                        id: r.id,
                                        value: r.id,
                                        label: r.value,
                                    }
                                })}
                                onChange={(value) => {
                                    const values = value as Option[]
                                    if (values) {
                                        const rendered: Tag[] = values.map((v) => {
                                            return {
                                                id: v.id as number,
                                                value: v.label,
                                            }
                                        })
                                        setRadioDetail({
                                            ...radioDetail,
                                            tags: rendered,
                                        })
                                    }
                                }}
                                bordered
                                disabled={!props.permissions.includes(Grants.EDIT_RADIO)}
                                isHidden
                            />
                        ) : null}
                    </div>
                    <div style={{ padding: '20px 0' }} className="u-elements-row">
                        <UploadInput
                            value={radioDetail.ownerAuthorization ? radioDetail.ownerAuthorization : null}
                            link={radioDetail.ownerAuthorization as string}
                            label={t('radios.copyright')}
                            text={radioDetail.ownerAuthorization ? t('radios.view_copyright_doc') : ''}
                            onChange={(file) => {
                                setRadioDetail({
                                    ...radioDetail,
                                    ownerAuthorization: file,
                                })
                            }}
                            disabled={!props.permissions.includes(Grants.EDIT_RADIO)}
                        />
                        <CheckboxInput
                            values={[
                                {
                                    label: '',
                                    status: radioDetail.isPublic || true,
                                },
                            ]}
                            onClick={() => {
                                setRadioDetail({
                                    ...radioDetail,
                                    isPublic: !radioDetail.isPublic,
                                })
                            }}
                            label={t('radios.public_radio')}
                            isHidden
                            disabled={!props.permissions.includes(Grants.EDIT_RADIO)}
                        />
                        <div style={{ flex: 2 }}>
                            <TextInput
                                label={t('radios.network')}
                                placeholder={t('placeholders.select_network')}
                                value={props.idNetwork?.toString() || ''}
                                disabled
                                // eslint-disable-next-line react/jsx-props-no-multi-spaces
                                onTextChange={(v) => {
                                    const val = v as unknown as Option
                                    setRadioDetail({
                                        ...radioDetail,
                                        idNetwork: val.id as number,
                                    })
                                }}
                                bordered
                                isHidden
                            />
                        </div>
                    </div>
                    <LoadingModal
                        isVisible={isCreating}
                        text={isCreating ? 'Sto aggiornando la radio...' : 'Sto creando la radio...'}
                    />
                </div>
            ) : null}
            <div>
                <div className="titleRow">
                    <div style={{ flex: 1 }}>
                        <h2 className="title">{t('radios.streams')}</h2>
                        <h3 className="subtitle" style={{ fontWeight: 400 }}>
                            {t('radios.manage_streams')}
                        </h3>
                        {error && <p className="u-normal-text u-bold-text u-error-text">{error}</p>}
                    </div>
                    <div
                        style={{ display: 'flex', flex: 2, gap: 10, alignItems: 'center', justifyContent: 'flex-end' }}
                    >
                        <StreamFilter
                            filter={filter}
                            anchorEl={anchorEl}
                            onClose={() => setAnchorEl(null)}
                            onReset={() => setFilter(initialFilter)}
                            onUpdate={(f) => setFilter({ ...f, offlineOnly: f.offlineOnly })}
                        />
                    </div>
                    {props.permissions.includes(Grants.EDIT_RADIO) ? (
                        <div style={{ flex: 1 }}>
                            <Button
                                label={t('general.add')}
                                onPress={() => {
                                    setStreamToEdit(voidStream)
                                }}
                                type="submit"
                            />
                        </div>
                    ) : null}
                </div>
            </div>
            <StreamsTable
                data={streamArray}
                selectStream={(stream) => setSelectedStream(stream)}
                showActions={actionsModal().length > 0}
            />
            <ActionsModal
                isVisible={selectedStream !== null}
                title={t('general.what_to_do')}
                onClose={() => setSelectedStream(null)}
                actions={actionsModal()}
            />
            <StreamModal
                isVisible={streamToEdit !== null}
                onClose={() => setStreamToEdit(null)}
                stream={streamToEdit as Stream}
                onSave={(newElement: Stream | VoidStream) => {
                    if ((newElement as Stream).id) {
                        void handleUpdateStream(newElement as Stream)
                    } else {
                        void handleCreateStream(newElement)
                    }
                }}
            />

            <LoadingModal
                isVisible={isCreating || isUpdating}
                text={isUpdating ? t('loadings.updating_stream') : t('loadings.creating_stream')}
            />
            <DeleteModal
                onDelete={() => {
                    void handleDeleteStream()
                }}
                title={`${t('radios.delete_stream')} ${streamToDelete?.url}`}
                text={`${t('radios.want_delete_stream')} ${streamToDelete?.url}?`}
                onClose={() => {
                    setStreamToDelete(null)
                }}
                isVisible={streamToDelete !== null}
            />
        </div>
    )
}

export default CreateRadioComponent
