import React, { useMemo, useState } from 'react'
import Button from '../../components/Button/Button'
import TextInput from '../../components/TextInput/TextInput'
import { InvalidRouteParamsException } from '../../resources/custom-exceptions'
import { useQueryParamsFromURL } from '../../resources/hooks/common'
import CustomAxios from '../../utility/FmWorldAxios'
import './../../styles/pages/LoginPage.sass'

interface ResetPasswordRouteParams {
    token?: string
}

interface NewPasswordToSend {
    newPassword: string
    newPasswordConfirm: string
}

const ResetPasswordPage: React.FC = () => {
    const [password, setPassword] = useState('')
    const [repeatPassword, setRepeatPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const routeParams = useQueryParamsFromURL<ResetPasswordRouteParams>({ token: '' })

    const routeParamsAreValid = useMemo((): boolean => {
        return Object.values(routeParams).every((value: string) => value !== '')
    }, [routeParams])

    const passwordsAreValid = useMemo(() => {
        return password !== '' && repeatPassword !== ''
    }, [password, repeatPassword])

    const initResetPasswordProcedure = async () => {
        try {
            if (routeParamsAreValid && passwordsAreValid && password === repeatPassword) {
                const newPasswordData: NewPasswordToSend = {
                    newPassword: password,
                    newPasswordConfirm: repeatPassword,
                }
                await CustomAxios.post('auth/set-new-password', newPasswordData).then((response) => response.data)
            } else {
                if (!routeParamsAreValid) throw InvalidRouteParamsException
                if (password !== repeatPassword) throw 'Passwords are not equal.'
            }
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <div className="u-full-page-container u-column-page">
            <div className="c-reset-password-panel">
                <div className="c-reset-password-panel__main-panel">
                    <div className="o-login-inputs-container">
                        <p className="u-medium-text u-bold-text">Reset Password</p>
                        <p className="u-normal-text">Crea una nuova password per il tuo account.</p>
                        <TextInput
                            interactiveIcon
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            placeholder="Password"
                            onTextChange={(newValue) => setPassword(newValue)}
                            icon="O"
                            iconPosition="right"
                            onIconClick={() => setShowPassword(!showPassword)}
                        />
                        <TextInput
                            value={repeatPassword}
                            type={showPassword ? 'text' : 'password'}
                            placeholder="Repeat password"
                            onTextChange={(newValue) => setRepeatPassword(newValue)}
                        />
                        <p className="u-normal-text">La nuova password deve presentare le seguenti caratteristiche:</p>
                        <div className="u-elements-row u-small-gap">
                            <Button
                                disabled={!passwordsAreValid}
                                label="Invia"
                                type="submit"
                                onPress={async () => initResetPasswordProcedure()}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPasswordPage
