import MaterialTable, { Column } from 'material-table'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Frequency } from '../../types/data'
import { Reducers, ReducerGraphic } from '../../types/reducers'
import { getLangTable } from '../../utility/functions'
import { Languages } from '../../utility/Languages'
import FixedTableTheme from '../FixedTableTheme'

interface Props {
    data: Frequency[]
    selectFrequency: (stream: Frequency) => void
}

const FrequenciesTable: React.FC<Props> = (props) => {
    const graphic = useSelector<Reducers, ReducerGraphic>((state) => state.graphic)
    const { t } = useTranslation()

    const columns: Column<Frequency>[] = [
        {
            title: t('frequencies.frequency') as string,
            field: 'frequency',
            render: (frequency: Frequency) => {
                return <p>{frequency.frequency} mHz</p>
            },
        },
        {
            title: t('general.city') as string,
            field: 'citta',
            render: (frequency: Frequency) => {
                return <p>{frequency.city?.name}</p>
            },
        },
        {
            title: t('headers.radio') as string,
            field: 'radio',
            render: (frequency: Frequency) => {
                return <p>{frequency.radio.name}</p>
            },
        },
    ]

    const data = useMemo(() => {
        return props.data
    }, [props.data])

    const actions = useMemo(() => {
        const actions = [
            (frequency: Frequency) => ({
                icon: 'more_vert',
                onClick: () => {
                    props.selectFrequency(frequency)
                },
            }),
        ]

        return actions
    }, [props.data])

    return (
        <FixedTableTheme>
            <MaterialTable
                columns={columns}
                data={data}
                actions={actions}
                title=""
                localization={getLangTable(graphic.currentLanguage as Languages)}
                options={{
                    actionsColumnIndex: -1,
                    search: false,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 50],
                    actionsCellStyle: {
                        border: '0',
                    },
                    toolbar: false,
                }}
                style={{
                    boxShadow: 'none',
                }}
            />
        </FixedTableTheme>
    )
}

export default FrequenciesTable
