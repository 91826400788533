import React from 'react'
import { COLORS } from '../../resources/colors'
import Icon from '../Icon'
import './Button.sass'

interface Props {
    icon?: string
    label: string
    type: 'submit' | 'submit-secondary' | 'primary' | 'secondary' | 'error'
    disabled?: boolean
    onPress?: (event: React.MouseEvent<HTMLButtonElement>) => void
    background?: string
    border?: string
    color?: string
    width?: string | number
    iconSize?: number
    hidden?: boolean
}

const Button: React.FC<Props> = (props) => {
    if (props.hidden) {
        return null
    }
    return (
        <button
            aria-label={props.label}
            className={`c-button c-button__${props.type}`}
            style={{
                border: props.border ? props.border : 0,
                backgroundColor: props.background ? props.background : COLORS.purple,
                color: props.color ? props.color : '#fff',
                opacity: props.disabled ? 0.6 : 1,
                width: props.width ? props.width : '',
            }}
            disabled={props.disabled}
            onClick={(event) => !props.disabled && props.onPress && props.onPress(event)}
        >
            <div className="o-button-content">
                {props.icon ? (
                    <Icon name={props.icon} size={props.iconSize ? props.iconSize : 20} color="#fff" />
                ) : null}
                {props.label}
            </div>
        </button>
    )
}

export default Button
