import React from 'react'
import Link from '../components/Link/Link'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import LanguageSelector from './LanguageSelector/LanguageSelector'
import appConfiguration from '../appConfiguration'

const StaticContentRegisterPage: React.FC = () => {
    const { t } = useTranslation()

    return (
        <div className="c-login-text-container">
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    flex: 1,
                    gap: 24,
                }}
            >
                <img className="logo" src={appConfiguration.logo} alt="logo" />
                <p className="u-large-text u-bold-text">
                    {appConfiguration.slogan !== undefined ? appConfiguration.slogan : t('auth.world_radio_aggregator')}
                </p>
                {appConfiguration.isFmWorld && (
                    <>
                        <p className="u-medium-text">{t('auth.first_paragraph_login')}</p>
                        <p className="u-medium-text">{t('auth.second_paragraph_login')}</p>
                    </>
                )}
            </div>

            <div className="u-elements-row u-small-gap">
                <LanguageSelector />
                <p className="u-small-text u-bold-text">22HBG - {moment().year()}</p>
                {/* <Link
                    label={t('auth.terms_conditions')}
                    underline="always"
                    type="text-colored"
                    target="external"
                    href="#"
                /> */}
                {appConfiguration.privacyPolicyUrl && (
                    <Link
                        label={t('auth.privacy_policy')}
                        underline="always"
                        type="text-colored"
                        target="external"
                        href="#"
                    />
                )}
            </div>
        </div>
    )
}

export default StaticContentRegisterPage
