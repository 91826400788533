import { useEffect } from 'react'
import appConfiguration from '../../appConfiguration'

export const useSetupPanel = (): void => {
    useEffect(() => {
        document.documentElement.style.setProperty('--accent-color', appConfiguration.colorPalette.accentColor)
        document.title = appConfiguration.appTitle
        const linkElements = [
            { rel: 'icon', href: '%PUBLIC_URL%/favicons/favicon.ico' },
            { rel: 'apple-touch-icon', sizes: '180x180', href: '%PUBLIC_URL%/favicons/apple-touch-icon.png' },
            { rel: 'icon', type: 'image/png', sizes: '32x32', href: '%PUBLIC_URL%/favicons/favicon-32x32.png' },
            { rel: 'icon', type: 'image/png', sizes: '16x16', href: '%PUBLIC_URL%/favicons/favicon-16x16.png' },
        ]

        linkElements.forEach((attrs) => {
            const link = document.createElement('link')
            Object.keys(attrs).forEach((key) => {
                if (appConfiguration.iconUrl !== undefined && key === 'href') {
                    link.setAttribute(key, appConfiguration.iconUrl)
                } else {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    link.setAttribute(key, attrs[key])
                }
            })
            document.head.appendChild(link)
        })
    }, [])
}
