import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../../components/Button/Button'
import Loader from '../../../components/Loader/Loader'
import ActionsModal from '../../../components/Modals/ActionsModal'
import DabRadioModal from '../../../components/Modals/DabRadioModal'
import DeleteModal from '../../../components/Modals/DeleteModal'
import LoadingModal from '../../../components/Modals/LoadingModal'
import DabRadiosTable from '../../../components/Tables/DabRadiosTable'
import TextInput from '../../../components/TextInput/TextInput'
import { getDabRadioAssoc } from '../../../resources/api-constants'
import { COLORS } from '../../../resources/colors'
import { Radio } from '../../../types/data'
import FmWorldAxios from '../../../utility/FmWorldAxios'

interface Props {
    muxId: number
    radios: Radio[]
    onReloadData: () => Promise<void>
}

const DabRadioTab: React.FC<Props> = (props) => {
    const [search, setSearch] = useState('')
    const [selectedRadio, setSelectedRadio] = useState<Radio | null>(null)
    const [radioToDelete, setRadioToDelete] = useState<Radio | null>(null)
    const [showAddRadio, setShowAddRadio] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isCreating, setIsCreating] = useState(false)
    const [error, setError] = useState('')
    
    const [filteredRadios, setFilteredRadios] = useState<Radio[]>([])
    const { t } = useTranslation()

    useEffect(() => {
        if (search) {
            const filtered = props.radios.filter((s) => s.name.toLowerCase().includes(search.toLowerCase()))
            setFilteredRadios(filtered)
        } else {
            setFilteredRadios(props.radios)
        }
    }, [search, props.radios])

    const handleDeleteAssoc = async () => {
        if (radioToDelete) {
            setError('')
            setIsLoading(true)
            try {
                await FmWorldAxios.delete(getDabRadioAssoc(props.muxId, radioToDelete.id))
                setRadioToDelete(null)
                setSelectedRadio(null)
                await props.onReloadData()
            } catch (error: any) {
                console.log('ERROR', error)
                setError(error.toString())
                setIsLoading(false)
            }
        }
    }

    const handleCreateAssoc = async (idRadio: number) => {
        setError('')
        setIsCreating(true)
        try {
            await FmWorldAxios.post(getDabRadioAssoc(props.muxId, idRadio))
            setIsCreating(false)
            setSelectedRadio(null)
            setShowAddRadio(false)
            await props.onReloadData()
        } catch (error: any) {
            console.log('ERROR', error)
            setError(error.toString())
        }
    }

    const actionsModal = [
        {
            text: t('dabs.delete_radio_association'),
            color: COLORS.redDelete,
            onClick: () => setRadioToDelete(selectedRadio),
        },
    ]

    return (
        <div>
            <div className="titleRow">
                <div style={{ flex: 1 }}>
                    <h2 className="title">{t('dabs.associated_radios_mux')}</h2>
                    <h3 className="subtitle" style={{ fontWeight: 400 }}>
                        {t('dabs.manage_radios_mux')}
                    </h3>
                    {error && <p className="u-normal-text u-bold-text u-error-text">{error}</p>}
                </div>
                <div style={{ display: 'flex', flex: 2, gap: 10, alignItems: 'center', justifyContent: 'flex-end' }}>
                    <div style={{ flex: 1 }} />
                    <div style={{ flex: 2 }}>
                        <TextInput
                            value={search}
                            placeholder={t('general.search')}
                            icon="search"
                            iconPosition="right"
                            onTextChange={(v) => setSearch(v)}
                        />
                    </div>
                    <div style={{ flex: 1 }}>
                        <Button
                            label={t('general.refresh')}
                            onPress={() => {
                                void props.onReloadData()
                            }}
                            type="submit"
                            background="rgba(100,38,119,0.14)"
                            border={`2px solid ${COLORS.purple}`}
                            color={COLORS.purple}
                        />
                    </div>
                    <div style={{ flex: 1 }}>
                        <Button
                            label={t('general.add')}
                            onPress={() => {
                                setShowAddRadio(true)
                            }}
                            type="submit"
                        />
                    </div>
                </div>
            </div>
            {isLoading ? (
                <div className="loadingGrid">
                    <Loader />
                </div>
            ) : (
                <div className="u-elements-row" style={{ alignItems: 'flex-start', marginTop: 20 }}>
                    <div style={{ flex: 1 }}>
                        <DabRadiosTable data={filteredRadios} selectRadio={(f) => setSelectedRadio(f)} />
                    </div>
                </div>
            )}

            <ActionsModal
                isVisible={selectedRadio !== null}
                title={t('general.what_to_do')}
                onClose={() => setSelectedRadio(null)}
                actions={actionsModal}
            />
            <LoadingModal isVisible={isCreating} text={t('loadings.updating_mux_radio_association')} />
            <DabRadioModal
                isVisible={showAddRadio}
                onClose={() => setShowAddRadio(false)}
                onSave={(r) => void handleCreateAssoc(r.id as number)}
            />
            <DeleteModal
                onDelete={() => {
                    void handleDeleteAssoc()
                }}
                title={`${t('dabs.delete_associate_radios')} ${radioToDelete?.name}`}
                text={`${t('dabs.want_delete_associate_radios')} ${radioToDelete?.name}?`}
                onClose={() => {
                    setRadioToDelete(null)
                }}
                isVisible={radioToDelete !== null}
            />
        </div>
    )
}

export default DabRadioTab
