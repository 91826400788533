import React, { useEffect, useMemo, useState } from 'react'
import './Modals.sass'
import Modal from '@mui/material/Modal'
import { Radio, UserNetwork } from '../../types/data'
import Button from '../Button/Button'
import { COLORS } from '../../resources/colors'
import TextInput from '../TextInput/TextInput'
import SelectInput, { Option } from '../SelectInput/SelectInput'
import { ReducerData, Reducers } from '../../types/reducers'
import { useSelector } from 'react-redux'
import AsyncSelectInput from '../SelectInput/AsyncSelectInput'
import FmWorldAxios from '../../utility/FmWorldAxios'
import { getRadios } from '../../resources/api-constants'
import { emailRegEx, passwordRegEx } from '../../utility/functions'

interface Props {
    isVisible: boolean
    onClose: () => void
    onSave: (role: number, radios: number[] | null, email?: string, password?: string) => void
    userData?: UserNetwork | null
    idNetwork: number
}

const UserNetworkModal: React.FC<Props> = (props) => {
    const [role, setRole] = useState<number>(0)
    const [email, setEmail] = useState(props.userData ? props.userData.email : '')
    const [radios, setRadios] = useState<Option[] | null>(null)
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const data = useSelector<Reducers, ReducerData>((state) => state.data)

    useEffect(() => {
        if (props.isVisible) {
            setRole(props.userData ? props.userData.idRole : 1)
            setEmail(props.userData ? props.userData.email : '')
            setRadios(
                props.userData
                    ? props.userData.radios.map((r) => {
                          return {
                              id: r.id,
                              value: r.id,
                              label: r.name,
                          }
                      })
                    : null
            )
        }
    }, [props.isVisible])

    const roleValue = () => {
        if (role) {
            const roleFound = data.networkRoles.find((r) => r.id === role)
            if (roleFound) {
                return {
                    id: roleFound.id,
                    value: roleFound.id,
                    label: roleFound.name,
                }
            }
            return null
        }
        return null
    }

    const checkDataIsValid = useMemo(() => {
        return (
            email &&
            emailRegEx.test(email) &&
            (role === 3 || role === 4 || role === 5) &&
            password &&
            passwordRegEx.test(password) &&
            password === confirmPassword
        )
    }, [email, password, confirmPassword, role])

    console.log(role)

    return (
        <Modal
            open={props.isVisible}
            onClose={() => props.onClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className="modalBox actionBox">
                <h2 className="modalBox-title">
                    {props.userData
                        ? `Modifica utente ${props.userData.firstName} ${props.userData.lastName}`
                        : 'Aggiungi utente'}
                </h2>
                <div>
                    {!props.userData ? (
                        <TextInput value={email} onTextChange={(e) => setEmail(e)} label="Email" placeholder="Email" />
                    ) : null}
                    <SelectInput
                        placeholder="Seleziona ruolo"
                        label="Ruolo"
                        value={roleValue()}
                        onChange={(e) => {
                            const val = e as Option
                            const roleFound = data.networkRoles.find((r) => r.id === (val.id as number))
                            if (roleFound) {
                                setRole(roleFound.id)
                            }
                        }}
                        options={data.networkRoles.map((r) => {
                            return {
                                id: r.id,
                                value: r.id,
                                label: r.name,
                            }
                        })}
                    />
                    <AsyncSelectInput
                        label="Radio"
                        isMulti={radios !== null}
                        value={radios}
                        placeholder="Tutte le radio selezionate"
                        fetchOptions={async (input: string) => {
                            if (input.length > 2) {
                                const radios = await FmWorldAxios.get(getRadios(), {
                                    params: { query: input, idNetwork: props.idNetwork },
                                }).then((res) => {
                                    return res.data.items
                                })
                                return radios.map((c: Radio) => {
                                    return {
                                        id: c.id,
                                        value: c.id,
                                        label: c.name,
                                    }
                                })
                            }
                            return []
                        }}
                        onChange={(value) => {
                            if (value) {
                                if (Array.isArray(value as Option[])) {
                                    setRadios(value as Option[])
                                } else {
                                    setRadios([value as Option])
                                }
                            } else {
                                setRadios(null)
                            }
                        }}
                    />
                    {!props.userData ? (
                        <>
                            <TextInput
                                value={password}
                                onTextChange={(e) => setPassword(e)}
                                label="Password"
                                placeholder="Password"
                                type="password"
                            />
                            <TextInput
                                value={confirmPassword}
                                onTextChange={(e) => setConfirmPassword(e)}
                                label="Conferma Password"
                                placeholder="Conferma Password"
                                type="password"
                            />
                        </>
                    ) : null}
                </div>
                <div className="modalBox-buttonsRow">
                    <div style={{ flex: '1 1 100%' }} />
                    <Button
                        label="Annulla"
                        border={'1px solid rgba(0,0,0,0.07)'}
                        onPress={() => props.onClose()}
                        type="secondary"
                        color={COLORS.darkGrey}
                        background="#fff"
                    />
                    <Button
                        disabled={
                            props.userData
                                ? email === props.userData.email &&
                                  role === props.userData.idRole &&
                                  JSON.stringify(radios) === JSON.stringify(
                                      props.userData.radios.map((r) => ({
                                          id: r.id,
                                          value: r.id,
                                          label: r.name,
                                      }))
                                  )
                                : !checkDataIsValid
                        }
                        label={props.userData ? 'Aggiorna' : 'Aggiungi'}
                        onPress={() => {
                            const radiosToSend = radios
                                ? radios.map((r) => {
                                      return r.id as number
                                  })
                                : null
                            if (radiosToSend === null) {
                                props.onSave(role, null, email, password)
                            }       
                            if (props.userData) {
                                props.onSave(role, radiosToSend)
                            } else {
                                props.onSave(role, radiosToSend, email, password)
                            }
                        }}
                        type="submit"
                        color="#fff"
                        background={COLORS.purple}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default UserNetworkModal
