export enum Languages {
    italian = 'it',
    english = 'en',
    french = 'fr',
}

export const LanguagesArray = [
    {
        id: Languages.english,
        value: 'English',
        flag: 'https://22hbgtest.s3.eu-west-2.amazonaws.com/Peperoni/contents/general/languages/en.svg',
    },
    {
        id: Languages.italian,
        value: 'Italiano',
        flag: 'https://22hbgtest.s3.eu-west-2.amazonaws.com/Peperoni/contents/general/languages/it.svg',
    },
    {
        id: Languages.french,
        value: 'Français',
        flag: 'https://22hbgtest.s3.eu-west-2.amazonaws.com/Peperoni/contents/general/languages/fr.svg',
    },
]
