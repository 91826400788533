import React, { useEffect, useState } from 'react'
import { Position } from '../../../types/data'
import Button from '../../Button/Button'
import TextInput from '../../TextInput/TextInput'

interface Props {
    position: number
    radio: Position
    length: number
    onPressButton: (newPosition: number) => void
}

const PositionItem: React.FC<Props> = (props) => {
    const [currentPosition, setCurrentPosition] = useState<string>(props.position.toString())

    useEffect(() => {
        setCurrentPosition(props.position.toString())
    }, [props.position, props.radio])

    return (
        <div style={{ display: 'flex', gap: 10 }}>
            <TextInput
                value={currentPosition}
                alignCenter
                bordered
                onTextChange={(e) => {
                    if (e === '0') {
                        setCurrentPosition('1')
                        return
                    }
                    if (parseInt(e, 10) > props.length) {
                        setCurrentPosition(props.length.toString())
                        return
                    }
                    setCurrentPosition(e)
                }}
                width={80}
            />
            {currentPosition && props.position !== parseInt(currentPosition, 10) ? (
                <Button
                    label="✓"
                    onPress={() => {
                        props.onPressButton(parseInt(currentPosition, 10))
                    }}
                    type="submit"
                    width={50}
                />
            ) : null}
        </div>
    )
}

export default PositionItem
