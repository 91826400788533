/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useEffect, useState } from 'react'
import CardContainer from '../../components/CardContainer/CardContainer'
import PageSidebarContainer from '../../components/PageSidebarContainer'
import { useLocation } from 'react-router-dom'
import BundlesTab from './tabs/BundlesTab'
import '../../styles/pages/NetworksPage.sass'
import UsersTab from './tabs/UsersTab'
import Loader from '../../components/Loader/Loader'
import FmWorldAxios from '../../utility/FmWorldAxios'
import { getNetworkDetail, getNetworkUserPermissions } from '../../resources/api-constants'
import { Grants } from '../../utility/Grants'
import PlayersTab from './tabs/PlayersTab'
import { PageName } from '../../components/Header/Header'
import { Network } from '../../types/data'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setPageHeader } from '../../store/actions/graphic'
import { ROUTES } from '../../resources/routes-constants'
import RadiosTab from './tabs/RadiosTab'
import ApiTab from './tabs/ApiTab'
import { Reducers, ReducerData } from '../../types/reducers'

enum Tab {
    Bundles = 'bundles',
    Users = 'users',
    Players = 'players',
    Radios = 'radios',
    ApiKeys = 'api_keys',
}

const NetworkDetailPage: React.FC = () => {
    const [selectedTab, setSelectedTab] = useState(Tab.Bundles)
    const [networkId, setNetworkId] = useState('')
    const [networkName, setNetworkName] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>('')
    const [permissions, setPermissions] = useState<Grants[]>([])
    const location = useLocation()
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const data = useSelector<Reducers, ReducerData>((state) => state.data)
    const selectedNetwork = data.selectedNetwork

    const renderTab = () => {
        switch (selectedTab) {
            case Tab.Bundles:
                return <BundlesTab permissions={permissions} />
            case Tab.Users:
                return <UsersTab />
            case Tab.Players:
                return <PlayersTab permissions={permissions} />
            case Tab.Radios:
                return <RadiosTab permissions={permissions} />
            case Tab.ApiKeys:
                return <ApiTab permissions={permissions} />
            default:
                return <BundlesTab permissions={permissions} />
        }
    }

    const fetchNetworkDetail = async () => {
        setError('')
        setIsLoading(true)
        try {
            if (!selectedNetwork) {
                return
            }
            const network: Network = await FmWorldAxios.get(getNetworkDetail(selectedNetwork.id)).then((res) => {
                console.log(res.data)
                return res.data
            })
            await fetchPermissions()
            if (network) {
                dispatch(
                    setPageHeader({
                        parent: {
                            name: t('headers.networks'),
                            route: ROUTES.NETWORK_LIST_ROUTE,
                        },
                        name: network.name,
                    })
                )
            }
        } catch (error: any) {
            setError(error.toString())
        }
        setIsLoading(false)
    }

    useEffect(() => {
        if (networkId) {
            console.log(networkId)
            void fetchNetworkDetail()
        }
    }, [networkId])

    const fetchPermissions = async () => {
        try {
            if (!selectedNetwork) {
                return
            }
            const permissionsFetch = await FmWorldAxios.get(getNetworkUserPermissions(selectedNetwork?.id)).then(
                (res) => {
                    return res.data
                }
            )
            console.log(permissionsFetch)
            setPermissions(permissionsFetch)
        } catch (error: any) {
            setError(error.toString())
        }
    }

    useEffect(() => {
        const idNetwork = location.pathname.replace('/network/', '')
        console.log(idNetwork)
        setNetworkId(idNetwork)
        if (location.state) {
            setNetworkName((location.state as PageName).name)
        }
    }, [location.pathname])

    useEffect(() => {
        if (networkId) {
            void fetchPermissions()
        }
    }, [networkId])

    if (isLoading) {
        return <Loader />
    }

    return (
        <PageSidebarContainer>
            {error && <p className="u-normal-text u-bold-text u-error-text">{error}</p>}
            <CardContainer>{renderTab()}</CardContainer>
        </PageSidebarContainer>
    )
}

export default NetworkDetailPage
