import React from 'react'
import { useTranslation } from 'react-i18next'
import './Loader.css'

interface Props {
    text?: string
}

const Loader: React.FC<Props> = (props) => {
    const { t } = useTranslation()

    return (
        <div className="loaderContainer">
            <div className="lds-facebook">
                <div />
                <div />
                <div />
            </div>
            <p style={{ textAlign: 'center' }}>{props.text ? props.text : t('loadings.generic_loading')}</p>
        </div>
    )
}

export default Loader
