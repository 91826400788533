import React, { useEffect, useState } from 'react'

interface Props {
    children?: any
}

const CustomSliderDetector: React.FC<Props> = (props) => {
    const [currentDotIndex, setCurrentDotIndex] = useState(0)
    const slider = document.getElementsByClassName('sliderComponent')

    const handleDragEnd = () => {
        setTimeout(() => {
            const dots: Element[] = Array.from(document.getElementsByClassName('carousel__dot'))
            const foundDot = dots.find((d) => d.className.indexOf('carousel__dot--selected') !== -1)
            if (foundDot) {
                setCurrentDotIndex(parseInt(foundDot.classList[2].replace('carousel__dot--', ''), 10))
            }
        }, 50)
    }

    useEffect(() => {
        const dotsParent = document.getElementsByClassName('dotCustom')
        if (dotsParent.length > 0) {
            dotsParent[0].scrollTo({ left: currentDotIndex * 15 - 15, behavior: 'smooth' })
        }
    }, [currentDotIndex])

    useEffect(() => {
        if (slider && slider.length > 0) {
            slider[0].addEventListener('mouseup', handleDragEnd)
        }
        return () => {
            if (slider && slider.length > 0) {
                slider[0].removeEventListener('mouseup', handleDragEnd)
            }
        }
    }, [slider])

    return <div id="customScroll">{props.children}</div>
}

export default CustomSliderDetector
