import MaterialTable, { Column } from 'material-table'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { PendingRadio, PendingRadioStatus } from '../../types/data'
import { Reducers, ReducerGraphic } from '../../types/reducers'
import { getLangTable } from '../../utility/functions'
import { Languages } from '../../utility/Languages'
import FixedTableTheme from '../FixedTableTheme'

interface Props {
    data: PendingRadio[]
}

const PendingElementsTable: React.FC<Props> = (props) => {
    const graphic = useSelector<Reducers, ReducerGraphic>((state) => state.graphic)
    const { t } = useTranslation()

    const getElementStatusLabel = (status: string) => {
        switch (status) {
            case PendingRadioStatus.PENDING_APPROVAL:
                return t('radios.statuses.pending_approval')
            case PendingRadioStatus.PENDING_PAYMENT:
                return t('radios.statuses.pending_payment')
            case PendingRadioStatus.APPROVED:
                return t('radios.statuses.approved')
            case PendingRadioStatus.REJECTED:
                return t('radios.statuses.rejected')
            default:
                return ''
        }
    }

    const columns: Column<PendingRadio>[] = [
        {
            title: t('radios.status') as string,
            field: 'status',
            render: (radio: PendingRadio) => {
                return <p>{getElementStatusLabel(radio.status)}</p>
            },
        },
        {
            title: t('auth.first_name') as string,
            field: 'name',
            render: (radio: PendingRadio) => {
                return <p>{radio.name}</p>
            },
        },
        {
            title: t('radios.notes') as string,
            field: 'notes',
            render: (radio: PendingRadio) => {
                return <p>{radio.notes}</p>
            },
        },
    ]

    const data = useMemo(() => {
        return props.data
    }, [props.data])

    return (
        <FixedTableTheme>
            <MaterialTable
                columns={columns}
                data={data}
                actions={[]}
                title=""
                localization={getLangTable(graphic.currentLanguage as Languages)}
                options={{
                    actionsColumnIndex: -1,
                    search: false,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 50],
                    actionsCellStyle: {
                        border: '0',
                    },
                    toolbar: false,
                }}
                style={{
                    boxShadow: 'none',
                }}
            />
        </FixedTableTheme>
    )
}

export default PendingElementsTable
