import React from 'react'
import './Modals.sass'
import Modal from '@mui/material/Modal'
import Loader from '../Loader/Loader'

interface Props {
    isVisible: boolean
    text: string
}

const LoadingModal: React.FC<Props> = (props) => {
    return (
        <Modal
            open={props.isVisible}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="backdropWhite"
        >
            <div className="modalLoading">
                <Loader text={props.text} />
            </div>
        </Modal>
    )
}

export default LoadingModal
