import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Button from '../../../components/Button/Button'
import RadioShowsGrid from '../../../components/Grids/RadioShowsGrid'
import TextInput from '../../../components/TextInput/TextInput'
import { getRadioDetail } from '../../../resources/api-constants'
import { COLORS } from '../../../resources/colors'
import { ROUTES } from '../../../resources/routes-constants'
import { Radio } from '../../../types/data'
import FmWorldAxios from '../../../utility/FmWorldAxios'
import { Grants } from '../../../utility/Grants'

interface Props {
    idRadio: number
    idNetwork: number | null
    permissions: Grants[]
}

const ShowsTab: React.FC<Props> = (props) => {
    const [search, setSearch] = useState('')
    const showRadiosGridRef = useRef(null)
    const [currentRadio, setCurrentRadio] = useState<Radio | null>(null)
    const [error, setError] = useState('')
    const navigate = useNavigate()
    const { t } = useTranslation()

    const fethRadioDetail = async () => {
        setError('')
        try {
            const radioName = await FmWorldAxios.get(getRadioDetail(props.idRadio)).then((res) => {
                return res.data
            })
            setCurrentRadio(radioName)
        } catch (error: any) {
            console.log('ERROR', error)
            setError(error.toString())
        }
    }

    useEffect(() => {
        void fethRadioDetail()
    }, [props.idRadio])

    return (
        <div>
            <div className="titleRow">
                <div style={{ flex: 1 }}>
                    <h2 className="title">{t('radios.shows')}</h2>
                    <h3 className="subtitle" style={{ fontWeight: 400 }}>
                        {t('radios.manage_shows')}
                    </h3>
                    {error && <p className="u-normal-text u-bold-text u-error-text">{error}</p>}
                </div>
                <div style={{ display: 'flex', flex: 2, gap: 10, alignItems: 'center', justifyContent: 'flex-end' }}>
                    <div style={{ flex: 1 }} />
                    <div style={{ flex: 2 }}>
                        <TextInput
                            value={search}
                            placeholder={t('general.search')}
                            icon="search"
                            iconPosition="right"
                            onTextChange={(v) => setSearch(v)}
                        />
                    </div>
                    <div style={{ flex: 1 }}>
                        <Button
                            label={t('general.refresh')}
                            onPress={() => {
                                setSearch('')
                            }}
                            type="submit"
                            background="rgba(100,38,119,0.14)"
                            border={`2px solid ${COLORS.purple}`}
                            color={COLORS.purple}
                        />
                    </div>
                    {props.permissions.includes(Grants.EDIT_RADIO) ? (
                        <div style={{ flex: 1 }}>
                            <Button
                                label={t('general.add')}
                                onPress={() => {
                                    navigate(`${ROUTES.SHOW_DETAIL_ROUTE}new`, {
                                        state: {
                                            name: t('shows.new_show'),
                                            parent: { id: props.idRadio, name: currentRadio ? currentRadio.name : '' },
                                            idNetwork: props.idNetwork,
                                        },
                                    })
                                }}
                                type="submit"
                            />
                        </div>
                    ) : null}
                </div>
            </div>
            <div>
                <RadioShowsGrid
                    nameRadio={currentRadio ? currentRadio.name : ''}
                    idNetwork={props.idNetwork}
                    idRadio={props.idRadio}
                    search={search}
                    ref={showRadiosGridRef}
                />
            </div>
        </div>
    )
}

export default ShowsTab
