import React, { useEffect, useState } from 'react'
import './Modals.sass'
import Modal from '@mui/material/Modal'
import { InteractiveMuxItem } from '../../types/data'
import TextInput from '../TextInput/TextInput'
import Button from '../Button/Button'
import { COLORS } from '../../resources/colors'
import { useTranslation } from 'react-i18next'
import CheckboxInput from '../CheckboxInput/CheckboxInput'

interface Props {
    isVisible: boolean
    onClose: () => void
    onSave: (newElement: InteractiveMuxItem | null) => void
    item: InteractiveMuxItem | null
}

const InteractiveMUXModal: React.FC<Props> = (props) => {
    const [itemToEdit, setItemToEdit] = useState<InteractiveMuxItem | null>(props.item)
    const { t } = useTranslation()

    useEffect(() => {
        if (props.isVisible) {
            setItemToEdit(props.item)
        }
    }, [props.isVisible])

    return (
        <Modal
            open={props.isVisible}
            onClose={() => props.onClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className="modalBox actionBox">
                <h2 className="modalBox-title">
                    {itemToEdit && itemToEdit.id_radio ? 'Modifica licenza' : 'Crea nuova licenza'}
                </h2>
                <div>
                    <TextInput
                        value={itemToEdit && itemToEdit.id_radio ? itemToEdit.id_radio.toString() : ''}
                        label={'ID Radio'}
                        onTextChange={(e) =>
                            setItemToEdit({
                                ...itemToEdit,
                                id_radio: parseInt(e, 10),
                            })
                        }
                    />
                    <div style={{ margin: '20px 0' }}>
                        <TextInput
                            value={itemToEdit ? itemToEdit.license_code ?? '' : ''}
                            label={'Codice di licenza'}
                            onTextChange={(e) =>
                                setItemToEdit({
                                    ...itemToEdit,
                                    license_code: e,
                                })
                            }
                        />
                    </div>
                </div>
                <div style={{ margin: '20px 0' }}>
                    <CheckboxInput
                        values={[
                            {
                                label: '',
                                status: itemToEdit && itemToEdit.status ? itemToEdit.status : false,
                            },
                        ]}
                        onClick={() => {
                            setItemToEdit({
                                ...itemToEdit,
                                status: !itemToEdit?.status,
                            })
                        }}
                        label={t('interactiveMux.status')}
                    />
                </div>
                <div className="modalBox-buttonsRow">
                    <div style={{ flex: '1 1 100%' }} />
                    <Button
                        label={t('general.cancel')}
                        border={'1px solid rgba(0,0,0,0.07)'}
                        onPress={() => props.onClose()}
                        type="secondary"
                        color={COLORS.darkGrey}
                        background="#fff"
                    />
                    <Button
                        disabled={!itemToEdit || !itemToEdit?.id_radio}
                        label={itemToEdit && itemToEdit.id_radio ? t('general.update') : t('general.add')}
                        onPress={() => props.onSave(itemToEdit)}
                        type="submit"
                        color="#fff"
                        background={COLORS.purple}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default InteractiveMUXModal
