import { ReducerUser, ReduxActionData } from '../../types/reducers'
import { USER_ACTIONS } from '../actions/user'

const initialState: ReducerUser = {
    user: null,
}

const userState: (state: ReducerUser, action: ReduxActionData<any, USER_ACTIONS>) => ReducerUser = (
    state = initialState,
    action: ReduxActionData<any, USER_ACTIONS>
) => {
    switch (action.type) {
        case USER_ACTIONS.SET_USER_DATA:
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.payload,
                },
            }
        case USER_ACTIONS.LOGOUT_USER:
            return initialState
        default:
            return state
    }
}

export default userState
