import React, { useCallback, useEffect, useState } from 'react'
import './Modals.sass'
import Modal from '@mui/material/Modal'
import Button from '../Button/Button'
import { COLORS } from '../../resources/colors'
import { useTranslation } from 'react-i18next'
import TextInput from '../TextInput/TextInput'
import CheckboxInput from '../CheckboxInput/CheckboxInput'
import axios from 'axios'
import FmWorldAxios from '../../utility/FmWorldAxios'
import { Radio } from '../../types/data'

export interface ParserConfiguration {
    isMonitorActive: boolean
    monitorUrl: string
    parser: {
        trackTitle: string | null
        trackArtist: string | null
        trackArtwork: string | null
        fetchTrackItunesArtwork: boolean
        trackTitleSeparator: string | null
        showTitle: string | null
        showSpeakers: string | null
        showArtwork: string | null
    }
}

interface Props {
    configuration: ParserConfiguration | null
    radio: Radio | null
    isVisible: boolean
    onClose: () => void
    onSave: (newConfig: ParserConfiguration) => void
}

const defaultParserConfiguration: ParserConfiguration = {
    isMonitorActive: true,
    monitorUrl: '',
    parser: {
        trackTitle: null,
        trackArtist: null,
        trackArtwork: null,
        fetchTrackItunesArtwork: false,
        trackTitleSeparator: '-',
        showTitle: null,
        showSpeakers: null,
        showArtwork: null,
    },
}

const OnAirMetaModal: React.FC<Props> = (props) => {
    const [parserConfiguration, setParserConfiguration] = useState<ParserConfiguration>(defaultParserConfiguration)
    const [rawObject, setRawObject] = useState<string | object>({})
    const [validatedObject, setValidatedObject] = useState<string | object>({})
    const [isLoadingValidatedObj, setIsLoadingValidatedObj] = useState(false)
    const [isLoadingRawObj, setIsLoadingRawObj] = useState(false)
    const { t } = useTranslation()

    useEffect(() => {
        if (!props.configuration) return
        setParserConfiguration(props.configuration)
    }, [props.configuration])

    const fetchValidatedObject = async () => {
        try {
            setIsLoadingValidatedObj(true)
            await fetchOriginalObject()
            const configCopy: ParserConfiguration = JSON.parse(JSON.stringify(parserConfiguration))
            if (configCopy.parser.fetchTrackItunesArtwork) {
                configCopy.parser.trackArtwork = null
            }
            if (configCopy.parser.trackArtist) {
                configCopy.parser.trackTitleSeparator = null
            }
            Object.keys(configCopy.parser).forEach((key) => {
                if (configCopy.parser[key as keyof ParserConfiguration['parser']] === null && key !== 'trackTitleSeparator') {
                    delete configCopy.parser[key as keyof ParserConfiguration['parser']]
                }
            })
            const data = await FmWorldAxios.post('system/test-parser', configCopy).then(
                (response) => response.data
            )
            if (data) setValidatedObject(data)
        } catch (error) {
            console.error(error)
        }
        setIsLoadingValidatedObj(false)
    }

    const fetchOriginalObject = async () => {
        try {
            if (!parserConfiguration.monitorUrl) return
            setIsLoadingRawObj(true)
            const data = await axios.get(parserConfiguration.monitorUrl).then((response) => response.data)
            if (data) setRawObject(data)
        } catch (error) {
            console.error(error)
        }
        setIsLoadingRawObj(false)
    }

    /**
     * Retrieves all the valid keys from an object recursively.
     *
     * @param obj - The object from which to retrieve the valid keys.
     * @param prefix - The prefix to be added to the keys.
     * @returns An array containing all the valid keys from the object, including nested keys if present.
     */
    const getAllValidKeys = useCallback(
        (obj: any, prefix = ''): any =>
            Object.keys(obj).reduce((res: any, el) => {
                if (typeof obj[el] === 'object' && obj[el] !== null) {
                    return [...res, el, ...getAllValidKeys(obj[el])]
                }
                return [...res, prefix + el]
            }, []),
        []
    )

    /**
     * Takes an object as input and returns a formatted string representation of the object.
     * Replaces the keys in the stringified object with HTML labels for better readability.
     *
     * @param {string | object} object - The object to be formatted.
     * @returns {string} - The formatted string representation of the input object.
     */
    const getPrettyJSONObject = useCallback((object: string | object) => {
        if (typeof object === 'string') return object
        if (Object.keys(object).length === 0) return ''

        const validKeys = getAllValidKeys(object)
        const stringifiedObject = JSON.stringify(object, null, 3)
        let finalStringifiedObject: any = stringifiedObject

        validKeys.forEach(
            (key: string) =>
                (finalStringifiedObject = finalStringifiedObject.replaceAll(
                    `"${key}"`,
                    `<label class="parsed-object-key">${key}</label>`
                ))
        )

        return finalStringifiedObject
    }, [])

    return (
        <Modal
            open={props.isVisible}
            onClose={() => props.onClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className="modalBox actionBox">
                <h2 className="modalBox-title">{t('radios.on_air_meta')}</h2>
                <div className="on-air-meta-modal-body">
                    <div className="on-air-meta-modal-body-column">
                        <div className="on-air-meta-modal-body-column__section">
                            <div className="on-air-meta-modal-body-column__section__subtitle">
                                {t('onAirParser.firstModalHint')}
                            </div>
                            <TextInput
                                value={parserConfiguration.monitorUrl}
                                onTextChange={(e) => {
                                    setParserConfiguration({ ...parserConfiguration, monitorUrl: e })
                                }}
                                placeholder="https://examplestream.com/data.json"
                                label={t('general.url')}
                            />
                            <CheckboxInput
                                values={[
                                    {
                                        label: '',
                                        status: parserConfiguration.isMonitorActive,
                                    },
                                ]}
                                onClick={() => {
                                    setParserConfiguration({
                                        ...parserConfiguration,
                                        isMonitorActive: !parserConfiguration.isMonitorActive,
                                    })
                                }}
                                label={t('interactiveMux.status')}
                            />
                        </div>
                        <div className="on-air-meta-modal-body-column__section">
                            <div className="on-air-meta-modal-body-column__section__subtitle">
                                {t('onAirParser.secondModalHint')}
                            </div>
                            <div className="on-air-meta-modal-body-column__section__subtitle">
                                Es: metadata.show.title, metadata.image, metadata.show[0].speakers, ...
                            </div>
                            <TextInput
                                value={parserConfiguration.parser.showTitle || ''}
                                onTextChange={(e) => {
                                    setParserConfiguration({
                                        ...parserConfiguration,
                                        parser: { ...parserConfiguration.parser, showTitle: e },
                                    })
                                }}
                                label={t('onAirParser.showTitle')}
                            />
                            <TextInput
                                value={parserConfiguration.parser.showArtwork || ''}
                                onTextChange={(e) => {
                                    setParserConfiguration({
                                        ...parserConfiguration,
                                        parser: { ...parserConfiguration.parser, showArtwork: e },
                                    })
                                }}
                                label={t('onAirParser.showImage')}
                            />
                            <TextInput
                                value={parserConfiguration.parser.showSpeakers || ''}
                                onTextChange={(e) => {
                                    setParserConfiguration({
                                        ...parserConfiguration,
                                        parser: { ...parserConfiguration.parser, showSpeakers: e },
                                    })
                                }}
                                label={t('onAirParser.speakers')}
                            />
                        </div>
                        <div className="on-air-meta-modal-body-column__section">
                            <div className="on-air-meta-modal-body-column__section__subtitle">
                                {t('onAirParser.thirdModalHint')}
                            </div>
                            <TextInput
                                value={parserConfiguration.parser.trackTitle || ''}
                                onTextChange={(e) => {
                                    setParserConfiguration({
                                        ...parserConfiguration,
                                        parser: { ...parserConfiguration.parser, trackTitle: e },
                                    })
                                }}
                                label={t('onAirParser.trackTitle')}
                            />
                            <TextInput
                                value={parserConfiguration.parser.trackTitleSeparator || ''}
                                onTextChange={(e) => {
                                    setParserConfiguration({
                                        ...parserConfiguration,
                                        parser: { ...parserConfiguration.parser, trackTitleSeparator: e },
                                    })
                                }}
                                label={t('onAirParser.trackTitleSeparator')}
                                disabled={!parserConfiguration.parser.trackTitle}
                            />
                            <TextInput
                                value={parserConfiguration.parser.trackArtist || ''}
                                onTextChange={(e) => {
                                    setParserConfiguration({
                                        ...parserConfiguration,
                                        parser: { ...parserConfiguration.parser, trackArtist: e },
                                    })
                                }}
                                label={t('onAirParser.artists')}
                            />
                            <TextInput
                                value={parserConfiguration.parser.trackArtwork || ''}
                                onTextChange={(e) => {
                                    setParserConfiguration({
                                        ...parserConfiguration,
                                        parser: { ...parserConfiguration.parser, trackArtwork: e },
                                    })
                                }}
                                label={t('onAirParser.trackArtwork')}
                                disabled={parserConfiguration.parser.fetchTrackItunesArtwork}
                            />
                            <CheckboxInput
                                values={[
                                    {
                                        label: '',
                                        status: parserConfiguration.parser.fetchTrackItunesArtwork,
                                    },
                                ]}
                                onClick={() => {
                                    setParserConfiguration({
                                        ...parserConfiguration,
                                        parser: {
                                            ...parserConfiguration.parser,
                                            fetchTrackItunesArtwork:
                                                !parserConfiguration.parser.fetchTrackItunesArtwork,
                                        },
                                    })
                                }}
                                label={t('onAirParser.fetchFromItunes')}
                            />
                        </div>
                    </div>
                    <div className="on-air-meta-modal-body-column">
                        <div className="on-air-meta-modal-body-object-container">
                            <Button
                                label={t('onAirParser.recover')}
                                border={'1px solid rgba(0,0,0,0.07)'}
                                onPress={() => void fetchOriginalObject()}
                                type="secondary"
                                color={COLORS.darkGrey}
                                background="#eee"
                                disabled={!parserConfiguration.monitorUrl || isLoadingRawObj}
                            />
                            <div style={{ borderBottom: '1px solid rgba(0,0,0,0.07)', marginBottom: 8 }}>
                                {t('onAirParser.rawObject')}
                            </div>
                            {isLoadingRawObj ? (
                                <div>{t('loadings.generic_loading')}...</div>
                            ) : (
                                <div
                                    style={{ whiteSpace: 'break-spaces' }}
                                    dangerouslySetInnerHTML={{ __html: getPrettyJSONObject(rawObject) }}
                                />
                            )}
                        </div>
                        <div className="on-air-meta-modal-body-object-container">
                            <Button
                                label={t('onAirParser.recover')}
                                border={'1px solid rgba(0,0,0,0.07)'}
                                onPress={() => void fetchValidatedObject()}
                                type="secondary"
                                color={COLORS.darkGrey}
                                background="#eee"
                                disabled={
                                    !parserConfiguration.monitorUrl ||
                                    !parserConfiguration.parser.trackTitle ||
                                    isLoadingValidatedObj
                                }
                            />
                            <div style={{ borderBottom: '1px solid rgba(0,0,0,0.07)', marginBottom: 8 }}>
                                {t('onAirParser.validatedObject')}
                            </div>
                            {isLoadingValidatedObj ? (
                                <div>{t('loadings.generic_loading')}...</div>
                            ) : (
                                <div
                                    style={{ whiteSpace: 'break-spaces' }}
                                    dangerouslySetInnerHTML={{ __html: getPrettyJSONObject(validatedObject) }}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className="modalBox-buttonsRow">
                    <div style={{ flex: '1 1 100%' }} />
                    <Button
                        label={t('general.cancel')}
                        border={'1px solid rgba(0,0,0,0.07)'}
                        onPress={() => props.onClose()}
                        type="secondary"
                        color={COLORS.darkGrey}
                        background="#fff"
                    />
                    <Button
                        disabled={!parserConfiguration.monitorUrl || !parserConfiguration.parser.trackTitle}
                        label={!props.configuration ? t('general.add') : t('general.update')}
                        onPress={() => props.onSave(parserConfiguration)}
                        type="submit"
                        color="#fff"
                        background={COLORS.purple}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default OnAirMetaModal
