import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import Button from '../components/Button/Button'
import CardContainer from '../components/CardContainer/CardContainer'
import LoadingModal from '../components/Modals/LoadingModal'
import PageSidebarContainer from '../components/PageSidebarContainer'
import TextInput from '../components/TextInput/TextInput'
import { COLORS } from '../resources/colors'
import { setPageHeader } from '../store/actions/graphic'
import { fetchUserData } from '../store/actions/thunk_actions'
import { Reducers, ReducerUser } from '../types/reducers'
import { getRequestHeader, passwordRegEx } from '../utility/functions'
import { EmailAuthProvider, getAuth, reauthenticateWithCredential, updatePassword } from 'firebase/auth'
import { User as FirebaseUser } from 'firebase/auth'
import { firebaseUserDataPut } from '../resources/api-constants'
import { User } from '../types/user'
import FmWorldAxios from '../utility/FmWorldAxios'

const UserPage: React.FC = () => {
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)

    const [userToEdit, setUserToEdit] = useState<User | null>(null)
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [oldPassword, setOldPassword] = useState('')
    const [isUpdating, setIsUpdating] = useState(false)
    const logoRef = useRef<HTMLImageElement>(null)
    const [error, setError] = useState('')
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(true)

        if (user.user) {
            setUserToEdit(user.user as unknown as User | null)
        }

        setIsLoading(false)
    }, [user.user])

    useEffect(() => {
        setIsLoading(true)
        dispatch(
            setPageHeader({
                parent: null,
                name: t('headers.user_profile'),
            })
        )
        setIsLoading(false)
    }, [])

    const handleInsertLogo = (e: any, ref: React.RefObject<HTMLImageElement>, onChange: (img: File) => void) => {
        if (e.target.files[0] && userToEdit) {
            const image = e.target.files[0]
            if (image) {
                onChange(image)
                const fr = new FileReader()
                fr.onload = function () {
                    if (ref && ref.current && fr.result) {
                        ref.current.src = fr.result as string
                    }
                }
                fr.readAsDataURL(image)
            }
        }
    }

    const handleUpdateUser = async () => {
        if (userToEdit && user.user) {
            setIsUpdating(true)
            try {
                const formData = new FormData()
                Object.keys(userToEdit).map((key) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    const currentField = userToEdit[key as any]
                    formData.append(key, Array.isArray(currentField) ? JSON.stringify(currentField) : currentField)
                    return
                })
                await FmWorldAxios.patch(
                    firebaseUserDataPut(user.user.id),
                    {
                        updated_on: new Date().toISOString(),
                        first_name: userToEdit?.first_name,
                        last_name: userToEdit?.last_name,
                    },
                    getRequestHeader(user.user.authToken ?? '')
                )
                await dispatch(fetchUserData(user.user.id))
            } catch (error: any) {
                setError(error.toString())
            }
            setIsUpdating(false)
        }
    }

    const handleReauthenticate = async (user: FirebaseUser, currentPassword: string) => {
        const credential = EmailAuthProvider.credential(user.email || '', currentPassword)
        return reauthenticateWithCredential(user, credential)
    }

    const handleUpdatePassword = async () => {
        const auth = getAuth()
        const user = auth.currentUser

        if (password && confirmPassword && passwordRegEx.test(password) && password === confirmPassword) {
            setIsUpdating(true)
            try {
                if (user) {
                    await handleReauthenticate(user, oldPassword)
                    await updatePassword(user, password)
                }
            } catch (error: any) {
                setError(error.toString())
            }
            setIsUpdating(false)
        } else {
            setError(
                'Password must be at least 6 characters long and contain at least one uppercase letter, one lowercase letter, one number and one special character.'
            )
        }
    }

    return (
        <PageSidebarContainer>
            <CardContainer>
                <div className="titleRow">
                    <div style={{ flex: 1 }}>
                        <h2 className="title">{t('users.your_profile')}</h2>
                        <h3 className="subtitle" style={{ fontWeight: 400 }}>
                            {t('users.manage_your_profile')}
                        </h3>
                        {error && <p className="u-normal-text u-bold-text u-error-text">{error}</p>}
                    </div>
                    <div
                        style={{ display: 'flex', flex: 2, gap: 10, alignItems: 'center', justifyContent: 'flex-end' }}
                    >
                        <div style={{ flex: 4 }} />
                    </div>
                </div>
                {userToEdit && !isLoading ? (
                    <div>
                        <div style={{ borderBottom: '2px solid rgba(0,0,0,0.07)', padding: '20px 0' }}>
                            <div className="u-elements-row">
                                <div className="uploadImage">
                                    <input
                                        className="uploadImage-upload"
                                        type="file"
                                        accept="image/*"
                                        onChange={(event) =>
                                            handleInsertLogo(event, logoRef, () => {
                                                setUserToEdit({
                                                    ...userToEdit,
                                                })
                                            })
                                        }
                                    />
                                </div>
                                <p className="captionImg" hidden>
                                    {t('users.edit_profile_image')}
                                    <br />({t('radios.500_500_px')}).
                                </p>
                            </div>
                            <div style={{ padding: '20px 0' }} className="u-elements-row">
                                <TextInput
                                    label={t('auth.first_name')}
                                    value={userToEdit.first_name}
                                    onTextChange={(text) => {
                                        setUserToEdit({
                                            ...userToEdit,
                                            first_name: text,
                                        })
                                    }}
                                    bordered
                                />
                                <TextInput
                                    label={t('auth.last_name')}
                                    value={userToEdit.last_name}
                                    onTextChange={(text) => {
                                        setUserToEdit({
                                            ...userToEdit,
                                            last_name: text,
                                        })
                                    }}
                                    bordered
                                />
                            </div>
                            <div style={{ flex: 1 }}>
                                <Button
                                    label={t('general.updateUser')}
                                    onPress={() => {
                                        void handleUpdateUser()
                                    }}
                                    disabled={!userToEdit}
                                    type="submit"
                                />
                            </div>
                        </div>
                        <div style={{ padding: '20px 0' }} className="u-elements-row">
                            <TextInput
                                label={t('auth.email')}
                                value={userToEdit.email}
                                onTextChange={() => {
                                    return
                                }}
                                bordered
                                disabled
                            />
                            <TextInput
                                label={t('auth.old_password')}
                                value={oldPassword}
                                type="password"
                                placeholder={t('auth.password')}
                                onTextChange={(text) => {
                                    setOldPassword(text)
                                }}
                                bordered
                            />
                            <TextInput
                                label={t('auth.password')}
                                value={password}
                                type="password"
                                placeholder={t('auth.password')}
                                onTextChange={(text) => {
                                    setPassword(text)
                                }}
                                borderColor={password && !passwordRegEx.test(password) ? COLORS.redDelete : ''}
                                bordered
                            />
                            <TextInput
                                label={t('auth.confirm_password')}
                                value={confirmPassword}
                                type="password"
                                placeholder={t('auth.password')}
                                onTextChange={(text) => {
                                    setConfirmPassword(text)
                                }}
                                borderColor={password && password !== confirmPassword ? COLORS.redDelete : ''}
                                bordered
                            />
                        </div>
                        <p className="u-normal-text" style={{ opacity: 0.7 }}>
                            {t('auth.password_advice')}
                        </p>
                        <div style={{ padding: '20px 0' }} className="u-elements-row">
                            <Button
                                label={t('general.updatePassword')}
                                onPress={() => {
                                    void handleUpdatePassword()
                                }}
                                disabled={
                                    (password &&
                                        oldPassword &&
                                        (!passwordRegEx.test(password) || password !== confirmPassword)) === true
                                }
                                type="submit"
                            />
                        </div>
                    </div>
                ) : null}
                <LoadingModal isVisible={isUpdating} text={'Sto aggiornando il tuo profilo...'} />
            </CardContainer>
        </PageSidebarContainer>
    )
}

export default UserPage
