import Popover, { PopoverOrigin } from '@mui/material/Popover'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { COLORS } from '../../resources/colors'
import Button from '../Button/Button'
import './FilterPopover.sass'

interface Props {
    anchorElem: HTMLButtonElement | null
    onClose: () => void
    onResetClick: () => void
    onFilterClick: () => void
    children: any
    position?: PopoverOrigin | null
}

const FilterPopover: React.FC<Props> = (props) => {
    const open = Boolean(props.anchorElem)
    const { t } = useTranslation()

    return (
        <Popover
            open={open}
            anchorEl={props.anchorElem}
            onClose={() => props.onClose()}
            anchorOrigin={
                props.position
                    ? {
                          ...props.position,
                      }
                    : {
                          vertical: 'bottom',
                          horizontal: 'left',
                      }
            }
            className="popoverOverflow"
        >
            <div className="filterPopover">
                <h2 className="filterPopover-title">{t('general.your_filters')}</h2>
                {props.children}
                <div className="filterPopover-buttons">
                    <div style={{ width: '25%' }} />
                    <Button
                        label="Reset"
                        onPress={() => {
                            props.onResetClick()
                            props.onClose()
                        }}
                        type="submit"
                        background="#fff"
                        border={`2px solid ${COLORS.lightGrey}`}
                        color={COLORS.darkGrey}
                    />
                    <Button
                        label="Aggiorna"
                        onPress={() => {
                            props.onFilterClick()
                            props.onClose()
                        }}
                        type="submit"
                    />
                </div>
            </div>
        </Popover>
    )
}

export default FilterPopover
