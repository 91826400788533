import React from 'react'
import './SectionTitle.sass'

interface Props {
    text: string
}

const SectionTitle: React.FC<Props> = (props) => {
    return <p className="sectionTitle">{props.text}</p>
}

export default SectionTitle
