import React from 'react'
import { PropsValue } from 'react-select'
import AsyncSelect from 'react-select/async'
import { Option } from './SelectInput'
import '../TextInput/TextInput.sass'
import './SelectInput.sass'
import { COLORS } from '../../resources/colors'

interface Props {
    label?: string
    flex?: number
    value: PropsValue<Option | Option[]> | null
    isMulti?: boolean
    placeholder?: string
    onChange: (value: PropsValue<Option | Option[]> | null) => void
    fetchOptions: (input: string) => Promise<Option[]>
    bordered?: boolean
    disabled?: boolean
}

const AsyncSelectInput: React.FC<Props> = (props) => {
    return (
        <div className="c-text-input" style={{ flex: props.flex ? props.flex : 1 }}>
            {props.label && <span className="o-label">{props.label}</span>}
            <AsyncSelect
                value={props.value}
                isDisabled={props.disabled ? props.disabled : false}
                isMulti={props.isMulti ? props.isMulti : false}
                defaultOptions
                placeholder={props.placeholder ? props.placeholder : 'Inizia a scrivere...'}
                loadOptions={props.fetchOptions}
                onChange={(value) => {
                    if (value) {
                        props.onChange(value)
                    }
                }}
                styles={{
                    container: (provided) => ({
                        ...provided,
                        width: '100%',
                    }),
                    indicatorSeparator: (provided) => ({
                        ...provided,
                        width: 0,
                    }),
                    control: (provided) => ({
                        ...provided,
                        padding: '4px 5px',
                        border: props.bordered ? '1px solid rgba(0,0,0,0.14)' : '0',
                        borderRadius: 8,
                        boxShadow: props.bordered ? 'none' : '0 3px 7px 0px rgba(0,0,0,0.08)',
                    }),
                    valueContainer: (provided) => ({
                        ...provided,
                        border: 0,
                        zIndex: 999999999,
                    }),
                    multiValue: (provided) => ({
                        ...provided,
                        backgroundColor: COLORS.lightPurpleBg,
                        boxShadow: '0 3px 7px 0px rgba(0,0,0,0.08)',
                        marginRight: 10,
                    }),
                    menu: (provided) => ({
                        ...provided,
                        zIndex: 99999999999,
                    }),
                }}
            />
        </div>
    )
}

export default AsyncSelectInput
