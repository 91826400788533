import { PlayerStatus } from '../../types/player'
import { ReducerPlayer, ReduxActionData } from '../../types/reducers'
import { PLAYER_ACTIONS } from '../actions/player'

const initialState: ReducerPlayer = {
    mediaPlayer: null,
    status: PlayerStatus.PAUSE,
}

const playerState: (state: ReducerPlayer, action: ReduxActionData<any, PLAYER_ACTIONS>) => ReducerPlayer = (
    state = initialState,
    action: ReduxActionData<any, PLAYER_ACTIONS>
) => {
    switch (action.type) {
        case PLAYER_ACTIONS.SET_MEDIA_PLAYER:
            return {
                ...state,
                mediaPlayer: action.payload,
            }
        case PLAYER_ACTIONS.SET_PLAYER_STATUS:
            return {
                ...state,
                status: action.payload,
            }
        default:
            return state
    }
}

export default playerState
