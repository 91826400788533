import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { COLORS } from '../../resources/colors'
import { Radio } from '../../types/data'
import Button from '../Button/Button'
import './ServiceTabs.sass'
import OnAirMetaModal, { ParserConfiguration } from '../Modals/OnAirMetaModal'
import FmWorldAxios from '../../utility/FmWorldAxios'

export interface Parser {
    id: number
    name: string
    filename: string
}

interface Props {
    radio: Radio | null
    onLinking: () => void
    onUpdate: (r: Radio) => void
    disabled?: boolean
}

const OnAirMeta: React.FC<Props> = (props) => {
    const [currentRadio, setCurrentRadio] = useState<Radio | null>(props.radio)
    const [onAirModalIsVisible, setOnAirModalIsVisible] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [currentParserConfiguration, setCurrentParserConfiguration] = useState<ParserConfiguration | null>(null)
    const { t } = useTranslation()

    useEffect(() => {
        setCurrentRadio(currentRadio)
        void getCurrentOnAirMetaParser()
    }, [props.radio])

    const getCurrentOnAirMetaParser = async () => {
        try {
            const currentConfiguration: ParserConfiguration = await FmWorldAxios.get(
                `radios/${props.radio?.id || -1}/onair/configuration`
            ).then((response) => response.data)
            setCurrentParserConfiguration(currentConfiguration)
        } catch (error) {
            console.error(error)
        }
    }

    const createOrUpdateOnAirMetaParser = async (newConfiguration: ParserConfiguration) => {
        try {
            setIsLoading(true)
            setOnAirModalIsVisible(false)
            const configCopy: ParserConfiguration = JSON.parse(JSON.stringify(newConfiguration))
            if (configCopy.parser.fetchTrackItunesArtwork) {
                configCopy.parser.trackArtwork = null
            }
            if (configCopy.parser.trackArtist) {
                configCopy.parser.trackTitleSeparator = null
            }
            Object.keys(configCopy.parser).forEach((key) => {
                if (configCopy.parser[key as keyof ParserConfiguration['parser']] === null && key !== 'trackTitleSeparator') {
                    delete configCopy.parser[key as keyof ParserConfiguration['parser']]
                }
            })
            if (currentParserConfiguration) {
                await FmWorldAxios.put(`radios/${props.radio?.id || -1}/onair/configuration`, configCopy).then(
                    (response) => response.data
                )
            } else {
                await FmWorldAxios.post(`radios/${props.radio?.id || -1}/onair/configuration`, configCopy).then(
                    (response) => response.data
                )
            }
            await getCurrentOnAirMetaParser()
        } catch (error) {
            console.error(error)
        }
        setIsLoading(false)
    }

    if (currentRadio) {
        return (
            <>
                <OnAirMetaModal
                    configuration={currentParserConfiguration}
                    radio={props.radio}
                    isVisible={onAirModalIsVisible}
                    onClose={() => setOnAirModalIsVisible(false)}
                    onSave={(newConfiguration) => void createOrUpdateOnAirMetaParser(newConfiguration)}
                />
                <div className="serviceTab">
                    <h2 className="serviceTab-title">{t('radios.on_air_meta')}</h2>
                    {!currentParserConfiguration ? (
                        <div className="subtitle" style={{ marginBottom: 16 }}>
                            {t('onAirParser.mainHintMessage')}
                        </div>
                    ) : (
                        <div className="configuration-recap-container">
                            <div className="configuration-recap-container__is-active-badge">
                                <div
                                    className={`circular-status-badge ${
                                        currentParserConfiguration.isMonitorActive ? 'active-badge' : 'not-active-badge'
                                    }`}
                                />{' '}
                                {t('general.active')}
                            </div>
                            <div className="configuration-recap-container__url-container">
                                <b> {t('general.feed')}:</b> {currentParserConfiguration.monitorUrl}
                            </div>
                        </div>
                    )}
                    <div className="serviceTab-buttons">
                        <div style={{ flex: 1 }}>
                            <Button
                                disabled={isLoading}
                                label={!currentParserConfiguration ? t('general.add') : t('general.update')}
                                onPress={() => setOnAirModalIsVisible(true)}
                                type="submit"
                                background={COLORS.purple}
                                border={`2px solid ${COLORS.purple}`}
                                color={'#fff'}
                            />
                        </div>
                    </div>
                </div>
            </>
        )
    }
    return null
}

export default OnAirMeta
