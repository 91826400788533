import React, { useEffect, useState } from 'react'
import { PLACEHOLDER } from '../../../resources/constants'
import TooltipComponent from '../../TooltipComponent/TooltipComponent'
import './GridItem.sass'

enum Orientation {
    VERTICAL,
    HORIZONTAL,
    SQUARE,
}

interface Props {
    name: string
    image: string
    onClick: () => void
}

const GridItem: React.FC<Props> = (props) => {
    const [orientation, setOrientation] = useState<Orientation>(Orientation.VERTICAL)

    useEffect(() => {
        if (props.image) {
            const img = new Image()
            img.onload = () => {
                if (img.width === img.height) {
                    setOrientation(Orientation.SQUARE)
                }
                if (img.width < img.height) {
                    setOrientation(Orientation.VERTICAL)
                }
                if (img.width > img.height) {
                    setOrientation(Orientation.HORIZONTAL)
                }
            }
            img.src = props.image
        }
    }, [props.image])

    return (
        <TooltipComponent tooltip={props.name} bottomValue={'0'}>
            <div className="gridItem" onClick={() => props.onClick()}>
                <img
                    style={{
                        maxWidth: '100%',
                        objectFit: orientation === Orientation.SQUARE ? 'cover' : 'contain',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%,-50%)',
                    }}
                    alt={`${props.name} logo`}
                    src={props.image ? props.image : PLACEHOLDER}
                    onError={(e: any) => (e.currentTarget.src = PLACEHOLDER)}
                />
            </div>
        </TooltipComponent>
    )
}

export default GridItem
