export const cron_locale_itIT = {
    everyText: 'ogni',
    emptyMonths: 'ogni mese',
    emptyMonthDays: 'ogni giorno del mese',
    emptyMonthDaysShort: 'giorno del mese',
    emptyWeekDays: 'ogni giorno della settimana',
    emptyWeekDaysShort: 'giorno della settimana',
    emptyHours: 'ogni ora',
    emptyMinutes: 'ogni minuto',
    emptyMinutesForHourPeriod: 'ogni',
    yearOption: 'anno',
    monthOption: 'mese',
    weekOption: 'settimana',
    dayOption: 'giorno',
    hourOption: 'ora',
    minuteOption: 'minuto',
    rebootOption: 'riavvia',
    prefixPeriod: 'Ogni',
    prefixMonths: 'in',
    prefixMonthDays: 'il',
    prefixWeekDays: 'il',
    prefixWeekDaysForMonthAndYearPeriod: 'e',
    prefixHours: 'alle',
    prefixMinutes: ':',
    prefixMinutesForHourPeriod: 'alle',
    suffixMinutesForHourPeriod: 'minuti(s)',
    errorInvalidCron: 'Espressione non valida',
    clearButtonText: 'Pulisci',
    weekDays: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
    months: [
        'Gennaio',
        'Febbraio',
        'Marzo',
        'Aprile',
        'Maggio',
        'Giugno',
        'Luglio',
        'Agosto',
        'Settembre',
        'Ottobre',
        'Novembre',
        'Dicembre',
    ],
    altWeekDays: ['DOM', 'LUN', 'MAR', 'MER', 'GIO', 'VEN', 'SAB'],
    altMonths: ['GEN', 'FEB', 'MAR', 'APR', 'MAG', 'GIU', 'LUG', 'AGO', 'SET', 'OTT', 'NOV', 'DIC'],
}
