import React from 'react'
import PageSidebarContainer from '../components/PageSidebarContainer'
import CardContainer from '../components/CardContainer/CardContainer'

const UserListPage: React.FC = () => {
    return (
        <PageSidebarContainer>
            <CardContainer>
                <table>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Role</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>John Doe</td>
                            <td>
                                <a href="mailto:  [email protected]"> [email protected]</a>
                            </td>
                            <td>Admin</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Jane Doe</td>
                            <td>
                                <a href="mailto:  [email protected]"> [email protected]</a>
                            </td>
                            <td>User</td>
                        </tr>
                    </tbody>
                </table>
            </CardContainer>
        </PageSidebarContainer>
    )
}

export default UserListPage
