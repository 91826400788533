import MaterialTable, { Column } from 'material-table'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { COLORS } from '../../resources/colors'
import { ROUTES } from '../../resources/routes-constants'
import { Mux } from '../../types/data'
import { Reducers, ReducerGraphic } from '../../types/reducers'
import { getLangTable } from '../../utility/functions'
import { Languages } from '../../utility/Languages'
import FixedTableTheme from '../FixedTableTheme'

interface Props {
    data: Mux[]
    selectMux: (mux: Mux) => void
}

const MuxTable: React.FC<Props> = (props) => {
    const graphic = useSelector<Reducers, ReducerGraphic>((state) => state.graphic)
    const navigate = useNavigate()
    const { t } = useTranslation()

    const columns: Column<Mux>[] = [
        {
            title: t('general.name') as string,
            field: 'name',
            render: (mux: Mux) => {
                return (
                    <p
                        onClick={() =>
                            navigate(`${ROUTES.DAB_DETAIL_ROUTE}${mux.id}`, {
                                state: { name: mux.name },
                            })
                        }
                        style={{ fontWeight: 'bold', color: COLORS.purple, cursor: 'pointer' }}
                    >
                        {mux.name}
                    </p>
                )
            },
        },
        {
            title: t('dabs.code') as string,
            field: 'code',
        },
        {
            title: t('headers.radio') as string,
            field: 'radios',
            render: (mux: Mux) => {
                return (
                    <p>
                        {mux.radios} {t('dabs.associated_radios')}
                    </p>
                )
            },
        },
    ]

    const data = useMemo(() => {
        return props.data
    }, [props.data])

    const actions = useMemo(() => {
        const actions = [
            (mux: Mux) => ({
                icon: 'more_vert',
                onClick: () => {
                    props.selectMux(mux)
                },
            }),
        ]

        return actions
    }, [props.data])

    return (
        <FixedTableTheme>
            <MaterialTable
                columns={columns}
                data={data}
                actions={actions}
                title=""
                localization={getLangTable(graphic.currentLanguage as Languages)}
                options={{
                    actionsColumnIndex: -1,
                    search: false,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 50],
                    actionsCellStyle: {
                        border: '0',
                    },
                    toolbar: false,
                }}
                style={{
                    boxShadow: 'none',
                }}
            />
        </FixedTableTheme>
    )
}

export default MuxTable
