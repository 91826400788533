import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getShowsRadio } from '../../resources/api-constants'
import { COLORS } from '../../resources/colors'
import { PLACEHOLDER } from '../../resources/constants'
import { ROUTES } from '../../resources/routes-constants'
import { Show } from '../../types/data'
import { ReducerData, Reducers } from '../../types/reducers'
import FmWorldAxios from '../../utility/FmWorldAxios'
import { usePrevious, useTimeout } from '../../utility/hooks/common'
import Loader from '../Loader/Loader'
import './Grid.sass'
import GridItem from './GridItem/GridItem'
import PageController from './PageController/PageController'

interface Props {
    nameRadio: string
    idRadio: number
    idNetwork: number
    search: string
}

const RadioShowsGrid: any = forwardRef((props: Props, ref: any) => {
    const data = useSelector<Reducers, ReducerData>((state) => state.data)
    const [pageSize, setPageSize] = useState(21)
    const navigate = useNavigate()
    const [currentPage, setCurrentPage] = useState(1)
    const [renderedShows, setRenderedShows] = useState<Show[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [totalPages, setTotalPages] = useState(data.totalRadios ? Math.ceil(data.totalRadios / pageSize) : 1)
    const [totalShows, setTotalShows] = useState(data.totalRadios ? data.totalRadios : 0)
    const previousSearch = usePrevious(props.search)
    const [currentTimeout, setCurrentTimeout] = useState<null | number>(null)

    useImperativeHandle(ref, () => ({
        reloadData: () => {
            setCurrentPage(1)
        },
    }))

    const fetchNewShows = async () => {
        setIsLoading(true)
        const newOffset = pageSize * (currentPage - 1)
        const params: any = {
            limit: pageSize,
            offset: newOffset,
        }
        if (props.search.length > 2) {
            params.query = props.search
        }
        const shows = await FmWorldAxios.get(getShowsRadio(props.idRadio), {
            params: { ...params },
        }).then((res: any) => {
            setTotalShows(res.data.count)
            setTotalPages(Math.ceil(res.data.count / pageSize))
            return res.data.items
        })
        // console.log(shows)
        setRenderedShows(shows)
        setCurrentPage(currentPage)
        setIsLoading(false)
    }

    useEffect(() => {
        if (currentPage <= totalPages) {
            void fetchNewShows()
        }
    }, [currentPage])

    useEffect(() => {
        if (previousSearch !== undefined) {
            if (currentTimeout) {
                setCurrentTimeout(null)
            }
            setCurrentTimeout(1000)
        }
    }, [props.search])

    useEffect(() => {
        if (props.search.length > 2 || (previousSearch && previousSearch.length > 2 && props.search.length < 3)) {
            if (currentPage === 1) {
                void fetchNewShows()
            } else {
                setCurrentPage(1)
            }
        }
    }, [pageSize])

    useTimeout(() => {
        if (currentPage === 1) {
            void fetchNewShows()
        } else {
            setCurrentPage(1)
        }
        setCurrentTimeout(null)
    }, currentTimeout)

    useEffect(() => {
        void fetchNewShows()
    }, [])

    // console.log(currentPage)

    if (isLoading) {
        return (
            <div className="loadingGrid">
                <Loader />
            </div>
        )
    }

    if (props.search.length > 2 && renderedShows.length === 0) {
        return (
            <div className="loadingGrid">
                <p className="title">Nessuno show trovata</p>
            </div>
        )
    }

    if (renderedShows.length === 0) {
        return (
            <div className="loadingGrid">
                <p className="title">
                    Premi su <span style={{ color: COLORS.purple, fontWeight: 700 }}>Aggiungi</span> per aggiungere la
                    tua prima radio
                </p>
            </div>
        )
    }

    return (
        <div>
            <div className="grid">
                {renderedShows.map((s) => {
                    return (
                        <GridItem
                            name={s.title}
                            key={s.id.toString()}
                            image={s.image ? (s.image as string) : PLACEHOLDER}
                            onClick={() => {
                                navigate(`${ROUTES.SHOW_DETAIL_ROUTE}${s.id}`, {
                                    state: {
                                        name: s.title,
                                        parent: { id: props.idRadio, name: props.nameRadio },
                                        idNetwork: props.idNetwork,
                                    },
                                })
                            }}
                        />
                    )
                })}
            </div>
            {renderedShows.length > 0 ? (
                <div className="grid-controller">
                    <PageController
                        minNumber={pageSize * currentPage - pageSize + 1}
                        maxNumber={pageSize * currentPage > totalShows ? totalShows : pageSize * currentPage}
                        totalNumber={totalShows}
                        pageSize={pageSize}
                        onFirstPageClick={() => setCurrentPage(1)}
                        onPrevPageClick={() => setCurrentPage(currentPage - 1)}
                        onNextPageClick={() => setCurrentPage(currentPage + 1)}
                        onLastPageClick={() => setCurrentPage(totalPages)}
                        onChangePageSize={(v) => setPageSize(v)}
                    />
                </div>
            ) : null}
        </div>
    )
})
RadioShowsGrid.displayName = 'RadioShowsGrid'
export default RadioShowsGrid
