import MaterialTable, { Column } from 'material-table'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Reducers, ReducerGraphic } from '../../types/reducers'
import { getLangTable } from '../../utility/functions'
import { Languages } from '../../utility/Languages'
import FixedTableTheme from '../FixedTableTheme'
import { TXControlMuxConfiguration } from '../../pages/RadiosPage/tabs/TxControlRadioConfigurations'

interface Props {
    data: TXControlMuxConfiguration[]
    onSelecteMux: (mux: TXControlMuxConfiguration) => void
}

const RadioTXControlMuxTable: React.FC<Props> = (props) => {
    const graphic = useSelector<Reducers, ReducerGraphic>((state) => state.graphic)
    const { t } = useTranslation()

    const columns: Column<TXControlMuxConfiguration>[] = [
        {
            title: t('general.name') as string,
            field: 'name',
            render: (mux: TXControlMuxConfiguration) => {
                return <p style={{ fontWeight: 700 }}>{mux.name}</p>
            },
        },
        {
            title: t('dabs.code') as string,
            field: 'code',
            render: (mux: TXControlMuxConfiguration) => {
                return <p>{mux.code}</p>
            },
        },
        {
            title: t('dabs.channel') as string,
            field: 'channel',
            render: (mux: TXControlMuxConfiguration) => {
                return <p>{mux.channel}</p>
            },
        },
    ]

    const data = useMemo(() => {
        return props.data
    }, [props.data])

    const actions = useMemo(() => {
        return [
            (muxConfiguration: TXControlMuxConfiguration) => ({
                icon: 'analytics',
                onClick: () => props.onSelecteMux(muxConfiguration),
            }),
        ]
    }, [props.data])

    return (
        <FixedTableTheme>
            <MaterialTable
                columns={columns}
                data={data}
                title=""
                localization={getLangTable(graphic.currentLanguage as Languages)}
                actions={actions ?? []}
                options={{
                    actionsColumnIndex: -1,
                    search: false,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 50],
                    actionsCellStyle: {
                        border: '0',
                    },
                }}
                style={{
                    boxShadow: 'none',
                }}
            />
        </FixedTableTheme>
    )
}

export default RadioTXControlMuxTable
