import MaterialTable, { Column } from 'material-table'
import moment from 'moment'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ApiKey } from '../../pages/NetworksPages/tabs/ApiTab'
import { ReducerGraphic, Reducers } from '../../types/reducers'
import { getLangTable } from '../../utility/functions'
import { Languages } from '../../utility/Languages'
import FixedTableTheme from '../FixedTableTheme'

interface Props {
    data: ApiKey[]
    selectKey: (api: ApiKey) => void
}

const ApiKeysTable: React.FC<Props> = (props) => {
    const graphic = useSelector<Reducers, ReducerGraphic>((state) => state.graphic)
    const { t } = useTranslation()

    const columns: Column<ApiKey>[] = [
        {
            title: t('general.name') as string,
            field: 'name',
        },
        {
            title: t('networks.token') as string,
            field: 'token',
        },
        {
            title: t('networks.expiration') as string,
            field: 'expiration',
            render: (api: ApiKey) => {
                return <p>{api.expireOn ? moment(api.expireOn).format('DD MMMM YYYY HH:mm') : null}</p>
            },
        },
    ]

    const data = useMemo(() => {
        return props.data
    }, [props.data])

    const actions = useMemo(() => {
        const actions = [
            (api: ApiKey) => ({
                icon: 'more_vert',
                onClick: () => {
                    props.selectKey(api)
                },
            }),
        ]

        return actions
    }, [props.data])

    return (
        <FixedTableTheme>
            <MaterialTable
                columns={columns}
                data={data}
                actions={actions}
                title=""
                localization={getLangTable(graphic.currentLanguage as Languages)}
                options={{
                    actionsColumnIndex: -1,
                    search: false,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 50],
                    actionsCellStyle: {
                        border: '0',
                    },
                    toolbar: false,
                }}
                style={{
                    boxShadow: 'none',
                }}
            />
        </FixedTableTheme>
    )
}

export default ApiKeysTable
