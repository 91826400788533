import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PageSidebarContainer from '../../components/PageSidebarContainer'
import CardContainer from '../../components/CardContainer/CardContainer'
import TextInput from '../../components/TextInput/TextInput'
import Button from '../../components/Button/Button'
import ActionsModal from '../../components/Modals/ActionsModal'
import DeleteModal from '../../components/Modals/DeleteModal'
import LoadingModal from '../../components/Modals/LoadingModal'
import Loader from '../../components/Loader/Loader'
import RadioModal from '../../components/Modals/RadioModal'
import { COLORS } from '../../resources/colors'
import { setPageHeader } from '../../store/actions/graphic'
import FmWorldAxios from '../../utility/FmWorldAxios'
import {
    getFormatGroupDetail,
    getFormatGroupRadios,
    deleteFormatGroupRadio,
    addRadioToFormatGroup,
} from '../../resources/api-constants'
import { Radio } from '../../types/data'
import FormatGroupRadiosTable from '../../components/Tables/FormatGroupRadiosTable'

interface FormatGroup {
    id: number
    name: string
    radios?: Radio[]
}

const FormatGroupDetailPage: React.FC = () => {
    const { id } = useParams<{ id: string }>()
    const [formatGroup, setFormatGroup] = useState<FormatGroup | null>(null)
    const [radios, setRadios] = useState<Radio[]>([])
    const [filteredRadios, setFilteredRadios] = useState<Radio[]>([])
    const [searchQuery, setSearchQuery] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isUpdating, setIsUpdating] = useState<boolean>(false)
    const [error, setError] = useState<string>('')
    const [selectedRadio, setSelectedRadio] = useState<Radio | null>(null)
    const [radioToDelete, setRadioToDelete] = useState<Radio | null>(null)
    const [radioToAdd, setRadioToAdd] = useState<{ id: number } | null>(null)
    const { t } = useTranslation()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(
            setPageHeader({
                parent: { name: t('headers.format_groups'), route: '/format-groups' },
                name: formatGroup?.name || t('headers.format_group_detail'),
            })
        )
        void fetchFormatGroupDetail()
    }, [id, dispatch, t])

    useEffect(() => {
        if (searchQuery.length > 2) {
            const filtered = radios.filter((radio) => radio.name.toLowerCase().includes(searchQuery.toLowerCase()))
            setFilteredRadios(filtered)
        } else {
            setFilteredRadios(radios)
        }
    }, [searchQuery, radios])

    const fetchFormatGroupDetail = async () => {
        setIsLoading(true)
        setError('')
        try {
            const [groupResponse, radiosResponse] = await Promise.all([
                FmWorldAxios.get(getFormatGroupDetail(Number(id))),
                FmWorldAxios.get(getFormatGroupRadios(Number(id))),
            ])
            setFormatGroup(groupResponse.data)
            setRadios(radiosResponse.data.items)
            setFilteredRadios(radiosResponse.data.items)
        } catch (error: any) {
            console.error('Error fetching format group detail', error)
            setError('Error fetching format group detail')
        }
        setIsLoading(false)
    }

    const handleAddRadio = async (radio: { id: number }) => {
        setIsUpdating(true)
        setError('')
        try {
            await FmWorldAxios.post(addRadioToFormatGroup(Number(id), radio.id))
            await fetchFormatGroupDetail()
            setRadioToAdd(null)
        } catch (error: any) {
            console.error('Error adding radio to format group', error)
            setError('Error adding radio to format group')
        }
        setIsUpdating(false)
    }

    const handleDeleteRadio = async () => {
        if (radioToDelete) {
            setIsUpdating(true)
            setError('')
            try {
                await FmWorldAxios.delete(deleteFormatGroupRadio(Number(id), radioToDelete.id))
                await fetchFormatGroupDetail()
                setRadioToDelete(null)
            } catch (error: any) {
                console.error('Error deleting radio from format group', error)
                setError('Error deleting radio from format group')
            }
            setIsUpdating(false)
        }
    }

    const actionsModal = [
        {
            text: t('general.delete'),
            color: COLORS.redDelete,
            onClick: () => {
                setRadioToDelete(selectedRadio)
                setSelectedRadio(null)
            },
        },
    ]

    return (
        <PageSidebarContainer>
            <CardContainer>
                <div className="titleRow">
                    <div style={{ flex: 1 }}>
                        <h2 className="title">{formatGroup?.name || t('formatGroups.format_group_detail')}</h2>
                        <h3 className="subtitle" style={{ fontWeight: 400 }}>
                            {t('formatGroups.manage_format_group_radios')}
                        </h3>
                        {error && <p className="u-normal-text u-bold-text u-error-text">{error}</p>}
                    </div>
                    <div
                        style={{ display: 'flex', flex: 2, gap: 10, alignItems: 'center', justifyContent: 'flex-end' }}
                    >
                        <div style={{ flex: 2 }}>
                            <TextInput
                                value={searchQuery}
                                placeholder={t('general.search')}
                                icon="search"
                                iconPosition="right"
                                onTextChange={(v) => setSearchQuery(v)}
                            />
                        </div>
                        <div style={{ flex: 1 }}>
                            <Button
                                label={t('general.refresh')}
                                onPress={() => {
                                    void fetchFormatGroupDetail()
                                }}
                                type="submit"
                                background="rgba(100,38,119,0.14)"
                                border={`2px solid ${COLORS.purple}`}
                                color={COLORS.purple}
                            />
                        </div>
                        <div style={{ flex: 1 }}>
                            <Button
                                label={t('general.add')}
                                onPress={() => {
                                    setRadioToAdd({ id: 0 })
                                }}
                                type="submit"
                            />
                        </div>
                    </div>
                </div>
                {isLoading ? (
                    <div className="loadingGrid">
                        <Loader />
                    </div>
                ) : (
                    <div style={{ alignItems: 'flex-start', marginTop: 20 }}>
                        <FormatGroupRadiosTable
                            data={filteredRadios}
                            selectRadio={(radio: Radio) => setSelectedRadio(radio)}
                        />
                    </div>
                )}
            </CardContainer>
            <ActionsModal
                isVisible={selectedRadio !== null}
                title={t('general.what_to_do')}
                onClose={() => setSelectedRadio(null)}
                actions={actionsModal}
            />
            <RadioModal
                isVisible={radioToAdd !== null}
                onClose={() => setRadioToAdd(null)}
                onSave={(radio) => {
                    void handleAddRadio(radio)
                }}
                formatGroupId={Number(id)}
            />
            <DeleteModal
                onDelete={() => {
                    void handleDeleteRadio()
                }}
                title={`${t('formatGroups.delete_this_radio')} ${radioToDelete?.name}`}
                text={`${t('formatGroups.want_delete_radio_from_group')} ${radioToDelete?.name}?`}
                onClose={() => {
                    setRadioToDelete(null)
                }}
                isVisible={radioToDelete !== null}
            />
            <LoadingModal isVisible={isUpdating} text={t('loadings.generic_loading')} />
        </PageSidebarContainer>
    )
}

export default FormatGroupDetailPage
