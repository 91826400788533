import { ReduxAction } from '../../types/reducers'
import { User } from '../../types/user'

export enum USER_ACTIONS {
    SET_USER_DATA = 'userActions/setUserData',
    LOGOUT_USER = 'userActions/logoutUser',
}

export const setLoggedUserData: ReduxAction<User, USER_ACTIONS> = (user: User) => {
    return {
        type: USER_ACTIONS.SET_USER_DATA,
        payload: user,
    }
}

export const logoutUser = () => {
    return {
        type: USER_ACTIONS.LOGOUT_USER,
    }
}
