import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getRadioNetworks } from '../../resources/api-constants'
import { COLORS } from '../../resources/colors'
import { PLACEHOLDER } from '../../resources/constants'
import { ROUTES } from '../../resources/routes-constants'
import { Radio } from '../../types/data'
import { ReducerData, ReducerGraphic, Reducers, ReducerUser } from '../../types/reducers'
import FmWorldAxios from '../../utility/FmWorldAxios'
import { Grants } from '../../utility/Grants'
import Loader from '../Loader/Loader'
import './Grid.sass'
import GridItem from './GridItem/GridItem'
import PageController from './PageController/PageController'

interface Props {
    idNetwork: number
    permissions: Grants[]
}

const RadioNetworksGrid: any = forwardRef((props: Props, ref: any) => {
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const data = useSelector<Reducers, ReducerData>((state) => state.data)
    const graphic = useSelector<Reducers, ReducerGraphic>((state) => state.graphic)
    const [pageSize, setPageSize] = useState(graphic.lastRadioSearch ? graphic.lastRadioSearch.pageSize : 21)
    const navigate = useNavigate()
    const [currentPage, setCurrentPage] = useState(graphic.lastRadioSearch ? graphic.lastRadioSearch.page : 1)
    const [renderedRadios, setRenderedRadios] = useState<Radio[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [totalPages, setTotalPages] = useState(data.totalRadios ? Math.ceil(data.totalRadios / pageSize) : 1)
    const [totalRadios, setTotalRadios] = useState(data.totalRadios ? data.totalRadios : 0)

    useImperativeHandle(ref, () => ({
        reloadData: () => {
            setCurrentPage(1)
        },
    }))

    const getParamsObject = () => {
        const params: any = {
            limit: pageSize,
            offset: pageSize * (currentPage - 1),
            // return_empty_streams: props.filter.returnEmptyStreams,
            // return_offline_streams: props.filter.returnOfflineStreams,
            // video_only: props.filter.videoOnly,
        }
        // if (props.filter.query && props.filter.query.length > 2) {
        //     params.query = props.filter.query
        // }
        // if (props.filter.countries.length > 0) {
        //     params.countries = props.filter.countries.toString()
        // }
        // if (props.filter.types.length > 0) {
        //     params.types = props.filter.types.toString()
        // }
        // if (props.filter.categories.length > 0) {
        //     params.categories = props.filter.categories.toString()
        // }
        // if (props.filter.networks.length > 0) {
        //     params.networks = props.filter.networks.toString()
        // }
        // if (!props.filter.returnEmptyStreams) {
        //     params.return_empty_streams = props.filter.returnEmptyStreams
        // }
        // if (!props.filter.returnOfflineStreams) {
        //     params.return_offline_streams = props.filter.returnOfflineStreams
        // }
        // if (props.filter.videoOnly) {
        //     params.video_only = props.filter.videoOnly
        // }
        return params
    }

    const fetchNewRadios = async () => {
        setIsLoading(true)
        // console.log(currentPage, props.search)
        const params: any = getParamsObject()
        const radios = await FmWorldAxios.get(getRadioNetworks(props.idNetwork), {
            params: { ...params },
        }).then((res) => {
            setTotalRadios(res.data.count)
            setTotalPages(Math.ceil(res.data.count / pageSize))
            return res.data.items
        })
        setRenderedRadios(radios)
        setIsLoading(false)
    }

    useEffect(() => {
        if (currentPage <= totalPages) {
            void fetchNewRadios()
        }
    }, [currentPage])

    useEffect(() => {
        if (currentPage > 1) {
            setCurrentPage(1)
        } else {
            void fetchNewRadios()
        }
    }, [pageSize])

    useEffect(() => {
        if (user.user) {
            void fetchNewRadios()
        }
    }, [user.user])

    // console.log(currentPage)

    if (isLoading) {
        return (
            <div className="loadingGrid">
                <Loader />
            </div>
        )
    }

    if (renderedRadios.length === 0) {
        if (props.permissions.includes(Grants.CREATE_RADIO)) {
            return (
                <div className="loadingGrid">
                    <p className="title">
                        Premi su <span style={{ color: COLORS.purple, fontWeight: 700 }}>Aggiungi</span> per aggiungere
                        la tua prima radio
                    </p>
                </div>
            )
        }
        return (
            <div className="loadingGrid">
                <p className="title">Nessuna radio aggiunta</p>
            </div>
        )
    }

    return (
        <div>
            <div className="grid">
                {renderedRadios.map((r) => {
                    return (
                        <GridItem
                            name={r.name}
                            key={r.id.toString()}
                            image={r.logo ? (r.logo as string) : PLACEHOLDER}
                            onClick={() => {
                                navigate(`${ROUTES.RADIO_DETAIL_ROUTE}${r.id}`, {
                                    state: { name: r.name, idNetwork: r.idNetwork },
                                })
                            }}
                        />
                    )
                })}
            </div>
            {renderedRadios.length > 0 ? (
                <div className="grid-controller">
                    <PageController
                        minNumber={pageSize * currentPage - pageSize + 1}
                        maxNumber={pageSize * currentPage > totalRadios ? totalRadios : pageSize * currentPage}
                        totalNumber={totalRadios}
                        pageSize={pageSize}
                        onFirstPageClick={() => setCurrentPage(1)}
                        onPrevPageClick={() => setCurrentPage(currentPage - 1)}
                        onNextPageClick={() => setCurrentPage(currentPage + 1)}
                        onLastPageClick={() => setCurrentPage(totalPages)}
                        onChangePageSize={(v) => setPageSize(v)}
                    />
                </div>
            ) : null}
        </div>
    )
})
RadioNetworksGrid.displayName = 'RadioGrid'
export default RadioNetworksGrid
