import MaterialTable, { Column } from 'material-table'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { COLORS } from '../../resources/colors'
import { UserNetwork } from '../../types/data'
import { Reducers, ReducerData, ReducerGraphic, ReducerUser } from '../../types/reducers'
import { getLangTable } from '../../utility/functions'
import { Languages } from '../../utility/Languages'
import FixedTableTheme from '../FixedTableTheme'
import { Grants } from '../../utility/Grants'

interface Props {
    data: UserNetwork[]
    selectUser: (user: UserNetwork) => void
    showActions?: boolean
}

const UsersNetworkTable: React.FC<Props> = (props) => {
    const graphic = useSelector<Reducers, ReducerGraphic>((state) => state.graphic)
    const data = useSelector<Reducers, ReducerData>((state) => state.data)
    const loggedUser = useSelector<Reducers, ReducerUser>((state) => state.user)
    const { t } = useTranslation()

    const columns: Column<UserNetwork>[] = [
        {
            title: t('general.name') as string,
            field: 'name',
            render: (user: UserNetwork) => {
                return (
                    <p>
                        <b>{user.id === loggedUser.user?.id ? t('general.you') : ''}</b>
                        {user.firstName} {user.lastName}
                    </p>
                )
            },
        },
        {
            title: t('auth.email') as string,
            field: 'email',
            render: (user: UserNetwork) => {
                return <p>{user.email}</p>
            },
        },
        {
            title: t('networks.role') as string,
            field: 'role',
            render: (user: UserNetwork) => {
                const roleFound = data.networkRoles.find((r) => r.id === user.idRole)
                if (roleFound) {
                    return <p>{roleFound.name}</p>
                }
                return ''
            },
        },
        {
            title: t('networks.status_confirm') as string,
            field: 'status',
            render: (user: UserNetwork) => {
                if (user.invitationPending) {
                    return (
                        <p>
                            {t('networks.pending_confirm')}{' '}
                            <span
                                onClick={() => {
                                    return
                                }}
                                style={{ color: COLORS.purple, textDecoration: 'underline' }}
                            >
                                {t('networks.resend_email')}
                            </span>
                        </p>
                    )
                }
                return <p>{t('networks.confirmed')}</p>
            },
        },
    ]

    const users = useMemo(() => {
        return props.data
    }, [props.data])

    const actions = useMemo(() => {
        const actions = [
            (user: UserNetwork) => ({
                icon: 'more_vert',
                onClick: () => {
                    {
                        loggedUser.user?.grants.includes(Grants.EDIT_NETWORK) 
                        ? props.selectUser(user) : null
                    }
                },
            }),
        ]

        return actions
    }, [props.data])

    console.log('users', loggedUser)

    return (
        <FixedTableTheme>
            <MaterialTable
            columns={columns}
            data={users}
            actions={
                props.showActions && loggedUser.user?.grants.includes(Grants.EDIT_NETWORK)
                ? actions
                : []
            }
            title=""
            localization={getLangTable(graphic.currentLanguage as Languages)}
            options={{
                actionsColumnIndex: -1,
                search: false,
                pageSize: 10,
                pageSizeOptions: [10, 20, 50],
                actionsCellStyle: {
                border: '0',
                },
                toolbar: false,
            }}
            style={{
                boxShadow: 'none',
            }}
            />
        </FixedTableTheme>
    )
}

export default UsersNetworkTable
