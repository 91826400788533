import React, { useMemo } from 'react'
import { RadioItem } from './AppGrid'

interface Props {
    item: RadioItem
    dimenRow: number
}

const AppGridItem: React.FC<Props> = React.memo((props) => {
    const dimenImage = props.dimenRow - 4 * 2

    const renderImageLayer = useMemo(() => {
        return (
            <img
                src={props.item.image.url}
                alt={props.item.title}
                style={{ height: '100%', width: '100%', borderRadius: 15 }}
            />
        )
    }, [props.item])

    return (
        <div
            style={{
                justifyContent: 'center',
                alignItems: 'center',
                flex: props.item.weight,
                display: 'flex',
                margin: 5,
            }}
        >
            <div style={{ display: 'flex', flex: 1, width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <div
                    style={{
                        backgroundColor: '#000',
                        padding: 0,
                        borderRadius: 15,
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: props.item.weight === 1 ? dimenImage : '100%',
                        height: dimenImage,
                        overflow: 'hidden',
                        display: 'flex',
                    }}
                >
                    {props.item.image ? renderImageLayer : null}
                </div>
            </div>
        </div>
    )
})
AppGridItem.displayName = 'AppGridItem'

export default AppGridItem
