import { MediaPlayer, PlayerStatus } from '../../types/player'
import { ReduxAction } from '../../types/reducers'
import { User } from '../../types/user'

export enum PLAYER_ACTIONS {
    SET_MEDIA_PLAYER = 'playerActions/setMediaPlayer',
    SET_PLAYER_STATUS = 'playerActions/setPlayerStatus',
}

export const setMediaPlayer: ReduxAction<MediaPlayer | null, PLAYER_ACTIONS> = (player: MediaPlayer | null) => {
    return {
        type: PLAYER_ACTIONS.SET_MEDIA_PLAYER,
        payload: player,
    }
}

export const setPlayerStatus: ReduxAction<PlayerStatus, PLAYER_ACTIONS> = (status: PlayerStatus) => {
    return {
        type: PLAYER_ACTIONS.SET_PLAYER_STATUS,
        payload: status,
    }
}
