import React from 'react'
import Select, { GroupBase, OptionsOrGroups, PropsValue } from 'react-select'
import { COLORS } from '../../resources/colors'
import '../TextInput/TextInput.sass'
import './SelectInput.sass'

export interface Option<T = number | string> {
    id: T
    value: T
    label: string
}

interface Props {
    label?: string
    flex?: number
    value: PropsValue<Option | Option[]> | null
    options: OptionsOrGroups<Option, GroupBase<Option>>
    isMulti?: boolean
    onChange: (value: PropsValue<Option | Option[]>) => void
    bordered?: boolean
    placeholder?: string
    disabled?: boolean
    position?: 'top' | 'bottom'
    isHidden?: boolean
}

const SelectInput: React.FC<Props> = (props) => {
    if (props.isHidden) return null
    return (
        <div className="c-text-input" style={{ flex: props.flex ? props.flex : 1 }}>
            {props.label && <span className="o-label">{props.label}</span>}
            <Select
                value={props.value}
                placeholder={props.placeholder ? props.placeholder : ''}
                isMulti={props.isMulti ? props.isMulti : false}
                menuPosition={props.position === 'top' ? 'fixed' : 'absolute'}
                isDisabled={props.disabled}
                options={props.options}
                onChange={(value) => {
                    if (value) {
                        props.onChange(value)
                    }
                }}
                styles={{
                    container: (provided) => ({
                        ...provided,
                        width: '100%',
                    }),
                    indicatorSeparator: (provided) => ({
                        ...provided,
                        width: 0,
                    }),
                    control: (provided) => ({
                        ...provided,
                        padding: '4px 5px',
                        border: props.bordered ? '1px solid rgba(0,0,0,0.14)' : '0',
                        borderRadius: 8,
                        boxShadow: props.bordered ? 'none' : '0 3px 7px 0px rgba(0,0,0,0.08)',
                    }),
                    valueContainer: (provided) => ({
                        ...provided,
                        border: 0,
                    }),
                    multiValue: (provided) => ({
                        ...provided,
                        backgroundColor: COLORS.lightPurpleBg,
                        boxShadow: '0 3px 7px 0px rgba(0,0,0,0.08)',
                        marginRight: 10,
                    }),
                    menu: (provided) => ({
                        ...provided,
                        zIndex: 99999999999,
                    }),
                }}
            />
        </div>
    )
}

export default SelectInput
