import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../../components/Button/Button'
import { useSelector } from 'react-redux'
import { Reducers, ReducerData } from '../../../types/reducers'
import CustomAxios from '../../../utility/CustomAxios'
import { getPortalSession } from '../../../resources/api-constants'

const PaymentsPage: React.FC = () => {
    const { t } = useTranslation()

    const { selectedNetwork } = useSelector<Reducers, ReducerData>((state) => state.data)
    const [portalUrl, setPortalUrl] = useState<string | null>(null)

    useEffect(() => {
        void fetchCustomerPortal()
    }, [])

    const fetchCustomerPortal = async () => {
        if (!selectedNetwork) return

        try {
            const response = await CustomAxios.post(getPortalSession(selectedNetwork.id)).then(
                (response) => response.data
            )

            setPortalUrl(response.url)
        } catch (error: any) {
            console.log(error)
        }
    }

    return (
        <div>
            <div className="titleRow">
                <div style={{ flex: 1 }}>
                    <h2 className="title">Gestisci i pagamenti di {selectedNetwork?.name}</h2>
                    <h3 className="subtitle" style={{ fontWeight: 400 }}>
                        {t('networks.view_api_keys')}
                    </h3>
                </div>
                <div style={{ display: 'flex', flex: 2, gap: 10, alignItems: 'center', justifyContent: 'flex-end' }}>
                    <div style={{ flex: 4 }} />
                    <div style={{ flex: 1 }}>
                        {portalUrl ? (
                            <Button
                                label={'Gestisci'}
                                onPress={() => {
                                    window.open(portalUrl, '_blank')
                                }}
                                type="submit"
                            />
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentsPage
