import { Modal } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getShowsRadio } from '../../resources/api-constants'
import { COLORS } from '../../resources/colors'
import { Show, ShowSchedule } from '../../types/data'
import FmWorldAxios from '../../utility/FmWorldAxios'
import Button from '../Button/Button'
import CronInput from '../CronInput/CronInput'
import AsyncSelectInput from '../SelectInput/AsyncSelectInput'
import { Option } from '../SelectInput/SelectInput'
import TextInput from '../TextInput/TextInput'
import 'antd/dist/antd.min.css'
import { VoidShowSchedule } from '../../utility/voidConstants'
import { useTranslation } from 'react-i18next'

interface Props {
    idRadio: number
    showSchedule: ShowSchedule | VoidShowSchedule | null
    isVisible: boolean
    onClose: () => void
    onSave: (s: ShowSchedule | VoidShowSchedule | null) => void
    onDelete: () => void
}

const ShowScheduleModal: React.FC<Props> = (props) => {
    const [showScheduleToEdit, setShowScheduleToEdit] = useState(props.showSchedule)
    const { t } = useTranslation()

    useEffect(() => {
        if (props.isVisible) {
            setShowScheduleToEdit(props.showSchedule)
        }
    }, [props.isVisible])

    return (
        <Modal
            open={props.isVisible}
            onClose={() => props.onClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className="modalBox actionBox">
                <h2 className="modalBox-title">{t('radios.edit_schedule')}</h2>
                {showScheduleToEdit ? (
                    <div>
                        <AsyncSelectInput
                            label={t('radios.show')}
                            value={
                                (showScheduleToEdit as ShowSchedule) && (showScheduleToEdit as ShowSchedule).idShow
                                    ? {
                                          id: (showScheduleToEdit as ShowSchedule).idShow,
                                          value: (showScheduleToEdit as ShowSchedule).idShow,
                                          label: (showScheduleToEdit as ShowSchedule).title,
                                      }
                                    : null
                            }
                            fetchOptions={async (input: string) => {
                                if (input.length > 2) {
                                    const shows = await FmWorldAxios.get(getShowsRadio(props.idRadio), {
                                        params: { query: input },
                                    }).then((res) => {
                                        return res.data.items
                                    })
                                    return shows.map((c: Show) => {
                                        return {
                                            id: c.id,
                                            value: c.id,
                                            label: c.title,
                                        }
                                    })
                                }
                                return []
                            }}
                            onChange={(value) => {
                                const v = value as Option
                                console.log(v)
                                setShowScheduleToEdit({
                                    ...showScheduleToEdit,
                                    title: v.label,
                                    idShow: v.id as number,
                                })
                            }}
                        />
                        <div style={{ margin: '20px 0' }}>
                            <TextInput
                                value={showScheduleToEdit.duration ? showScheduleToEdit.duration.toString() : '0'}
                                label="Durata"
                                onTextChange={(e) =>
                                    setShowScheduleToEdit({
                                        ...showScheduleToEdit,
                                        duration: parseFloat(e),
                                    })
                                }
                            />
                        </div>
                        <CronInput
                            value={showScheduleToEdit.schedule}
                            label={t('radios.when_on_air')}
                            onValueChange={(v) => {
                                setShowScheduleToEdit({
                                    ...showScheduleToEdit,
                                    schedule: v,
                                })
                            }}
                        />
                    </div>
                ) : null}
                <div className="modalBox-buttonsRow">
                    <div style={{ flex: '1 1 100%' }} />
                    {showScheduleToEdit && (showScheduleToEdit as ShowSchedule).idSchedule ? (
                        <Button
                            label={t('general.delete')}
                            onPress={() => {
                                props.onDelete()
                            }}
                            type="submit"
                            background="#fff"
                            border={`2px solid ${COLORS.redDelete}`}
                            color={COLORS.redDelete}
                        />
                    ) : null}
                    <Button
                        label={t('general.cancel')}
                        border={'1px solid rgba(0,0,0,0.07)'}
                        onPress={() => props.onClose()}
                        type="secondary"
                        color={COLORS.darkGrey}
                        background="#fff"
                    />
                    <Button
                        disabled={
                            !showScheduleToEdit || !showScheduleToEdit?.idShow || showScheduleToEdit.duration === 0
                        }
                        label={
                            showScheduleToEdit && (showScheduleToEdit as ShowSchedule).idSchedule
                                ? t('general.update')
                                : t('general.add')
                        }
                        onPress={() => props.onSave(showScheduleToEdit)}
                        type="submit"
                        color="#fff"
                        background={COLORS.purple}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default ShowScheduleModal
