import { Parser } from '../components/ServiceTabs/OnAirMeta'
import { UserInfo } from './user'

export interface InteractiveMuxItemConfiguration {
    RadioName: string
    QRData: string
    IsPayload: boolean
    UseCenterImage: boolean
    CenterImageUrl: string
    OverloadLayout: boolean
    OverloadLayoutData: {
        BackgroundColor: string
        TextColor: string
        CoverEnabled: boolean
        CoverTop: number
        CoverLeft: number
        CoverWidth: number
        CoverHeight: number
        TextEnabled: boolean
        TextWidth: number
        TextHeight: number
        TextTop: number
        TextLeft: number
        QRTop: number
        QRLeft: number
        QRWidth: number
        QRHeight: number
    }
    StartDateTime: string
    EndDateTime: string
    LastUpdate: string
}

export interface InteractiveMuxItem {
    id_radio?: number
    logo?: string
    name?: string
    license_code?: string
    status?: boolean
    configuration?: InteractiveMuxItemConfiguration
}

export enum PendingRadioStatus {
    PENDING_PAYMENT = 'PENDING_PAYMENT',
    PENDING_APPROVAL = 'PENDING_APPROVAL',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
}

export interface PendingRadio {
    createdOn: string
    email: string
    id: number
    idCountry: string
    idNetwork: number
    name: string
    notes: string
    phoneNumber: string
    rejectNotes: string | null
    status: string
    updated_on: string
    hasPromotionCode: boolean
}

export interface Radio {
    id: number
    name: string
    logo: string | File
    slogan: string
    website: string
    email: string
    phone: string | null
    socialContacts?: string
    createdOn?: string
    updatedOn?: string
    imageH2: string | File
    imageH3: string | File
    imageH4: string | File
    ownerAuthorization: string | File
    metadataMonitor: boolean
    metadataParser: Parser | null
    metadataUrl: string
    grants?: string
    categories: Category[]
    tags?: Tag[]
    streams: Stream[]
    idCountry: string | null
    idRadioType: number | null
    idNetwork: number
    idMetadataParser: number
    isPublic?: boolean
    latitude?: number | null
    longitude?: number | null
    verifiedOn?: string | null
}

export interface Category {
    id: number
    value: string
}

export interface Tag {
    id: number
    value: string
}

export interface Stream {
    id: number
    idLanguage: string | null
    url: string
    isVideo: boolean
    serverType: string
    isOnline: boolean
    bitrate: string
    createdOn: string
    updatedOn: string
    priority: number
}

export interface Country {
    id: string
    name: string
}

export interface RadioType {
    id: number
    value: string
}

export interface City {
    id: number
    idProvince: number
    name: string
    capital: boolean
}

export interface Province {
    id: number
    idState: number
    name: string
    capital: boolean
}

export interface Frequency {
    id: number
    idRadio: number
    frequency: number
    idCity: number
    city: City
    radio: Radio
}

export interface State {
    id: number
    name: string
    idCountry: string
}

export interface Show {
    createdOn: string
    duration: number | null
    id: number
    idRadio: number
    radioName: string
    idShow: number
    image: string | File
    rss: string
    rssSync: boolean
    schedule: string | null
    subtitle: string
    summary: string
    title: string
    updatedOn: string
}

export interface ShowSchedule {
    duration: number
    idSchedule: number
    idShow: number
    image: string
    schedule: string
    scheduleInfo: {
        start: TimeObj
        end: TimeObj
    }
    subtitle: string
    summary: string
    title: string
}

interface TimeObj {
    hour: number
    minute: number
}

export interface XperiData {
    ac: string
    active: boolean
    apiKey: string
    broadcastIds: string
    idRadio: number
    idXperi: number
    lastPush: string
    lastUpdate: string
    password: string
    username: string
}

export interface Network {
    createdOn: string
    email: string
    id: number
    logo: string
    name: string
    updatedOn: string
    bundles: number
    users: number
    referentFirstName: string
    referentLastName: string
    phoneNumber: string
    stripeCustomerId: string
}

export interface Bundle {
    createdOn: string
    description: string
    id: number
    isActive: boolean
    logo: string | null
    name: string
    radioCount: string
    updatedOn: string
    isGroup: boolean
}

export interface Position extends Radio {
    bundleInfo: BundleInfo
}

export interface BundleInfo {
    liveShowed: boolean
    position: number
    sponsored: boolean
    weight: number
}

export interface UserNetwork {
    id: number
    updatedOn: string
    invitationPending: boolean
    idRole: number
    radios: Radio[]
    createdOn: string
    email: string
    firebaseUuid: string
    firstName: string
    language: string
    lastName: string
}

export interface UserMux {
    id: number
    idSso: number
    lastSsoSync: string
    associatedOn: string
    updatedOn: string
    userInfo: UserInfo
    invitationPending: boolean
    idRole: number
}

export interface PlayerNetwork {
    id: number
    idNetwork: number
    name: string
    networkName: string
}

export interface Mux {
    id: number
    code: string
    name: string
    radios: number
}

export interface ServiceType {
    id: number
    name: string
}

export interface Service {
    id: number
    name: string
    configuration: any
    type: number
}

export interface MuxServiceAssociation extends Pick<Service, 'id' | 'name'> {
    configuration: {
        radios: ({
            channel: number
        } & Pick<Radio, 'id'>)[]
        instanceUrl: string
        deviceId: number
        userToken: string
    }
    type: ServiceType
}

export interface DabFrequency {
    id: number
    capital: boolean
    frequency: string
    idProvince: number
    name: string
}

export interface StreamOffline {
    audioVolume: number | null
    duration: number | null
    endDate: string | null
    id: number
    idStream: number
    lastUpdate: string
    radio: Radio
    startDate: string
    status: string
    statusCode: number
    stream: Stream
}

export interface Role {
    id: number
    name: string
    description: string
}

export interface Notification {
    id: number
    email: string
    logo: string
    name: string
    type: 'MUX' | 'NETWORK'
}

export interface PageData {
    name: string
    parent: {
        name: string
        route: string
    } | null
}
