import Box from '@mui/material/Box'
import Popover from '@mui/material/Popover'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import {
    confirmMuxInvite,
    confirmNetworkInvite,
    getMuxUsersDetail,
    getNetworksUsersDetail,
} from '../../../resources/api-constants'
import { COLORS } from '../../../resources/colors'
import { fetchNotifications } from '../../../store/actions/thunk_actions'
import { Notification } from '../../../types/data'
import { Reducers, ReducerData, ReducerUser } from '../../../types/reducers'
import FmWorldAxios from '../../../utility/FmWorldAxios'
import Icon from '../../Icon'
import DeleteModal from '../../Modals/DeleteModal'
import LoadingModal from '../../Modals/LoadingModal'
import './NotificationPopover.sass'

interface Props {
    anchorElem: HTMLElement | null
    onClose: (e: any) => void
    isTimeOut?: boolean
}

const NotificationPopover: React.FC<Props> = (props) => {
    const data = useSelector<Reducers, ReducerData>((state) => state.data)
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const [loading, setLoading] = useState(false)
    const [deleting, setDeleting] = useState(false)
    const [inviteToDelete, setInviteToDelete] = useState<Notification | null>(null)
    const dispatch = useDispatch()

    const handleAcceptInvite = async (invitation: Notification) => {
        setLoading(true)
        try {
            if (invitation.type === 'MUX') {
                await FmWorldAxios.post(confirmMuxInvite(invitation.id))
            } else {
                await FmWorldAxios.post(confirmNetworkInvite(invitation.id))
            }

            await dispatch(fetchNotifications())
        } catch (error: any) {
            console.log(error)
        }
        setLoading(false)
    }

    const handleDeclineInvite = async () => {
        if (user.user && inviteToDelete) {
            setDeleting(true)
            try {
                if (inviteToDelete.type === 'MUX') {
                    await FmWorldAxios.delete(getMuxUsersDetail(inviteToDelete.id, user.user.id))
                } else {
                    await FmWorldAxios.delete(getNetworksUsersDetail(inviteToDelete.id, user.user.id))
                }

                await dispatch(fetchNotifications())
            } catch (error: any) {
                console.log(error)
            }
            setDeleting(false)
        }
    }

    const open = Boolean(props.anchorElem)
    return (
        <Popover
            open={open}
            anchorEl={props.anchorElem}
            onClose={(event) => {
                props.onClose(event)
            }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            className="popoverOverflow"
            disableRestoreFocus
        >
            <Box
                sx={{
                    'position': 'relative',
                    '&::before': {
                        backgroundColor: props.isTimeOut ? COLORS.purple : '#fafafa',
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        width: 12,
                        height: 12,
                        top: 12,
                        transform: 'rotate(45deg)',
                        left: -3,
                    },
                }}
            />
            {data.notifications.length > 0 && props.isTimeOut ? (
                <div className="temporaryAlert">
                    Hai {data.notifications.length} {data.notifications.length > 1 ? 'inviti' : 'invito'} in sospeso
                </div>
            ) : (
                <div className="notificationsList">
                    {data.notifications.length > 0 ? (
                        <>
                            <h3 style={{ fontSize: 14, marginBottom: 5, opacity: 0.8 }}>Notifiche</h3>
                            {data.notifications.map((n) => {
                                if (n.type === 'MUX') {
                                    return (
                                        <div key={n.id} className="notificationsList-notification">
                                            <p style={{ opacity: 0.7 }}>
                                                Hai ricevuto un invito per il mux <b>{n.name}</b>
                                            </p>
                                            <div className="notificationsList-notification--iconsContainer">
                                                <div
                                                    className="notificationsList-notification--iconsContainer_icon"
                                                    style={{ borderColor: COLORS.green }}
                                                    onClick={() => void handleAcceptInvite(n)}
                                                >
                                                    <Icon name="check" size={12} color={COLORS.green} />
                                                </div>
                                                <div
                                                    className="notificationsList-notification--iconsContainer_icon"
                                                    style={{ borderColor: COLORS.redDelete }}
                                                    onClick={() => setInviteToDelete(n)}
                                                >
                                                    <Icon name="close" size={12} color={COLORS.redDelete} />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }

                                return (
                                    <div key={n.id} className="notificationsList-notification">
                                        <p style={{ opacity: 0.7 }}>
                                            Hai ricevuto un invito per il network <b>{n.name}</b>
                                        </p>
                                        <div className="notificationsList-notification--iconsContainer">
                                            <div
                                                className="notificationsList-notification--iconsContainer_icon"
                                                style={{ borderColor: COLORS.green }}
                                                onClick={() => void handleAcceptInvite(n)}
                                            >
                                                <Icon name="check" size={12} color={COLORS.green} />
                                            </div>
                                            <div
                                                className="notificationsList-notification--iconsContainer_icon"
                                                style={{ borderColor: COLORS.redDelete }}
                                                onClick={() => setInviteToDelete(n)}
                                            >
                                                <Icon name="close" size={12} color={COLORS.redDelete} />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </>
                    ) : (
                        <div className="notificationsList-noNotifications">Nessun invito in sospeso</div>
                    )}
                </div>
            )}
            <LoadingModal isVisible={loading || deleting} text={'Carica'} />
            <DeleteModal
                onDelete={() => {
                    void handleDeclineInvite()
                }}
                title={`Rifiutare l'invito a "${inviteToDelete?.name}"`}
                text={`Vuoi rifiutare l'invito a "${inviteToDelete?.name}"?`}
                onClose={() => {
                    setInviteToDelete(null)
                }}
                isVisible={inviteToDelete !== null}
            />
        </Popover>
    )
}

export default NotificationPopover
