import MaterialTable, { Column } from 'material-table'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { COLORS } from '../../resources/colors'
import { ROUTES } from '../../resources/routes-constants'
import { StreamOffline } from '../../types/data'
import { Reducers, ReducerGraphic } from '../../types/reducers'
import { getLangTable } from '../../utility/functions'
import { Languages } from '../../utility/Languages'
import FixedTableTheme from '../FixedTableTheme'

interface Props {
    data: StreamOffline[]
    selectStream: (stream: StreamOffline) => void
    isComplete?: boolean
}

const OfflineStreamsTable: React.FC<Props> = (props) => {
    const graphic = useSelector<Reducers, ReducerGraphic>((state) => state.graphic)
    const navigate = useNavigate()
    const { t } = useTranslation()

    const columns: Column<StreamOffline>[] = [
        {
            title: '',
            field: 'offline',
            render: () => {
                return <div className="offlineDot" />
            },
            width: '12%',
            headerStyle: { width: 50 },
            cellStyle: {
                width: 50,
                maxWidth: 50,
            },
        },
        {
            title: t('headers.radio') as string,
            field: 'radio',
            render: (stream: StreamOffline) => {
                return (
                    <p
                        style={{ fontWeight: 'bold', color: COLORS.purple, cursor: 'pointer' }}
                        onClick={() =>
                            navigate(`${ROUTES.RADIO_DETAIL_ROUTE}${stream.radio.id}`, {
                                state: { name: stream.radio.name },
                            })
                        }
                    >
                        {stream.radio.name}
                    </p>
                )
            },
        },
        {
            title: t('general.error') as string,
            field: 'errore',
            render: (stream: StreamOffline) => {
                return (
                    <p>
                        {t('general.error')}
                        <br />
                        <b>
                            {stream.statusCode} - {stream.status}
                        </b>
                    </p>
                )
            },
        },
    ]

    const data = useMemo(() => {
        return props.data
    }, [props.data])

    const actions = useMemo(() => {
        return []
        const actions = [
            (stream: StreamOffline) => ({
                icon: 'more_vert',
                onClick: () => {
                    props.selectStream(stream)
                },
            }),
        ]

        return actions
    }, [props.data])

    return (
        <div className={props.isComplete ? '' : 'hideFooter'}>
            <FixedTableTheme>
                <MaterialTable
                    columns={columns}
                    data={data}
                    actions={actions}
                    title=""
                    localization={getLangTable(graphic.currentLanguage as Languages)}
                    options={{
                        actionsColumnIndex: -1,
                        search: false,
                        pageSize: props.isComplete ? 10 : 4,
                        header: props.isComplete,
                        toolbar: false,
                        pageSizeOptions: [10, 20, 50],
                        actionsCellStyle: {
                            border: '0',
                        },
                        tableLayout: 'auto',
                    }}
                    style={{
                        boxShadow: 'none',
                    }}
                />
            </FixedTableTheme>
        </div>
    )
}

export default OfflineStreamsTable
