import { PopoverOrigin } from '@mui/material/Popover'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getCities, getRadios } from '../../resources/api-constants'
import { City, Radio } from '../../types/data'
import { ReducerData, Reducers } from '../../types/reducers'
import FmWorldAxios from '../../utility/FmWorldAxios'
import AsyncSelectInput from '../SelectInput/AsyncSelectInput'
import SelectInput, { Option } from '../SelectInput/SelectInput'
import FilterPopover from './FilterPopover'

export interface MuxFilterObject {
    query: string
    countries: string[]
    cities: Option[]
    radios: Option[]
}

interface Props {
    hideRadio?: boolean
    filter: MuxFilterObject
    anchorEl: HTMLButtonElement | null
    onClose: () => void
    onReset: () => void
    onUpdate: (f: MuxFilterObject) => void
    position?: PopoverOrigin | null
}

const MuxFilter: React.FC<Props> = (props) => {
    const data = useSelector<Reducers, ReducerData>((state) => state.data)
    const [filter, setFilter] = useState<MuxFilterObject>(props.filter)
    const { t } = useTranslation()

    useEffect(() => {
        if (props.anchorEl) {
            setFilter(props.filter)
        }
    }, [props.anchorEl])

    const countriesValue = () => {
        const options: Option[] = []
        filter.countries.forEach((c) => {
            const found = data.countries.find((country) => country.id === c)
            if (found) {
                options.push({
                    id: found.id,
                    value: found.id,
                    label: found.name,
                })
            }
        })
        return options
    }

    return (
        <FilterPopover
            anchorElem={props.anchorEl}
            onResetClick={() => props.onReset()}
            onFilterClick={() => props.onUpdate(filter)}
            onClose={() => props.onClose()}
            position={props.position ? props.position : null}
        >
            <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
                <SelectInput
                    options={data.countries.map((c) => {
                        return {
                            id: c.id,
                            value: c.id,
                            label: c.name,
                        }
                    })}
                    isMulti
                    value={countriesValue()}
                    label={t('general.countries')}
                    placeholder={t('placeholders.select_countries')}
                    onChange={(v) => {
                        const values = v as Option[]
                        setFilter({
                            ...filter,
                            countries: values.map((c) => {
                                return c.id as string
                            }),
                        })
                    }}
                />
                <AsyncSelectInput
                    label={t('general.city')}
                    value={filter.cities}
                    placeholder={t('placeholders.write_to_select_city')}
                    fetchOptions={async (input: string) => {
                        if (input.length > 2) {
                            const params: any = {
                                query: input,
                            }
                            if (filter.countries.length > 0) {
                                params.countries = filter.countries.toString()
                            }
                            const cities = await FmWorldAxios.get(getCities(), {
                                params: { ...params },
                            }).then((res) => {
                                return res.data.items
                            })
                            return cities.map((c: City) => {
                                return {
                                    id: c.id,
                                    value: c.id,
                                    label: c.name,
                                }
                            })
                        }
                        return []
                    }}
                    isMulti
                    onChange={(value) => {
                        const v = value as Option[]
                        setFilter({ ...filter, cities: v })
                    }}
                />
                {!props.hideRadio ? (
                    <AsyncSelectInput
                        label={t('headers.radios')}
                        value={filter.radios}
                        placeholder={t('placeholders.write_to_select_radio')}
                        fetchOptions={async (input: string) => {
                            if (input.length > 2) {
                                const params: any = {
                                    query: input,
                                }
                                if (filter.countries.length > 0) {
                                    params.countries = filter.countries.toString()
                                }
                                const radios = await FmWorldAxios.get(getRadios(), {
                                    params: { ...params },
                                }).then((res) => {
                                    return res.data.items
                                })
                                return radios.map((c: Radio) => {
                                    return {
                                        id: c.id,
                                        value: c.id,
                                        label: c.name,
                                    }
                                })
                            }
                            return []
                        }}
                        isMulti
                        onChange={(value) => {
                            const v = value as Option[]
                            setFilter({ ...filter, radios: v })
                        }}
                    />
                ) : null}
            </div>
        </FilterPopover>
    )
}

export default MuxFilter
