import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getStreamsOffline } from '../../resources/api-constants'
import { COLORS } from '../../resources/colors'
import { StreamOffline } from '../../types/data'
import FmWorldAxios from '../../utility/FmWorldAxios'
import CardContainer from '../CardContainer/CardContainer'
import Loader from '../Loader/Loader'
import OfflineStreamListModal from '../Modals/OfflineStreamListModal'
import OfflineStreamsTable from '../Tables/OfflineStreamsTable'

const StreamOfflineCard: React.FC = () => {
    const [streams, setStreams] = useState<StreamOffline[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [showModalsStreams, setShowModalsStreams] = useState(false)
    const [error, setError] = useState('')
    const { t } = useTranslation()

    const fetchStreamsOffline = async () => {
        setError('')
        setIsLoading(true)
        try {
            const streams = await FmWorldAxios.get(getStreamsOffline(), { params: { offline: true } }).then((res) => {
                return res.data.items
            })
            setStreams(streams)
        } catch (error: any) {
            setError(error.toString())
        }
        setIsLoading(false)
    }

    useEffect(() => {
        void fetchStreamsOffline()
    }, [])

    return (
        <CardContainer>
            <div className="titleRow">
                <div>
                    <h2 className="subtitle">{t('homepage.last_stream_offline')}</h2>
                    {error && <p className="u-normal-text u-bold-text u-error-text">{error}</p>}
                </div>
                {!isLoading ? (
                    <p
                        style={{
                            color: COLORS.purple,
                            fontWeight: '600',
                            textDecoration: 'underline',
                            cursor: 'pointer',
                        }}
                        onClick={() => setShowModalsStreams(true)}
                    >
                        {t('general.see_all')}
                    </p>
                ) : null}
            </div>

            {isLoading ? (
                <div className="loadingGrid" style={{ maxHeight: 120 }}>
                    <Loader />
                </div>
            ) : (
                <OfflineStreamsTable
                    data={streams}
                    selectStream={() => {
                        return
                    }}
                />
            )}
            <OfflineStreamListModal
                streams={streams}
                onClose={() => setShowModalsStreams(false)}
                isVisible={showModalsStreams}
            />
        </CardContainer>
    )
}

export default StreamOfflineCard
