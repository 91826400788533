import React, { useEffect, useRef, useState } from 'react'
import Button from '../../../components/Button/Button'
import { getShowDetail, getShows } from '../../../resources/api-constants'
import { COLORS } from '../../../resources/colors'
import { Show } from '../../../types/data'
import FmWorldAxios from '../../../utility/FmWorldAxios'
import '../../../components/Grids/Grid.sass'
import Loader from '../../../components/Loader/Loader'
import TextInput from '../../../components/TextInput/TextInput'
import { voidShow, VoidShow } from '../../../utility/voidConstants'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../../resources/routes-constants'
import DeleteModal from '../../../components/Modals/DeleteModal'
// import FmWorldMultipartAxios from '../../../utility/FmWorldMultipartAxios'
import LoadingModal from '../../../components/Modals/LoadingModal'
import TextAreaInput from '../../../components/TextAreaInput/TextAreaInput'
import FmWorldMultipartAxios from '../../../utility/FmWorldMultipartAxios'
import { Grants } from '../../../utility/Grants'
import { PLACEHOLDER } from '../../../resources/constants'
import { useTranslation } from 'react-i18next'

interface Props {
    idShow: string
    idNetwork: number
    idRadio: number
    nameRadio: string
    permissions: Grants[]
}

const ShowDetailTab: React.FC<Props> = (props) => {
    const [showDetail, setShowDetail] = useState<Show | VoidShow | null>(null)
    const [isLoading, setIsLoading] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)
    const [isCreating, setIsCreating] = useState(false)
    const [showModalDelete, setShowModalDelete] = useState(false)
    const [error, setError] = useState('')
    const navigate = useNavigate()
    const logoRef = useRef<HTMLImageElement>(null)
    const { t } = useTranslation()

    const fetchShowDetail = async () => {
        setIsLoading(true)
        const show = await FmWorldAxios.get(getShowDetail(parseInt(props.idShow, 10))).then((res) => {
            return res.data
        })
        setShowDetail(show)
        setIsLoading(false)
    }

    useEffect(() => {
        if (props.idShow && props.idShow !== 'new') {
            void fetchShowDetail()
        } else {
            setShowDetail({ ...voidShow, idRadio: props.idRadio })
        }
    }, [props.idShow])

    const handleInsertLogo = (e: any, ref: React.RefObject<HTMLImageElement>, onChange: (img: File) => void) => {
        if (e.target.files[0] && showDetail) {
            const image = e.target.files[0]
            if (image) {
                onChange(image)
                const fr = new FileReader()
                fr.onload = function () {
                    if (ref && ref.current && fr.result) {
                        ref.current.src = fr.result as string
                    }
                }
                fr.readAsDataURL(image)
            }
        }
    }

    const editShow = async () => {
        if (showDetail) {
            setError('')
            setIsUpdating(true)
            try {
                const formData = new FormData()
                Object.keys(showDetail).map((key) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    const currentField = showDetail[key as any]
                    formData.append(key, Array.isArray(currentField) ? JSON.stringify(currentField) : currentField)
                    return
                })
                await FmWorldMultipartAxios.put(getShowDetail(parseInt(props.idShow, 10)), formData)
                setIsUpdating(false)
                navigate(`${ROUTES.SHOW_DETAIL_ROUTE}${props.idShow}`, {
                    state: {
                        name: showDetail.title,
                        parent: { id: props.idRadio, name: props.nameRadio },
                        idNetwork: props.idNetwork,
                    },
                })
            } catch (error: any) {
                console.log('ERROR', error)
                setIsUpdating(false)
                setError(error.toString())
            }
        }
    }

    const createShow = async () => {
        if (showDetail && showDetail.title) {
            setError('')
            setIsCreating(true)
            try {
                const formData = new FormData()
                Object.keys(showDetail).map((key) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    const currentField = showDetail[key as any]
                    formData.append(key, Array.isArray(currentField) ? JSON.stringify(currentField) : currentField)
                    return
                })
                // console.log(formData.getAll('logo'), formData.getAll('name'))
                const newId = await FmWorldMultipartAxios.post(getShows(), formData).then((res) => {
                    return res.data.id
                })
                setIsCreating(false)
                navigate(`${ROUTES.SHOW_DETAIL_ROUTE}${newId}`, {
                    state: {
                        name: showDetail.title,
                        parent: { id: props.idRadio, name: props.nameRadio },
                        idNetwork: props.idNetwork,
                    },
                })
            } catch (error: any) {
                console.log('ERROR', error)
                setIsCreating(false)
                setError(error.toString())
            }
        }
    }

    const deleteShow = async () => {
        if (showDetail) {
            setError('')
            setIsLoading(true)
            try {
                await FmWorldAxios.delete(getShowDetail(parseInt(props.idShow, 10)))
                setShowModalDelete(false)
                navigate(`${ROUTES.RADIO_DETAIL_ROUTE}${props.idRadio}`, {
                    state: { name: props.nameRadio, idNetwork: props.idNetwork },
                })
            } catch (error: any) {
                console.log('ERROR', error)
                setError(error.toString())
            }
            setIsLoading(false)
        }
    }

    if (isLoading) {
        return (
            <div className="loadingGrid">
                <Loader />
            </div>
        )
    }

    return (
        <div>
            <div className="titleRow">
                <div style={{ flex: 1 }}>
                    <h2 className="title">{t('shows.show_details')}</h2>
                    <h3 className="subtitle" style={{ fontWeight: 400 }}>
                        {t('shows.manage_show_details')}
                    </h3>
                    {error && <p className="u-normal-text u-bold-text u-error-text">{error}</p>}
                </div>
                <div style={{ display: 'flex', flex: 2, gap: 10, alignItems: 'center', justifyContent: 'flex-end' }}>
                    <div style={{ flex: 3 }} />
                    {props.permissions.includes(Grants.EDIT_RADIO) ? (
                        <div style={{ flex: 1.5 }}>
                            {props.idShow && props.idShow !== 'new' ? (
                                <Button
                                    label={t('shows.delete_show')}
                                    onPress={() => {
                                        setShowModalDelete(true)
                                    }}
                                    type="submit"
                                    background="#fff"
                                    border={`2px solid ${COLORS.redDelete}`}
                                    color={COLORS.redDelete}
                                />
                            ) : null}
                        </div>
                    ) : null}
                    {props.permissions.includes(Grants.EDIT_RADIO) ? (
                        <div style={{ flex: 1 }}>
                            {props.idShow && props.idShow !== 'new' ? (
                                <Button
                                    disabled={!showDetail || !showDetail.title}
                                    label={t('general.update')}
                                    onPress={() => {
                                        void editShow()
                                    }}
                                    type="submit"
                                />
                            ) : (
                                <Button
                                    disabled={!showDetail || !showDetail.title}
                                    label={t('general.add')}
                                    onPress={() => {
                                        void createShow()
                                    }}
                                    type="submit"
                                />
                            )}
                        </div>
                    ) : null}
                </div>
            </div>
            {showDetail ? (
                <div>
                    <div style={{ borderBottom: '2px solid rgba(0,0,0,0.07)', padding: '20px 0' }}>
                        <div className="u-elements-row">
                            <div className="uploadImage">
                                <img
                                    ref={logoRef}
                                    alt="logo1"
                                    src={showDetail.image ? (showDetail.image as string) : PLACEHOLDER}
                                    className="uploadImage-logo"
                                    onError={(e: any) => {
                                        e.currentTarget.src = PLACEHOLDER
                                    }}
                                />
                                {props.permissions.includes(Grants.EDIT_RADIO) ? (
                                    <input
                                        className="uploadImage-upload"
                                        type="file"
                                        accept="image/*"
                                        onChange={(event) =>
                                            handleInsertLogo(event, logoRef, (img) => {
                                                setShowDetail({
                                                    ...showDetail,
                                                    image: img,
                                                })
                                            })
                                        }
                                    />
                                ) : null}
                            </div>
                            <p className="captionImg">
                                {t('shows.customize_logo_show')}
                                <br />({t('radios.500_500_px')})
                            </p>
                        </div>
                        <div className="u-elements-row">
                            <TextInput
                                label={t('shows.title')}
                                value={showDetail.title}
                                onTextChange={(text) => {
                                    setShowDetail({
                                        ...showDetail,
                                        title: text,
                                    })
                                }}
                                bordered
                                disabled={!props.permissions.includes(Grants.EDIT_RADIO)}
                            />
                            <TextInput
                                label={t('shows.subtitle')}
                                value={showDetail.subtitle}
                                onTextChange={(text) => {
                                    setShowDetail({
                                        ...showDetail,
                                        subtitle: text,
                                    })
                                }}
                                bordered
                                disabled={!props.permissions.includes(Grants.EDIT_RADIO)}
                            />
                            <div style={{ flex: 1 }} />
                        </div>
                    </div>
                    <div style={{ padding: '20px 0' }} className="u-elements-row">
                        <TextAreaInput
                            label={t('shows.description')}
                            value={showDetail.summary}
                            onTextChange={(text) => {
                                setShowDetail({
                                    ...showDetail,
                                    summary: text,
                                })
                            }}
                            bordered
                            disabled={!props.permissions.includes(Grants.EDIT_RADIO)}
                        />
                    </div>
                </div>
            ) : null}
            <LoadingModal
                isVisible={isCreating || isUpdating}
                text={isUpdating ? t('loadings.updating_show') : t('loadings.creating_show')}
            />
            <DeleteModal
                onDelete={() => {
                    void deleteShow()
                }}
                title={`${t('shows.delete_show')} ${showDetail?.title}`}
                text={`${t('shows.want_delete_show')} ${showDetail?.title}?`}
                onClose={() => {
                    setShowModalDelete(false)
                }}
                isVisible={showModalDelete}
            />
        </div>
    )
}

export default ShowDetailTab
