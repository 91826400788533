import { ReducerData, ReducerGraphic, ReduxActionData } from '../../types/reducers'
import { Languages } from '../../utility/Languages'
import { GRAPHIC_ACTIONS } from '../actions/graphic'

const initialState: ReducerGraphic = {
    collapsedBar: false,
    currentLanguage: Languages.italian,
    pageHeader: null,
    lastRadioSearch: null,
    lastNetworkSearch: null,
    lastFrequencySearch: null,
    lastMuxSearch: null,
    lastMuxFrequenciesSearch: null,
}

const graphicState: (state: ReducerGraphic, action: ReduxActionData<any, GRAPHIC_ACTIONS>) => ReducerGraphic = (
    state = initialState,
    action: ReduxActionData<any, GRAPHIC_ACTIONS>
) => {
    switch (action.type) {
        case GRAPHIC_ACTIONS.SET_COLLAPSED_BAR:
            return {
                ...state,
                collapsedBar: action.payload,
            }
        case GRAPHIC_ACTIONS.SET_LAST_SEARCH_RADIO:
            return {
                ...state,
                lastRadioSearch: action.payload,
            }
        case GRAPHIC_ACTIONS.SET_LAST_SEARCH_NETWORK:
            return {
                ...state,
                lastNetworkSearch: action.payload,
            }
        case GRAPHIC_ACTIONS.SET_LAST_SEARCH_FREQUENCY:
            return {
                ...state,
                lastFrequencySearch: action.payload,
            }
        case GRAPHIC_ACTIONS.SET_LAST_SEARCH_MUX:
            return {
                ...state,
                lastMuxSearch: action.payload,
            }
        case GRAPHIC_ACTIONS.SET_LAST_SEARCH_MUX_FREQUENCIES:
            return {
                ...state,
                lastMuxFrequenciesSearch: action.payload,
            }
        case GRAPHIC_ACTIONS.SET_CURRENT_LANGUAGE:
            return {
                ...state,
                currentLanguage: action.payload,
            }
        case GRAPHIC_ACTIONS.SET_PAGE_HEADER:
            return {
                ...state,
                pageHeader: action.payload,
            }
        default:
            return state
    }
}

export default graphicState
