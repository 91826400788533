import { useEffect, useRef } from 'react'

/**
 * Mantiene lo stato precendente del valore passato
 * @param value any
 */
export const usePrevious = (value: any): any => {
    const ref = useRef()
    useEffect(() => {
        ref.current = value
    })
    return ref.current
}

/**
 * Hook that manage the interval
 * @param callback Callback function to execute in the loop
 * @param timeout Delay time
 */
export const useTimeout = (callback: any, timeout: number | null): void => {
    const savedCallback = useRef<any>(null)

    useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    useEffect(() => {
        const tick = (): void => {
            savedCallback.current()
        }

        if (timeout !== null) {
            const id = setTimeout(tick, timeout)
            return (): void => clearTimeout(id)
        }

        return () => null
    }, [timeout])
}
