import { Category, Country, Network, Notification, Province, RadioType, Role, State, Tag } from '../../types/data'
import { ReduxAction } from '../../types/reducers'

export enum DATA_ACTIONS {
    SET_TOTAL_RADIOS = 'dataActions/setTotalRadios',
    SET_TOTAL_NETWORKS = 'dataActions/setTotalNetworks',
    SET_TOTAL_FREQUENCIES = 'dataActions/setTotalFrequencies',
    SET_TOTAL_DAB = 'dataActions/setTotalDab',
    SET_TOTAL_INTERACTIVE_MUX = 'dataActions/setTotalInteractiveMUX',
    SET_RADIO_TYPES = 'dataActions/setRadioTypes',
    SET_COUNTRIES = 'dataActions/setCountries',
    SET_STATES = 'dataActions/setStates',
    SET_PROVINCES = 'dataActions/setProvinces',
    SET_CATEGORIES = 'dataActions/setCategories',
    SET_RADIO_TAGS = 'dataActions/setRadioTags',
    SET_SINGLE_NETWORK = 'dataActions/setSingleNetwork',
    SET_NETWORK_ROLES = 'dataActions/setNetworkRoles',
    SET_MUX_ROLES = 'dataActions/setMuxRoles',
    SET_NOTIFICATIONS = 'dataActions/setNotifications',
    SET_SELECTED_NETWORK = 'dataActions/setSelectedNetwork',
}

export const setSelectedNetwork: ReduxAction<Network, DATA_ACTIONS> = (Network: Network) => {
    return {
        type: DATA_ACTIONS.SET_SELECTED_NETWORK,
        payload: Network,
    }
}

export const setTotalRadios: ReduxAction<number, DATA_ACTIONS> = (total: number) => {
    return {
        type: DATA_ACTIONS.SET_TOTAL_RADIOS,
        payload: total,
    }
}

export const setTotalNetworks: ReduxAction<number, DATA_ACTIONS> = (total: number) => {
    return {
        type: DATA_ACTIONS.SET_TOTAL_NETWORKS,
        payload: total,
    }
}

export const setTotalFrequencies: ReduxAction<number, DATA_ACTIONS> = (total: number) => {
    return {
        type: DATA_ACTIONS.SET_TOTAL_FREQUENCIES,
        payload: total,
    }
}

export const setTotalDab: ReduxAction<number, DATA_ACTIONS> = (total: number) => {
    return {
        type: DATA_ACTIONS.SET_TOTAL_DAB,
        payload: total,
    }
}

export const setTotalInteractiveMUX: ReduxAction<number, DATA_ACTIONS> = (total: number) => {
    return {
        type: DATA_ACTIONS.SET_TOTAL_INTERACTIVE_MUX,
        payload: total,
    }
}

export const setRadioTypes: ReduxAction<RadioType[], DATA_ACTIONS> = (data: RadioType[]) => {
    return {
        type: DATA_ACTIONS.SET_RADIO_TYPES,
        payload: data,
    }
}

export const setCountries: ReduxAction<Country[], DATA_ACTIONS> = (data: Country[]) => {
    return {
        type: DATA_ACTIONS.SET_COUNTRIES,
        payload: data,
    }
}

export const setStates: ReduxAction<State[], DATA_ACTIONS> = (data: State[]) => {
    return {
        type: DATA_ACTIONS.SET_STATES,
        payload: data,
    }
}

export const setProvinces: ReduxAction<Province[], DATA_ACTIONS> = (data: Province[]) => {
    return {
        type: DATA_ACTIONS.SET_PROVINCES,
        payload: data,
    }
}

export const setCategories: ReduxAction<Category[], DATA_ACTIONS> = (data: Category[]) => {
    return {
        type: DATA_ACTIONS.SET_CATEGORIES,
        payload: data,
    }
}

export const setRadioTags: ReduxAction<Tag[], DATA_ACTIONS> = (data: Tag[]) => {
    return {
        type: DATA_ACTIONS.SET_RADIO_TAGS,
        payload: data,
    }
}

export const setSingleNetwork: ReduxAction<number | null, DATA_ACTIONS> = (id: number | null) => {
    return {
        type: DATA_ACTIONS.SET_SINGLE_NETWORK,
        payload: id,
    }
}

export const setNetworkRoles: ReduxAction<Role[], DATA_ACTIONS> = (data: Role[]) => {
    return {
        type: DATA_ACTIONS.SET_NETWORK_ROLES,
        payload: data,
    }
}

export const setMuxRoles: ReduxAction<Role[], DATA_ACTIONS> = (data: Role[]) => {
    return {
        type: DATA_ACTIONS.SET_MUX_ROLES,
        payload: data,
    }
}

export const setNotifications: ReduxAction<Notification[], DATA_ACTIONS> = (data: any[]) => {
    return {
        type: DATA_ACTIONS.SET_NOTIFICATIONS,
        payload: data,
    }
}
