export interface MediaPlayer {
    id: number
    url: string
    name: string
    type: 'audio' | 'video'
}

export enum PlayerStatus {
    PLAY = 'play',
    PAUSE = 'pause',
}
