export enum Grants {
    VIEW_ALL_USERS = 'VIEW_ALL_USERS',
    EDIT_ALL_USERS = 'EDIT_ALL_USERS',
    DELETE_ALL_USERS = 'DELETE_ALL_USERS',
    VIEW_ALL_NETWORKS = 'VIEW_ALL_NETWORKS',
    EDIT_ALL_NETWORKS = 'EDIT_ALL_NETWORKS',
    DELETE_ALL_NETWORKS = 'DELETE_ALL_NETWORKS',
    VIEW_ALL_RADIOS = 'VIEW_ALL_RADIOS',
    EDIT_ALL_RADIOS = 'EDIT_ALL_RADIOS',
    VIEW_NETWORK = 'VIEW_NETWORK',
    EDIT_NETWORK = 'EDIT_NETWORK',
    DELETE_NETWORK = 'DELETE_NETWORK',
    VIEW_RADIO = 'VIEW_RADIO',
    EDIT_RADIO = 'EDIT_RADIO',
    DELETE_RADIO = 'DELETE_RADIO',
    CREATE_RADIO = 'CREATE_RADIO',
    CREATE_NETWORK = 'CREATE_NETWORK',
    CREATE_BUNDLE = 'CREATE_BUNDLE',
    EDIT_BUNDLE = 'EDIT_BUNDLE',
    DELETE_BUNDLE = 'DELETE_BUNDLE',
    VIEW_BUNDLE = 'VIEW_BUNDLE',
    VIEW_NETWORK_USERS = 'VIEW_NETWORK_USERS',
    EDIT_NETWORK_USERS = 'EDIT_NETWORK_USERS',
    DELETE_NETWORK_USERS = 'DELETE_NETWORK_USERS',
    ADD_NETWORK_USERS = 'ADD_NETWORK_USERS',
    MANAGE_SYSTEM = 'MANAGE_SYSTEM',
}
