/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Loader from '../../../components/Loader/Loader'
import { getPendingElements } from '../../../resources/api-constants'
import { COLORS } from '../../../resources/colors'
import { PendingRadio } from '../../../types/data'
import FmWorldAxios from '../../../utility/FmWorldAxios'
import { Grants } from '../../../utility/Grants'
import { useSelector } from 'react-redux'
import { ReducerData, Reducers } from '../../../types/reducers'
import PendingElementsTable from '../../../components/Tables/PendingElementsTable'
import PendingElementsAdminTable from '../../../components/Tables/PendingElementsAdminTable'

interface Props {
    permissions: Grants[]
}

const PendingElementsAdminView: React.FC<Props> = (props) => {
    const [error, setError] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [pendingElements, setPendingElements] = useState<PendingRadio[]>([])
    const data = useSelector<Reducers, ReducerData>((state) => state.data)
    const selectedNetwork = data.selectedNetwork

    const { t } = useTranslation()

    const fetchPendingElements = async () => {
        setError('')
        setIsLoading(true)
        try {
            if (!selectedNetwork) {
                return
            }
            const fetchedElements = await FmWorldAxios.get(getPendingElements()).then((res) => {
                return res.data.items
            })
            setPendingElements(fetchedElements)
        } catch (error: any) {
            console.log('ERROR', error)
            setError(error.toString())
        }
        setIsLoading(false)
    }

    const updateBundles = async () => {
        await fetchPendingElements()
    }

    useEffect(() => {
        void fetchPendingElements()
    }, [selectedNetwork])

    if (isLoading) {
        return (
            <div className="loadingGrid">
                <Loader />
            </div>
        )
    }

    return (
        <div>
            <div className="titleRow">
                <div style={{ flex: 1 }}>
                    <h2 className="title">{t('sidemenus.pending_elements')}</h2>
                    <h3 className="subtitle" style={{ fontWeight: 400 }}>
                        {t('networks.view_your_radios')}
                    </h3>
                    {error && <p className="u-normal-text u-bold-text u-error-text">{error}</p>}
                </div>
            </div>
            <PendingElementsAdminTable data={pendingElements} onReload={() => void fetchPendingElements()} />
        </div>
    )
}

export default PendingElementsAdminView
