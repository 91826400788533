import MaterialTable, { Column } from 'material-table'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { COLORS } from '../../resources/colors'
import { setMediaPlayer } from '../../store/actions/player'
import { Stream } from '../../types/data'
import { Reducers, ReducerGraphic } from '../../types/reducers'
import { getLangTable } from '../../utility/functions'
import { Languages } from '../../utility/Languages'
import FixedTableTheme from '../FixedTableTheme'
import Icon from '../Icon'

interface Props {
    data: Stream[]
    radioName?: string
    selectStream: (stream: Stream) => void
    showActions?: boolean
}

const StreamsTable: React.FC<Props> = (props) => {
    const graphic = useSelector<Reducers, ReducerGraphic>((state) => state.graphic)
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const columns: Column<Stream>[] = [
        {
            title: t('radios.offline') as string,
            field: 'isOnline',
            render: (stream: Stream) => {
                if (!stream.isOnline) {
                    return <div className="offlineDot" />
                }
                return null
            },
        },
        {
            title: t('general.url') as string,
            field: 'url',
            render: (stream: Stream) => {
                return <p style={{ fontWeight: 700, color: COLORS.purple }}>{stream.url}</p>
            },
        },
        {
            title: t('radios.media') as string,
            field: 'media',
            render: (stream: Stream) => {
                if (stream.isVideo) {
                    return <Icon name="video" size={16} color={COLORS.lightGrey} />
                }
                return <Icon name="audio" size={18} color={COLORS.lightGrey} />
            },
        },
        {
            title: t('radios.server') as string,
            field: 'server',
            render: (stream: Stream) => {
                return <p>{stream.serverType}</p>
            },
        },
    ]

    const data = useMemo(() => {
        return props.data
    }, [props.data])

    const actions = useMemo(() => {
        const actions = [
            (stream: Stream) => ({
                icon: 'play_circle_outlined',
                onClick: () => {
                    dispatch(
                        setMediaPlayer({
                            id: stream.id,
                            url: stream.url,
                            name: props.radioName || '',
                            type: stream.isVideo ? 'video' : 'audio',
                        })
                    )
                },
            }),
            (stream: Stream) => ({
                icon: 'more_vert',
                onClick: () => {
                    props.selectStream(stream)
                },
            }),
        ]

        return actions
    }, [props.data])

    return (
        <FixedTableTheme>
            <MaterialTable
                columns={columns}
                data={data}
                actions={props.showActions ? actions : []}
                title=""
                localization={getLangTable(graphic.currentLanguage as Languages)}
                options={{
                    actionsColumnIndex: -1,
                    search: false,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 50],
                    actionsCellStyle: {
                        border: '0',
                    },
                }}
                style={{
                    boxShadow: 'none',
                }}
            />
        </FixedTableTheme>
    )
}

export default StreamsTable
