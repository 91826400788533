import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../../components/Button/Button'
import Loader from '../../../components/Loader/Loader'
import ActionsModal from '../../../components/Modals/ActionsModal'
import DeleteModal from '../../../components/Modals/DeleteModal'
import LoadingModal from '../../../components/Modals/LoadingModal'
import UserNetworkModal from '../../../components/Modals/UserNetworkModal'
import UsersNetworkTable from '../../../components/Tables/UsersNetworkTable'
import TextInput from '../../../components/TextInput/TextInput'
import { getNetworksUsers, getNetworksUsersDetail } from '../../../resources/api-constants'
import { COLORS } from '../../../resources/colors'
import { UserNetwork } from '../../../types/data'
import FmWorldAxios from '../../../utility/FmWorldAxios'
import { Grants } from '../../../utility/Grants'
import { useSelector } from 'react-redux'
import { Reducers, ReducerData, ReducerUser } from '../../../types/reducers'

const UsersTab: React.FC = () => {
    const [search, setSearch] = useState('')
    const [error, setError] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)
    const [isCreating, setIsCreating] = useState(false)
    const [selectUserForActions, setSelectUserForActions] = useState<UserNetwork | null>(null)
    const [editUser, setEditUser] = useState<UserNetwork | null>(null)
    const [addUserModal, setAddUserModal] = useState(false)
    const [deletedUser, setDeletedUser] = useState<UserNetwork | null>(null)
    const [users, setUsers] = useState<UserNetwork[]>([])
    const [filteredUsers, setFilteredUsers] = useState<UserNetwork[]>([])
    const { t } = useTranslation()
    const data = useSelector<Reducers, ReducerData>((state) => state.data)
    const selectedNetwork = data.selectedNetwork
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)

    const fetchUsers = async () => {
        setError('')
        setIsLoading(true)
        try {
            if (!selectedNetwork) {
                return
            }
            const usersFetch = await FmWorldAxios.get(getNetworksUsers(selectedNetwork.id)).then((res) => {
                return res.data
            })
            setEditUser(null)
            setUsers(usersFetch)
        } catch (error: any) {
            console.log('ERROR', error)
            setError(error.toString())
        }
        setIsLoading(false)
    }

    useEffect(() => {
        if (selectedNetwork?.id) {
            void fetchUsers()
        }
    }, [selectedNetwork?.id])

    useEffect(() => {
        if (search) {
            const filtered = users.filter(
                (u) =>
                    u.firstName.toLowerCase().includes(search.toLowerCase()) ||
                    u.lastName.toLowerCase().includes(search.toLowerCase()) ||
                    u.email.toLowerCase().includes(search.toLowerCase())
            )
            setFilteredUsers(filtered)
        } else {
            setFilteredUsers(users)
        }
    }, [search, users])

    const actionsModal = () => {
        const actions = []
        if (user.user?.grants.includes(Grants.EDIT_NETWORK_USERS)) {
            actions.push({
                text: t('networks.edit_user'),
                color: COLORS.purple,
                onClick: () => {
                    setEditUser(selectUserForActions)
                    setSelectUserForActions(null)
                },
            })
        }
        if (selectUserForActions?.id === user.user?.id) {
            return actions
        }

        if (user.user?.grants.includes(Grants.DELETE_NETWORK_USERS)) {
            actions.push({
                text: t('networks.delete_user'),
                color: COLORS.redDelete,
                onClick: () => {
                    setDeletedUser(selectUserForActions)
                    setSelectUserForActions(null)
                },
            })
        }

        return actions
    }

    const handleEditUser = async (idRole: number, radios?: number[] | null) => {
        if (editUser) {
            setError('')
            setIsUpdating(true)
            try {
                if (!selectedNetwork) {
                    return
                }
                const body = {
                    idRole,
                    radios: radios ?? [],
                }
                await FmWorldAxios.put(getNetworksUsersDetail(selectedNetwork?.id, editUser.id), body)
                await fetchUsers()
                setEditUser(null)
                setAddUserModal(false)
            } catch (error: any) {
                setError(error.toString())
            }
            setIsUpdating(false)
        }
    }

    const handleCreateUser = async (idRole: number, radios: number[] | null, email: string, password: string) => {
        setError('')
        setIsCreating(true)
        try {
            if (radios === null) {
                radios = []
            }
            const body = {
                idRole,
                radios,
                email,
                password,
            }

            if (!selectedNetwork) {
                return
            }
            await FmWorldAxios.post(getNetworksUsers(selectedNetwork?.id), body)
            setEditUser(null)
            setAddUserModal(false)
            await fetchUsers()
        } catch (error: any) {
            setError(error.toString())
        }
        setIsCreating(false)
    }

    const handleDeleteUser = async () => {
        if (deletedUser) {
            setError('')
            setIsLoading(true)
            try {
                if (!selectedNetwork) {
                    return
                }
                await FmWorldAxios.delete(getNetworksUsersDetail(selectedNetwork?.id, deletedUser.id))
                await fetchUsers()
                setDeletedUser(null)
            } catch (error: any) {
                setError(error.toString())
            }
            setIsLoading(false)
        }
    }

    if (isLoading) {
        return (
            <div className="loadingGrid">
                <Loader />
            </div>
        )
    }

    return (
        <div>
            <div className="titleRow">
                <div style={{ flex: 1 }}>
                    <h2 className="title">{t('networks.users')}</h2>
                    <h3 className="subtitle" style={{ fontWeight: 400 }}>
                        {t('networks.manage_users_network')}
                    </h3>
                    {error && <p className="u-normal-text u-bold-text u-error-text">{error}</p>}
                </div>
                <div style={{ display: 'flex', flex: 2, gap: 10, alignItems: 'center', justifyContent: 'flex-end' }}>
                    <div style={{ flex: 1 }} />
                    <div style={{ flex: 2 }}>
                        <TextInput
                            value={search}
                            placeholder={t('general.search')}
                            icon="search"
                            iconPosition="right"
                            onTextChange={(v) => setSearch(v)}
                        />
                    </div>
                    <div style={{ flex: 1 }}>
                        <Button
                            label={t('general.refresh')}
                            onPress={() => {
                                setSearch('')
                            }}
                            type="submit"
                            background="rgba(100,38,119,0.14)"
                            border={`2px solid ${COLORS.purple}`}
                            color={COLORS.purple}
                        />
                    </div>
                    {user.user?.grants.includes(Grants.ADD_NETWORK_USERS) ? (
                        <div style={{ flex: 1 }}>
                            <Button
                                label={t('general.add')}
                                onPress={() => {
                                    setAddUserModal(true)
                                }}
                                type="submit"
                            />
                        </div>
                    ) : null}
                </div>
            </div>
            <div>
                <UsersNetworkTable
                    showActions={actionsModal().length > 0}
                    data={filteredUsers}
                    selectUser={(u) => setSelectUserForActions(u)}
                />
            </div>
            <ActionsModal
                isVisible={selectUserForActions !== null}
                title={t('general.what_to_do')}
                onClose={() => setSelectUserForActions(null)}
                actions={actionsModal()}
            />
            <UserNetworkModal
                isVisible={addUserModal || editUser !== null}
                userData={editUser}
                onClose={() => {
                    setEditUser(null)
                    setAddUserModal(false)
                }}
                idNetwork={selectedNetwork?.id || 0}
                onSave={(role, radios, email, password) => {
                    radios = radios ?? []
                    if (email && password) {
                        void handleCreateUser(role, radios, email, password)
                    } else {
                        void handleEditUser(role, radios)
                    }
                }}
            />
            <DeleteModal
                onDelete={() => {
                    void handleDeleteUser()
                }}
                title={`${t('networks.delete_this_user')} ${
                    deletedUser?.firstName ? deletedUser?.firstName : deletedUser?.email
                } ${deletedUser?.lastName ? deletedUser?.lastName : ''}`}
                text={`${t('networks.want_delete_user')} ${
                    deletedUser?.firstName ? deletedUser?.firstName : deletedUser?.email
                } ${deletedUser?.lastName ? deletedUser?.lastName : ''}?`}
                onClose={() => {
                    setDeletedUser(null)
                }}
                isVisible={deletedUser !== null}
            />
            <LoadingModal
                isVisible={isCreating || isUpdating}
                text={isCreating ? t('loadings.creating_user') : t('loadings.inviting_user')}
            />
        </div>
    )
}

export default UsersTab
