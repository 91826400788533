import React from 'react'
import '../resources/fonts/fontello/css/fmworldfrontend.css'

interface Props {
    name: string
    size: number
    color: string
    style?: any
    onClick?: () => void
}

const Icon: React.FC<Props> = (props) => {
    return (
        <i
            onClick={() => {
                if (props.onClick) {
                    props.onClick()
                }
            }}
            className={`icon-${props.name}`}
            style={{ fontSize: props.size, color: props.color, ...props.style }}
        />
    )
}

export default Icon
