import React, { useEffect, useState } from 'react'
import './Modals.sass'
import Modal from '@mui/material/Modal'
import { Radio } from '../../types/data'
import Button from '../Button/Button'
import { COLORS } from '../../resources/colors'
import { getRadios } from '../../resources/api-constants'
import FmWorldAxios from '../../utility/FmWorldAxios'
import AsyncSelectInput from '../SelectInput/AsyncSelectInput'
import { Option } from '../SelectInput/SelectInput'
import RadioFilter, { RadioFilterObject } from '../Filters/RadioFilter'
import { useTranslation } from 'react-i18next'

interface Props {
    isVisible: boolean
    onClose: () => void
    onSave: (newElement: Option) => void
}

const initialFilter = {
    countries: [],
    categories: [],
    types: [],
    videoOnly: false,
    returnEmptyStreams: true,
    returnOfflineStreams: true,
    networks: [],
    returnVerifiedStreams: false,
}

const DabRadioModal: React.FC<Props> = (props) => {
    const [selectedRadio, setSelectedRadio] = useState<Option | null>(null)
    const [filter, setFilter] = useState<RadioFilterObject>(initialFilter)
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const { t } = useTranslation()

    useEffect(() => {
        if (props.isVisible) {
            setSelectedRadio(null)
        }
    }, [props.isVisible])

    const handleFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const setFilterParams = () => {
        const localFilter: any = {
            videoOnly: filter.videoOnly,
            returnEmptyStreams: filter.returnEmptyStreams,
            returnVerifiedStreams: filter.returnVerifiedStreams,
            returnOfflineStreams: filter.returnOfflineStreams,
        }
        if (filter.countries && filter.countries.length > 0) {
            localFilter.countries = filter.countries.toString()
        }
        if (filter.categories && filter.categories.length > 0) {
            localFilter.categories = filter.categories.toString()
        }
        if (filter.types && filter.types.length > 0) {
            localFilter.types = filter.types.toString()
        }
        if (filter.networks && filter.networks.length > 0) {
            localFilter.networks = filter.networks.toString()
        }
        return localFilter
    }

    return (
        <Modal
            open={props.isVisible}
            onClose={() => props.onClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className="modalBox actionBox">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 15 }}>
                    <h2 className="modalBox-title" style={{ flex: 2 }}>
                        {t('dabs.add_radio_mux')}
                    </h2>
                    <div style={{ flex: 1 }}>
                        <Button
                            label={t('radios.filter_radio')}
                            onPress={(event) => {
                                handleFilter(event)
                            }}
                            type="submit"
                            background="#fff"
                            border={`2px solid ${
                                JSON.stringify(filter) !== JSON.stringify(initialFilter) ? COLORS.green : COLORS.purple
                            }`}
                            color={
                                JSON.stringify(filter) !== JSON.stringify(initialFilter) ? COLORS.green : COLORS.purple
                            }
                        />
                        <RadioFilter
                            filter={filter}
                            anchorEl={anchorEl}
                            onClose={() => setAnchorEl(null)}
                            onReset={() => {
                                setFilter(initialFilter)
                            }}
                            onUpdate={(f) => setFilter(f)}
                            position={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                        />
                    </div>
                </div>
                <div>
                    <AsyncSelectInput
                        label={t('radios.radio')}
                        value={selectedRadio}
                        placeholder={t('placeholders.write_to_select_radio')}
                        fetchOptions={async (input: string) => {
                            if (input.length > 2) {
                                const localFilter = setFilterParams()
                                const radios = await FmWorldAxios.get(getRadios(), {
                                    params: { ...localFilter, query: input },
                                }).then((res) => {
                                    return res.data.items
                                })
                                return radios.map((c: Radio) => {
                                    return {
                                        id: c.id,
                                        value: c.id,
                                        label: c.name,
                                    }
                                })
                            }
                            return []
                        }}
                        onChange={(value) => {
                            const v = value as Option
                            setSelectedRadio(v)
                        }}
                    />
                    <div className="modalBox-buttonsRow">
                        <div style={{ flex: '1 1 100%' }} />
                        <Button
                            label={t('general.cancel')}
                            border={'1px solid rgba(0,0,0,0.07)'}
                            onPress={() => props.onClose()}
                            type="secondary"
                            color={COLORS.darkGrey}
                            background="#fff"
                        />
                        <Button
                            disabled={!selectedRadio}
                            label={t('general.add')}
                            onPress={() => props.onSave(selectedRadio as Option)}
                            type="submit"
                            color="#fff"
                            background={COLORS.purple}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default DabRadioModal
