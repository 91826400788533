import moment from 'moment'
import momentTZ from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import Button from '../../../components/Button/Button'
import Loader from '../../../components/Loader/Loader'
import DeleteModal from '../../../components/Modals/DeleteModal'
import LoadingModal from '../../../components/Modals/LoadingModal'
import ShowScheduleModal from '../../../components/Modals/ShowScheduleModal'
import ShowScheduleTable from '../../../components/Tables/ShowScheduleTable'
import { createSchedule, getSchedule, getScheduleDetail } from '../../../resources/api-constants'
import { COLORS } from '../../../resources/colors'
import { ShowSchedule } from '../../../types/data'
import FmWorldAxios from '../../../utility/FmWorldAxios'
import { Grants } from '../../../utility/Grants'
import { voidShowSchedule, VoidShowSchedule } from '../../../utility/voidConstants'
import QueryString from 'qs'
import { useTranslation } from 'react-i18next'
import SelectInput, { Option } from '../../../components/SelectInput/SelectInput'
import useEnhancedEffect from '@mui/material/utils/useEnhancedEffect'

export interface EventSchedule {
    title: string
    startTime: string
    endTime: string
    daysOfWeek: number[]
    event: ShowSchedule
}

interface Props {
    idRadio: number
    permissions: Grants[]
}

const ShowsScheduleTab: React.FC<Props> = (props) => {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')
    const [events, setEvents] = useState<EventSchedule[]>([])
    const [selectedShowSchedule, setSelectedShowSchedule] = useState<ShowSchedule | VoidShowSchedule | null>(null)
    const [showModalDelete, setShowModalDelete] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)
    const [isCreating, setIsCreating] = useState(false)
    const [timezone, setTimezone] = useState<Option>({
        id: Intl.DateTimeFormat().resolvedOptions().timeZone,
        value: Intl.DateTimeFormat().resolvedOptions().timeZone,
        label: Intl.DateTimeFormat().resolvedOptions().timeZone,
    })
    const { t } = useTranslation()

    const fetchShows = async () => {
        setError('')
        setIsLoading(true)
        try {
            const shows: ShowSchedule[][] = await FmWorldAxios.get(getSchedule(props.idRadio), {
                params: {
                    timezone: timezone.id ? timezone.id : 'Europe/Rome',
                },
                paramsSerializer: (params) => QueryString.stringify(params, { encode: false }),
            }).then((res) => {
                return res.data
            })
            const eventsTemp: EventSchedule[] = []
            shows.map((day: any, index: any) => {
                return day.map((show: ShowSchedule) => {
                    const startTime = moment({
                        hours: show.scheduleInfo.start.hour,
                        minutes: show.scheduleInfo.start.minute,
                    })
                    let endTime = moment({ hours: show.scheduleInfo.end.hour, minutes: show.scheduleInfo.end.minute })
                    if (startTime > endTime) {
                        const start = startTime.clone()
                        const end = endTime.clone()

                        const i = index === 6 ? 0 : index + 1

                        eventsTemp.push({
                            title: show.title,
                            startTime: start.startOf('day').format('HH:mm'),
                            endTime: end.format('HH:mm'),
                            daysOfWeek: [i],
                            event: show,
                        })
                        endTime = endTime.endOf('day')
                    }
                    eventsTemp.push({
                        title: show.title,
                        startTime: startTime.format('HH:mm'),
                        endTime: endTime.format('HH:mm'),
                        daysOfWeek: [index],
                        event: show,
                    })
                    return
                })
            })
            setEvents(eventsTemp)
        } catch (error: any) {
            console.log('ERROR', error)
            setError(error.toString())
        }
        setIsLoading(false)
    }

    useEffect(() => {
        setEvents([])
        void fetchShows()
    }, [props.idRadio])

    useEnhancedEffect(() => {
        void fetchShows()
    }, [timezone])

    const handleUpdatedSchedule = async (updatedSchedule: ShowSchedule) => {
        setError('')
        setIsUpdating(true)
        try {
            const schedule = {
                idShow: updatedSchedule.idShow,
                schedule: updatedSchedule.schedule,
                duration: updatedSchedule.duration,
            }
            await FmWorldAxios.put(getScheduleDetail(updatedSchedule.idSchedule), schedule)
            await fetchShows()
            setSelectedShowSchedule(null)
        } catch (error: any) {
            setError(error.toString())
        }
        setIsUpdating(false)
    }

    const handleCreateSchedule = async (updatedSchedule: VoidShowSchedule) => {
        setError('')
        setIsCreating(true)
        try {
            const schedule = {
                idShow: updatedSchedule.idShow,
                schedule: updatedSchedule.schedule,
                duration: updatedSchedule.duration,
            }
            await FmWorldAxios.post(createSchedule(), schedule)
            await fetchShows()
            setSelectedShowSchedule(null)
        } catch (error: any) {
            setError(error.toString())
        }
        setIsCreating(false)
    }

    const handleDeleteSchedule = async () => {
        if (selectedShowSchedule) {
            setError('')
            setIsLoading(true)
            try {
                await FmWorldAxios.delete(getScheduleDetail((selectedShowSchedule as ShowSchedule).idSchedule))
                setShowModalDelete(false)
                setSelectedShowSchedule(null)
                await fetchShows()
            } catch (error: any) {
                setError(error.toString())
            }
            setIsLoading(false)
        }
    }

    const zonesOption = () => {
        const zones = momentTZ.tz.names()
        return zones.map((z) => {
            // const value = momentTZ.tz(z).utcOffset() / 60
            return {
                id: z,
                value: z,
                label: z,
            }
        })
    }

    if (isLoading) {
        return (
            <div className="loadingGrid">
                <Loader />
            </div>
        )
    }

    return (
        <div>
            <div className="titleRow">
                <div style={{ flex: 1 }}>
                    <h2 className="title">{t('radios.schedule')}</h2>
                    <h3 className="subtitle" style={{ fontWeight: 400 }}>
                        {t('radios.manage_schedule')}
                    </h3>
                    {error && <p className="u-normal-text u-bold-text u-error-text">{error}</p>}
                </div>
                <div style={{ display: 'flex', flex: 2, gap: 10, alignItems: 'center', justifyContent: 'flex-end' }}>
                    <div style={{ flex: 2 }}>
                        <SelectInput
                            placeholder={t('placeholders.select_timezone')}
                            options={zonesOption()}
                            value={timezone}
                            onChange={(e) => {
                                const zone = e as Option
                                setTimezone(zone)
                            }}
                        />
                    </div>
                    <div style={{ flex: 1 }}>
                        <Button
                            label={t('general.refresh')}
                            onPress={() => {
                                void fetchShows()
                            }}
                            type="submit"
                            background="rgba(100,38,119,0.14)"
                            border={`2px solid ${COLORS.purple}`}
                            color={COLORS.purple}
                        />
                    </div>
                    {props.permissions.includes(Grants.EDIT_RADIO) ? (
                        <div style={{ flex: 1 }}>
                            <Button
                                label={t('general.add')}
                                onPress={() => {
                                    setSelectedShowSchedule(voidShowSchedule)
                                }}
                                type="submit"
                            />
                        </div>
                    ) : null}
                </div>
            </div>
            <ShowScheduleTable
                events={events}
                timezone={timezone.id as string}
                onPress={(e) => {
                    if (props.permissions.includes(Grants.EDIT_RADIO)) {
                        setSelectedShowSchedule(e)
                    }
                }}
            />
            <ShowScheduleModal
                showSchedule={selectedShowSchedule}
                isVisible={selectedShowSchedule !== null}
                onClose={() => setSelectedShowSchedule(null)}
                onSave={(s) => {
                    if ((s as ShowSchedule).idSchedule) {
                        void handleUpdatedSchedule(s as ShowSchedule)
                    } else {
                        void handleCreateSchedule(s as VoidShowSchedule)
                    }
                    return
                }}
                onDelete={() => {
                    setShowModalDelete(true)
                }}
                idRadio={props.idRadio}
            />
            <LoadingModal
                isVisible={isCreating || isUpdating}
                text={isUpdating ? t('loadings.updating_schedule') : t('loadings.creating_schedule')}
            />
            <DeleteModal
                onDelete={() => {
                    void handleDeleteSchedule()
                }}
                title={`${t('radios.delete_schedule')} ${selectedShowSchedule?.title}`}
                text={`${t('radios.want_delete_schedule')} ${selectedShowSchedule?.title}?`}
                onClose={() => {
                    setShowModalDelete(false)
                }}
                isVisible={showModalDelete}
            />
        </div>
    )
}

export default ShowsScheduleTab
