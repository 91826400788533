import React, { useEffect, useState } from 'react'
import './Modals.sass'
import Modal from '@mui/material/Modal'
import { City, DabFrequency } from '../../types/data'
import TextInput from '../TextInput/TextInput'
import Button from '../Button/Button'
import { COLORS } from '../../resources/colors'
import { VoidDabFrequency } from '../../utility/voidConstants'
import { getCities } from '../../resources/api-constants'
import FmWorldAxios from '../../utility/FmWorldAxios'
import AsyncSelectInput from '../SelectInput/AsyncSelectInput'
import { Option } from '../SelectInput/SelectInput'

interface Props {
    isVisible: boolean
    onClose: () => void
    onSave: (newElement: DabFrequency | VoidDabFrequency) => void
    frequency: DabFrequency | VoidDabFrequency | null
    lockedRadio?: boolean
}

const DabFrequencyModal: React.FC<Props> = (props) => {
    const [frequencyToEdit, setFrequencyToEdit] = useState<DabFrequency | VoidDabFrequency | null>(props.frequency)

    const cittaValue = () => {
        if ((frequencyToEdit as DabFrequency).id) {
            const f = frequencyToEdit as DabFrequency
            return {
                id: f.id,
                value: f.id,
                label: f.name,
            }
        }
        if ((frequencyToEdit as VoidDabFrequency).idCity && (frequencyToEdit as VoidDabFrequency).idCity !== -1) {
            const f = frequencyToEdit as VoidDabFrequency
            return {
                id: f.idCity,
                value: f.idCity,
                label: f.name ? f.name : '',
            }
        }
        return null
    }

    useEffect(() => {
        if (props.isVisible) {
            setFrequencyToEdit(props.frequency)
        }
    }, [props.isVisible])

    return (
        <Modal
            open={props.isVisible}
            onClose={() => props.onClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className="modalBox actionBox">
                <h2 className="modalBox-title">
                    {frequencyToEdit && (frequencyToEdit as DabFrequency).name
                        ? 'Modifica frequenza'
                        : 'Crea frequenza'}
                </h2>
                {frequencyToEdit ? (
                    <div>
                        <div style={{ margin: '20px 0' }}>
                            <TextInput
                                value={frequencyToEdit.frequency}
                                label="Frequenza / Canale di trasmissione"
                                onTextChange={(e) =>
                                    setFrequencyToEdit({
                                        ...frequencyToEdit,
                                        frequency: e,
                                    })
                                }
                            />
                        </div>
                        <AsyncSelectInput
                            disabled={props.frequency !== null && (props.frequency as DabFrequency).id !== undefined}
                            label="Città"
                            value={cittaValue()}
                            placeholder="Inizia a scrivere per inserire la città"
                            fetchOptions={async (input: string) => {
                                if (input.length > 2) {
                                    const cities = await FmWorldAxios.get(getCities(), {
                                        params: { query: input },
                                    }).then((res) => {
                                        return res.data.items
                                    })
                                    return cities.map((c: City) => {
                                        return {
                                            id: c.id,
                                            value: c.id,
                                            label: c.name,
                                        }
                                    })
                                }
                                return []
                            }}
                            onChange={(value) => {
                                const v = value as Option
                                if ((props.frequency as VoidDabFrequency).idCity !== undefined) {
                                    setFrequencyToEdit({
                                        ...frequencyToEdit,
                                        idCity: v.id as number,
                                        name: v.label as string,
                                    })
                                } else {
                                    setFrequencyToEdit({
                                        ...frequencyToEdit,
                                        id: v.id as number,
                                        idCity: v.id as number,
                                        name: v.label as string,
                                    })
                                }
                            }}
                        />
                    </div>
                ) : null}
                <div className="modalBox-buttonsRow">
                    <div style={{ flex: '1 1 100%' }} />
                    <Button
                        label="Annulla"
                        border={'1px solid rgba(0,0,0,0.07)'}
                        onPress={() => props.onClose()}
                        type="secondary"
                        color={COLORS.darkGrey}
                        background="#fff"
                    />
                    <Button
                        disabled={!frequencyToEdit || !frequencyToEdit?.frequency}
                        label={frequencyToEdit && (frequencyToEdit as DabFrequency).id ? 'Aggiorna' : 'Aggiungi'}
                        onPress={() => props.onSave(frequencyToEdit as DabFrequency)}
                        type="submit"
                        color="#fff"
                        background={COLORS.purple}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default DabFrequencyModal
