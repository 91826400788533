import React, { useState } from 'react'
import Modal from '@mui/material/Modal'
import { useTranslation } from 'react-i18next'
import TextInput from '../TextInput/TextInput'
import Button from '../Button/Button'
import { COLORS } from '../../resources/colors'
import LoadingModal from './LoadingModal'
import { getApiKeysNetwork } from '../../resources/api-constants'
import FmWorldAxios from '../../utility/FmWorldAxios'

interface Props {
    isVisible: boolean
    idNetwork: number
    onUpdate: () => void
    onClose: () => void
}

const AddApiKeyModal: React.FC<Props> = (props) => {
    const [error, setError] = useState('')
    const [value, setValue] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const { t } = useTranslation()

    const addKey = async () => {
        if (value) {
            setIsLoading(true)
            try {
                await FmWorldAxios.post(getApiKeysNetwork(props.idNetwork), {
                    name: value,
                })
            } catch (error: any) {
                setError(error)
            }
            setIsLoading(false)
        }
    }

    return (
        <Modal
            open={props.isVisible}
            onClose={() => props.onClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className="modalBox actionBox">
                <h2 className="modalBox-title" style={{ flex: 2 }}>
                    {t('networks.add_api_key')}
                </h2>
                {error && <p className="u-normal-text u-bold-text u-error-text">{error}</p>}
                <TextInput
                    value={value}
                    onTextChange={(e) => {
                        setValue(e)
                    }}
                    label={t('general.name')}
                />
                <div className="modalBox-buttonsRow">
                    <div style={{ flex: '1 1 100%' }} />
                    <Button
                        label={t('general.cancel')}
                        border={'1px solid rgba(0,0,0,0.07)'}
                        onPress={() => props.onClose()}
                        type="secondary"
                        color={COLORS.darkGrey}
                        background="#fff"
                    />
                    <Button
                        disabled={!value}
                        label={t('general.add')}
                        onPress={async () => {
                            if (value) {
                                await addKey()
                                props.onUpdate()
                            }
                        }}
                        type="submit"
                        color="#fff"
                        background={COLORS.purple}
                    />
                </div>
                <LoadingModal isVisible={isLoading} text={t('loadings.updating')} />
            </div>
        </Modal>
    )
}

export default AddApiKeyModal
