import React, { useEffect, useState } from 'react'
import CardContainer from '../../components/CardContainer/CardContainer'
import PageSidebarContainer from '../../components/PageSidebarContainer'
import '../../styles/pages/RadiosPage.sass'
import { useLocation, useNavigate } from 'react-router-dom'
import ShowDetailTab from './tabs/ShowDetailTab'
import { PageName } from '../../components/Header/Header'
import { Grants } from '../../utility/Grants'
import Loader from '../../components/Loader/Loader'
import { getRadioDetail, getShowDetail, getNetworkUserPermissions } from '../../resources/api-constants'
import FmWorldAxios from '../../utility/FmWorldAxios'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { setPageHeader } from '../../store/actions/graphic'
import { Radio, Show } from '../../types/data'
import { ROUTES } from '../../resources/routes-constants'
import LoadingModal from '../../components/Modals/LoadingModal'

enum Tab {
    ShowDetail = 'show_details',
    Podcasts = 'Podcast',
}

const arrayTab = [Tab.ShowDetail]

const ShowDetailPage: React.FC = () => {
    const [selectedTab, setSelectedTab] = useState(Tab.ShowDetail)
    const [showId, setShowId] = useState('')
    const [radioId, setRadioId] = useState(-1)
    const [idNetwork, setIdNetwork] = useState(-1)
    const [radioName, setRadioName] = useState('')
    const [permissions, setPermissions] = useState<Grants[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const renderTab = () => {
        switch (selectedTab) {
            case Tab.ShowDetail:
                return (
                    <ShowDetailTab
                        idShow={showId}
                        permissions={permissions}
                        idNetwork={idNetwork}
                        idRadio={radioId}
                        nameRadio={radioName}
                    />
                )
            default:
                return (
                    <ShowDetailTab
                        idShow={showId}
                        permissions={permissions}
                        idNetwork={idNetwork}
                        idRadio={radioId}
                        nameRadio={radioName}
                    />
                )
        }
    }

    const fetchRadio = async (idShow: string) => {
        setIsLoading(true)
        try {
            const show: Show = await FmWorldAxios.get(getShowDetail(parseInt(idShow, 10))).then((res) => {
                return res.data
            })
            if (show) {
                const radio: Radio = await FmWorldAxios.get(getRadioDetail(show.idRadio)).then((res) => {
                    return res.data
                })
                dispatch(
                    setPageHeader({
                        parent: {
                            name: show.radioName,
                            route: `${ROUTES.RADIO_DETAIL_ROUTE}${show.idRadio}`,
                        },
                        name: show.title,
                    })
                )
                if (radio) {
                    setRadioId(radio.id)
                    setRadioName(radio.name)
                    setIdNetwork(radio.idNetwork)
                    void fetchPermissions(radio.idNetwork)
                }
            }
        } catch (error: any) {
            setError(error.toString())
        }
        setIsLoading(false)
    }

    useEffect(() => {
        const idShow = location.pathname.replace('/show/', '')
        setShowId(idShow)
        if (idShow !== 'new') {
            void fetchRadio(idShow)
        } else {
            if (location && location.state) {
                const state = location.state as PageName
                if (state.parent) {
                    setRadioId(state.parent.id)
                    setRadioName(state.parent.name)
                    dispatch(
                        setPageHeader({
                            parent: {
                                name: state.parent.name,
                                route: `${ROUTES.RADIO_DETAIL_ROUTE}${state.parent.id}`,
                            },
                            name: state.name,
                        })
                    )
                }
                if (state.idNetwork !== undefined) {
                    setIdNetwork(state.idNetwork)
                    void fetchPermissions(state.idNetwork)
                }
                return
            }
            navigate(ROUTES.RADIO_LIST_ROUTE)
        }
    }, [location.pathname])

    const fetchPermissions = async (networkId: number) => {
        setError('')
        setIsLoading(true)
        try {
            const permissionsFetch = await FmWorldAxios.get(getNetworkUserPermissions(networkId)).then((res) => {
                return res.data
            })
            setPermissions(permissionsFetch)
        } catch (error: any) {
            setError(error.toString())
        }
        setIsLoading(false)
    }

    if (isLoading) {
        return <Loader />
    }

    return (
        <PageSidebarContainer>
            {showId && showId !== 'new' ? (
                <div className="tabMenu">
                    {arrayTab.map((tab) => {
                        return (
                            <p
                                onClick={() => setSelectedTab(tab)}
                                className={selectedTab === tab ? 'tabMenu-tab_active' : 'tabMenu-tab'}
                                key={tab}
                            >
                                {t(`shows.${tab}`)}
                            </p>
                        )
                    })}
                </div>
            ) : null}
            {error && <p className="u-normal-text u-bold-text u-error-text">{error}</p>}
            <CardContainer>{renderTab()}</CardContainer>
            <LoadingModal isVisible={isLoading} text={t('loadings.generic_loading')} />
        </PageSidebarContainer>
    )
}

export default ShowDetailPage
