import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import RadioTXControlMuxTable from '../../../components/Tables/RadioTXControlMuxTable'
import TxControlDashboardModal from '../../../components/Modals/TxControlDashboardModal'

interface Props {
    configurations: TXControlMuxConfiguration[]
}

export interface TXControlMuxConfiguration {
    id: number
    code: string
    name: string
    channel: number
    deviceId: number
    dashboardUrl: string
}

const TxControlRadioConfigurations: React.FC<Props> = (props) => {
    const [selectedMux, setSelectedMux] = useState<TXControlMuxConfiguration | null>(null)
    const { t } = useTranslation()

    return (
        <div>
            <div className="titleRow">
                <div style={{ flex: 1 }}>
                    <h2 className="title">{t('radios.mux_txcontrol_dashboards')}</h2>
                    <h3 className="subtitle" style={{ fontWeight: 400 }}>
                        {t('radios.manage_mux_txcontrol_dashboards')}
                    </h3>
                </div>
            </div>
            <RadioTXControlMuxTable data={props.configurations} onSelecteMux={(mux) => setSelectedMux(mux)} />
            <TxControlDashboardModal mux={selectedMux} onClose={() => setSelectedMux(null)} />
        </div>
    )
}

export default TxControlRadioConfigurations
