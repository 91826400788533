import React, { useEffect, useState } from 'react'
import './Modals.sass'
import Modal from '@mui/material/Modal'
import { UserMux } from '../../types/data'
import Button from '../Button/Button'
import { COLORS } from '../../resources/colors'
import TextInput from '../TextInput/TextInput'
import SelectInput, { Option } from '../SelectInput/SelectInput'
import { ReducerData, Reducers } from '../../types/reducers'
import { useSelector } from 'react-redux'
import { emailRegEx } from '../../utility/functions'

interface Props {
    isVisible: boolean
    onClose: () => void
    onSave: (role: number, email?: string) => void
    userData?: UserMux | null
    idMux: number
}

const UserMuxModal: React.FC<Props> = (props) => {
    const [role, setRole] = useState<number>(1)
    const [email, setEmail] = useState(props.userData ? props.userData.userInfo.email : '')

    const data = useSelector<Reducers, ReducerData>((state) => state.data)

    useEffect(() => {
        if (props.isVisible) {
            setRole(props.userData ? props.userData.idRole : 1)
            setEmail(props.userData ? props.userData.userInfo.email : '')
        }
    }, [props.isVisible])

    const roleValue = () => {
        if (role) {
            const roleFound = data.muxRoles.find((r) => r.id === role)
            if (roleFound) {
                return {
                    id: roleFound.id,
                    value: roleFound.id,
                    label: roleFound.name,
                }
            }
            return null
        }
        return null
    }

    return (
        <Modal
            open={props.isVisible}
            onClose={() => props.onClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className="modalBox actionBox">
                <h2 className="modalBox-title">
                    {props.userData
                        ? `Modifica utente ${props.userData.userInfo.firstName} ${props.userData.userInfo.lastName}`
                        : 'Aggiungi utente'}
                </h2>
                <div>
                    {!props.userData ? (
                        <TextInput value={email} onTextChange={(e) => setEmail(e)} label="Email" placeholder="Email" />
                    ) : null}
                    <SelectInput
                        placeholder="Seleziona ruolo"
                        label="Ruolo"
                        value={roleValue()}
                        onChange={(e) => {
                            const val = e as Option
                            const roleFound = data.muxRoles.find((r) => r.id === (val.id as number))
                            if (roleFound) {
                                setRole(roleFound.id)
                            }
                        }}
                        options={data.muxRoles.map((r) => {
                            return {
                                id: r.id,
                                value: r.id,
                                label: r.name,
                            }
                        })}
                    />
                </div>
                <div className="modalBox-buttonsRow">
                    <div style={{ flex: '1 1 100%' }} />
                    <Button
                        label="Annulla"
                        border={'1px solid rgba(0,0,0,0.07)'}
                        onPress={() => props.onClose()}
                        type="secondary"
                        color={COLORS.darkGrey}
                        background="#fff"
                    />
                    <Button
                        disabled={!role || !email || !emailRegEx.test(email)}
                        label={props.userData ? 'Aggiorna' : 'Aggiungi'}
                        onPress={() => {
                            if (props.userData) {
                                props.onSave(role)
                            } else {
                                props.onSave(role, email)
                            }
                        }}
                        type="submit"
                        color="#fff"
                        background={COLORS.purple}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default UserMuxModal
