import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Button from '../../../components/Button/Button'
import Loader from '../../../components/Loader/Loader'
import LoadingModal from '../../../components/Modals/LoadingModal'
import TextInput from '../../../components/TextInput/TextInput'
import { createPlayer, getNetworksPlayerDetail, getPlayerDetail } from '../../../resources/api-constants'
import { ROUTES } from '../../../resources/routes-constants'
import { PlayerNetwork } from '../../../types/data'
import FmWorldAxios from '../../../utility/FmWorldAxios'
import { Grants } from '../../../utility/Grants'
import { VoidPlayer } from '../../../utility/voidConstants'

interface Props {
    idPlayer: number | string
    idNetwork: number
    nameNetwork: string
    permissions: Grants[]
}

const PlayerDetailTab: React.FC<Props> = (props) => {
    const [error, setError] = useState('')
    const [playerDetail, setPlayerDetail] = useState<PlayerNetwork | VoidPlayer | null>(null)
    const [isCreating, setIsCreating] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()
    const { t } = useTranslation()

    const fetchPlayerDetail = async () => {
        setIsLoading(true)
        setError('')
        try {
            const playerFetch = await FmWorldAxios.get(getNetworksPlayerDetail(props.idPlayer as number)).then(
                (res) => {
                    return res.data
                }
            )
            setPlayerDetail(playerFetch)
        } catch (error: any) {
            setError(error.toString())
        }
        setIsLoading(false)
    }

    useEffect(() => {
        if (props.idPlayer && props.idPlayer !== 'new') {
            void fetchPlayerDetail()
        } else {
            setPlayerDetail({
                idNetwork: props.idNetwork,
                name: '',
            })
        }
    }, [props.idPlayer])

    const handleCreatePlayer = async () => {
        setIsCreating(true)
        setError('')
        try {
            const newId = await FmWorldAxios.post(createPlayer(), playerDetail).then((res) => {
                return res.data.id
            })
            navigate(`${ROUTES.PLAYER_DETAIL_ROUTE}${newId}`, {
                state: {
                    name: playerDetail?.name,
                    idNetwork: props.idNetwork,
                    parent: { name: props.nameNetwork, id: props.idNetwork },
                },
            })
        } catch (error: any) {
            setError(error.toString())
        }
        setIsCreating(false)
    }

    const handleUpdatePlayer = async () => {
        setIsUpdating(true)
        setError('')
        try {
            await FmWorldAxios.put(getPlayerDetail(props.idPlayer as number), playerDetail)
            navigate(`${ROUTES.PLAYER_DETAIL_ROUTE}${props.idPlayer}`, {
                state: {
                    name: playerDetail?.name,
                    idNetwork: props.idNetwork,
                    parent: { name: props.nameNetwork, id: props.idNetwork },
                },
            })
        } catch (error: any) {
            setError(error.toString())
        }
        setIsUpdating(false)
    }

    if (isLoading) {
        return <Loader />
    }

    return (
        <div>
            <div className="titleRow">
                <div style={{ flex: 1 }}>
                    <h2 className="title">{t('networks.player_details')}</h2>
                    <h3 className="subtitle" style={{ fontWeight: 400 }}>
                        {t('networks.manage_player_details')}
                    </h3>
                    {error && <p className="u-normal-text u-bold-text u-error-text">{error}</p>}
                </div>
                <div style={{ display: 'flex', flex: 2, gap: 10, alignItems: 'center', justifyContent: 'flex-end' }}>
                    <div style={{ flex: 4 }} />
                    {props.permissions.includes(Grants.EDIT_NETWORK) || props.idPlayer === 'new' ? (
                        <div style={{ flex: 1 }}>
                            {props.idPlayer && props.idPlayer !== 'new' ? (
                                <Button
                                    disabled={!playerDetail || !playerDetail.name}
                                    label={t('general.update')}
                                    onPress={() => {
                                        void handleUpdatePlayer()
                                    }}
                                    type="submit"
                                />
                            ) : (
                                <Button
                                    disabled={!playerDetail || !playerDetail.name}
                                    label={t('general.add')}
                                    onPress={() => {
                                        void handleCreatePlayer()
                                    }}
                                    type="submit"
                                />
                            )}
                        </div>
                    ) : null}
                </div>
            </div>
            {playerDetail ? (
                <div>
                    <div style={{ borderBottom: '2px solid rgba(0,0,0,0.07)', padding: '20px 0' }}>
                        <div className="u-elements-row">
                            <TextInput
                                label={t('general.name')}
                                value={playerDetail.name}
                                onTextChange={(text) => {
                                    setPlayerDetail({
                                        ...playerDetail,
                                        name: text,
                                    })
                                }}
                                placeholder={t('networks.write_player_name')}
                                bordered
                                disabled={!props.permissions.includes(Grants.EDIT_RADIO) && props.idPlayer !== 'new'}
                            />
                            <div style={{ flex: 1 }} />
                        </div>
                    </div>
                </div>
            ) : null}
            <LoadingModal
                isVisible={isCreating || isUpdating}
                text={isCreating ? t('loadings.creating_player') : t('loadings.updating')}
            />
        </div>
    )
}

export default PlayerDetailTab
