import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { ReducerGraphic, Reducers } from '../../types/reducers'
import LanguagePopover from './LanguagePopover/LanguagePopover'
import './LanguageSelector.sass'

const LanguageSelector: React.FC = () => {
    const graphic = useSelector<Reducers, ReducerGraphic>((state) => state.graphic)
    const [anchorEl, setAnchorEl] = useState<HTMLImageElement | null>(null)

    return (
        <div className="languageSelector">
            <img
                src={`https://22hbgtest.s3.eu-west-2.amazonaws.com/Peperoni/contents/general/languages/${graphic.currentLanguage}.svg`}
                onError={(e: any) => {
                    e.currentTarget.src =
                        'https://22hbgtest.s3.eu-west-2.amazonaws.com/Peperoni/contents/general/languages/en.svg'
                }}
                alt="language"
                className="languageSelector-flag"
                onClick={(event: React.MouseEvent<HTMLImageElement>) => {
                    setAnchorEl(event.currentTarget)
                }}
            />
            <LanguagePopover
                anchorElem={anchorEl}
                onClose={() => {
                    setAnchorEl(null)
                }}
            />
        </div>
    )
}

export default LanguageSelector
