import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import Button from '../../components/Button/Button'
import Link from '../../components/Link/Link'
import LoadingModal from '../../components/Modals/LoadingModal'
import StaticContentRegisterPage from '../../components/StaticContentRegisterPage'
import TextInput from '../../components/TextInput/TextInput'
import { COLORS } from '../../resources/colors'
import { ROUTES } from '../../resources/routes-constants'
import { emailRegEx } from '../../utility/functions'
import './../../styles/pages/LoginPage.sass'
import { sendPasswordResetEmail } from 'firebase/auth'
import { auth } from '../../utility/configFirebase'

export interface ResetPasswordPageParams {
    userEmail?: string
}

const RequestResetPasswordPage: React.FC = () => {
    const [email, setEmail] = useState('')
    const [error, setError] = useState('')
    const [success, setSuccess] = useState(false)
    const location = useLocation()
    const [isLoading, setIsLoading] = useState(false)
    const { t } = useTranslation()

    useEffect(() => {
        const state = location.state !== null ? (location.state as ResetPasswordPageParams) : undefined
        const userEmailFromLocation: string = state && state.userEmail ? state.userEmail : ''
        setEmail(userEmailFromLocation)
    }, [location])

    const requestResetPassword = async () => {
        setIsLoading(true)
        try {
            await sendPasswordResetEmail(auth, email)
            setSuccess(true)
        } catch (error: any) {
            console.error(error)
            setError(error.toString())
            setSuccess(false)
        }
        setIsLoading(false)
    }

    return (
        <div className="u-full-page-container u-row-page" style={{ gap: 0 }}>
            <div className="c-login-interactive-panel">
                <div className="c-login-interactive-panel__main-panel">
                    <div className="o-login-inputs-container">
                        <p className="u-medium-text u-bold-text">{t('auth.reset_password')}</p>
                        <p className="u-normal-text">{t('auth.forgot_password_message')}</p>
                        <TextInput
                            value={email}
                            placeholder={t('auth.email')}
                            borderColor={email && !emailRegEx.test(email) ? COLORS.redDelete : ''}
                            onTextChange={(newValue) => setEmail(newValue)}
                        />
                        <p className="u-normal-text">{t('auth.forgot_password_actions')}</p>
                        {error && (
                            <p className="u-normal-text u-bold-text u-error-text">
                                {t('genral.error')}: {error}
                            </p>
                        )}
                        {success && (
                            <p className="u-normal-text u-bold-text u-success-text">
                                {t('auth.reset_password_confirm')}
                            </p>
                        )}
                        <div className="u-elements-row u-small-gap">
                            <Link
                                label={t('general.back')}
                                type="button"
                                target="internal"
                                targetUrl={{ url: ROUTES.LOGIN_ROUTE }}
                            />
                            <Button
                                label={t('general.send')}
                                type="submit"
                                onPress={async () => {
                                    if (!isLoading && email && emailRegEx.test(email)) {
                                        await requestResetPassword()
                                    }
                                }}
                                disabled={!email || !emailRegEx.test(email)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <LoadingModal text={t('loadings.sending_reset_email')} isVisible={isLoading} />
            <StaticContentRegisterPage />
        </div>
    )
}

export default RequestResetPasswordPage
