import FMWLogo from './resources/images/fmworld-logowhite.svg'
import RMLogo from './resources/images/radio-maria.webp'

interface ColorPalette {
    accentColor: string
}

interface AppConfiguration {
    routerBaseName: string
    fmwBaseUrl: string
    firebaseConfiguration: {
        apiKey: string
        authDomain: string
        databaseURL?: string
        projectId: string
        storageBucket: string
        messagingSenderId: string
        appId: string
        measurementId?: string
    }
    authorization: {
        sociaLoginEnabled: boolean
        registrationEnabled: boolean
    }
    colorPalette: ColorPalette
    appTitle: string
    iconUrl?: string
    logo: string
    redisEnabled: boolean
    features: {
        grafanaAnalytics: boolean
        dabControl: boolean
    }
    isFmWorld: boolean
    slogan?: string
    privacyPolicyUrl: string | undefined
}

enum ConfigurationNames {
    development = 'development',
    staging = 'staging',
    production = 'production',
    radioMariaAutomotive = 'radioMariaAutomotive',
}

const FMWColorPalette: ColorPalette = {
    accentColor: '#642677',
}

const appConfigurations: Record<ConfigurationNames, AppConfiguration> = {
    [ConfigurationNames.development]: {
        routerBaseName: process.env.PUBLIC_URL,
        fmwBaseUrl: 'https://connect.fm-world.com/core2-dev/',
        firebaseConfiguration: {
            apiKey: 'AIzaSyD4QQcUfc7NMR0gsyjwlqr7KFPW5VNiov8',
            authDomain: 'fm-world-5d77d.firebaseapp.com',
            databaseURL: 'https://fm-world-5d77d.firebaseio.com',
            projectId: 'fm-world-5d77d',
            storageBucket: 'fm-world-5d77d.appspot.com',
            messagingSenderId: '594205530532',
            appId: '1:594205530532:web:f51313b41f7e6ae09c4931',
            measurementId: 'G-QMKYZ81TPJ',
        },
        authorization: {
            sociaLoginEnabled: true,
            registrationEnabled: true,
        },
        colorPalette: FMWColorPalette,
        appTitle: 'FM-world Panel',
        logo: FMWLogo,
        redisEnabled: true,
        features: {
            grafanaAnalytics: true,
            dabControl: true,
        },
        isFmWorld: true,
        privacyPolicyUrl: 'https://www.fm-world.it/privacy/',
    },
    [ConfigurationNames.staging]: {
        routerBaseName: '/',
        fmwBaseUrl: 'https://connect.fm-world.com/core2-staging/',
        firebaseConfiguration: {
            apiKey: 'AIzaSyD4QQcUfc7NMR0gsyjwlqr7KFPW5VNiov8',
            authDomain: 'fm-world-5d77d.firebaseapp.com',
            databaseURL: 'https://fm-world-5d77d.firebaseio.com',
            projectId: 'fm-world-5d77d',
            storageBucket: 'fm-world-5d77d.appspot.com',
            messagingSenderId: '594205530532',
            appId: '1:594205530532:web:f51313b41f7e6ae09c4931',
            measurementId: 'G-QMKYZ81TPJ',
        },
        authorization: {
            sociaLoginEnabled: true,
            registrationEnabled: true,
        },
        colorPalette: FMWColorPalette,
        appTitle: 'FM-world Panel',
        logo: FMWLogo,
        redisEnabled: true,
        features: {
            grafanaAnalytics: true,
            dabControl: true,
        },
        isFmWorld: true,
        privacyPolicyUrl: 'https://www.fm-world.it/privacy/',
    },
    [ConfigurationNames.production]: {
        routerBaseName: '/',
        fmwBaseUrl: 'https://connect.fm-world.com/core2/',
        firebaseConfiguration: {
            apiKey: 'AIzaSyD4QQcUfc7NMR0gsyjwlqr7KFPW5VNiov8',
            authDomain: 'fm-world-5d77d.firebaseapp.com',
            databaseURL: 'https://fm-world-5d77d.firebaseio.com',
            projectId: 'fm-world-5d77d',
            storageBucket: 'fm-world-5d77d.appspot.com',
            messagingSenderId: '594205530532',
            appId: '1:594205530532:web:f51313b41f7e6ae09c4931',
            measurementId: 'G-QMKYZ81TPJ',
        },
        authorization: {
            sociaLoginEnabled: true,
            registrationEnabled: true,
        },
        colorPalette: FMWColorPalette,
        appTitle: 'FM-world Panel',
        logo: FMWLogo,
        redisEnabled: true,
        features: {
            grafanaAnalytics: true,
            dabControl: true,
        },
        isFmWorld: true,
        privacyPolicyUrl: 'https://www.fm-world.it/privacy/',
    },
    [ConfigurationNames.radioMariaAutomotive]: {
        routerBaseName: '/',
        fmwBaseUrl: 'https://d2xxkoozyebngv.cloudfront.net/',
        firebaseConfiguration: {
            apiKey: 'AIzaSyCCTygAyHnTsdM4drVnHt1FMDTL_uRZKkU',
            authDomain: 'radio-maria-automotive-cms.firebaseapp.com',
            projectId: 'radio-maria-automotive-cms',
            storageBucket: 'radio-maria-automotive-cms.firebasestorage.app',
            messagingSenderId: '1071753871320',
            appId: '1:1071753871320:web:f713f7154453edc525fcad',
        },
        authorization: {
            sociaLoginEnabled: false,
            registrationEnabled: false,
        },
        colorPalette: {
            ...FMWColorPalette,
            accentColor: '#0081c8',
        },
        appTitle: 'Radio Maria CMS',
        iconUrl: 'https://radiomaria.it/wp-content/uploads/2019/04/cropped-cropped-santaLina-32x32.png',
        logo: RMLogo,
        redisEnabled: false,
        features: {
            grafanaAnalytics: false,
            dabControl: false,
        },
        isFmWorld: false,
        slogan: 'Radio Maria CMS',
        privacyPolicyUrl: undefined,
    },
}

const appConfiguration =
    appConfigurations[process.env.REACT_APP_RELEASE_ENV as ConfigurationNames] ||
    appConfigurations[ConfigurationNames.development]

export default appConfiguration
