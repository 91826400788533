import React, { useEffect, useState } from 'react'
import './Modals.sass'
import Modal from '@mui/material/Modal'
import { Frequency, City, Radio } from '../../types/data'
import TextInput from '../TextInput/TextInput'
import Button from '../Button/Button'
import { COLORS } from '../../resources/colors'
import { Option } from '../SelectInput/SelectInput'
import AsyncSelectInput from '../SelectInput/AsyncSelectInput'
import { VoidFrequency } from '../../utility/voidConstants'
import FmWorldAxios from '../../utility/FmWorldAxios'
import { getCities, getRadios } from '../../resources/api-constants'
import { useTranslation } from 'react-i18next'
import RadioFilter, { RadioFilterObject } from '../Filters/RadioFilter'

const initialFilter = {
    countries: [],
    categories: [],
    types: [],
    videoOnly: false,
    returnEmptyStreams: true,
    returnOfflineStreams: true,
    networks: [],
    returnVerifiedStreams: false,
}

interface Props {
    isVisible: boolean
    onClose: () => void
    onSave: (newElement: Frequency | VoidFrequency) => void
    frequency: Frequency | VoidFrequency | null
    lockedRadio?: boolean
}

const FrequencyModal: React.FC<Props> = (props) => {
    const [frequencyToEdit, setFrequencyToEdit] = useState<Frequency | VoidFrequency | null>(props.frequency)
    const [filter, setFilter] = useState<RadioFilterObject>(initialFilter)
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const { t } = useTranslation()

    useEffect(() => {
        if (props.isVisible) {
            setFrequencyToEdit(props.frequency)
        }
    }, [props.isVisible])

    const setFilterParams = () => {
        const localFilter: any = {
            videoOnly: filter.videoOnly,
            returnEmptyStreams: filter.returnEmptyStreams,
            returnOfflineStreams: filter.returnOfflineStreams,
            returnVerifiedStreams: filter.returnVerifiedStreams,
        }
        if (filter.countries && filter.countries.length > 0) {
            localFilter.countries = filter.countries.toString()
        }
        if (filter.categories && filter.categories.length > 0) {
            localFilter.categories = filter.categories.toString()
        }
        if (filter.types && filter.types.length > 0) {
            localFilter.types = filter.types.toString()
        }
        if (filter.networks && filter.networks.length > 0) {
            localFilter.networks = filter.networks.toString()
        }
        return localFilter
    }

    return (
        <Modal
            open={props.isVisible}
            onClose={() => props.onClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className="modalBox actionBox">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 15 }}>
                    <h2 className="modalBox-title" style={{ flex: 2 }}>
                        {frequencyToEdit && (frequencyToEdit as Frequency).id
                            ? t('frequencies.edit_frequency')
                            : t('frequencies.create_frequency')}
                    </h2>
                    {!props.lockedRadio ? (
                        <div style={{ flex: 1 }}>
                            <Button
                                label="Filtra radio"
                                onPress={(event) => {
                                    setAnchorEl(event.currentTarget)
                                }}
                                type="submit"
                                background="#fff"
                                border={`2px solid ${
                                    JSON.stringify(filter) !== JSON.stringify(initialFilter)
                                        ? COLORS.green
                                        : COLORS.purple
                                }`}
                                color={
                                    JSON.stringify(filter) !== JSON.stringify(initialFilter)
                                        ? COLORS.green
                                        : COLORS.purple
                                }
                            />
                            <RadioFilter
                                filter={filter}
                                anchorEl={anchorEl}
                                onClose={() => setAnchorEl(null)}
                                onReset={() => {
                                    setFilter(initialFilter)
                                }}
                                onUpdate={(f) => setFilter(f)}
                                position={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                            />
                        </div>
                    ) : null}
                </div>
                {frequencyToEdit ? (
                    <div>
                        {!props.lockedRadio && props.frequency?.idRadio ? (
                            <AsyncSelectInput
                                label={t('frequencies.radio')}
                                disabled={props.lockedRadio ? props.lockedRadio : false}
                                value={
                                    (frequencyToEdit as Frequency) && (frequencyToEdit as Frequency).radio
                                        ? {
                                              id: (frequencyToEdit as Frequency).radio.id,
                                              value: (frequencyToEdit as Frequency).radio.id,
                                              label: (frequencyToEdit as Frequency).radio.name,
                                          }
                                        : null
                                }
                                placeholder={t('placeholders.write_to_select_radio')}
                                fetchOptions={async (input: string) => {
                                    if (input.length > 2) {
                                        const localFilter = setFilterParams()
                                        const radios = await FmWorldAxios.get(getRadios(), {
                                            params: { ...localFilter, query: input },
                                        }).then((res) => {
                                            return res.data.items
                                        })
                                        return radios.map((c: Radio) => {
                                            return {
                                                id: c.id,
                                                value: c.id,
                                                label: c.name,
                                            }
                                        })
                                    }
                                    return []
                                }}
                                onChange={(value) => {
                                    const v = value as Option
                                    if ((frequencyToEdit as Frequency) && (frequencyToEdit as Frequency).radio) {
                                        setFrequencyToEdit({
                                            ...frequencyToEdit,
                                            idRadio: v.id as number,
                                            radio: {
                                                ...(frequencyToEdit as Frequency).radio,
                                                id: v.id as number,
                                                name: v.label,
                                            },
                                        })
                                    } else {
                                        setFrequencyToEdit({
                                            ...frequencyToEdit,
                                            idRadio: v.id as number,
                                            radio: {
                                                id: v.id as number,
                                                name: v.label,
                                                createdOn: '',
                                                email: '',
                                                idMetadataParser: -1,
                                                idCountry: '',
                                                idRadioType: 0,
                                                imageH2: '',
                                                imageH3: '',
                                                imageH4: '',
                                                logo: '',
                                                metadataMonitor: false,
                                                metadataParser: null,
                                                metadataUrl: '',
                                                ownerAuthorization: '',
                                                phone: '',
                                                slogan: '',
                                                socialContacts: '',
                                                updatedOn: '',
                                                website: '',
                                                categories: [],
                                                tags: [],
                                                streams: [],
                                                idNetwork: 0,
                                                isPublic: false,
                                                latitude: null,
                                                longitude: null,
                                            },
                                        })
                                    }
                                }}
                            />
                        ) : null}
                        <div style={{ margin: '20px 0' }}>
                            <TextInput
                                value={frequencyToEdit.frequency.toString()}
                                label={`${t('frequencies.frequency')} (mHz)`}
                                onTextChange={(e) =>
                                    setFrequencyToEdit({
                                        ...frequencyToEdit,
                                        frequency: e.replace(',', '.'),
                                    })
                                }
                            />
                        </div>
                        <AsyncSelectInput
                            label={t('general.city')}
                            value={
                                (frequencyToEdit as Frequency) && (frequencyToEdit as Frequency).city
                                    ? {
                                          id: (frequencyToEdit as Frequency).city.id,
                                          value: (frequencyToEdit as Frequency).city.id,
                                          label: (frequencyToEdit as Frequency).city.name,
                                      }
                                    : null
                            }
                            placeholder={t('placeholders.write_to_select_city')}
                            fetchOptions={async (input: string) => {
                                if (input.length > 2) {
                                    const cities = await FmWorldAxios.get(getCities(), {
                                        params: { query: input },
                                    }).then((res) => {
                                        return res.data.items
                                    })
                                    return cities.map((c: City) => {
                                        return {
                                            id: c.id,
                                            value: c.id,
                                            label: c.name,
                                        }
                                    })
                                }
                                return []
                            }}
                            onChange={(value) => {
                                const v = value as Option
                                if ((frequencyToEdit as Frequency) && (frequencyToEdit as Frequency).city) {
                                    setFrequencyToEdit({
                                        ...frequencyToEdit,
                                        idCity: v.id as number,
                                        city: {
                                            ...(frequencyToEdit as Frequency).city,
                                            id: v.id as number,
                                            name: v.label,
                                        },
                                    })
                                } else {
                                    setFrequencyToEdit({
                                        ...frequencyToEdit,
                                        idCity: v.id as number,
                                        city: {
                                            id: v.id as number,
                                            name: v.label,
                                            idProvince: 0,
                                            capital: false,
                                        },
                                    })
                                }
                            }}
                        />
                    </div>
                ) : null}
                <div className="modalBox-buttonsRow">
                    <div style={{ flex: '1 1 100%' }} />
                    <Button
                        label={t('general.cancel')}
                        border={'1px solid rgba(0,0,0,0.07)'}
                        onPress={() => props.onClose()}
                        type="secondary"
                        color={COLORS.darkGrey}
                        background="#fff"
                    />
                    <Button
                        disabled={!frequencyToEdit || !frequencyToEdit?.frequency}
                        label={
                            frequencyToEdit && (frequencyToEdit as Frequency).id
                                ? t('general.update')
                                : t('general.add')
                        }
                        onPress={() => props.onSave(frequencyToEdit as Frequency)}
                        type="submit"
                        color="#fff"
                        background={COLORS.purple}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default FrequencyModal
