import React, { useEffect, useMemo, useState } from 'react'
import CardContainer from '../../components/CardContainer/CardContainer'
import PageSidebarContainer from '../../components/PageSidebarContainer'
import RadioDetailTab from './tabs/RadioDetailTab'
import '../../styles/pages/RadiosPage.sass'
import { useLocation } from 'react-router-dom'
import StreamsTab from './tabs/StreamsTab'
import FrequenciesTab from './tabs/FrequenciesTab'
import ShowsTab from './tabs/ShowsTab'
import ShowsScheduleTab from './tabs/ShowsScheduleTab'
import ServicesTab from './tabs/ServicesTab'
import FmWorldAxios from '../../utility/FmWorldAxios'
import {
    getRadioDetail,
    getRadioInteractiveMUXItem,
    getNetworkUserPermissions,
    getRadioTxControlMuxConfigurations,
} from '../../resources/api-constants'
import Loader from '../../components/Loader/Loader'
import { Grants } from '../../utility/Grants'
import { useTranslation } from 'react-i18next'
import DabTab from './tabs/DabTab'
import LoadingModal from '../../components/Modals/LoadingModal'
import { InteractiveMuxItem, Radio } from '../../types/data'
import { useDispatch } from 'react-redux'
import { setPageHeader } from '../../store/actions/graphic'
import { ROUTES } from '../../resources/routes-constants'
import InteractiveMUXTab from './tabs/InteractiveMUXTab'
import TxControlRadioConfigurations, { TXControlMuxConfiguration } from './tabs/TxControlRadioConfigurations'
import AnalyticsTab from './tabs/AnalyticsTab'
import appConfiguration from '../../appConfiguration'

enum Tab {
    RadioDetail = 'radio_details',
    Streams = 'streams',
    Frequencies = 'frequencies',
    Dabs = 'dabs',
    Shows = 'shows',
    Schedule = 'schedule',
    Services = 'services',
    InteractiveMUX = 'interactiveMUX',
    TxControlMuxs = 'mediaDab',
    Analytics = 'analytics',
}

const RadioDetailPage: React.FC = () => {
    const [selectedTab, setSelectedTab] = useState(Tab.RadioDetail)
    const [radioId, setRadioId] = useState('')
    const [radioName, setRadioName] = useState('')
    const [radioLogo, setRadioLogo] = useState('')
    const [radioCountry, setRadioCountry] = useState<string | null>(null)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')
    const [permissions, setPermissions] = useState<Grants[]>([])
    const [idNetwork, setIdNetwork] = useState<number | null>(-1)
    const [interactiveMuxService, setinteractiveMuxService] = useState<InteractiveMuxItem | null>(null)
    const [radioTxControlMuxConfigurations, setRadioTxControlMuxConfigurations] = useState<TXControlMuxConfiguration[]>(
        []
    )
    const location = useLocation()
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const tabs = useMemo(() => {
        const standardTabs = [Tab.RadioDetail, Tab.Frequencies, Tab.Dabs, Tab.Shows, Tab.Schedule]

        if (appConfiguration.features.grafanaAnalytics) {
            standardTabs.push(Tab.Analytics)
        }

        if (interactiveMuxService) {
            standardTabs.push(Tab.InteractiveMUX)
        }

        if (radioTxControlMuxConfigurations.length > 0) {
            standardTabs.push(Tab.TxControlMuxs)
        }

        if (permissions.includes(Grants.EDIT_RADIO)) {
            standardTabs.splice(1, 0, Tab.Streams)
            standardTabs.push(Tab.Services)
        }

        return standardTabs
    }, [permissions, interactiveMuxService, radioTxControlMuxConfigurations])

    const renderTab = () => {
        switch (selectedTab) {
            case Tab.RadioDetail:
                return <RadioDetailTab idNetwork={idNetwork} permissions={permissions} idRadio={radioId} />
            case Tab.Streams:
                return <StreamsTab radioName={radioName} permissions={permissions} idRadio={parseInt(radioId, 10)} />
            case Tab.Frequencies:
                if (radioCountry?.toUpperCase() === 'IT') {
                    return <FrequenciesTab permissions={permissions} idRadio={parseInt(radioId, 10)} />
                }
                return null
            case Tab.Dabs:
                if (radioCountry?.toUpperCase() === 'IT') {
                    return <DabTab permissions={permissions} idRadio={parseInt(radioId, 10)} />
                }
                return null
            case Tab.Shows:
                return <ShowsTab permissions={permissions} idNetwork={idNetwork} idRadio={parseInt(radioId, 10)} />
            case Tab.Schedule:
                return <ShowsScheduleTab permissions={permissions} idRadio={parseInt(radioId, 10)} />
            case Tab.Services:
                return <ServicesTab permissions={permissions} idRadio={parseInt(radioId, 10)} />
            case Tab.Analytics:
                return <AnalyticsTab idRadio={parseInt(radioId, 10)} />
            case Tab.InteractiveMUX:
                return (
                    <InteractiveMUXTab
                        permissions={permissions}
                        idRadio={parseInt(radioId, 10)}
                        radioLogo={radioLogo}
                        radioName={radioName}
                    />
                )
            case Tab.TxControlMuxs:
                if (radioTxControlMuxConfigurations.length === 0) {
                    return null
                }

                return <TxControlRadioConfigurations configurations={radioTxControlMuxConfigurations} />
            default:
                return <RadioDetailTab idNetwork={idNetwork} permissions={permissions} idRadio={radioId} />
        }
    }

    const fetchRadioData = async () => {
        if (radioId && radioId !== 'new') {
            setIsLoading(true)
            try {
                const radio: Radio = await FmWorldAxios.get(getRadioDetail(parseInt(radioId, 10))).then((res) => {
                    return res.data
                })

                if (radio) {
                    await fetchPermissions(radio.idNetwork)
                    await fetchServices(radio.id)
                    setIdNetwork(radio.idNetwork)
                    setRadioName(radio.name)
                    setRadioLogo(radio.logo as string)
                    setRadioCountry(radio.idCountry)
                    dispatch(
                        setPageHeader({
                            parent: {
                                name: t('headers.radios'),
                                route: ROUTES.RADIO_LIST_ROUTE,
                            },
                            name: radio.name,
                        })
                    )
                }
                console.log('Radio data: ', radio)
            } catch (error: any) {
                setError(error.toString())
            }
            setIsLoading(false)
        } else {
            setIsLoading(true)
            try {
                await fetchPermissions(null)
                dispatch(
                    setPageHeader({
                        parent: {
                            name: t('headers.radios'),
                            route: ROUTES.RADIO_LIST_ROUTE,
                        },
                        name: t('radios.new_radio'),
                    })
                )
            } catch (error: any) {
                setError(error.toString())
            }
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (radioId) {
            void fetchRadioData()
        }
    }, [radioId])

    useEffect(() => {
        const idRadio = location.pathname.replace('/radio/', '')
        setRadioId(idRadio)
    }, [location.pathname])

    const fetchPermissions = async (networkId: number | null) => {
        setError('')
        try {
            const permissionsFetch = await FmWorldAxios.get(getNetworkUserPermissions(networkId)).then((res) => {
                return res.data
            })
            setPermissions(permissionsFetch)
        } catch (error: any) {
            setError(error.toString())
        }
    }

    const fetchServices = async (radioId: number) => {
        try {
            const interactiveMuxItem: InteractiveMuxItem = await FmWorldAxios.get(
                getRadioInteractiveMUXItem(radioId)
            ).then((res) => {
                return res.data
            })

            setinteractiveMuxService(interactiveMuxItem)
        } catch (error: any) {
            console.log('Fetch interactive mux error: ', error)
        }

        try {
            const radioTxControlMuxConfigurations: TXControlMuxConfiguration[] = await FmWorldAxios.get(
                getRadioTxControlMuxConfigurations(radioId)
            ).then((res) => {
                return res.data
            })

            setRadioTxControlMuxConfigurations(radioTxControlMuxConfigurations)
        } catch (error: any) {
            console.log('Fetch radio txControl mux configurations: ', error)
        }
    }

    if (isLoading) {
        return <Loader />
    }

    return (
        <PageSidebarContainer>
            {radioId && radioId !== 'new' ? (
                <div className="tabMenu">
                    {tabs.map((tab) => {
                        if (radioCountry?.toUpperCase() !== 'IT' && (tab === Tab.Dabs || tab === Tab.Frequencies)) {
                            return null
                        }
                        if (!permissions.includes(Grants.MANAGE_SYSTEM) && tab === Tab.Services) {
                            return null
                        }
                        return (
                            <p
                                onClick={() => setSelectedTab(tab)}
                                className={selectedTab === tab ? 'tabMenu-tab_active' : 'tabMenu-tab'}
                                key={tab}
                            >
                                {t(`radios.${tab}`)}
                            </p>
                        )
                    })}
                </div>
            ) : null}
            {error && <p className="u-normal-text u-bold-text u-error-text">{error}</p>}
            <CardContainer>{renderTab()}</CardContainer>
            <LoadingModal isVisible={isLoading} text={t('loadings.generic_loading')} />
        </PageSidebarContainer>
    )
}

export default RadioDetailPage
