import React from 'react'
import { COLORS } from '../../resources/colors'
import Button from '../Button/Button'
import '../TextInput/TextInput.sass'
import './UploadInput.sass'
import { useTranslation } from 'react-i18next'

interface Props {
    label: string
    text: string
    link: string
    value: string | File | null
    onChange: (file: File) => void
    width?: number | string
    flex?: number
    disabled?: boolean
}

const UploadInput: React.FC<Props> = (props) => {
    const { t } = useTranslation()
    const handleChange = (e: any) => {
        if (e.target.files[0]) {
            props.onChange(e.target.files[0])
        }
    }

    return (
        <div
            className="c-text-input"
            style={{ width: props.width ? props.width : '', flex: props.flex ? props.flex : 1 }}
        >
            <span className="o-label">{props.label}</span>
            <div className="uploadField">
                <p
                    className="uploadField-text"
                    style={{
                        color: props.text ? COLORS.purple : 'inherit',
                        fontWeight: props.text ? '700' : '400',
                        cursor: props.link ? 'pointer' : 'default',
                    }}
                    onClick={() => {
                        if (props.text) {
                            window.open(props.link, '_blank')
                        }
                    }}
                >
                    {props.text ? props.text : t('general.no_authorization_doc')}
                </p>
                <div className="uploadField-button">
                    <Button type="submit" label="Carica" disabled={props.disabled} />
                    {!props.disabled ? (
                        <input
                            type="file"
                            accept="text/*, .jpg, .jpeg, .png, .pdf"
                            className="uploadField-button--upload"
                            onChange={(e) => handleChange(e)}
                        />
                    ) : null}
                </div>
            </div>
            {props.link && (
                <a
                    style={{
                        textDecoration: 'underline',
                        opacity: 0.7,
                        fontSize: '.8em',
                        paddingLeft: 8,
                        color: 'inherit',
                        fontWeight: 500,
                    }}
                    href={props.link}
                    download
                >
                    Scarica documento
                </a>
            )}
        </div>
    )
}

export default UploadInput
