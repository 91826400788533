import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Loader from '../../../components/Loader/Loader'

const AnalyticsTab: React.FC<{ idRadio: number }> = ({ idRadio }) => {
    const [loading, setLoading] = useState(false)
    const { t } = useTranslation()
    const grafanaRadioStats = `https://analytics.fm-world.it/d/KJM06zcSk/single-radio-dashboard?orgId=1&theme=light&var-radio=1&var-id_radio=${idRadio}`

    useEffect(() => {
        setTimeout(() => setLoading(false), 1500)
    }, [])

    return (
        <div>
            <div className="titleRow">
                <div style={{ flex: 1 }}>
                    <h2 className="title">{t('radios.analytics')}</h2>
                    <h3 className="subtitle" style={{ fontWeight: 400 }}>
                        {t('radios.manage_analytics')}
                    </h3>
                </div>
            </div>
            <div style={{ height: 'calc(100vh - 310px)' }}>
                {loading && <Loader />}
                <iframe
                    title="radioStats"
                    id="stats"
                    src={grafanaRadioStats}
                    width="100%"
                    style={{ minHeight: '100%', border: 0, borderRadius: 6, overflow: 'hidden' }}
                    frameBorder="0"
                    scrolling="no"
                />
            </div>
        </div>
    )
}

export default AnalyticsTab
