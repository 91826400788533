import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { getBundleRadios, getBundleRadiosDetail } from '../../resources/api-constants'
import { COLORS } from '../../resources/colors'
import { Bundle, Position } from '../../types/data'
import FmWorldAxios from '../../utility/FmWorldAxios'
import AppPreview from '../AppPreview/AppPreview'
import Button from '../Button/Button'
import Loader from '../Loader/Loader'
import AddRadioToBundleModal from '../Modals/AddRadioToBundleModal'
import DeleteModal from '../Modals/DeleteModal'
import LoadingModal from '../Modals/LoadingModal'
import BundleRadioTable from '../Tables/BundleRadioTable'
import TextInput from '../TextInput/TextInput'

interface Props {
    bundle: Bundle
    networkId: number
    networkName: string
}

const BundlePositionList: React.FC<Props> = (props) => {
    const [isLoading, setIsLoading] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)
    const [error, setError] = useState('')
    const [search, setSearch] = useState('')
    const [positions, setPositions] = useState<Position[]>([])
    const [editedRadios, setEditedRadios] = useState<Position[]>([])
    const [selectedPosition, setSelectedPosition] = useState<Position | null>(null)
    const [addRadioVisible, setAddRadioVisible] = useState(false)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [showMobilePreview, setShowMobilePreview] = useState(false)
    const { t } = useTranslation()

    const fetchPositions = async () => {
        setError('')
        setIsLoading(true)
        try {
            const positionsFetch = await FmWorldAxios.get(getBundleRadios(props.bundle.id)).then((res) => {
                return res.data.items
            })
            setPositions(positionsFetch)
            setEditedRadios([])
        } catch (error: any) {
            console.log('ERROR', error)
            setError(error.toString())
        }
        setIsLoading(false)
    }

    useEffect(() => {
        void fetchPositions()
    }, [props.bundle])

    const getWindowWidth = () => {
        setWindowWidth(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', getWindowWidth)
        return () => {
            window.removeEventListener('resize', getWindowWidth)
        }
    }, [])

    if (isLoading) {
        return (
            <div className="loadingGrid">
                <Loader />
            </div>
        )
    }

    const updatingRadios = async () => {
        setError('')
        setIsUpdating(true)
        try {
            for (let i = 0; i < editedRadios.length; i++) {
                const body = {
                    isSponsored: editedRadios[i].bundleInfo.sponsored,
                    position: editedRadios[i].bundleInfo.position,
                    weight: editedRadios[i].bundleInfo.weight,
                    liveShowed: editedRadios[i].bundleInfo.liveShowed,
                }
                await FmWorldAxios.put(getBundleRadiosDetail(props.bundle.id, editedRadios[i].id), body)
            }
            void fetchPositions()
        } catch (error: any) {
            setError(error.toString())
        }
        setIsUpdating(false)
    }

    const deletingPosition = async () => {
        setError('')
        if (selectedPosition) {
            setIsLoading(true)
            try {
                await FmWorldAxios.delete(getBundleRadiosDetail(props.bundle.id, selectedPosition.id))
                setSelectedPosition(null)
                void fetchPositions()
            } catch (error: any) {
                setError(error.toString())
            }
            setIsLoading(false)
        }
    }

    return (
        <div style={{ marginTop: 50, marginBottom: 20 }}>
            <div className="titleRow">
                <div style={{ flex: windowWidth > 1500 ? 1 : 0.6 }}>
                    <h2 className="title">
                        {props.bundle.name} - {t('networks.bundle_detail')}
                    </h2>
                    <h3 className="subtitle" style={{ fontWeight: 400 }}>
                        {t('networks.manage_position_bundle')}
                    </h3>
                    {error && <p className="u-normal-text u-bold-text u-error-text">{error}</p>}
                </div>
                <div style={{ display: 'flex', flex: 2, gap: 10, alignItems: 'center', justifyContent: 'flex-end' }}>
                    {windowWidth > 1500 ? <div style={{ flex: 1 }} /> : null}
                    <div style={{ flex: 2 }}>
                        <TextInput
                            value={search}
                            placeholder={t('general.search')}
                            icon="search"
                            iconPosition="right"
                            onTextChange={(v) => setSearch(v)}
                        />
                    </div>
                    <div style={{ flex: 1 }}>
                        <Button
                            label={t('general.restore')}
                            onPress={() => {
                                if (editedRadios.length > 0) {
                                    void fetchPositions()
                                }
                            }}
                            type="submit"
                            background="#fff"
                            border={`2px solid ${COLORS.redDelete}`}
                            color={COLORS.redDelete}
                            disabled={editedRadios.length === 0}
                        />
                    </div>
                    <div style={{ flex: 1 }}>
                        <Button
                            label={t('general.refresh')}
                            onPress={() => {
                                if (editedRadios.length > 0) {
                                    void updatingRadios()
                                }
                            }}
                            type="submit"
                            background={COLORS.green}
                            border={`2px solid ${COLORS.green}`}
                            color="#fff"
                            disabled={editedRadios.length === 0}
                        />
                    </div>
                    <div style={{ flex: 1.5 }}>
                        <Button
                            label={t('networks.add_radio')}
                            onPress={() => {
                                setAddRadioVisible(true)
                            }}
                            type="submit"
                        />
                    </div>
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    gap: 20,
                    alignItems: 'flex-start',
                }}
            >
                <div style={{ flex: 3 }}>
                    <BundleRadioTable
                        networkName={props.networkName}
                        networkId={props.networkId}
                        positions={positions}
                        search={search}
                        updatePosition={(newPositions, updated) => {
                            setPositions(newPositions)
                            const indexInEdited = editedRadios.findIndex((p) => p.id === updated.id)
                            if (indexInEdited !== -1) {
                                const newEdited = JSON.parse(JSON.stringify(editedRadios))
                                newEdited.splice(indexInEdited, 1, updated)
                                setEditedRadios(newEdited)
                            } else {
                                setEditedRadios([...editedRadios, updated])
                            }
                        }}
                        deletePosition={(pos) => {
                            setSelectedPosition(pos)
                        }}
                    />
                </div>
                {windowWidth >= 1630 ? (
                    <div
                        style={{
                            flex: 1.5,
                            backgroundColor: COLORS.lightPurpleBg,
                            marginTop: 30,
                            borderRadius: 20,
                            flexShrink: 0,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            paddingTop: 30,
                        }}
                    >
                        <AppPreview positions={positions} />
                    </div>
                ) : null}
                {windowWidth < 1630 ? (
                    <>
                        {showMobilePreview ? (
                            <div
                                style={{
                                    flex: 1.5,
                                    backgroundColor: COLORS.lightPurpleBg,
                                    position: 'fixed',
                                    right: 0,
                                    top: 0,
                                    borderRadius: 20,
                                    flexShrink: 0,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'flex-start',
                                    paddingTop: 20,
                                    paddingLeft: 10,
                                    bottom: 0,
                                    zIndex: 99999,
                                    transform: 'scale(0.9)',
                                }}
                            >
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: -10,
                                        right: 5,
                                    }}
                                >
                                    <Button
                                        icon="close"
                                        type="primary"
                                        label=""
                                        iconSize={20}
                                        onPress={() => {
                                            setShowMobilePreview(false)
                                        }}
                                    />
                                </div>
                                <AppPreview positions={positions} isFixed />
                            </div>
                        ) : (
                            <div style={{ position: 'fixed', right: 0, top: 200 }}>
                                <Button
                                    icon="smartphone"
                                    type="primary"
                                    label=""
                                    iconSize={35}
                                    onPress={() => {
                                        setShowMobilePreview(true)
                                    }}
                                />
                            </div>
                        )}
                    </>
                ) : null}
            </div>
            <LoadingModal isVisible={isUpdating} text="Sto aggiornando" />
            <DeleteModal
                onClose={() => setSelectedPosition(null)}
                onDelete={() => void deletingPosition()}
                isVisible={selectedPosition !== null}
                title={selectedPosition ? `${t('networks.delete_position')} ${selectedPosition.name}` : ''}
                text={selectedPosition ? `${t('networks.want_delete_position')} ${selectedPosition.name}?` : ''}
            />
            <AddRadioToBundleModal
                isVisible={addRadioVisible}
                onClose={() => setAddRadioVisible(false)}
                onSave={async () => {
                    setAddRadioVisible(false)
                    await fetchPositions()
                }}
                bundle={props.bundle}
                maxPos={positions.length + 1}
            />
        </div>
    )
}

export default BundlePositionList
