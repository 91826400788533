import { ReducerData, ReduxActionData } from '../../types/reducers'
import { DATA_ACTIONS } from '../actions/data'

const initialState: ReducerData = {
    selectedNetwork: null,
    totalRadios: 0,
    totalNetworks: 0,
    totalFrequencies: 0,
    totalDab: 0,
    totalInteractiveMUX: 0,
    radioTypes: [],
    countries: [],
    categories: [],
    radioTags: [],
    states: [],
    provinces: [],
    networkRoles: [],
    muxRoles: [],
    singleNetwork: null,
    notifications: [],
}

const dataState: (state: ReducerData, action: ReduxActionData<any, DATA_ACTIONS>) => ReducerData = (
    state = initialState,
    action: ReduxActionData<any, DATA_ACTIONS>
) => {
    switch (action.type) {
        case DATA_ACTIONS.SET_SELECTED_NETWORK:
            return {
                ...state,
                selectedNetwork: action.payload,
            }
        case DATA_ACTIONS.SET_TOTAL_RADIOS:
            return {
                ...state,
                totalRadios: action.payload,
            }
        case DATA_ACTIONS.SET_TOTAL_NETWORKS:
            return {
                ...state,
                totalNetworks: action.payload,
            }
        case DATA_ACTIONS.SET_TOTAL_FREQUENCIES:
            return {
                ...state,
                totalFrequencies: action.payload,
            }
        case DATA_ACTIONS.SET_TOTAL_DAB:
            return {
                ...state,
                totalDab: action.payload,
            }
        case DATA_ACTIONS.SET_TOTAL_INTERACTIVE_MUX:
            return {
                ...state,
                totalInteractiveMUX: action.payload,
            }
        case DATA_ACTIONS.SET_RADIO_TYPES:
            return {
                ...state,
                radioTypes: action.payload,
            }
        case DATA_ACTIONS.SET_COUNTRIES:
            return {
                ...state,
                countries: action.payload,
            }
        case DATA_ACTIONS.SET_STATES:
            return {
                ...state,
                states: action.payload,
            }
        case DATA_ACTIONS.SET_PROVINCES:
            return {
                ...state,
                provinces: action.payload,
            }
        case DATA_ACTIONS.SET_CATEGORIES:
            return {
                ...state,
                categories: action.payload,
            }
        case DATA_ACTIONS.SET_RADIO_TAGS:
            return {
                ...state,
                radioTags: action.payload,
            }
        case DATA_ACTIONS.SET_SINGLE_NETWORK:
            return {
                ...state,
                singleNetwork: action.payload,
            }
        case DATA_ACTIONS.SET_NETWORK_ROLES:
            return {
                ...state,
                networkRoles: action.payload,
            }
        case DATA_ACTIONS.SET_MUX_ROLES:
            return {
                ...state,
                muxRoles: action.payload,
            }
        case DATA_ACTIONS.SET_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.payload,
            }
        default:
            return state
    }
}

export default dataState
