import React from 'react'
import './Modals.sass'
import Modal from '@mui/material/Modal'
import { TXControlMuxConfiguration } from '../../pages/RadiosPage/tabs/TxControlRadioConfigurations'

interface Props {
    mux: TXControlMuxConfiguration | null
    onClose: () => void
}

const TxControlDashboardModal: React.FC<Props> = (props) => {
    console.log(props.mux)
    return (
        <Modal
            open={props.mux !== null}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            onClose={() => props.onClose()}
        >
            <div className="modalBox tx-control-dashboard">
                {props.mux !== null ? (
                    <iframe
                        frameBorder={0}
                        className="tx-control-dashboard__iframe"
                        title={`${props.mux.name}-dashboard`}
                        src={`${props.mux.dashboardUrl}&var-id_device=${props.mux.deviceId}&var-channel=${props.mux.channel}`}
                    />
                ) : null}
            </div>
        </Modal>
    )
}

export default TxControlDashboardModal
