import { FrequencyFilterObject } from '../../components/Filters/FrequencyFilter'
import { MuxFilterObject } from '../../components/Filters/MuxFilter'
import { FilterMuxFrequencies } from '../../components/Filters/MuxFrequenciesFilter'
import { PageData } from '../../types/data'
import { LastInfo, LastRadioInfo, ReduxAction } from '../../types/reducers'
import { Languages } from '../../utility/Languages'

export enum GRAPHIC_ACTIONS {
    SET_COLLAPSED_BAR = 'graphicActions/setCollapsedBar',
    SET_CURRENT_LANGUAGE = 'graphicActions/setCurrentLanguage',
    SET_PAGE_HEADER = 'graphicActions/setPageHeader',
    SET_LAST_SEARCH_RADIO = 'graphicActions/setLastSearchRadio',
    SET_LAST_SEARCH_NETWORK = 'graphicActions/setLastSearchNetwork',
    SET_LAST_SEARCH_FREQUENCY = 'graphicActions/setLastSearchFrequency',
    SET_LAST_SEARCH_MUX = 'graphicActions/setLastSearchMux',
    SET_LAST_SEARCH_MUX_FREQUENCIES = 'graphicActions/setLastSearchMuxFrequencies',
}

export const setCollapsedBar: ReduxAction<boolean, GRAPHIC_ACTIONS> = (status: boolean) => {
    return {
        type: GRAPHIC_ACTIONS.SET_COLLAPSED_BAR,
        payload: status,
    }
}

export const setCurrentLanguage: ReduxAction<Languages, GRAPHIC_ACTIONS> = (lang: Languages) => {
    return {
        type: GRAPHIC_ACTIONS.SET_CURRENT_LANGUAGE,
        payload: lang,
    }
}

export const setPageHeader: ReduxAction<PageData | null, GRAPHIC_ACTIONS> = (data: PageData | null) => {
    return {
        type: GRAPHIC_ACTIONS.SET_PAGE_HEADER,
        payload: data,
    }
}

export const setLastSearchRadio: ReduxAction<LastRadioInfo | null, GRAPHIC_ACTIONS> = (
    lastInfo: LastRadioInfo | null
) => {
    return {
        type: GRAPHIC_ACTIONS.SET_LAST_SEARCH_RADIO,
        payload: lastInfo,
    }
}

export const setLastSearchNetwork: ReduxAction<LastInfo | null, GRAPHIC_ACTIONS> = (lastInfo: LastInfo | null) => {
    return {
        type: GRAPHIC_ACTIONS.SET_LAST_SEARCH_NETWORK,
        payload: lastInfo,
    }
}

export const setLastSearchFrequency: ReduxAction<FrequencyFilterObject | null, GRAPHIC_ACTIONS> = (
    lastInfo: FrequencyFilterObject | null
) => {
    return {
        type: GRAPHIC_ACTIONS.SET_LAST_SEARCH_FREQUENCY,
        payload: lastInfo,
    }
}

export const setLastSearchMux: ReduxAction<MuxFilterObject | null, GRAPHIC_ACTIONS> = (
    lastInfo: MuxFilterObject | null
) => {
    return {
        type: GRAPHIC_ACTIONS.SET_LAST_SEARCH_MUX,
        payload: lastInfo,
    }
}

export const setLastSearchMuxFrequencies: ReduxAction<FilterMuxFrequencies | null, GRAPHIC_ACTIONS> = (
    lastInfo: FilterMuxFrequencies | null
) => {
    return {
        type: GRAPHIC_ACTIONS.SET_LAST_SEARCH_MUX_FREQUENCIES,
        payload: lastInfo,
    }
}
