/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { COLORS } from '../../resources/colors'
import { ROUTES } from '../../resources/routes-constants'
import { logoutUser, setLoggedUserData } from '../../store/actions/user'
import { ReducerGraphic, Reducers, ReducerUser } from '../../types/reducers'
import { Grants } from '../../utility/Grants'
import Icon from '../Icon'
import TooltipComponent from '../TooltipComponent/TooltipComponent'
import './Header.sass'
import { Network } from '../../types/data'
import SelectInput from '../SelectInput/SelectInput'
import { PropsValue } from 'react-select'
import { setSelectedNetwork } from '../../store/actions/data'
import { VoidNetwork, voidNetwork } from '../../utility/voidConstants'
import NetworkModal from '../Modals/NetworkModal'
import FmWorldMultipartAxios from '../../utility/FmWorldMultipartAxios'
import { getCurrentFMWorldUser, getNetworks, logFmWorldUser } from '../../resources/api-constants'
import { fetchNetworks } from '../../store/actions/thunk_actions'
import Button from '../Button/Button'
import FmWorldAxios from '../../utility/FmWorldAxios'
import appConfiguration from '../../appConfiguration'

export interface PageName {
    name: string
    idNetwork?: number
    parent?: {
        name: string
        id: number
    }
}

const Header: React.FC = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const graphic = useSelector<Reducers, ReducerGraphic>((state) => state.graphic)
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const [networks, setNetworks] = React.useState<Network[]>((user.user?.networks as unknown as Network[]) ?? [])
    const [localSelectedNetwork, setLocalSelectedNetwork] = React.useState<Network | null>(null)
    const [loading, setLoading] = React.useState<boolean>(false)

    const [error, setError] = React.useState<string>('')
    const [creatingNetwork, setCreatingNetwork] = React.useState<Network | VoidNetwork | null>(null)

    useEffect(() => {
        if (user.user?.networks && user.user.networks.length > 0) {
            setNetworks((user.user.networks as unknown as Network[]) ?? [])
        }
    }, [user.user])

    const handleCreateNetwork = async (network: Network) => {
        try {
            setLoading(true)
            const formData = new FormData()
            Object.keys(network).map((key) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const currentField = network[key as any]
                formData.append(key, Array.isArray(currentField) ? JSON.stringify(currentField) : currentField)
                return
            })
            await FmWorldMultipartAxios.post(getNetworks(), formData)
            setCreatingNetwork(null)
            dispatch(fetchNetworks())

            const userInfo = await getCurrentFMWorldUser(user.user?.authToken as string)
            dispatch(setLoggedUserData({ ...user.user, ...userInfo }))
            location.reload()
            navigate(`${ROUTES.HOMEPAGE_ROUTE}`)
        } catch (error: any) {
            console.log('ERROR', error)
            setError(error.toString())
        }
        setLoading(false)
    }

    const renderTitle = () => {
        if (graphic.pageHeader) {
            if (graphic.pageHeader.parent) {
                return (
                    <h2 className="header-title">
                        <span
                            style={{ color: COLORS.purple, cursor: 'pointer' }}
                            onClick={() => {
                                if (graphic.pageHeader && graphic.pageHeader.parent) {
                                    navigate(`${graphic.pageHeader.parent.route}`)
                                }
                            }}
                        >
                            {graphic.pageHeader.parent.name}
                        </span>{' '}
                        - {graphic.pageHeader.name}
                    </h2>
                )
            }
            return <h2 className="header-title">{graphic.pageHeader.name}</h2>
        }
        return <div />
    }

    return (
        <div className="header">
            {renderTitle()}
            <div className="header-iconContainer">
                {user.user && user.user.idRole === 1 && appConfiguration.isFmWorld && (
                    <div>
                        <Button
                            label={t('homepage.create_network')}
                            type={'submit'}
                            onPress={() => setCreatingNetwork(voidNetwork)}
                        />
                    </div>
                )}
                {user.user?.networks && user.user.networks.length === 0 ? (
                    null
                ) : (
                    <SelectInput
                        options={
                            networks === undefined
                                ? [{ id: 0, value: 0, label: '' }]
                                : networks.map((network) => {
                                      return { id: network.id, value: network.id, label: network.name }
                                  })
                        }
                        onChange={(value: PropsValue<any>) => {
                            if (value) {
                                const selectedNetwork = networks.find((network) => network.id === value.value) || null
                                setLocalSelectedNetwork(selectedNetwork)
                                if (selectedNetwork) {
                                    console.log('selectedNetwork', selectedNetwork)

                                    dispatch(setSelectedNetwork(selectedNetwork))
                                    navigate(ROUTES.NETWORKS_BUNDLES_ROUTE)
                                }
                            }
                        }}
                        placeholder={t('headers.select_network')}
                        value={
                            localSelectedNetwork
                                ? {
                                      id: localSelectedNetwork.id,
                                      value: localSelectedNetwork.id,
                                      label: localSelectedNetwork.name,
                                  }
                                : networks[0]
                                ? { id: networks[0].id, value: networks[0].id, label: networks[0].name }
                                : null
                        }
                    />
                )}

                <Link to={ROUTES.SETTINGS_ROUTE}>
                    {user.user && user.user.grants && user.user.grants.includes(Grants.MANAGE_SYSTEM) ? (
                        <TooltipComponent tooltip={t('headers.settings')}>
                            <Icon name="settings" color={COLORS.blueGrey} size={20} />
                        </TooltipComponent>
                    ) : null}
                </Link>
                {/* <Link to={ROUTES.FAQ_ROUTE}>
                    <TooltipComponent tooltip={t('headers.faq')}>
                        <Icon
                            name="questions"
                            color={'#fff'}
                            size={16}
                            style={{
                                backgroundColor: COLORS.blueGrey,
                                width: 25,
                                height: 25,
                                borderRadius: 20,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        />
                    </TooltipComponent>
                </Link> */}
                <Link onClick={() => dispatch(logoutUser())} to={ROUTES.HOMEPAGE_ROUTE}>
                    <TooltipComponent tooltip={t('auth.logout')}>
                        <Icon name="logout" color={COLORS.blueGrey} size={25} />
                    </TooltipComponent>
                </Link>
                <NetworkModal
                    isVisible={creatingNetwork !== null}
                    onClose={() => setCreatingNetwork(null)}
                    onSave={(network) => {
                        void handleCreateNetwork(network as Network)
                    }}
                    loading={loading}
                    network={creatingNetwork}
                />
            </div>
        </div>
    )
}

export default Header
