import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { PlayerBundle } from '../../pages/PlayerPage/tabs/PlayerBundlesTab'
import { COLORS } from '../../resources/colors'
import { Bundle, Country } from '../../types/data'
import { Reducers, ReducerData } from '../../types/reducers'
import Button from '../Button/Button'
import SelectInput, { Option } from '../SelectInput/SelectInput'
import './ServiceTabs.sass'

interface Props {
    onRemove: () => void
    update: (p: PlayerBundle) => void
    filteredBundles: Bundle[]
    filteredCountries: Country[]
    allBundles: Bundle[]
    playerBundle: PlayerBundle
}

const PlayerBundleAssociation: React.FC<Props> = (props) => {
    const data = useSelector<Reducers, ReducerData>((state) => state.data)
    const { t } = useTranslation()

    const bundleValue = () => {
        if (props.playerBundle.idBundle !== -1) {
            const bundleFind = props.allBundles.find((b) => b.id === props.playerBundle.idBundle)
            if (bundleFind) {
                return {
                    id: bundleFind.id,
                    value: bundleFind.id,
                    label: bundleFind.name,
                }
            }
        }
        return null
    }

    const countriesValues = () => {
        if (props.playerBundle.countries.length > 0) {
            const countriesArray = data.countries.filter(
                (c) => props.playerBundle.countries.find((p) => p === c.id) !== undefined
            )
            return countriesArray.map((c) => {
                return {
                    id: c.id,
                    value: c.id,
                    label: c.name,
                }
            })
        }
        return null
    }

    return (
        <div className="serviceTab">
            <SelectInput
                label={t('networks.bundle')}
                placeholder={t('networks.bundle')}
                options={props.filteredBundles.map((b) => {
                    return {
                        id: b.id,
                        value: b.id,
                        label: b.name,
                    }
                })}
                value={bundleValue()}
                onChange={(e) => {
                    const val = e as Option
                    props.update({ ...props.playerBundle, idBundle: val.id as number })
                }}
            />
            <div style={{ margin: '20px 0' }}>
                <SelectInput
                    label={t('networks.countries')}
                    placeholder={t('general.all_countries')}
                    isMulti
                    options={props.filteredCountries.map((c) => {
                        return {
                            id: c.id,
                            value: c.id,
                            label: c.name,
                        }
                    })}
                    value={countriesValues()}
                    onChange={(e) => {
                        if (e && (e as Option[]).length > 0) {
                            const val = e as Option[]
                            props.update({
                                ...props.playerBundle,
                                countries: val.map((c) => {
                                    return c.id as string
                                }),
                            })
                        } else {
                            props.update({
                                ...props.playerBundle,
                                countries: [],
                            })
                        }
                    }}
                />
            </div>
            <div className="serviceTab-buttons">
                <div style={{ flex: 1 }}>
                    <Button
                        label={t('general.unlink')}
                        onPress={() => {
                            props.onRemove()
                        }}
                        type="submit"
                        background={'rgba(100,38,119,0.14)'}
                        border={`2px solid ${COLORS.purple}`}
                        color={COLORS.purple}
                    />
                </div>
            </div>
        </div>
    )
}

export default PlayerBundleAssociation
