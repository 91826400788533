import React, { useState, useEffect } from 'react'
import { getFormatGroupDetail, getFormatGroups } from '../../resources/api-constants'
import { Radio } from '../../types/data'
import PageSidebarContainer from '../../components/PageSidebarContainer'
import CardContainer from '../../components/CardContainer/CardContainer'
import Button from '../../components/Button/Button'
import TextInput from '../../components/TextInput/TextInput'
import Loader from '../../components/Loader/Loader'
import ActionsModal from '../../components/Modals/ActionsModal'
import DeleteModal from '../../components/Modals/DeleteModal'
import LoadingModal from '../../components/Modals/LoadingModal'
import { COLORS } from '../../resources/colors'
import FormatGroupModal from '../../components/Modals/FormatGrouModal'
import FmWorldAxios from '../../utility/FmWorldAxios'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import FormatGroupTable from '../../components/Tables/FormatGroupTable'
import { ROUTES } from '../../resources/routes-constants'
import { setPageHeader } from '../../store/actions/graphic'
import { Reducers, ReducerUser } from '../../types/reducers'

export interface FormatGroup {
    id: number
    name: string
    radios?: Radio[]
}

const voidFormatGroup = { id: 0, name: '' }

const FormatGroupsPage: React.FC = () => {
    const [searchQuery, setSearchQuery] = useState<string>('')
    const [isLoading, setIsLoading] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)
    const [isCreating, setIsCreating] = useState(false)
    const [error, setError] = useState('')
    const [formatGroups, setFormatGroups] = useState<FormatGroup[]>([])
    const [filteredFormatGroups, setFilteredFormatGroups] = useState<FormatGroup[]>([])
    const [selectedFormatGroup, setSelectedFormatGroup] = useState<FormatGroup | null>(null)
    const [formatGroupToDelete, setFormatGroupToDelete] = useState<FormatGroup | null>(null)
    const [formatGroupToEdit, setFormatGroupToEdit] = useState<FormatGroup | null>(null)

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation()

    useEffect(() => {
        dispatch(
            setPageHeader({
                parent: null,
                name: t('headers.format_groups'),
            })
        )
        void fetchFormatGroupList()
    }, [])

    useEffect(() => {
        if (searchQuery.length > 2) {
            const filtered = formatGroups.filter((group) =>
                group.name.toLowerCase().includes(searchQuery.toLowerCase())
            )
            setFilteredFormatGroups(filtered)
        } else {
            setFilteredFormatGroups(formatGroups)
        }
    }, [searchQuery, formatGroups])

    const fetchFormatGroupList = async () => {
        setError('')
        setIsLoading(true)
        try {
            const response = await FmWorldAxios.get(getFormatGroups())
            setFormatGroups(response.data.items)
            setFilteredFormatGroups(response.data.items)
        } catch (error: any) {
            console.error('Errore nel recupero dei gruppi di formato', error)
            setError('Errore nel recupero dei gruppi di formato')
        }
        setIsLoading(false)
    }

    const actionsModal = [
        {
            text: t('general.view'),
            color: COLORS.blueGrey,
            onClick: () =>
                navigate(`${ROUTES.FORMAT_GROUP_DETAIL_ROUTE}${selectedFormatGroup?.id}`, {
                    state: { name: selectedFormatGroup?.name },
                }),
        },
        {
            text: t('general.edit'),
            color: COLORS.purple,
            onClick: () => {
                setFormatGroupToEdit(selectedFormatGroup)
                setSelectedFormatGroup(null)
            },
        },
        {
            text: t('general.delete'),
            color: COLORS.redDelete,
            onClick: () => {
                setFormatGroupToDelete(selectedFormatGroup)
                setSelectedFormatGroup(null)
            },
        },
    ]

    const handleUpdateFormatGroup = async (formatGroup: FormatGroup) => {
        setError('')
        setIsUpdating(true)
        try {
            await FmWorldAxios.put(getFormatGroupDetail(formatGroup.id), { name: formatGroup.name })
            setFormatGroupToEdit(null)
            await fetchFormatGroupList()
        } catch (error: any) {
            console.error('Errore nel salvataggio del gruppo di formato', error)
            setError('Errore nel salvataggio del gruppo di formato')
        }
        setIsUpdating(false)
    }

    const handleCreateFormatGroup = async (formatGroup: FormatGroup) => {
        setError('')
        setIsCreating(true)
        try {
            await FmWorldAxios.post(getFormatGroups(), { name: formatGroup.name })
            setFormatGroupToEdit(null)
            await fetchFormatGroupList()
        } catch (error: any) {
            console.error('Errore nella creazione del gruppo di formato', error)
            setError('Errore nella creazione del gruppo di formato')
        }
        setIsCreating(false)
    }

    const handleDeleteFormatGroup = async () => {
        if (formatGroupToDelete) {
            setError('')
            setIsLoading(true)
            try {
                await FmWorldAxios.delete(getFormatGroupDetail(formatGroupToDelete.id))
                setFormatGroupToDelete(null)
                await fetchFormatGroupList()
            } catch (error: any) {
                console.error('Errore nella cancellazione del gruppo di formato', error)
                setError('Errore nella cancellazione del gruppo di formato')
            }
            setIsLoading(false)
        }
    }

    return (
        <PageSidebarContainer>
            <CardContainer>
                <div className="titleRow">
                    <div style={{ flex: 1 }}>
                        <h2 className="title">{t('formatGroups.your_format_groups')}</h2>
                        <h3 className="subtitle" style={{ fontWeight: 400 }}>
                            {t('formatGroups.manage_format_groups')}
                        </h3>
                        {error && <p className="u-normal-text u-bold-text u-error-text">{error}</p>}
                    </div>
                    <div
                        style={{ display: 'flex', flex: 2, gap: 10, alignItems: 'center', justifyContent: 'flex-end' }}
                    >
                        <div style={{ flex: 2 }}>
                            <TextInput
                                value={searchQuery}
                                placeholder={t('general.search')}
                                icon="search"
                                iconPosition="right"
                                onTextChange={(v) => setSearchQuery(v)}
                            />
                        </div>
                        <div style={{ flex: 1 }}>
                            <Button
                                label={t('general.refresh')}
                                onPress={() => {
                                    void fetchFormatGroupList()
                                }}
                                type="submit"
                                background="rgba(100,38,119,0.14)"
                                border={`2px solid ${COLORS.purple}`}
                                color={COLORS.purple}
                            />
                        </div>
                        <div style={{ flex: 1 }}>
                            <Button
                                label={t('general.add')}
                                onPress={() => {
                                    setFormatGroupToEdit(voidFormatGroup)
                                }}
                                type="submit"
                            />
                        </div>
                    </div>
                </div>
                {isLoading ? (
                    <div className="loadingGrid">
                        <Loader />
                    </div>
                ) : (
                    <div style={{ alignItems: 'flex-start', marginTop: 20 }}>
                        <FormatGroupTable
                            data={filteredFormatGroups}
                            selectFormatGroup={(fg) => setSelectedFormatGroup(fg)}
                        />
                    </div>
                )}
            </CardContainer>
            <ActionsModal
                isVisible={selectedFormatGroup !== null}
                title={t('general.what_to_do')}
                onClose={() => setSelectedFormatGroup(null)}
                actions={actionsModal}
            />
            <FormatGroupModal
                isVisible={formatGroupToEdit !== null}
                onClose={() => setFormatGroupToEdit(null)}
                onSave={(formatGroup) => {
                    if (formatGroup.id) {
                        void handleUpdateFormatGroup({ ...formatGroup, name: formatGroup.name || '' })
                    } else {
                        void handleCreateFormatGroup({ ...formatGroup, name: formatGroup.name || '' })
                    }
                }}
                formatGroup={formatGroupToEdit}
            />
            <DeleteModal
                onDelete={() => {
                    void handleDeleteFormatGroup()
                }}
                title={`${t('formatGroups.delete_this_format_group')} ${formatGroupToDelete?.name}`}
                text={`${t('formatGroups.want_delete_format_group')} ${formatGroupToDelete?.name}?`}
                onClose={() => {
                    setFormatGroupToDelete(null)
                }}
                isVisible={formatGroupToDelete !== null}
            />
            <LoadingModal isVisible={isCreating || isUpdating} text={t('loadings.generic_loading')} />
        </PageSidebarContainer>
    )
}

export default FormatGroupsPage
