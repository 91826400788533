import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Button from '../../../components/Button/Button'
import MuxFilter, { MuxFilterObject } from '../../../components/Filters/MuxFilter'
import Loader from '../../../components/Loader/Loader'
import ActionsModal from '../../../components/Modals/ActionsModal'
import DeleteModal from '../../../components/Modals/DeleteModal'
import LoadingModal from '../../../components/Modals/LoadingModal'
import MuxModal from '../../../components/Modals/MuxModal'
import RadioDabModal from '../../../components/Modals/RadioDabModal'
import MuxTable from '../../../components/Tables/MuxTable'
import TextInput from '../../../components/TextInput/TextInput'
import { getDabRadioAssoc, getMuxDetail, getMuxList } from '../../../resources/api-constants'
import { COLORS } from '../../../resources/colors'
import { ROUTES } from '../../../resources/routes-constants'
import { setLastSearchMux } from '../../../store/actions/graphic'
import { Mux } from '../../../types/data'
import { Reducers, ReducerUser } from '../../../types/reducers'
import FmWorldAxios from '../../../utility/FmWorldAxios'
import { Grants } from '../../../utility/Grants'
import { VoidMux } from '../../../utility/voidConstants'

const initialFilter = {
    query: '',
    countries: [],
    cities: [],
    radios: [],
}

interface Props {
    idRadio: number
    permissions: Grants[]
}

const DabTab: React.FC<Props> = (props) => {
    const [filter, setFilter] = useState<MuxFilterObject>(initialFilter)
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const [isLoading, setIsLoading] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)
    const [isCreating, setIsCreating] = useState(false)
    const [addModal, setAddModal] = useState(false)
    const [error, setError] = useState('')
    const [muxs, setMuxs] = useState<Mux[]>([])
    const [filteredMuxs, setFilteredMuxs] = useState<Mux[]>([])
    const [selectedMux, setSelectedMux] = useState<Mux | null>(null)
    const [muxToDelete, setMuxToDelete] = useState<Mux | null>(null)
    const [muxToEdit, setMuxToEdit] = useState<Mux | VoidMux | null>(null)
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const getParams = () => {
        const params: any = {
            radios: props.idRadio,
        }
        if (filter.cities.length > 0) {
            params.cities = filter.cities
                .map((c) => {
                    return c.id
                })
                .toString()
        }
        if (filter.countries.length > 0) {
            params.countries = filter.countries.toString()
        }
        return params
    }

    const fetchMuxList = async () => {
        setError('')
        setIsLoading(true)
        try {
            const params = getParams()
            const muxFetch = await FmWorldAxios.get(getMuxList(), { params: { ...params } }).then((res) => {
                return res.data.items
            })
            setMuxs(muxFetch)
        } catch (error: any) {
            console.log('ERROR', error)
            setError(error.toString())
        }
        setIsLoading(false)
    }

    useEffect(() => {
        if (user.user) {
            void fetchMuxList()
        }
    }, [user.user])

    useEffect(() => {
        if (filter.query.length > 2) {
            const filtered = muxs.filter(
                (n) =>
                    n.name.toLowerCase().includes(filter.query.toLowerCase()) ||
                    n.code.toLowerCase().includes(filter.query.toLowerCase())
            )
            setFilteredMuxs(filtered)
        } else {
            setFilteredMuxs(muxs)
        }
        dispatch(setLastSearchMux(filter))
    }, [filter.query, muxs])

    useEffect(() => {
        void fetchMuxList()
        dispatch(setLastSearchMux(filter))
    }, [filter.cities, filter.radios, filter.countries])

    const actionsModal = [
        {
            text: t('dabs.view_mux'),
            color: COLORS.blueGrey,
            onClick: () =>
                navigate(`${ROUTES.DAB_DETAIL_ROUTE}${selectedMux?.id}`, {
                    state: { name: selectedMux?.name },
                }),
        },
        {
            text: t('dabs.edit_mux'),
            color: COLORS.purple,
            onClick: () => {
                setSelectedMux(null)
                setMuxToEdit(selectedMux)
            },
        },
        {
            text: t('dabs.delete_radio_association'),
            color: COLORS.redDelete,
            onClick: () => {
                setSelectedMux(null)
                setMuxToDelete(selectedMux)
            },
        },
    ]

    const handleUpdateMux = async (mux: Mux) => {
        setError('')
        setIsUpdating(true)
        try {
            const updated = {
                name: mux.name,
                code: mux.code,
            }
            await FmWorldAxios.put(getMuxDetail(mux.id), updated)
            setSelectedMux(null)
            setMuxToEdit(null)
            await fetchMuxList()
        } catch (error: any) {
            console.log('ERROR', error)
            setError(error.toString())
        }
        setIsUpdating(false)
    }

    const handleCreateAssoc = async (idMux: number) => {
        setError('')
        setIsCreating(true)
        try {
            await FmWorldAxios.post(getDabRadioAssoc(idMux, props.idRadio))
            setIsCreating(false)
            setAddModal(false)
            void fetchMuxList()
        } catch (error: any) {
            console.log('ERROR', error)
            setError(error.toString())
        }
    }
    const handleDeleteAssoc = async () => {
        if (muxToDelete) {
            setError('')
            setIsLoading(true)
            try {
                await FmWorldAxios.delete(getDabRadioAssoc(muxToDelete.id, props.idRadio))
                setMuxToDelete(null)
                setSelectedMux(null)
                await fetchMuxList()
            } catch (error: any) {
                console.log('ERROR', error)
                setError(error.toString())
                setIsLoading(false)
            }
        }
    }

    const handleFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    return (
        <div>
            <div className="titleRow">
                <div style={{ flex: 1 }}>
                    <h2 className="title">{t('dabs.your_muxs')}</h2>
                    <h3 className="subtitle" style={{ fontWeight: 400 }}>
                        {t('dabs.manage_muxs')}
                    </h3>
                    {error && <p className="u-normal-text u-bold-text u-error-text">{error}</p>}
                </div>
                <div style={{ display: 'flex', flex: 2, gap: 10, alignItems: 'center', justifyContent: 'flex-end' }}>
                    <div style={{ flex: 2 }}>
                        <TextInput
                            value={filter.query}
                            placeholder={t('general.search')}
                            icon="search"
                            iconPosition="right"
                            onTextChange={(v) => setFilter({ ...filter, query: v })}
                        />
                    </div>
                    <div style={{ flex: 1 }}>
                        <Button
                            label={t('general.filter')}
                            onPress={(e) => {
                                handleFilter(e)
                            }}
                            type="submit"
                            background="#fff"
                            border={`2px solid ${
                                JSON.stringify(filter) !== JSON.stringify(initialFilter) ? COLORS.green : COLORS.purple
                            }`}
                            color={
                                JSON.stringify(filter) !== JSON.stringify(initialFilter) ? COLORS.green : COLORS.purple
                            }
                        />
                        <MuxFilter
                            filter={filter}
                            anchorEl={anchorEl}
                            onClose={() => setAnchorEl(null)}
                            onReset={() => setFilter(initialFilter)}
                            hideRadio
                            onUpdate={(f) =>
                                setFilter({
                                    ...filter,
                                    radios: f.radios,
                                    cities: f.cities,
                                    countries: f.countries,
                                })
                            }
                        />
                    </div>
                    <div style={{ flex: 1 }}>
                        <Button
                            label={t('general.refresh')}
                            onPress={() => {
                                void fetchMuxList()
                            }}
                            type="submit"
                            background="rgba(100,38,119,0.14)"
                            border={`2px solid ${COLORS.purple}`}
                            color={COLORS.purple}
                        />
                    </div>
                    {props.permissions.includes(Grants.EDIT_RADIO) ? (
                        <div style={{ flex: 1 }}>
                            <Button
                                label={t('general.add')}
                                onPress={() => {
                                    setAddModal(true)
                                }}
                                type="submit"
                            />
                        </div>
                    ) : null}
                </div>
            </div>
            {isLoading ? (
                <div className="loadingGrid">
                    <Loader />
                </div>
            ) : (
                <div style={{ alignItems: 'flex-start', marginTop: 20 }}>
                    <MuxTable data={filteredMuxs} selectMux={(m) => setSelectedMux(m)} />
                </div>
            )}
            <ActionsModal
                isVisible={selectedMux !== null}
                title={t('general.what_to_do')}
                onClose={() => setSelectedMux(null)}
                actions={actionsModal}
            />
            <MuxModal
                isVisible={muxToEdit !== null}
                onClose={() => setMuxToEdit(null)}
                onSave={(mux) => {
                    if ((mux as Mux).id) {
                        void handleUpdateMux(mux as Mux)
                    }
                }}
                mux={muxToEdit}
            />
            <RadioDabModal
                isVisible={addModal}
                onClose={() => {
                    setAddModal(false)
                }}
                selectedMuxs={muxs}
                onSave={(mux) => {
                    if (mux) {
                        void handleCreateAssoc(mux.id as number)
                    }
                }}
            />
            <DeleteModal
                onDelete={() => {
                    void handleDeleteAssoc()
                }}
                title={`${t('dabs.delete_associate_radios')} ${muxToDelete?.name}`}
                text={`${t('dabs.want_delete_associate_radios')} ${muxToDelete?.name}?`}
                onClose={() => {
                    setMuxToDelete(null)
                }}
                isVisible={muxToDelete !== null}
            />
            <LoadingModal isVisible={isCreating || isUpdating} text={t('loadings.updating')} />
        </div>
    )
}

export default DabTab
