import React from 'react'
import './Modals.sass'
import Modal from '@mui/material/Modal'
import Button from '../Button/Button'
import { COLORS } from '../../resources/colors'
import { StreamOffline } from '../../types/data'
import OfflineStreamsTable from '../Tables/OfflineStreamsTable'
import { useTranslation } from 'react-i18next'

interface Props {
    isVisible: boolean
    streams: StreamOffline[]
    onClose: () => void
}

const OfflineStreamListModal: React.FC<Props> = (props) => {
    const { t } = useTranslation()

    return (
        <Modal
            open={props.isVisible}
            onClose={() => props.onClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className="modalBox" style={{ width: '80vw', height: '80vh', overflow: 'scroll' }}>
                <h2 className="modalBox-title">{t('homepage.stream_offline')}</h2>
                <div>
                    <OfflineStreamsTable
                        data={props.streams}
                        selectStream={() => {
                            return
                        }}
                        isComplete
                    />
                </div>
                <div className="modalBox-buttonsRow">
                    <div style={{ flex: '1 1 100%' }} />
                    <div style={{ flex: '1 1 100%' }} />
                    <div style={{ flex: '1 1 100%' }} />
                    <Button
                        label="Chiudi"
                        border={'1px solid rgba(0,0,0,0.07)'}
                        onPress={() => props.onClose()}
                        type="secondary"
                        color={COLORS.darkGrey}
                        background="#fff"
                    />
                </div>
            </div>
        </Modal>
    )
}

export default OfflineStreamListModal
