import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../../components/Button/Button'
import Loader from '../../../components/Loader/Loader'
import ActionsModal from '../../../components/Modals/ActionsModal'
import AddApiKeyModal from '../../../components/Modals/AddApiKeyModal'
import DeleteModal from '../../../components/Modals/DeleteModal'
import ApiKeysTable from '../../../components/Tables/ApiKeysTable'
import { getApiKey, getApiKeysNetwork } from '../../../resources/api-constants'
import { COLORS } from '../../../resources/colors'
import FmWorldAxios from '../../../utility/FmWorldAxios'
import { Grants } from '../../../utility/Grants'
import { useSelector } from 'react-redux'
import { Reducers, ReducerData } from '../../../types/reducers'

export interface ApiKey {
    id: number
    name: string
    token: string
    expireOn: string
}

interface Props {
    permissions: Grants[]
}

const ApiTab: React.FC<Props> = (props) => {
    const [error, setError] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [showAddModal, setShowAddModal] = useState(false)
    const [keys, setKeys] = useState<ApiKey[]>([])
    const [selectedKey, setSelectedKey] = useState<ApiKey | null>(null)
    const [keyToDelete, setKeyToDelete] = useState<ApiKey | null>(null)
    const { t } = useTranslation()
    const data = useSelector<Reducers, ReducerData>((state) => state.data)
    const selectedNetwork = data.selectedNetwork

    const fetchKeys = async () => {
        setError('')
        setIsLoading(true)
        try {
            if (!selectedNetwork) {
                throw new Error('No network selected')
            }
            const keysFetched = await FmWorldAxios.get(getApiKeysNetwork(selectedNetwork?.id)).then((res) => {
                return res.data.items
            })
            console.log(keysFetched)
            setKeys(keysFetched)
        } catch (error: any) {
            setError(error.toString())
        }
        setIsLoading(false)
    }

    useEffect(() => {
        if (props.permissions.includes(Grants.EDIT_NETWORK)) {
            void fetchKeys()
        }
    }, [selectedNetwork])

    const deleteApiKey = async () => {
        if (keyToDelete) {
            setIsLoading(true)
            try {
                if (!selectedNetwork) {
                    throw new Error('No network selected')
                }
                await FmWorldAxios.delete(getApiKey(selectedNetwork.id, keyToDelete.id))
                setKeyToDelete(null)
                await fetchKeys()
            } catch (error: any) {
                setError(error.toString())
            }
            setIsLoading(false)
        }
    }

    const actionsModal = [
        {
            text: t('networks.delete_api_key'),
            color: COLORS.redDelete,
            onClick: () => {
                setSelectedKey(null)
                setKeyToDelete(selectedKey)
            },
        },
    ]

    if (isLoading) {
        return (
            <div className="loadingGrid">
                <Loader />
            </div>
        )
    }

    return (
        <div>
            <div className="titleRow">
                <div style={{ flex: 1 }}>
                    <h2 className="title">{t('networks.api_keys')}</h2>
                    <h3 className="subtitle" style={{ fontWeight: 400 }}>
                        {t('networks.view_api_keys')}
                    </h3>
                    {error && <p className="u-normal-text u-bold-text u-error-text">{error}</p>}
                </div>
                <div style={{ display: 'flex', flex: 2, gap: 10, alignItems: 'center', justifyContent: 'flex-end' }}>
                    <div style={{ flex: 4 }} />
                    <div style={{ flex: 1 }}>
                        <Button
                            label={t('general.refresh')}
                            onPress={() => {
                                return
                            }}
                            type="submit"
                            background="rgba(100,38,119,0.14)"
                            border={`2px solid ${COLORS.purple}`}
                            color={COLORS.purple}
                        />
                    </div>
                    <div style={{ flex: 1 }}>
                        <Button
                            label={t('general.add')}
                            onPress={() => {
                                setShowAddModal(true)
                            }}
                            type="submit"
                        />
                    </div>
                </div>
            </div>
            <div>
                <ApiKeysTable
                    data={keys}
                    selectKey={(k) => {
                        setSelectedKey(k)
                    }}
                />
            </div>
            <AddApiKeyModal
                isVisible={showAddModal}
                idNetwork={selectedNetwork?.id || 0}
                onClose={() => setShowAddModal(false)}
                onUpdate={() => {
                    setShowAddModal(false)
                    void fetchKeys()
                }}
            />
            <ActionsModal
                isVisible={selectedKey !== null}
                title={t('general.what_to_do')}
                onClose={() => setSelectedKey(null)}
                actions={actionsModal}
            />
            <DeleteModal
                onDelete={() => {
                    void deleteApiKey()
                }}
                title={`${t('networks.delete_api_key')} ${keyToDelete?.name}`}
                text={`${t('networks.want_delete_api_key')} ${keyToDelete?.name}?`}
                onClose={() => {
                    setKeyToDelete(null)
                }}
                isVisible={keyToDelete !== null}
            />
        </div>
    )
}

export default ApiTab
