import '../../styles/pages/NetworksPage.sass'

import { useTranslation } from 'react-i18next'

import MenuItem from './Item/MenuItem'
import { ROUTES } from '../../resources/routes-constants'
import SectionTitle from './SectionTitle/SectionTitle'
import { useSelector } from 'react-redux'
import { Reducers, ReducerUser } from '../../types/reducers'
import { Grants } from '../../utility/Grants'

const NetworkTabs: React.FC = () => {
    const { t } = useTranslation()

    const user = useSelector<Reducers, ReducerUser>((state) => state.user)

    return (
        <>
            <SectionTitle text={t('sidemenus.network_items')} />
            {user.user?.grants && !user.user?.grants.includes(Grants.MANAGE_SYSTEM) ? (
                <MenuItem path={ROUTES.NETWORKS_PENDING_ELEMENTS} name={t('sidemenus.pending_elements')} icon={''} />
            ) : null}
            <MenuItem path={ROUTES.NETWORKS_USERS_ROUTE} name={t('networks.users')} icon={''} />
            <MenuItem path={ROUTES.NETWORKS_RADIO_ROUTE} name={t('networks.radios')} icon={''} />
            <MenuItem name={t('sidemenus.payments')} icon="" path={ROUTES.NETWORKS_PAYMENTS_ROUTE} />

            {user.user?.grants && user.user?.grants.includes(Grants.MANAGE_SYSTEM) ? (
                <>
                    <MenuItem path={ROUTES.NETWORKS_BUNDLES_ROUTE} name={t('networks.bundles')} icon={''} />
                    <MenuItem path={ROUTES.NETWORKS_PLAYERS_ROUTE} name={t('networks.players')} icon={''} />
                    <MenuItem path={ROUTES.NETWORKS_API_ROUTE} name={t('networks.api_keys')} icon={''} />
                </>
            ) : null}
        </>
    )
}

export default NetworkTabs
