import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { COLORS } from '../../../resources/colors'
import Icon from '../../Icon'
import './MenuItem.sass'

interface Props {
    name: string
    icon: string
    count?: string
    detailPath?: string
    path: string
    sizeIcon?: number
    marginRightIcon?: number
    title?: string
}

const MenuItem: React.FC<Props> = (props) => {
    const location = useLocation()

    return (
        <Link to={props.path} state={props.title ? { name: props.title } : null}>
            <div
                className={
                    location.pathname === props.path ||
                    (props.detailPath && location.pathname.includes(props.detailPath))
                        ? 'menuItem active'
                        : 'menuItem'
                }
            >
                {location.pathname === props.path ||
                (props.detailPath && location.pathname.includes(props.detailPath)) ? (
                    <div className="indicator" />
                ) : null}
                <div className="menuItem-container">
                    <Icon
                        name={props.icon}
                        color={
                            location.pathname === props.path ||
                            (props.detailPath && location.pathname.includes(props.detailPath))
                                ? COLORS.purple
                                : COLORS.darkGrey
                        }
                        size={props.sizeIcon ? props.sizeIcon : 22}
                        style={{ marginRight: props.marginRightIcon ? props.marginRightIcon : '' }}
                    />
                    <span className="menuItem-container_span">{props.name}</span>
                    {props.count ? <span className="menuItem-container_count">{props.count}</span> : null}
                </div>
            </div>
        </Link>
    )
}

export default MenuItem
