import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Button from '../../../components/Button/Button'
import Loader from '../../../components/Loader/Loader'
import LoadingModal from '../../../components/Modals/LoadingModal'
import PlayerBundleAssociation from '../../../components/ServiceTabs/PlayerBundleAssociation'
import { getNetworksBundle, getPlayerBundles, setPlayerMultiAssociation } from '../../../resources/api-constants'
import { COLORS } from '../../../resources/colors'
import { Bundle, Country } from '../../../types/data'
import { Reducers, ReducerData } from '../../../types/reducers'
import FmWorldAxios from '../../../utility/FmWorldAxios'
import { Grants } from '../../../utility/Grants'

interface Props {
    idPlayer: number | string
    idNetwork: number
    nameNetwork: string
    permissions: Grants[]
}

export interface PlayerBundle {
    idBundle: number
    countries: string[]
}

const PlayerBundlesTab: React.FC<Props> = (props) => {
    const data = useSelector<Reducers, ReducerData>((state) => state.data)
    const [error, setError] = useState('')
    const [playerBundles, setPlayerBundles] = useState<PlayerBundle[]>([])
    const [bundles, setBundles] = useState<Bundle[]>([])
    const [selectableBundles, setSelectableBundles] = useState<Bundle[]>([])
    const [selectableCountries, setSelectableCountries] = useState<Country[]>(data.countries)
    const [isUpdating, setIsUpdating] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const fetchBundles = async () => {
        setError('')
        setIsLoading(true)
        try {
            const bundlesFetch = await FmWorldAxios.get(getNetworksBundle(props.idNetwork)).then((res) => {
                return res.data
            })
            setBundles(bundlesFetch)
        } catch (error: any) {
            console.log('ERROR', error)
            setError(error.toString())
        }
        setIsLoading(false)
    }

    useEffect(() => {
        setSelectableBundles(bundles.filter((b) => playerBundles.find((p) => p.idBundle === b.id) === undefined))
    }, [bundles, playerBundles])

    useEffect(() => {
        if (playerBundles.length > 0) {
            const arrayCountries: string[] = []
            playerBundles.forEach((p) => {
                p.countries.forEach((c) => {
                    arrayCountries.push(c)
                })
            })
            const filtered = data.countries.filter((c) => arrayCountries.find((a) => a === c.id) === undefined)
            setSelectableCountries(filtered)
        } else {
            setSelectableCountries(data.countries)
        }
    }, [data.countries, playerBundles])

    const fetchPlayerBundles = async () => {
        setIsLoading(true)
        setError('')
        try {
            const playerFetch = await FmWorldAxios.get(getPlayerBundles(props.idPlayer as number)).then((res) => {
                return res.data.map((b: any) => {
                    return {
                        idBundle: b.id,
                        countries: b.countries.map((c: Country) => {
                            return c.id
                        }),
                    }
                })
            })
            setPlayerBundles(playerFetch)
        } catch (error: any) {
            setError(error.toString())
        }
        setIsLoading(false)
    }

    useEffect(() => {
        void fetchPlayerBundles()
        void fetchBundles()
    }, [props.idPlayer])

    const handleUpdatePlayer = async () => {
        setIsUpdating(true)
        setError('')
        try {
            await FmWorldAxios.post(setPlayerMultiAssociation(props.idPlayer as number), playerBundles)
            await fetchPlayerBundles()
        } catch (error: any) {
            setError(error.toString())
        }
        setIsUpdating(false)
    }

    if (isLoading) {
        return <Loader />
    }

    return (
        <div>
            <div className="titleRow">
                <div style={{ flex: 1 }}>
                    <h2 className="title" style={{ textTransform: 'capitalize' }}>
                        {t('networks.bundles_associated')}
                    </h2>
                    <h3 className="subtitle" style={{ fontWeight: 400 }}>
                        {t('networks.manage_bundles_player')}
                    </h3>
                    <p style={{ fontStyle: 'italic', opacity: 0.6 }}>{t('networks.warning_bundles_player')}</p>
                    {error && <p className="u-normal-text u-bold-text u-error-text">{error}</p>}
                </div>
                <div style={{ display: 'flex', flex: 1, gap: 10, alignItems: 'center', justifyContent: 'flex-end' }}>
                    <div style={{ flex: 1 }} />
                    {props.permissions.includes(Grants.EDIT_NETWORK) || props.idPlayer === 'new' ? (
                        <>
                            <div style={{ flex: 1 }}>
                                <Button
                                    disabled={
                                        playerBundles.length > 0 &&
                                        playerBundles[playerBundles.length - 1].idBundle === -1 &&
                                        selectableBundles.length > 0 &&
                                        selectableCountries.length > 0
                                    }
                                    label={t('networks.add_bundle')}
                                    onPress={() => {
                                        setPlayerBundles([
                                            ...playerBundles,
                                            {
                                                idBundle: -1,
                                                countries: [],
                                            },
                                        ])
                                    }}
                                    type="submit"
                                    background={'rgba(100,38,119,0.14)'}
                                    border={`2px solid ${COLORS.purple}`}
                                    color={COLORS.purple}
                                />
                            </div>
                            <div style={{ flex: 1 }}>
                                <Button
                                    label={t('general.update')}
                                    onPress={() => {
                                        void handleUpdatePlayer()
                                    }}
                                    disabled={
                                        playerBundles.find((p) => p.idBundle === -1) !== undefined ||
                                        playerBundles.filter((p) => p.countries.length === 0).length === 0 ||
                                        playerBundles.filter((p) => p.countries.length === 0).length > 1
                                    }
                                    type="submit"
                                />
                            </div>
                        </>
                    ) : null}
                </div>
            </div>
            <div className="servicesGrid">
                {playerBundles.map((b, i) => {
                    return (
                        <PlayerBundleAssociation
                            onRemove={() => {
                                setPlayerBundles(playerBundles.filter((p) => p.idBundle !== b.idBundle))
                            }}
                            update={(n) => {
                                setPlayerBundles(
                                    playerBundles.map((nb, index) => {
                                        if (index === i) {
                                            return n
                                        }
                                        return nb
                                    })
                                )
                            }}
                            filteredCountries={selectableCountries}
                            filteredBundles={selectableBundles}
                            allBundles={bundles}
                            playerBundle={b}
                            key={b.idBundle}
                        />
                    )
                })}
            </div>
            <LoadingModal isVisible={isUpdating} text={t('loadings.updating')} />
        </div>
    )
}

export default PlayerBundlesTab
