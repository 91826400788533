import MaterialTable, { Column } from 'material-table'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { COLORS } from '../../resources/colors'
import { PLACEHOLDER } from '../../resources/constants'
import { ROUTES } from '../../resources/routes-constants'
import { Position } from '../../types/data'
import { Reducers, ReducerGraphic } from '../../types/reducers'
import { getLangTable, sortArrayPerPositions } from '../../utility/functions'
import { Languages } from '../../utility/Languages'
import CheckboxInput from '../CheckboxInput/CheckboxInput'
import FixedTableTheme from '../FixedTableTheme'
import SelectInput, { Option } from '../SelectInput/SelectInput'
import PositionItem from './Items/PositionItem'

interface Props {
    positions: Position[]
    networkName: string
    networkId: number
    search: string
    updatePosition: (pos: Position[], updated: Position) => void
    deletePosition: (position: Position) => void
}

const BundleRadioTable: React.FC<Props> = (props) => {
    const tableRef = React.useRef<any>()
    const graphic = useSelector<Reducers, ReducerGraphic>((state) => state.graphic)
    const navigate = useNavigate()
    const { t } = useTranslation()

    const orderedPositions: Position[] = useMemo(() => {
        if (props.search.length > 2) {
            return sortArrayPerPositions(
                props.positions.filter((p) => p.name.toLowerCase().includes(props.search.toLowerCase()))
            )
        }
        return sortArrayPerPositions(props.positions)
    }, [props.positions, props.search])

    const columns: Column<Position>[] = useMemo(() => {
        return [
            {
                title: t('networks.position') as string,
                field: 'position',
                render: (position: Position) => {
                    return (
                        <PositionItem
                            position={position.bundleInfo.position}
                            radio={position}
                            length={props.positions.length}
                            onPressButton={(newPos: number) => {
                                const newArr = sortArrayPerPositions(props.positions)
                                const newObject = {
                                    ...position,
                                    bundleInfo: {
                                        ...position.bundleInfo,
                                        position: newPos,
                                    },
                                }
                                const indexFound = newArr.findIndex((p: Position) => p.id === position.id)
                                if (indexFound !== -1) {
                                    newArr.splice(indexFound, 1)
                                    newArr.splice(newPos - 1, 0, newObject)
                                    const newOrderedArray = newArr.map((p: Position, i: number) => {
                                        return {
                                            ...p,
                                            bundleInfo: {
                                                ...p.bundleInfo,
                                                position: i + 1,
                                            },
                                        }
                                    })
                                    props.updatePosition(newOrderedArray, {
                                        ...position,
                                        bundleInfo: {
                                            ...position.bundleInfo,
                                            position: newPos,
                                        },
                                    })
                                }
                            }}
                        />
                    )
                },
                width: '12%',
                headerStyle: { width: 160 },
                cellStyle: {
                    width: 160,
                    maxWidth: 160,
                    cellWidth: '12%',
                },
            },
            {
                title: t('networks.radio') as string,
                field: 'radios',
                render: (position: Position) => {
                    return (
                        <div style={{ display: 'flex', gap: 15, justifyContent: 'flex-start', alignItems: 'center' }}>
                            <img
                                src={position.logo ? (position.logo as string) : PLACEHOLDER}
                                alt={position.name}
                                style={{ width: 60, height: 60 }}
                                onError={(e: any) => {
                                    e.currentTarget.src = PLACEHOLDER
                                }}
                            />
                            <p
                                onClick={() =>
                                    navigate(`${ROUTES.RADIO_DETAIL_ROUTE}${position.id}`, {
                                        state: {
                                            name: position.name,
                                            idNetwork: position.idNetwork,
                                            parent: { name: props.networkName, id: props.networkId },
                                        },
                                    })
                                }
                                style={{ fontWeight: 'bold', color: COLORS.purple, cursor: 'pointer' }}
                            >
                                {position.name}
                            </p>
                        </div>
                    )
                },
                width: '75%',
                cellStyle: {
                    cellWidth: '75%',
                },
            },
            {
                title: t('networks.weight') as string,
                field: 'weight',
                render: (position: Position) => {
                    return (
                        <SelectInput
                            value={{
                                id: position.bundleInfo.weight,
                                value: position.bundleInfo.weight,
                                label: position.bundleInfo.weight.toString(),
                            }}
                            options={[
                                { id: 1, value: 1, label: '1' },
                                { id: 2, value: 2, label: '2' },
                                { id: 3, value: 3, label: '3' },
                                { id: 4, value: 4, label: '4' },
                            ]}
                            onChange={(e) => {
                                const val = e as Option
                                const foundPos = props.positions.find((p) => p.id === position.id)
                                if (foundPos) {
                                    const newArray: Position[] = props.positions.map((p) => {
                                        if (p.id === foundPos.id) {
                                            return {
                                                ...p,
                                                bundleInfo: {
                                                    ...p.bundleInfo,
                                                    weight: val.id as number,
                                                },
                                            }
                                        }
                                        return {
                                            ...p,
                                        }
                                    })
                                    props.updatePosition(newArray, {
                                        ...position,
                                        bundleInfo: {
                                            ...position.bundleInfo,
                                            weight: val.id as number,
                                        },
                                    })
                                }
                            }}
                        />
                    )
                },
                width: '7%',
                headerStyle: { width: 120 },
                cellStyle: {
                    width: 120,
                    maxWidth: 120,
                    cellWidth: '7%',
                },
            },
            {
                title: t('networks.sponsor') as string,
                field: 'sponsored',
                render: (position: Position) => {
                    return (
                        <CheckboxInput
                            values={[{ label: '', status: position.bundleInfo.sponsored }]}
                            onClick={() => {
                                const foundPos = props.positions.find((p) => p.id === position.id)
                                if (foundPos) {
                                    const newArray: Position[] = props.positions.map((p) => {
                                        if (p.id === foundPos.id) {
                                            return {
                                                ...p,
                                                bundleInfo: {
                                                    ...p.bundleInfo,
                                                    sponsored: !p.bundleInfo.sponsored,
                                                },
                                            }
                                        }
                                        return {
                                            ...p,
                                        }
                                    })
                                    props.updatePosition(newArray, {
                                        ...position,
                                        bundleInfo: {
                                            ...position.bundleInfo,
                                            sponsored: !position.bundleInfo.sponsored,
                                        },
                                    })
                                }
                            }}
                        />
                    )
                },
                width: '6%',
                headerStyle: { width: 100, maxWidth: 100 },
                cellStyle: {
                    width: 100,
                    maxWidth: 100,
                    cellWidth: '6%',
                },
            },
        ]
    }, [orderedPositions])

    const actions = useMemo(() => {
        const actions = [
            (position: Position) => ({
                icon: 'do_disturb_on',
                iconProps: {
                    color: 'error',
                },
                onClick: () => {
                    props.deletePosition(position)
                },
            }),
        ]

        return actions
    }, [props.positions])

    return (
        <FixedTableTheme>
            <MaterialTable
                columns={columns}
                data={orderedPositions}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                actions={actions}
                ref={tableRef}
                title=""
                localization={getLangTable(graphic.currentLanguage as Languages)}
                options={{
                    actionsColumnIndex: -1,
                    search: false,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 50],
                    actionsCellStyle: {
                        border: '0',
                    },
                    toolbar: false,
                    tableLayout: 'auto',
                }}
                style={{
                    boxShadow: 'none',
                }}
            />
        </FixedTableTheme>
    )
}

export default BundleRadioTable
