import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CheckboxInput from '../CheckboxInput/CheckboxInput'
import FilterPopover from './FilterPopover'

export interface FilterStreams {
    query: string
    offlineOnly: boolean
}

interface Props {
    filter: FilterStreams
    anchorEl: HTMLButtonElement | null
    onClose: () => void
    onReset: () => void
    onUpdate: (f: FilterStreams) => void
}

const StreamFilter: React.FC<Props> = (props) => {
    const [filter, setFilter] = useState<FilterStreams>(props.filter)
    const { t } = useTranslation()

    useEffect(() => {
        if (props.anchorEl) {
            setFilter(props.filter)
        }
    }, [props.anchorEl])

    return (
        <FilterPopover
            anchorElem={props.anchorEl}
            onResetClick={() => props.onReset()}
            onFilterClick={() => props.onUpdate(filter)}
            onClose={() => props.onClose()}
        >
            <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
                <CheckboxInput
                    values={[
                        {
                            label: '',
                            status: filter.offlineOnly,
                        },
                    ]}
                    onClick={() => {
                        setFilter({
                            ...filter,
                            offlineOnly: !filter.offlineOnly,
                        })
                    }}
                    label={t('radios.show_only_offline_streams')}
                />
            </div>
        </FilterPopover>
    )
}

export default StreamFilter
