import React, { CSSProperties } from 'react'
import './TooltipComponent.sass'

interface Props {
    children: any
    bottomValue?: string
    tooltip: string
    style?: CSSProperties
}

const TooltipComponent: React.FC<Props> = (props) => {
    return (
        <div className="tooltip" style={{ ...props.style }}>
            {props.children}
            <span className="tooltiptext" style={{ bottom: props.bottomValue ? props.bottomValue : '-125%' }}>
                {props.tooltip}
            </span>
        </div>
    )
}

export default TooltipComponent
