import { Category, Tag } from '../types/data'

export interface VoidRadio {
    name: string
    logo: string | File
    slogan: string
    website: string
    email: string
    phone: string
    socialContacts: string
    imageH2: string | File
    imageH3: string | File
    imageH4: string | File
    ownerAuthorization: string | File
    metadataMonitor: boolean
    metadataParser: string
    metadataUrl: string
    categories: Category[]
    tags: Tag[]
    idCountry: string | null
    idRadioType: number | null
    idNetwork: number | null
    isPublic: boolean
    latitude: number | null
    longitude: number | null
    verifiedOn: string | null
}

export const voidRadio: VoidRadio = {
    name: '',
    logo: '',
    slogan: '',
    website: '',
    email: '',
    phone: '',
    socialContacts: '',
    imageH2: '',
    imageH3: '',
    imageH4: '',
    ownerAuthorization: '',
    metadataMonitor: false,
    metadataParser: '',
    metadataUrl: '',
    categories: [],
    tags: [],
    idCountry: null,
    idRadioType: null,
    idNetwork: null,
    isPublic: false,
    latitude: null,
    longitude: null,
    verifiedOn: null,
}

export interface VoidStream {
    idLanguage: string | null
    url: string
    isVideo: boolean
    serverType: string
    isOnline: boolean
    bitrate: string
    priority: number
}

export const voidStream = {
    idLanguage: null,
    url: '',
    isVideo: false,
    serverType: '',
    isOnline: true,
    bitrate: '0',
    priority: 0,
}

export interface VoidFrequency {
    idRadio: number
    frequency: string
    idCity: number
}

export const voidFrequency = {
    idRadio: -1,
    frequency: '',
    idCity: -1,
}

export interface VoidDabFrequency {
    frequency: string
    idCity: number
    name?: string
}

export const voidDabFrequency = {
    frequency: '',
    idCity: -1,
}

export interface VoidShow {
    duration: number | null
    idRadio: number
    image: string | File
    rss: string
    rssSync: boolean
    schedule: string | null
    subtitle: string
    summary: string
    title: string
}

export const voidShow = {
    duration: null,
    idRadio: 0,
    image: '',
    rss: '',
    rssSync: false,
    schedule: null,
    subtitle: '',
    summary: '',
    title: '',
}

export interface VoidShowSchedule {
    idShow: number | null
    title: string
    idRadio: number | null
    schedule: string
    duration: number
}

export const voidShowSchedule: VoidShowSchedule = {
    idShow: null,
    title: '',
    idRadio: null,
    schedule: '',
    duration: 0,
}

export interface VoidNetwork {
    email: string
    logo: string | File
    name: string
    referentFirstName: string
    referentLastName: string
    phoneNumber: string
}

export const voidNetwork: VoidNetwork = {
    email: '',
    logo: '',
    name: '',
    referentFirstName: '',
    referentLastName: '',
    phoneNumber: '',
}

export interface VoidBundle {
    idNetwork: number
    logo: string | File
    name: string
    description: string
    isActive: boolean
    isGroup: boolean
}

export const voidBundle: VoidBundle = {
    idNetwork: 0,
    logo: '',
    name: '',
    description: '',
    isActive: false,
    isGroup: false,
}

export interface VoidMux {
    code: string
    name: string
}

export const voidMux: VoidMux = {
    code: '',
    name: '',
}

export interface VoidPlayer {
    idNetwork: number
    name: string
}
