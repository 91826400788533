/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { PlayerStatus } from '../../types/player'
import { ReducerPlayer, Reducers } from '../../types/reducers'
import './Player.sass'
import './AnimatedText.css'
import { useDispatch } from 'react-redux'
import { setMediaPlayer, setPlayerStatus } from '../../store/actions/player'
import ReactPlayer from 'react-player'
import Icon from '../Icon'

const VideoPlayer: React.FC = () => {
    const player = useSelector<Reducers, ReducerPlayer>((state) => state.player)
    const dispatch = useDispatch()
    const playerRef = useRef<any>(null)

    useEffect(() => {
        if (player.mediaPlayer?.type === 'video') {
            dispatch(setPlayerStatus(PlayerStatus.PLAY))
        }
    }, [player.mediaPlayer])

    if (player.mediaPlayer && player.mediaPlayer.type === 'video') {
        return (
            <div className="videoPlayer">
                <Icon
                    name="close"
                    color="#fff"
                    size={15}
                    style={{
                        position: 'absolute',
                        top: 5,
                        right: 5,
                        zIndex: 9999999,
                        cursor: 'pointer',
                        backgroundColor: '#000',
                        padding: 5,
                    }}
                    onClick={() => {
                        dispatch(setMediaPlayer(null))
                    }}
                />
                <ReactPlayer
                    className="player"
                    ref={playerRef}
                    url={player.mediaPlayer.url}
                    playing={player.status === PlayerStatus.PLAY}
                    onEnded={() => {
                        dispatch(setPlayerStatus(PlayerStatus.PAUSE))
                    }}
                    onError={() => {
                        dispatch(setPlayerStatus(PlayerStatus.PAUSE))
                    }}
                    width={'25vw'}
                    style={{
                        maxHeight: '28vh',
                        minHeight: 80,
                        minWidth: 200,
                    }}
                    controls
                />
            </div>
        )
    }
    return null
}

export default VideoPlayer
