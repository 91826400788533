import {
    getCategories,
    getCountries,
    getCurrentFMWorldUser,
    getFmFrequencies,
    getMuxList,
    getNetworks,
    getPendingNetworkInvitations,
    getPendinMuxInvitations,
    getProvinces,
    getRadioInteractiveMUXItems,
    getRadios,
    getRadioTags,
    getRadioTypes,
    getRoles,
    getStates,
    logFmWorldUser,
} from '../../resources/api-constants'
import { Notification } from '../../types/data'
import { Reducers } from '../../types/reducers'
import FmWorldAxios from '../../utility/FmWorldAxios'
import {
    setCategories,
    setCountries,
    setMuxRoles,
    setNetworkRoles,
    setNotifications,
    setProvinces,
    setRadioTags,
    setRadioTypes,
    setSelectedNetwork,
    setSingleNetwork,
    setStates,
    setTotalDab,
    setTotalFrequencies,
    setTotalInteractiveMUX,
    setTotalNetworks,
    setTotalRadios,
} from './data'
import { setLoggedUserData } from './user'

export const fetchSelectedNetwork: any = () => async (dispatch: (any: any) => void) => {
    const data = await FmWorldAxios.get(getNetworks()).then((res) => {
        return res.data.items[0]
    })
    dispatch(setSelectedNetwork(data))
}

export const fetchRadios: any = () => async (dispatch: (any: any) => void) => {
    const total = await FmWorldAxios.get(getRadios(), { params: { limit: 10 } }).then((res) => {
        return res.data.count
    })
    dispatch(setTotalRadios(total))
}

export const fetchNetworks: any = () => async (dispatch: (any: any) => void) => {
    const total = await FmWorldAxios.get(getNetworks(), { params: { limit: 3 } }).then((res) => {
        return res.data.count
    })
    if (total.length == 1) {
        dispatch(setSingleNetwork(total[0]))
    }
    dispatch(setTotalNetworks(total))
}

export const fetchFrequencies: any = () => async (dispatch: (any: any) => void) => {
    const total = await FmWorldAxios.get(getFmFrequencies(), { params: { limit: 3 } }).then((res) => {
        return res.data.count
    })
    dispatch(setTotalFrequencies(total))
}

export const fetchDab: any = () => async (dispatch: (any: any) => void) => {
    const total = await FmWorldAxios.get(getMuxList()).then((res) => {
        return res.data.count
    })
    dispatch(setTotalDab(total))
}

export const fetchInteractiveMux: any = () => async (dispatch: (any: any) => void) => {
    const total = await FmWorldAxios.get(getRadioInteractiveMUXItems()).then((res) => {
        return res.data.length
    })

    dispatch(setTotalInteractiveMUX(total))
}

export const fetchRadioTypes: any = () => async (dispatch: (any: any) => void) => {
    const data = await FmWorldAxios.get(getRadioTypes()).then((res) => {
        return res.data
    })
    dispatch(setRadioTypes(data))
}

export const fetchCountries: any = () => async (dispatch: (any: any) => void) => {
    const data = await FmWorldAxios.get(getCountries()).then((res) => {
        return res.data
    })
    dispatch(setCountries(data))
}

export const fetchNotifications: any = () => async (dispatch: (any: any) => void) => {
    const pendingNetworkInvitations: Notification[] = await FmWorldAxios.get(getPendingNetworkInvitations()).then(
        (res) => {
            return (res.data as Notification[]).map((item) => {
                return {
                    ...item,
                    type: 'NETWORK',
                }
            })
        }
    )
    const pendingMuxInvitations: Notification[] = await FmWorldAxios.get(getPendinMuxInvitations()).then((res) => {
        return (res.data as Notification[]).map((item) => {
            return {
                ...item,
                type: 'MUX',
            }
        })
    })
    dispatch(setNotifications([...pendingNetworkInvitations, ...pendingMuxInvitations]))
}

export const fetchProvinces: any = () => async (dispatch: (any: any) => void) => {
    const data = await FmWorldAxios.get(getProvinces()).then((res) => {
        return res.data
    })
    dispatch(setProvinces(data))
}

export const fetchStates: any = () => async (dispatch: (any: any) => void) => {
    const data = await FmWorldAxios.get(getStates()).then((res) => {
        return res.data.items
    })
    dispatch(setStates(data))
}

export const fetchCategories: any = () => async (dispatch: (any: any) => void) => {
    const data = await FmWorldAxios.get(getCategories()).then((res) => {
        return res.data
    })
    dispatch(setCategories(data))
}

export const fetchRadioTags: any = () => async (dispatch: (any: any) => void) => {
    const data = await FmWorldAxios.get(getRadioTags()).then((res) => {
        return res.data
    })
    dispatch(setRadioTags(data))
}

export const fetchRoles: any = () => async (dispatch: (any: any) => void) => {
    const networkRoles = await FmWorldAxios.get(getRoles(), {
        params: {
            context: 'NETWORK',
        },
    }).then((res) => res.data)

    const muxRoles = await FmWorldAxios.get(getRoles(), {
        params: {
            context: 'MUX',
        },
    }).then((res) => res.data)

    dispatch(setNetworkRoles(networkRoles))
    dispatch(setMuxRoles(muxRoles))
}

export const fetchUserData: any =
    () => async (dispatch: (any: any) => void, getState: () => Reducers) => {
        const userAccessToken = getState().user.user?.authToken
        const data = await getCurrentFMWorldUser(userAccessToken || '')
        dispatch(setLoggedUserData(data))
    }
