import React, { useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import { useSelector } from 'react-redux'
import { PlayerStatus } from '../../types/player'
import { ReducerPlayer, Reducers } from '../../types/reducers'
import './Player.sass'
import './AnimatedText.css'
import Icon from '../Icon'
import { useDispatch } from 'react-redux'
import { setPlayerStatus } from '../../store/actions/player'

const AudioPlayer: React.FC = () => {
    const player = useSelector<Reducers, ReducerPlayer>((state) => state.player)
    const dispatch = useDispatch()
    const playerRef = useRef<any>(null)
    const [error, setError] = useState('')

    useEffect(() => {
        if (player.mediaPlayer?.type === 'audio') {
            setError('')
            dispatch(setPlayerStatus(PlayerStatus.PLAY))
        }
    }, [player.mediaPlayer])

    if (player.mediaPlayer && player.mediaPlayer.type === 'audio') {
        return (
            <>
                <div className="reactPlayer">
                    {player.status === PlayerStatus.PLAY ? (
                        <ReactPlayer
                            className="player"
                            ref={playerRef}
                            url={player.mediaPlayer.url}
                            playing={player.status === PlayerStatus.PLAY}
                            onEnded={() => {
                                dispatch(setPlayerStatus(PlayerStatus.PAUSE))
                            }}
                            onError={() => {
                                setError('errore di riproduzione')
                                dispatch(setPlayerStatus(PlayerStatus.PAUSE))
                            }}
                        />
                    ) : null}
                </div>
                <div className="audioPlayer">
                    <div className="audioPlayer-meta">
                        <div className="bar">
                            <p className="bar_content">{player.mediaPlayer.name}</p>
                        </div>
                    </div>
                    <div className="audioPlayer-controls">
                        <Icon
                            name={player.status === PlayerStatus.PLAY ? 'pause' : 'play'}
                            size={18}
                            color="#fff"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                if (error) {
                                    return
                                }
                                if (player.status === PlayerStatus.PLAY) {
                                    dispatch(setPlayerStatus(PlayerStatus.PAUSE))
                                } else {
                                    setError('')
                                    dispatch(setPlayerStatus(PlayerStatus.PLAY))
                                }
                            }}
                        />
                    </div>
                    {error ? <p className="u-normal-text u-bold-text u-error-text">{error}</p> : null}
                </div>
            </>
        )
    }
    return null
}

export default AudioPlayer
