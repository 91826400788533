import React, { useCallback, useEffect, useMemo } from 'react'
import { useQueryParamsFromURL } from '../../resources/hooks/common'
import CustomAxios from '../../utility/FmWorldAxios'

interface ConfirmAccountRouteParams {
    email?: string
    token?: string
}

const ConfirmAccountPage: React.FC = () => {
    const routeParams = useQueryParamsFromURL<ConfirmAccountRouteParams>({ email: '', token: '' })

    const routeParamsAreValid = useMemo((): boolean => {
        console.log(routeParams)
        return Object.values(routeParams).every((value: string) => value !== '')
    }, [routeParams])

    const safelyCallConfirmApi = useCallback(async () => {
        try {
            if (routeParamsAreValid) {
                await CustomAxios.post(`auth/confirm-email?email=${routeParams.email}&token=${routeParams.token}`).then(
                    () => {
                        return
                    }
                )
            } else {
                throw 'Invalid params in route.'
            }
        } catch (error) {
            console.error(error)
        }
    }, [routeParamsAreValid, routeParams])

    useEffect(() => {
        const timer = setTimeout(async () => {
            await safelyCallConfirmApi()
        }, 50)
        return () => {
            clearTimeout(timer)
        }
    }, [safelyCallConfirmApi])

    return <></>
}

export default ConfirmAccountPage
