import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../../components/Button/Button'
import Loader from '../../../components/Loader/Loader'
import ActionsModal from '../../../components/Modals/ActionsModal'
import DeleteModal from '../../../components/Modals/DeleteModal'
import LoadingModal from '../../../components/Modals/LoadingModal'
import TextInput from '../../../components/TextInput/TextInput'
import { getMuxUsers, getMuxUsersDetail } from '../../../resources/api-constants'
import { COLORS } from '../../../resources/colors'
import { UserMux } from '../../../types/data'
import FmWorldAxios from '../../../utility/FmWorldAxios'
import { Grants } from '../../../utility/Grants'
import UserMuxModal from '../../../components/Modals/UserMuxModal'
import UsersMuxTable from '../../../components/Tables/UsersMuxTable'

interface Props {
    idMux: number
    permissions: Grants[]
}

const DabUsersTab: React.FC<Props> = (props) => {
    const [search, setSearch] = useState('')
    const [error, setError] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)
    const [isCreating, setIsCreating] = useState(false)
    const [selectUserForActions, setSelectUserForActions] = useState<UserMux | null>(null)
    const [editUser, setEditUser] = useState<UserMux | null>(null)
    const [addUserModal, setAddUserModal] = useState(false)
    const [deletedUser, setDeletedUser] = useState<UserMux | null>(null)
    const [users, setUsers] = useState<UserMux[]>([])
    const [filteredUsers, setFilteredUsers] = useState<UserMux[]>([])
    const { t } = useTranslation()

    const fetchUsers = async () => {
        setError('')
        setIsLoading(true)
        try {
            const usersFetch = await FmWorldAxios.get(getMuxUsers(props.idMux)).then((res) => {
                return res.data
            })
            setEditUser(null)
            setUsers(usersFetch)
        } catch (error: any) {
            console.log('ERROR', error)
            setError(error.toString())
        }
        setIsLoading(false)
    }

    useEffect(() => {
        if (props.idMux) {
            void fetchUsers()
        }
    }, [props.idMux])

    useEffect(() => {
        if (search) {
            const filtered = users.filter(
                (u) =>
                    u.userInfo.firstName.toLowerCase().includes(search.toLowerCase()) ||
                    u.userInfo.lastName.toLowerCase().includes(search.toLowerCase()) ||
                    u.userInfo.email.toLowerCase().includes(search.toLowerCase())
            )
            setFilteredUsers(filtered)
        } else {
            setFilteredUsers(users)
        }
    }, [search, users])

    const actionsModal = () => {
        const actions = []
        if (props.permissions.includes(Grants.EDIT_NETWORK_USERS)) {
            actions.push({
                text: t('networks.edit_user'),
                color: COLORS.purple,
                onClick: () => {
                    setEditUser(selectUserForActions)
                    setSelectUserForActions(null)
                },
            })
        }
        if (props.permissions.includes(Grants.DELETE_NETWORK_USERS)) {
            actions.push({
                text: t('networks.delete_user'),
                color: COLORS.redDelete,
                onClick: () => {
                    setDeletedUser(selectUserForActions)
                    setSelectUserForActions(null)
                },
            })
        }

        return actions
    }

    const handleEditUser = async (idRole: number) => {
        if (editUser) {
            setError('')
            setIsUpdating(true)
            try {
                const body = {
                    idRole,
                }
                await FmWorldAxios.put(getMuxUsersDetail(props.idMux, editUser.id), body)
                await fetchUsers()
                setEditUser(null)
                setAddUserModal(false)
            } catch (error: any) {
                setError(error.toString())
            }
            setIsUpdating(false)
        }
    }

    const handleCreateUser = async (idRole: number, email: string) => {
        setError('')
        setIsCreating(true)
        try {
            const body = {
                idRole,
                email,
            }
            await FmWorldAxios.post(getMuxUsers(props.idMux), body)
            setEditUser(null)
            setAddUserModal(false)
            await fetchUsers()
        } catch (error: any) {
            setError(error.toString())
        }
        setIsCreating(false)
    }

    const handleDeleteUser = async () => {
        if (deletedUser) {
            setError('')
            setIsLoading(true)
            try {
                await FmWorldAxios.delete(getMuxUsersDetail(props.idMux, deletedUser.id))
                await fetchUsers()
                setDeletedUser(null)
            } catch (error: any) {
                setError(error.toString())
            }
            setIsLoading(false)
        }
    }

    if (isLoading) {
        return (
            <div className="loadingGrid">
                <Loader />
            </div>
        )
    }

    return (
        <div>
            <div className="titleRow">
                <div style={{ flex: 1 }}>
                    <h2 className="title">{t('networks.users')}</h2>
                    <h3 className="subtitle" style={{ fontWeight: 400 }}>
                        {t('networks.manage_users_network')}
                    </h3>
                    {error && <p className="u-normal-text u-bold-text u-error-text">{error}</p>}
                </div>
                <div style={{ display: 'flex', flex: 2, gap: 10, alignItems: 'center', justifyContent: 'flex-end' }}>
                    <div style={{ flex: 1 }} />
                    <div style={{ flex: 2 }}>
                        <TextInput
                            value={search}
                            placeholder={t('general.search')}
                            icon="search"
                            iconPosition="right"
                            onTextChange={(v) => setSearch(v)}
                        />
                    </div>
                    <div style={{ flex: 1 }}>
                        <Button
                            label={t('general.refresh')}
                            onPress={() => {
                                setSearch('')
                            }}
                            type="submit"
                            background="rgba(100,38,119,0.14)"
                            border={`2px solid ${COLORS.purple}`}
                            color={COLORS.purple}
                        />
                    </div>
                    {props.permissions.includes(Grants.ADD_NETWORK_USERS) ? (
                        <div style={{ flex: 1 }}>
                            <Button
                                label={t('general.add')}
                                onPress={() => {
                                    setAddUserModal(true)
                                }}
                                type="submit"
                            />
                        </div>
                    ) : null}
                </div>
            </div>
            <div>
                <UsersMuxTable
                    showActions={actionsModal().length > 0}
                    data={filteredUsers}
                    selectUser={(u) => setSelectUserForActions(u)}
                />
            </div>
            <ActionsModal
                isVisible={selectUserForActions !== null}
                title={t('general.what_to_do')}
                onClose={() => setSelectUserForActions(null)}
                actions={actionsModal()}
            />
            <UserMuxModal
                isVisible={addUserModal || editUser !== null}
                userData={editUser}
                onClose={() => {
                    setEditUser(null)
                    setAddUserModal(false)
                }}
                idMux={props.idMux}
                onSave={(role, email) => {
                    if (email) {
                        void handleCreateUser(role, email)
                    } else {
                        void handleEditUser(role)
                    }
                }}
            />
            <DeleteModal
                onDelete={() => {
                    void handleDeleteUser()
                }}
                title={`${t('networks.delete_this_user')} ${
                    deletedUser?.userInfo.firstName ? deletedUser?.userInfo.firstName : deletedUser?.userInfo.email
                } ${deletedUser?.userInfo.lastName ? deletedUser?.userInfo.lastName : ''}`}
                text={`${t('networks.want_delete_user')} ${
                    deletedUser?.userInfo.firstName ? deletedUser?.userInfo.firstName : deletedUser?.userInfo.email
                } ${deletedUser?.userInfo.lastName ? deletedUser?.userInfo.lastName : ''}?`}
                onClose={() => {
                    setDeletedUser(null)
                }}
                isVisible={deletedUser !== null}
            />
            <LoadingModal
                isVisible={isCreating || isUpdating}
                text={isCreating ? t('loadings.creating_user') : t('loadings.inviting_user')}
            />
        </div>
    )
}

export default DabUsersTab
