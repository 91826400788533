/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-key */
import { useState } from 'react'
import PageSidebarContainer from '../../../components/PageSidebarContainer'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Reducers, ReducerUser } from '../../../types/reducers'
import { useLocation } from 'react-router-dom'
import CreateRadioComponent from './CreateRadioComponent'

const CreateRadioTab: React.FC = () => {
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const location = useLocation()

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isCreating, setIsCreating] = useState<boolean>()
    const { t } = useTranslation()
    const [error, setError] = useState<string>('')
    const idNetwork = (location.state as any).idNetwork

    // const sendApprovation = async () => {
    //     try {
    //         await FmWorldAxios.post(`/networks/${idNetwork}/radios`, { name: approvationText })
    //         setNameFound('')
    //     } catch (error: any) {
    //         setError(error.toString())
    //     }
    // }

    return (
        <PageSidebarContainer>
            <div style={{ flex: 1 }}>
                <div className="titleRow">
                    <div style={{ flex: 1 }}>
                        <h3 className="title" style={{ fontWeight: 400 }}>
                            {t('radios.create_radio')}
                        </h3>
                        <div>
                            <CreateRadioComponent idNetwork={idNetwork} permissions={user.user?.grants ?? []} />
                        </div>
                    </div>
                </div>
            </div>
        </PageSidebarContainer>
    )
}

export default CreateRadioTab
