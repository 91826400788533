import React, { useState } from 'react'
import Popover from '@mui/material/Popover'
import { Languages, LanguagesArray } from '../../../utility/Languages'
import './LanguagePopover.sass'
import { useSelector } from 'react-redux'
import { Reducers, ReducerGraphic, ReducerUser } from '../../../types/reducers'
import { COLORS } from '../../../resources/colors'
import LoadingModal from '../../Modals/LoadingModal'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { fetchUserData } from '../../../store/actions/thunk_actions'
import { setCurrentLanguage } from '../../../store/actions/graphic'

interface Props {
    anchorElem: HTMLImageElement | null
    onClose: () => void
}

const LanguagePopover: React.FC<Props> = (props) => {
    const open = Boolean(props.anchorElem)
    const [isUpdating, setIsUpdating] = useState(false)
    const { t } = useTranslation()
    const graphic = useSelector<Reducers, ReducerGraphic>((state) => state.graphic)
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const dispatch = useDispatch()

    const handleChangeLanguage = async (lang: Languages) => {
        if (user.user) {
            setIsUpdating(true)
            try {
                const userToEdit = {
                    ...user,
                    language: lang,
                }
                const formData = new FormData()
                Object.keys(userToEdit).map((key) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    const currentField = userToEdit[key as any]
                    formData.append(key, Array.isArray(currentField) ? JSON.stringify(currentField) : currentField)
                    return
                })
                await dispatch(fetchUserData(user.user.id))
                props.onClose()
            } catch (error) {
                console.log(error)
            }
            setIsUpdating(false)
        }
    }

    return (
        <Popover
            open={open}
            anchorEl={props.anchorElem}
            onClose={() => props.onClose()}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            className="popoverOverflow"
        >
            <div className="languagePopover">
                {LanguagesArray.map((l) => {
                    return (
                        <div className="languageContainer" key={l.id}>
                            <img
                                src={l.flag}
                                alt={l.value}
                                onClick={() => {
                                    if (user.user && user.user && l.id !== user.user.language) {
                                        void handleChangeLanguage(l.id)
                                    } else {
                                        dispatch(setCurrentLanguage(l.id))
                                    }
                                }}
                            />
                            <span
                                style={{
                                    color: graphic.currentLanguage === l.id ? COLORS.purple : '',
                                    fontWeight: graphic.currentLanguage === l.id ? 'bold' : 'normal',
                                }}
                                onClick={() => {
                                    if (user.user && user.user && l.id !== user.user.language) {
                                        void handleChangeLanguage(l.id)
                                    } else {
                                        dispatch(setCurrentLanguage(l.id))
                                    }
                                }}
                            >
                                {l.value}
                            </span>
                        </div>
                    )
                })}
            </div>
            <LoadingModal isVisible={isUpdating} text={t('loadings.changing_language')} />
        </Popover>
    )
}

export default LanguagePopover
