import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Button from '../../components/Button/Button'
import CardContainer from '../../components/CardContainer/CardContainer'
import RadioFilter, { RadioFilterObject } from '../../components/Filters/RadioFilter'
import RadioGrid from '../../components/Grids/RadioGrid'
import PageSidebarContainer from '../../components/PageSidebarContainer'
import TextInput from '../../components/TextInput/TextInput'
import { COLORS } from '../../resources/colors'
import { ROUTES } from '../../resources/routes-constants'
import { setPageHeader } from '../../store/actions/graphic'
import { Reducers, ReducerGraphic, ReducerUser } from '../../types/reducers'
import { Grants } from '../../utility/Grants'
import { usePrevious, useTimeout } from '../../utility/hooks/common'

const initialRadioFilter = {
    query: '',
    countries: [],
    categories: [],
    types: [],
    videoOnly: false,
    returnEmptyStreams: true,
    returnOfflineStreams: true,
    returnVerifiedStreams: false,
    networks: [],
}

const RadiosListPage: React.FC = () => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const radioGridRef = useRef(null)
    const navigate = useNavigate()
    const graphic = useSelector<Reducers, ReducerGraphic>((state) => state.graphic)
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const [filter, setFilter] = useState<RadioFilterObject>(
        graphic.lastRadioSearch
            ? {
                  query: graphic.lastRadioSearch.query,
                  countries: graphic.lastRadioSearch.countries,
                  categories: graphic.lastRadioSearch.categories,
                  types: graphic.lastRadioSearch.types,
                  videoOnly: graphic.lastRadioSearch.videoOnly,
                  returnEmptyStreams: graphic.lastRadioSearch.returnEmptyStreams,
                  returnOfflineStreams: graphic.lastRadioSearch.returnOfflineStreams,
                  networks: graphic.lastRadioSearch.networks,
                  returnVerifiedStreams: graphic.lastRadioSearch.returnVerifiedStreams,
              }
            : initialRadioFilter
    )
    const [searchInput, setSearchInput] = useState<string>(
        graphic.lastRadioSearch ? graphic.lastRadioSearch.query : initialRadioFilter.query
    )
    const [currentTimeout, setCurrentTimeout] = useState<null | number>(null)
    const previousSearchInput = usePrevious(searchInput)
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const handleFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    useEffect(() => {
        dispatch(
            setPageHeader({
                parent: null,
                name: t('headers.radios'),
            })
        )
    }, [])

    useEffect(() => {
        if (previousSearchInput !== undefined) {
            if (currentTimeout) {
                setCurrentTimeout(null)
            }
            setCurrentTimeout(1000)
        }
    }, [searchInput])

    useTimeout(() => {
        setFilter({
            ...filter,
            query: searchInput,
        })
        setCurrentTimeout(null)
    }, currentTimeout)

    return (
        <PageSidebarContainer>
            <CardContainer>
                <div className="titleRow">
                    <div style={{ flex: 1 }}>
                        <h2 className="title">{t('radios.your_radios')}</h2>
                        <h3 className="subtitle" style={{ fontWeight: 400 }}>
                            {t('radios.manage_radios')}
                        </h3>
                    </div>
                    <div
                        style={{ display: 'flex', flex: 2, gap: 10, alignItems: 'center', justifyContent: 'flex-end' }}
                    >
                        <div style={{ flex: 2 }}>
                            <TextInput
                                value={searchInput}
                                placeholder={t('general.search')}
                                icon="search"
                                iconPosition="right"
                                onTextChange={(v) => {
                                    setSearchInput(v)
                                }}
                            />
                        </div>
                        <div style={{ flex: 1 }}>
                            <Button
                                label={t('general.filter')}
                                onPress={(event) => {
                                    handleFilter(event)
                                }}
                                type="submit"
                                background="#fff"
                                border={`2px solid ${
                                    JSON.stringify(filter) !== JSON.stringify(initialRadioFilter)
                                        ? COLORS.green
                                        : COLORS.purple
                                }`}
                                color={
                                    JSON.stringify(filter) !== JSON.stringify(initialRadioFilter)
                                        ? COLORS.green
                                        : COLORS.purple
                                }
                            />
                            <RadioFilter
                                filter={filter}
                                anchorEl={anchorEl}
                                onClose={() => setAnchorEl(null)}
                                onReset={() => {
                                    setSearchInput('')
                                    setFilter(initialRadioFilter)
                                }}
                                onUpdate={(f) => setFilter(f)}
                            />
                        </div>
                        <div style={{ flex: 1 }}>
                            <Button
                                label={t('general.refresh')}
                                onPress={() => {
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    radioGridRef.current?.reloadData()
                                }}
                                type="submit"
                                background="rgba(100,38,119,0.14)"
                                border={`2px solid ${COLORS.purple}`}
                                color={COLORS.purple}
                            />
                        </div>
                        {user.user?.grants.includes(Grants.CREATE_RADIO) ? (
                            <div style={{ flex: 1 }}>
                                <Button
                                    label={t('general.add')}
                                    onPress={() => {
                                        navigate(`${ROUTES.RADIO_DETAIL_ROUTE}new`, {
                                            state: { name: t('radios.new_radio') },
                                        })
                                    }}
                                    type="submit"
                                />
                            </div>
                        ) : null}
                    </div>
                </div>
                <RadioGrid filter={filter} ref={radioGridRef} />
            </CardContainer>
        </PageSidebarContainer>
    )
}

export default RadiosListPage
