import React from 'react'
import './TextInput.sass'
import Icon from '../Icon'

interface Props {
    value: string
    placeholder?: string
    type?: 'text' | 'password' | 'number'
    width?: number | string
    disabled?: boolean
    flex?: number
    label?: string
    autocomplete?: boolean
    icon?: string
    iconPosition?: 'left' | 'right'
    interactiveIcon?: boolean
    alignCenter?: boolean
    bordered?: boolean
    background?: string
    borderColor?: string
    onTextChange: (newValue: string) => void
    onIconClick?: () => void
    isHidden?: boolean
}

const TextInput: React.FC<Props> = (props) => {
    if (props.isHidden) return null
    return (
        <>
            <div
                className="c-text-input"
                style={{
                    width: props.width ? props.width : '',
                    flex: props.flex ? props.flex : 1,
                    maxWidth: props.width ? props.width : '',
                }}
            >
                {props.label && <span className="o-label">{props.label}</span>}
                <div
                    className={`o-input-container ${
                        props.icon && props.iconPosition
                            ? props.iconPosition === 'left'
                                ? 'o-input-container__left-icon'
                                : 'o-input-container__right-icon'
                            : 'o-input-container__right-icon'
                    }`}
                >
                    {props.icon && (
                        <div className="o-input-icon">
                            <Icon name={props.icon} size={16} color="#ccc" />
                        </div>
                    )}
                    <input
                        aria-label={props.label ? props.label : props.placeholder ? props.placeholder : ''}
                        className={`o-input ${
                            props.icon && props.iconPosition
                                ? props.iconPosition === 'left'
                                    ? 'o-input-box__left-spaced'
                                    : 'o-input-box__right-spaced'
                                : ''
                        }`}
                        type={props.type ? props.type : 'text'}
                        placeholder={props.placeholder}
                        value={props.value}
                        disabled={props.disabled}
                        onChange={(e) => props.onTextChange(e.target.value)}
                        style={{
                            background: props.disabled ? '#efefef' : props.background ? props.background : '#fff',
                            boxShadow: props.bordered ? 'none' : '0px 3px 7px 0px rgba(0, 0, 0, .08)',
                            border: props.bordered ? '1px solid rgba(0,0,0,0.14)' : '0',
                            borderColor: props.borderColor ? props.borderColor : 'rgba(0,0,0,0.14)',
                            textAlign: props.alignCenter ? 'center' : 'left',
                            opacity: props.disabled ? 0.6 : 1,
                        }}
                    />
                </div>
            </div>
        </>
    )
}

export default TextInput
