import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Button from '../../../components/Button/Button'
import Loader from '../../../components/Loader/Loader'
import ActionsModal from '../../../components/Modals/ActionsModal'
import DeleteModal from '../../../components/Modals/DeleteModal'
import PlayersTable from '../../../components/Tables/PlayersTable'
import TextInput from '../../../components/TextInput/TextInput'
import { getNetworksPlayers, getPlayerDetail } from '../../../resources/api-constants'
import { COLORS } from '../../../resources/colors'
import { ROUTES } from '../../../resources/routes-constants'
import { PlayerNetwork } from '../../../types/data'
import FmWorldAxios from '../../../utility/FmWorldAxios'
import { Grants } from '../../../utility/Grants'
import { useSelector } from 'react-redux'
import { Reducers, ReducerData } from '../../../types/reducers'

interface Props {
    permissions: Grants[]
}

const PlayersTab: React.FC<Props> = (props) => {
    const [search, setSearch] = useState('')
    const [error, setError] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [selectPlayerForActions, setSelectPlayerForActions] = useState<PlayerNetwork | null>(null)
    const [deletedPlayer, setDeletedPlayer] = useState<PlayerNetwork | null>(null)
    const [players, setPlayers] = useState<PlayerNetwork[]>([])
    const navigate = useNavigate()
    const { t } = useTranslation()

    const data = useSelector<Reducers, ReducerData>((state) => state.data)
    const selectedNetwork = data.selectedNetwork

    const fetchUsers = async () => {
        setError('')
        setIsLoading(true)
        try {
            if (!selectedNetwork) {
                throw new Error('No network selected')
            }
            const playersFetch = await FmWorldAxios.get(getNetworksPlayers(selectedNetwork.id)).then((res) => {
                return res.data.items
            })
            setPlayers(playersFetch)
        } catch (error: any) {
            console.log('ERROR', error)
            setError(error.toString())
        }
        setIsLoading(false)
    }

    useEffect(() => {
        if (!selectedNetwork) {
            throw new Error('No network selected')
        }
        if (selectedNetwork.id) {
            void fetchUsers()
        }
    }, [selectedNetwork])

    const actionsModal = () => {
        const actions = [
            {
                text: t('networks.view_player'),
                color: COLORS.blueGrey,
                onClick: () => navigate(`${ROUTES.PLAYER_DETAIL_ROUTE}${selectPlayerForActions?.id}`),
            },
        ]
        if (props.permissions.includes(Grants.DELETE_NETWORK_USERS)) {
            actions.push({
                text: t('networks.delete_player'),
                color: COLORS.redDelete,
                onClick: () => {
                    setDeletedPlayer(selectPlayerForActions)
                    setSelectPlayerForActions(null)
                },
            })
        }

        return actions
    }

    const handleDeletePlayer = async () => {
        if (deletedPlayer) {
            setError('')
            setIsLoading(true)
            try {
                await FmWorldAxios.delete(getPlayerDetail(deletedPlayer.id))
                await fetchUsers()
                setDeletedPlayer(null)
            } catch (error: any) {
                setError(error.toString())
            }
            setIsLoading(false)
        }
    }

    if (isLoading) {
        return (
            <div className="loadingGrid">
                <Loader />
            </div>
        )
    }

    return (
        <div>
            <div className="titleRow">
                <div style={{ flex: 1 }}>
                    <h2 className="title">{t('networks.players')}</h2>
                    <h3 className="subtitle" style={{ fontWeight: 400 }}>
                        {t('networks.manage_player_network')}
                    </h3>
                    {error && <p className="u-normal-text u-bold-text u-error-text">{error}</p>}
                </div>
                <div style={{ display: 'flex', flex: 2, gap: 10, alignItems: 'center', justifyContent: 'flex-end' }}>
                    <div style={{ flex: 1 }} />
                    <div style={{ flex: 2 }}>
                        <TextInput
                            value={search}
                            placeholder={t('general.search')}
                            icon="search"
                            iconPosition="right"
                            onTextChange={(v) => setSearch(v)}
                        />
                    </div>
                    <div style={{ flex: 1 }}>
                        <Button
                            label={t('general.refresh')}
                            onPress={() => {
                                setSearch('')
                            }}
                            type="submit"
                            background="rgba(100,38,119,0.14)"
                            border={`2px solid ${COLORS.purple}`}
                            color={COLORS.purple}
                        />
                    </div>
                    {props.permissions.includes(Grants.ADD_NETWORK_USERS) ? (
                        <div style={{ flex: 1 }}>
                            <Button
                                label={t('general.add')}
                                onPress={() => {
                                    navigate(`${ROUTES.PLAYER_DETAIL_ROUTE}new`, {
                                        state: {
                                            name: t('networks.new_player'),
                                            idNetwork: selectedNetwork?.id,
                                            parent: { name: selectedNetwork?.name, id: selectedNetwork?.id },
                                        },
                                    })
                                }}
                                type="submit"
                            />
                        </div>
                    ) : null}
                </div>
            </div>
            <div>
                <PlayersTable
                    data={players}
                    idNetwork={selectedNetwork?.id || 0}
                    nameNetwork={selectedNetwork?.name || ''}
                    selectNetwork={(p) => setSelectPlayerForActions(p)}
                />
            </div>
            <ActionsModal
                isVisible={selectPlayerForActions !== null}
                title="Cosa vuoi fare?"
                onClose={() => setSelectPlayerForActions(null)}
                actions={actionsModal()}
            />
            <DeleteModal
                onDelete={() => {
                    void handleDeletePlayer()
                }}
                title={`Eliminare il player ${deletedPlayer?.name}`}
                text={`Vuoi eliminare il player ${deletedPlayer?.name}?`}
                onClose={() => {
                    setDeletedPlayer(null)
                }}
                isVisible={deletedPlayer !== null}
            />
        </div>
    )
}

export default PlayersTab
