import React from 'react'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import itLocale from '@fullcalendar/core/locales/it'
import ShowScheduleItem from './Items/ShowScheduleItem'
import { EventSchedule } from '../../pages/RadiosPage/tabs/ShowsScheduleTab'
import { ShowSchedule } from '../../types/data'

interface Props {
    events: EventSchedule[]
    timezone: string
    onPress: (e: ShowSchedule) => void
}

const ShowScheduleTable: React.FC<Props> = (props) => {
    return (
        <div style={{ width: '100%', marginTop: 20 }}>
            <FullCalendar
                timeZone={props.timezone}
                plugins={[timeGridPlugin]}
                initialView="timeGridWeek"
                weekends
                events={props.events.flat()}
                allDaySlot={false}
                firstDay={1}
                eventClick={(arg) => {
                    props.onPress(arg.event._def.extendedProps.event)
                }}
                locale={itLocale}
                nowIndicator
                dayHeaderFormat={{
                    weekday: 'long',
                }}
                headerToolbar={{
                    right: '',
                }}
                eventContent={(arg) => {
                    return <ShowScheduleItem event={arg.event._def.extendedProps.event} />
                }}
            />
        </div>
    )
}

export default ShowScheduleTable
