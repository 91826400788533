import React, { useEffect, useState } from 'react'
import './Modals.sass'
import Modal from '@mui/material/Modal'
import { PendingRadio, Radio } from '../../types/data'
import Button from '../Button/Button'
import { COLORS } from '../../resources/colors'
import { useTranslation } from 'react-i18next'
import TextAreaInput from '../TextAreaInput/TextAreaInput'
import AsyncSelectInput from '../SelectInput/AsyncSelectInput'
import { Option } from '../SelectInput/SelectInput'
import FmWorldAxios from '../../utility/FmWorldAxios'
import { getRadiosName } from '../../resources/api-constants'
import TextInput from '../TextInput/TextInput'

interface Props {
    isVisible: boolean
    radio: PendingRadio | null
    onClose: () => void
    onSave: () => void
}

enum RadioValidationChoices {
    REJECT = 'REJECT',
    ASSOCIATE = 'ASSOCIATE',
    CREATE = 'CREATE',
}

const RadioPendingEditModal: React.FC<Props> = (props) => {
    const [radioToEdit, setRadioToEdit] = useState<PendingRadio | null>(props.radio)
    const [currentStep, setCurrentStep] = useState(0)
    const [currentChoice, setCurrentChoice] = useState<RadioValidationChoices | null>(null)
    const [selectedRadio, setSelectedRadio] = useState<Option | null>(null)
    const [rejectedReason, setRejectedReason] = useState('')
    const [selectedRadioId, setSelectedRadioId] = useState('')
    const [requestIsLoading, setRequestIsLoading] = useState(false)
    const [promoCode, setPromoCode] = useState('')
    const { t } = useTranslation()

    useEffect(() => {
        if (props.isVisible) {
            setRadioToEdit(props.radio)
        }
    }, [props.isVisible])

    const saveChoiceAndProceed = (choice: string) => {
        setCurrentChoice(choice as RadioValidationChoices)
        setCurrentStep(1)
    }

    const fetchRadioByName = async (value: string, limit: number) => {
        setRequestIsLoading(true)
        try {
            const radio = await FmWorldAxios.get(getRadiosName(value, limit))
            setRequestIsLoading(false)
            return radio.data.items
        } catch (error: any) {
            console.error('ERROR', error)
        }
        setRequestIsLoading(false)
    }

    const associateRadio = async () => {
        setRequestIsLoading(true)
        try {
            if (selectedRadioId === '') {
                return
            }
            const data: any = {}
            if (promoCode !== '') data.promotion_code = promoCode
            await FmWorldAxios.post(`/new-radio-requests/${props.radio?.id}/radios/${selectedRadioId}`, data)
            props.onSave()
        } catch (error) {
            console.error('ERROR', error)
        }
        setRequestIsLoading(false)
    }

    const createRadio = async () => {
        setRequestIsLoading(true)
        try {
            const data: any = {}
            if (promoCode !== '') data.promotion_code = promoCode
            await FmWorldAxios.post(`/new-radio-requests/${props.radio?.id}/radios`, data)
            props.onSave()
        } catch (error) {
            console.error('ERROR', error)
        }
        setRequestIsLoading(false)
    }

    const rejectRadio = async () => {
        setRequestIsLoading(true)
        try {
            await FmWorldAxios.put(`/new-radio-requests/${props.radio?.id}`, {
                status: 'REJECTED',
                reject_notes: rejectedReason,
            })
            props.onSave()
        } catch (error) {
            console.error('ERROR', error)
        }
        setRequestIsLoading(false)
    }

    const renderCurrentChoiceElements = () => {
        switch (currentChoice) {
            case RadioValidationChoices.REJECT:
                return (
                    <div>
                        <TextAreaInput
                            placeholder="Spiega all'utente perché stai rigettando la richiesta. (Facoltativo)"
                            value={rejectedReason}
                            onTextChange={(text) => {
                                setRejectedReason(text)
                            }}
                            bordered
                        />
                    </div>
                )
            case RadioValidationChoices.ASSOCIATE:
                return (
                    <div>
                        <div>Ricerca la radio tra quelle già presenti in FM-world</div>
                        <div style={{ height: 16 }} />
                        {!radioToEdit?.hasPromotionCode ? (
                            <TextInput
                                value={promoCode}
                                label={t('general.promo_code')}
                                onTextChange={(value) => setPromoCode(value)}
                            />
                        ) : null}
                        <div style={{ height: 16 }} />
                        <AsyncSelectInput
                            label={t('radios.radio')}
                            value={selectedRadio}
                            placeholder={t('placeholders.write_to_select_radio')}
                            fetchOptions={async (input: string) => {
                                if (input.length > 2) {
                                    const radios = await fetchRadioByName(input, 10)
                                    return radios.map((c: Radio) => {
                                        return {
                                            id: c.id,
                                            value: c.name,
                                            label: c.name,
                                        }
                                    })
                                }
                                return []
                            }}
                            onChange={(value) => {
                                const v = value as Option
                                setSelectedRadioId(v.id.toString())
                                setSelectedRadio(v)
                            }}
                        />
                    </div>
                )
            case RadioValidationChoices.CREATE:
                return (
                    <div>
                        {!radioToEdit?.hasPromotionCode ? (
                            <TextInput
                                value={promoCode}
                                label={t('general.promo_code')}
                                onTextChange={(value) => setPromoCode(value)}
                            />
                        ) : null}
                        <div style={{ height: 16 }} />
                        <span>Cliccando su &apos;Conferma&apos; si procederà alla creazione di questa radio.</span>
                    </div>
                )
            default:
                return null
        }
    }

    const renderCurrentStepElements = () => {
        switch (currentStep) {
            case 0:
                return (
                    <div className="options-grid">
                        <div
                            className="options-grid__button"
                            onClick={() => saveChoiceAndProceed(RadioValidationChoices.ASSOCIATE)}
                        >
                            Associa a radio già esistente
                        </div>
                        <div
                            className="options-grid__button"
                            onClick={() => saveChoiceAndProceed(RadioValidationChoices.CREATE)}
                        >
                            Approva e crea nuova radio
                        </div>
                        <div
                            className="options-grid__button red-button"
                            onClick={() => saveChoiceAndProceed(RadioValidationChoices.REJECT)}
                        >
                            Rifiuta
                        </div>
                    </div>
                )
            case 1:
                return (
                    <div>
                        <div>{renderCurrentChoiceElements()}</div>
                        <div className="modalBox-buttonsRow">
                            <div style={{ flex: '1 1 100%' }} />
                            <Button
                                label={t('general.cancel')}
                                disabled={requestIsLoading}
                                border={'1px solid rgba(0,0,0,0.07)'}
                                onPress={() => props.onClose()}
                                type="secondary"
                                color={COLORS.darkGrey}
                                background="#fff"
                            />
                            <Button
                                disabled={
                                    requestIsLoading ||
                                    (currentChoice === RadioValidationChoices.ASSOCIATE && selectedRadioId === '')
                                }
                                label="Conferma"
                                onPress={() => {
                                    if (currentChoice === RadioValidationChoices.REJECT) {
                                        void rejectRadio()
                                    }
                                    if (currentChoice === RadioValidationChoices.ASSOCIATE) {
                                        void associateRadio()
                                    }
                                    if (currentChoice === RadioValidationChoices.CREATE) {
                                        void createRadio()
                                    }
                                }}
                                type="submit"
                                color="#fff"
                                background={COLORS.purple}
                            />
                        </div>
                    </div>
                )

            default:
                return null
        }
    }

    return (
        <Modal
            open={props.isVisible}
            onClose={() => props.onClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className="modalBox actionBox">
                <div style={{ zIndex: 0, position: 'relative' }}>
                    <h2 className="modalBox-title">Valutazione richiesta</h2>
                    <span>Validazione radio {radioToEdit?.name}</span>
                    <div style={{ height: 16 }} />
                    {renderCurrentStepElements()}
                </div>
            </div>
        </Modal>
    )
}

export default RadioPendingEditModal
