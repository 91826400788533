import React, { useMemo, useState } from 'react'
import { Position } from '../../types/data'
import './AppPreview.sass'
import Grid, { RadioItem } from './AppGrid/AppGrid'
import { converterToPagerItem } from '../../utility/functions'
import { CarouselProvider, Slider, Slide, DotGroup } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import CustomSliderDetector from '../CustomSliderDetector'
import { IPHONE_BACKGROUND } from '../../resources/constants'
import { useTranslation } from 'react-i18next'

interface Props {
    positions: Position[]
    isFixed?: boolean
}

const AppPreview: React.FC<Props> = (props) => {
    const numColumns = 4
    const numRows = 5
    const [totalPages, setTotalPages] = useState(0)
    const { t } = useTranslation()

    const creatWeightTable2 = (array: RadioItem[], col: number, row: number) => {
        const nCol = col
        const nRow = row

        const mapData = []
        const maxRowWeight = nCol
        let lastIndex = 0

        for (let ir = 0; ir < nRow; ir++) {
            const arrayCol = []
            let rowWeight = 0

            for (let ic = 0; ic < nCol; ic++) {
                // const index = ic + nCol * ir
                // alcuni valori sono null allora li setto a Weight 1
                if (array[lastIndex] && array[lastIndex].weight && array[lastIndex].weight > maxRowWeight) {
                    array[lastIndex].weight = 1
                }

                rowWeight += array[lastIndex] ? array[lastIndex].weight : 1

                if (rowWeight <= maxRowWeight) {
                    arrayCol.push(array[lastIndex])
                    lastIndex += 1
                } else {
                    // se il rowWeight è maggiore del massimo del peso della riga allora verifico che non ci siano spazi liberi nella griglia
                    rowWeight -= array[lastIndex] ? array[lastIndex].weight : 1
                    if (rowWeight < maxRowWeight) {
                        arrayCol.push(undefined)
                    }
                }
            }
            mapData.push(arrayCol)
        }
        const remainingItems = array.slice(lastIndex, array.length)
        return {
            mapData,
            remainingItems,
        }
    }

    const weightViewPager = useMemo(() => {
        const viewPagerArray = []
        let tempArray = converterToPagerItem(props.positions)
        do {
            const result = creatWeightTable2(tempArray, numColumns, numRows)
            viewPagerArray.push(result.mapData)
            tempArray = result.remainingItems
        } while (tempArray.length)
        let tempPage = 0
        const renderViewPager = viewPagerArray.map((elements, indexPager) => {
            tempPage += 1
            return (
                <Slide index={indexPager} key={`page${indexPager.toString()}`}>
                    <Grid data={elements} nColum={numColumns} nRow={numRows} />
                </Slide>
            )
        })
        setTotalPages(tempPage)
        return renderViewPager
    }, [props.positions])

    return (
        <div
            className="backgroundPhone"
            style={{
                backgroundImage: `url('${IPHONE_BACKGROUND}')`,
                padding: props.isFixed ? '30px 12px' : '30px 0 0 12px',
            }}
        >
            <div className="appPreviewContainer">
                <div className="appPreviewContainer--sponsoredContainer">
                    {props.positions
                        .filter((p) => p.bundleInfo.sponsored)
                        .map((p) => {
                            return (
                                <div className="appPreviewContainer--sponsoredContainer--icon" key={p.id.toString()}>
                                    <img
                                        className="appPreviewContainer--sponsoredContainer--icon_image"
                                        src={p.logo as string}
                                        alt={p.name}
                                    />
                                </div>
                            )
                        })}
                </div>
                <div className="appPreviewContainer--title">
                    <h2 className="appPreviewContainer--title_text">Highlights</h2>
                    <div className="appPreviewContainer--title_divider" />
                </div>
                <CarouselProvider naturalSlideWidth={100} naturalSlideHeight={125} totalSlides={totalPages}>
                    <Slider className="sliderComponent">{weightViewPager}</Slider>
                    <CustomSliderDetector>
                        <DotGroup className="dotCustom" />
                    </CustomSliderDetector>
                </CarouselProvider>
                <div style={{ marginTop: 30 }}>
                    <p
                        style={{
                            color: '#fff',
                            textAlign: 'center',
                            opacity: 0.8,
                            fontWeight: 'bold',
                            letterSpacing: 0.3,
                        }}
                    >
                        {t('networks.drag_to_change')}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default AppPreview
