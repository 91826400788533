import React, { useEffect, useState } from 'react'
import CardContainer from '../../components/CardContainer/CardContainer'
import PageSidebarContainer from '../../components/PageSidebarContainer'
import '../../styles/pages/RadiosPage.sass'
import { useLocation, useNavigate } from 'react-router-dom'
import { PageName } from '../../components/Header/Header'
import { Grants } from '../../utility/Grants'
import Loader from '../../components/Loader/Loader'
import { getNetworkUserPermissions, getNetworksPlayerDetail } from '../../resources/api-constants'
import FmWorldAxios from '../../utility/FmWorldAxios'
import PlayerDetailTab from './tabs/PlayerDetailTab'
import PlayerBundlesTab from './tabs/PlayerBundlesTab'
import { useTranslation } from 'react-i18next'
import LoadingModal from '../../components/Modals/LoadingModal'
import { useDispatch } from 'react-redux'
import { setPageHeader } from '../../store/actions/graphic'
import { ROUTES } from '../../resources/routes-constants'
import { PlayerNetwork } from '../../types/data'

enum Tab {
    PlayerDetail = 'player_details',
    PlayerBundles = 'bundles_associated',
}

const arrayTab = [Tab.PlayerDetail, Tab.PlayerBundles]

const PlayerDetailPage: React.FC = () => {
    const [selectedTab, setSelectedTab] = useState(Tab.PlayerDetail)
    const [playerId, setPlayerId] = useState('')
    const [networkId, setNetworkId] = useState(-1)
    const [networkName, setNetworkName] = useState('')
    const [permissions, setPermissions] = useState<Grants[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const renderTab = () => {
        switch (selectedTab) {
            case Tab.PlayerDetail:
                return (
                    <PlayerDetailTab
                        idPlayer={playerId !== 'new' ? parseInt(playerId, 10) : playerId}
                        nameNetwork={networkName}
                        idNetwork={networkId}
                        permissions={permissions}
                    />
                )
            case Tab.PlayerBundles:
                return (
                    <PlayerBundlesTab
                        idPlayer={parseInt(playerId, 10)}
                        nameNetwork={networkName}
                        idNetwork={networkId}
                        permissions={permissions}
                    />
                )
            default:
                return (
                    <PlayerDetailTab
                        idPlayer={playerId !== 'new' ? parseInt(playerId, 10) : playerId}
                        nameNetwork={networkName}
                        idNetwork={networkId}
                        permissions={permissions}
                    />
                )
        }
    }

    const fetchPlayer = async (playerId: string) => {
        setIsLoading(true)
        try {
            const playerFetch: PlayerNetwork = await FmWorldAxios.get(
                getNetworksPlayerDetail(parseInt(playerId, 10))
            ).then((res) => {
                return res.data
            })
            if (playerFetch) {
                dispatch(
                    setPageHeader({
                        parent: {
                            name: playerFetch.networkName,
                            route: `${ROUTES.NETWORK_DETAIL_ROUTE}${playerFetch.idNetwork}`,
                        },
                        name: playerFetch.name,
                    })
                )
                void fetchPermissions(playerFetch.idNetwork)
                setNetworkId(playerFetch.idNetwork)
            }
        } catch (error: any) {
            setError(error.toString())
        }
        setIsLoading(false)
    }

    useEffect(() => {
        const idPlayer = location.pathname.replace('/player/', '')
        setPlayerId(idPlayer)
        if (idPlayer !== 'new') {
            void fetchPlayer(idPlayer)
        } else {
            if (location && location.state) {
                const state = location.state as PageName
                if (state.parent) {
                    setNetworkId(state.parent.id)
                    setNetworkName(state.parent.name)
                    void fetchPermissions(state.parent.id)
                    dispatch(
                        setPageHeader({
                            parent: {
                                name: state.parent.name,
                                route: `${ROUTES.NETWORK_DETAIL_ROUTE}${state.parent.id}`,
                            },
                            name: state.name,
                        })
                    )
                }
                return
            }
            navigate(ROUTES.NETWORK_LIST_ROUTE)
        }
    }, [location.pathname])

    const fetchPermissions = async (networkId: number) => {
        setError('')
        setIsLoading(true)
        try {
            const permissionsFetch = await FmWorldAxios.get(getNetworkUserPermissions(networkId)).then((res) => {
                return res.data
            })
            setPermissions(permissionsFetch)
        } catch (error: any) {
            setError(error.toString())
        }
        setIsLoading(false)
    }

    if (isLoading) {
        return <Loader />
    }

    return (
        <PageSidebarContainer>
            {playerId && playerId !== 'new' ? (
                <div className="tabMenu">
                    {arrayTab.map((tab) => {
                        return (
                            <p
                                onClick={() => setSelectedTab(tab)}
                                className={selectedTab === tab ? 'tabMenu-tab_active' : 'tabMenu-tab'}
                                key={tab}
                            >
                                {t(`networks.${tab}`)}
                            </p>
                        )
                    })}
                </div>
            ) : null}
            {error && <p className="u-normal-text u-bold-text u-error-text">{error}</p>}
            <CardContainer>{renderTab()}</CardContainer>
            <LoadingModal isVisible={isLoading} text={t('loadings.generic_loading')} />
        </PageSidebarContainer>
    )
}

export default PlayerDetailPage
