import React from 'react'
import { useSelector } from 'react-redux'
import { ReducerGraphic, Reducers } from '../types/reducers'

interface Props {
    children: any
}

const PageSidebarContainer: React.FC<Props> = (props) => {
    const graphic = useSelector<Reducers, ReducerGraphic>((state) => state.graphic)

    return (
        <div
            className="u-page-sidebar-container"
            style={{ marginRight: !graphic.collapsedBar && window.innerWidth > 1499 ? 250 : 0 }}
        >
            {props.children}
        </div>
    )
}

export default PageSidebarContainer
