import MaterialTable, { Column } from 'material-table'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { COLORS } from '../../resources/colors'
import { PLACEHOLDER } from '../../resources/constants'
import { ROUTES } from '../../resources/routes-constants'
import { Radio } from '../../types/data'
import FixedTableTheme from '../FixedTableTheme'

interface FormatGroupRadiosTableProps {
    data: Radio[]
    selectRadio: (radio: Radio) => void
}

const FormatGroupRadiosTable: React.FC<FormatGroupRadiosTableProps> = ({ data, selectRadio }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const columns: Column<Radio>[] = [
        {
            title: t('headers.radioId') as string,
            field: 'id',
            render: (radio: Radio) => <p>{radio.id}</p>,
        },
        {
            title: t('headers.radioName') as string,
            field: 'name',
            render: (radio: Radio) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', gap: 25 }}>
                        <img
                            src={typeof radio.logo === 'string' ? radio.logo : PLACEHOLDER}
                            alt={`${radio.name} logo`}
                            style={{ width: 50, height: 50, borderRadius: '50%', cursor: 'pointer' }}
                            onClick={() => {
                                navigate(`${ROUTES.RADIO_DETAIL_ROUTE}${radio.id}`, {
                                    state: { name: radio.name, idNetwork: radio.idNetwork },
                                })
                            }}
                            onError={(e) => (e.currentTarget.src = PLACEHOLDER)}
                        />
                        <p
                            style={{ color: COLORS.purple, fontWeight: 'bold', cursor: 'pointer' }}
                            onClick={() => {
                                navigate(`${ROUTES.RADIO_DETAIL_ROUTE}${radio.id}`, {
                                    state: { name: radio.name, idNetwork: radio.idNetwork },
                                })
                            }}
                        >
                            {radio.name}
                        </p>
                    </div>
                )
            },
        },
    ]

    const memoizedData = useMemo(() => data, [data])

    const actions = useMemo(
        () => [
            {
                icon: 'more_vert',
                tooltip: t('headers.selectRadio'),
                onClick: (event: any, data: Radio | Radio[]) => {
                    if (!Array.isArray(data)) {
                        selectRadio(data)
                    }
                },
            },
        ],
        [selectRadio]
    )

    return (
        <FixedTableTheme>
            <MaterialTable
                columns={columns}
                data={memoizedData}
                actions={actions}
                options={{
                    actionsColumnIndex: -1,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 50],
                    search: false,
                    actionsCellStyle: {
                        border: '0',
                    },
                    toolbar: false,
                }}
                style={{
                    boxShadow: 'none',
                }}
            />
        </FixedTableTheme>
    )
}

export default FormatGroupRadiosTable
