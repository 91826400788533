import React from 'react'
import { useTranslation } from 'react-i18next'
import { COLORS } from '../../../resources/colors'
import Icon from '../../Icon'
import SelectInput, { Option } from '../../SelectInput/SelectInput'
import './PageController.sass'

const pageSizeOptions = [
    {
        id: 21,
        value: 21,
        label: '21',
    },
    {
        id: 42,
        value: 42,
        label: '42',
    },
    {
        id: 63,
        value: 63,
        label: '63',
    },
]

interface Props {
    minNumber: number
    maxNumber: number
    totalNumber: number
    pageSize: number
    onFirstPageClick: () => void
    onPrevPageClick: () => void
    onNextPageClick: () => void
    onLastPageClick: () => void
    onChangePageSize: (n: number) => void
}

const PageController: React.FC<Props> = (props) => {
    const { t } = useTranslation()

    return (
        <div className="pageController">
            <SelectInput
                options={pageSizeOptions}
                value={{
                    id: props.pageSize,
                    value: props.pageSize,
                    label: props.pageSize.toString(),
                }}
                onChange={(val) => {
                    const value = val as Option
                    props.onChangePageSize(value.id as number)
                }}
                position="top"
                flex={0.1}
            />
            <div
                className="pageController-control"
                onClick={() => {
                    if (props.minNumber !== 1) {
                        props.onFirstPageClick()
                    }
                }}
            >
                <Icon
                    name="first-page"
                    color={props.minNumber === 1 ? COLORS.lightBlueGrey : COLORS.blueGrey}
                    size={20}
                />
            </div>
            <div
                className="pageController-control"
                onClick={() => {
                    if (props.minNumber !== 1) {
                        props.onPrevPageClick()
                    }
                }}
            >
                <Icon
                    name="chevron-left"
                    color={props.minNumber === 1 ? COLORS.lightBlueGrey : COLORS.blueGrey}
                    size={20}
                />
            </div>
            <p className="pageController-stats">
                {props.minNumber} - {props.maxNumber} {t('general.of')} {props.totalNumber}
            </p>
            <div
                className="pageController-control"
                onClick={() => {
                    if (props.maxNumber !== props.totalNumber) {
                        props.onNextPageClick()
                    }
                }}
            >
                <Icon
                    name="chevron-right"
                    color={props.maxNumber === props.totalNumber ? COLORS.lightBlueGrey : COLORS.blueGrey}
                    size={20}
                />
            </div>
            <div
                className="pageController-control"
                onClick={() => {
                    if (props.maxNumber !== props.totalNumber) {
                        props.onLastPageClick()
                    }
                }}
            >
                <Icon
                    name="last-page"
                    color={props.maxNumber === props.totalNumber ? COLORS.lightBlueGrey : COLORS.blueGrey}
                    size={20}
                />
            </div>
        </div>
    )
}

export default PageController
