import React, { useEffect, useState } from 'react'
import Button from '../../../components/Button/Button'
import Loader from '../../../components/Loader/Loader'
import DeleteModal from '../../../components/Modals/DeleteModal'
import ActionsModal from '../../../components/Modals/ActionsModal'
import TextInput from '../../../components/TextInput/TextInput'
import { getFmFrequencies, getFmFrequencyDetail } from '../../../resources/api-constants'
import { COLORS } from '../../../resources/colors'
import { Frequency } from '../../../types/data'
import FmWorldAxios from '../../../utility/FmWorldAxios'
import { voidFrequency, VoidFrequency } from '../../../utility/voidConstants'
import Italy from '../../../components/Maps/Italy/Italy'
import { useSelector } from 'react-redux'
import { Reducers, ReducerData } from '../../../types/reducers'
import RadioFrequenciesTable from '../../../components/Tables/RadioFrequenciesTable'
import LoadingModal from '../../../components/Modals/LoadingModal'
import FrequencyModal from '../../../components/Modals/FrequencyModal'
import { fetchFrequencies } from '../../../store/actions/thunk_actions'
import { useDispatch } from 'react-redux'
import { Grants } from '../../../utility/Grants'
import { sortArrayPerFrequency } from '../../../utility/functions'
import FrequencyFilter, { FrequencyFilterObject } from '../../../components/Filters/FrequencyFilter'
import { useTranslation } from 'react-i18next'

interface Props {
    idRadio: number
    permissions: Grants[]
}

export const initialFilter = {
    query: '',
    countries: [],
    states: [],
    cities: [],
    radios: [],
}

const FrequenciesTab: React.FC<Props> = (props) => {
    const [filter, setFilter] = useState<FrequencyFilterObject>(initialFilter)
    const [isLoading, setIsLoading] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)
    const [isCreating, setIsCreating] = useState(false)
    const [error, setError] = useState('')
    const [frequencies, setFrequencies] = useState<Frequency[]>([])
    const [filteredFrequencies, setFilteredFrequencies] = useState<Frequency[]>([])
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const data = useSelector<Reducers, ReducerData>((state) => state.data)
    const [selectedFrequency, setSelectedFrequency] = useState<Frequency | null>(null)
    const [frequencyToDelete, setFrequencyToDelete] = useState<Frequency | null>(null)
    const [frequencyToEdit, setFrequencyToEdit] = useState<Frequency | VoidFrequency | null>(null)
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const getParams = () => {
        const params: any = {
            radios: props.idRadio,
        }
        if (filter.states.length > 0) {
            params.states = filter.states
                .map((r) => {
                    return r.id
                })
                .toString()
        }
        if (filter.cities.length > 0) {
            params.cities = filter.cities
                .map((c) => {
                    return c.id
                })
                .toString()
        }
        if (filter.countries.length > 0) {
            params.countries = filter.countries
        }
        return params
    }

    const fetchFrequenciesLocal = async () => {
        setError('')
        setIsLoading(true)
        try {
            const params: any = getParams()
            const frequenciesFetch = await FmWorldAxios.get(getFmFrequencies(), {
                params: { ...params },
            }).then((res) => {
                const frequencies = res.data.items.map((f: any) => {
                    return {
                        ...f,
                        frequency: parseFloat(f.frequency),
                    }
                })
                const sortedFrequncy = sortArrayPerFrequency(frequencies)
                return sortedFrequncy
            })
            setFrequencies(frequenciesFetch)
        } catch (error: any) {
            console.log('ERROR', error)
            setError(error.toString())
        }
        setIsLoading(false)
    }

    useEffect(() => {
        void fetchFrequenciesLocal()
    }, [props.idRadio])

    useEffect(() => {
        void fetchFrequenciesLocal()
    }, [filter.states, filter.cities, filter.countries])

    useEffect(() => {
        if (filter.query) {
            const filtered = frequencies.filter(
                (s) =>
                    s.frequency.toString().includes(filter.query) ||
                    s.city.name.toLowerCase().includes(filter.query.toLowerCase())
            )
            setFilteredFrequencies(filtered)
        } else {
            setFilteredFrequencies(frequencies)
        }
    }, [filter.query, frequencies])

    const actionsModal = () => {
        const actions = []
        if (props.permissions.includes(Grants.EDIT_RADIO)) {
            actions.push({
                text: t('frequencies.edit_frequency'),
                color: COLORS.purple,
                onClick: () => setFrequencyToEdit(selectedFrequency),
            })
            actions.push({
                text: t('frequencies.delete_frequency'),
                color: COLORS.redDelete,
                onClick: () => setFrequencyToDelete(selectedFrequency),
            })
        }
        return actions
    }

    if (isLoading) {
        return (
            <div className="loadingGrid">
                <Loader />
            </div>
        )
    }

    const handleDeleteFrequency = async () => {
        if (frequencyToDelete) {
            setError('')
            setIsLoading(true)
            try {
                await FmWorldAxios.delete(getFmFrequencyDetail(frequencyToDelete.id))
                dispatch(fetchFrequencies())
                setFrequencyToDelete(null)
                setSelectedFrequency(null)
                await fetchFrequenciesLocal()
            } catch (error: any) {
                console.log('ERROR', error)
                setError(error.toString())
                setIsLoading(false)
            }
        }
    }

    const handleUpdateFrequency = async (newFrequency: Frequency) => {
        if (frequencyToEdit) {
            setError('')
            setIsUpdating(true)
            try {
                const frequencyToPass: VoidFrequency = {
                    idRadio: newFrequency.idRadio,
                    frequency: newFrequency.frequency.toString(),
                    idCity: newFrequency.idCity,
                }
                await FmWorldAxios.put(getFmFrequencyDetail((frequencyToEdit as Frequency).id), frequencyToPass)
                dispatch(fetchFrequencies())
                setIsUpdating(false)
                setFrequencyToEdit(null)
                setSelectedFrequency(null)
                await fetchFrequenciesLocal()
            } catch (error: any) {
                console.log('ERROR', error)
                setError(error.toString())
                setIsUpdating(false)
            }
        }
    }

    const handleCreateFrequency = async (newFrequency: VoidFrequency) => {
        if (frequencyToEdit) {
            setError('')
            setIsCreating(true)
            try {
                const frequencyToPass: VoidFrequency = {
                    idRadio: props.idRadio,
                    frequency: newFrequency.frequency,
                    idCity: newFrequency.idCity,
                }
                await FmWorldAxios.post(getFmFrequencies(), frequencyToPass)
                setIsCreating(false)
                setFrequencyToEdit(null)
                await fetchFrequenciesLocal()
            } catch (error: any) {
                console.log('ERROR', error)
                setError(error.toString())
            }
        }
    }

    const handleFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    return (
        <div>
            <div className="titleRow">
                <div style={{ flex: 1 }}>
                    <h2 className="title">{t('radios.frequencies')}</h2>
                    <h3 className="subtitle" style={{ fontWeight: 400 }}>
                        {t('radios.manage_frequencies')}
                    </h3>
                    {error && <p className="u-normal-text u-bold-text u-error-text">{error}</p>}
                </div>
                <div style={{ display: 'flex', flex: 2, gap: 10, alignItems: 'center', justifyContent: 'flex-end' }}>
                    <div style={{ flex: 2 }}>
                        <TextInput
                            value={filter.query}
                            placeholder={t('general.search')}
                            icon="search"
                            iconPosition="right"
                            onTextChange={(v) => setFilter({ ...filter, query: v })}
                        />
                    </div>
                    <div style={{ flex: 1 }}>
                        <Button
                            label={t('general.filter')}
                            onPress={(e) => {
                                handleFilter(e)
                            }}
                            type="submit"
                            background="#fff"
                            border={`2px solid ${
                                JSON.stringify(filter) !== JSON.stringify(initialFilter) ? COLORS.green : COLORS.purple
                            }`}
                            color={
                                JSON.stringify(filter) !== JSON.stringify(initialFilter) ? COLORS.green : COLORS.purple
                            }
                        />
                        <FrequencyFilter
                            hideRadio
                            filter={filter}
                            anchorEl={anchorEl}
                            onClose={() => setAnchorEl(null)}
                            onReset={() => setFilter(initialFilter)}
                            onUpdate={(f) =>
                                setFilter({
                                    ...filter,
                                    states: f.states,
                                    radios: f.radios,
                                    cities: f.cities,
                                    countries: f.countries,
                                })
                            }
                        />
                    </div>
                    <div style={{ flex: 1 }}>
                        <Button
                            label={t('general.refresh')}
                            onPress={() => {
                                setFilter(initialFilter)
                                void fetchFrequenciesLocal()
                            }}
                            type="submit"
                            background="rgba(100,38,119,0.14)"
                            border={`2px solid ${COLORS.purple}`}
                            color={COLORS.purple}
                        />
                    </div>
                    {props.permissions.includes(Grants.EDIT_RADIO) ? (
                        <div style={{ flex: 1 }}>
                            <Button
                                label={t('general.add')}
                                onPress={() => {
                                    setFrequencyToEdit(voidFrequency)
                                }}
                                type="submit"
                            />
                        </div>
                    ) : null}
                </div>
            </div>
            <div className="u-elements-row" style={{ alignItems: 'flex-start', marginTop: 20 }}>
                <div style={{ flex: 1 }}>
                    <RadioFrequenciesTable
                        data={filteredFrequencies}
                        selectFrequency={(f) => setSelectedFrequency(f)}
                        showActions={actionsModal().length > 0}
                    />
                </div>
                <div style={{ flex: 1, padding: '20px 30px', background: COLORS.lightPurpleBg, borderRadius: 10 }}>
                    <Italy
                        selected={data.states
                            .filter((r) => filter.states.find((reg) => reg.id === r.id))
                            .map((r) => {
                                return r.name
                            })}
                        onSelect={(r) => {
                            if (filter.states.find((region) => r.id === region.id)) {
                                setFilter({ ...filter, states: filter.states.filter((reg) => reg.id !== r.id) })
                            } else {
                                setFilter({
                                    ...filter,
                                    states: [...filter.states, { id: r.id, value: r.id, label: r.name }],
                                })
                            }
                        }}
                    />
                </div>
            </div>
            <ActionsModal
                isVisible={selectedFrequency !== null}
                title={t('general.what_to_do')}
                onClose={() => setSelectedFrequency(null)}
                actions={actionsModal()}
            />
            <LoadingModal
                isVisible={isCreating || isUpdating}
                text={isUpdating ? t('loadings.updating_frequency') : t('loadings.creating_frequency')}
            />
            <FrequencyModal
                isVisible={frequencyToEdit !== null}
                onClose={() => setFrequencyToEdit(null)}
                onSave={(freq) => {
                    if ((freq as Frequency).id) {
                        void handleUpdateFrequency(freq as Frequency)
                    } else {
                        void handleCreateFrequency(freq as VoidFrequency)
                    }
                }}
                frequency={frequencyToEdit}
                lockedRadio
            />
            <DeleteModal
                onDelete={() => {
                    void handleDeleteFrequency()
                }}
                title={`${t('frequencies.delete_this_frequency')} ${frequencyToDelete?.frequency} mHz ${t(
                    'general.in'
                )} ${frequencyToDelete?.city.name}`}
                text={`${t('frequencies.want_delete_frequency')} ${frequencyToDelete?.frequency} mHz ${t(
                    'general.in'
                )} ${frequencyToDelete?.city.name}?`}
                onClose={() => {
                    setFrequencyToDelete(null)
                }}
                isVisible={frequencyToDelete !== null}
            />
        </div>
    )
}

export default FrequenciesTab
