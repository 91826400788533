import React from 'react'
import Cron from 'react-js-cron'
import { useSelector } from 'react-redux'
import { ReducerGraphic, Reducers } from '../../types/reducers'
import { getCronLocale } from '../../utility/functions'
import { Languages } from '../../utility/Languages'

interface Props {
    value: string
    width?: number | string
    disabled?: boolean
    flex?: number
    label?: string
    bordered?: boolean
    background?: string
    onValueChange: (newValue: string) => void
}

const CronInput: React.FC<Props> = (props) => {
    const graphic = useSelector<Reducers, ReducerGraphic>((state) => state.graphic)

    return (
        <div
            className="c-text-input"
            style={{ width: props.width ? props.width : '', flex: props.flex ? props.flex : 1 }}
        >
            {props.label && <span className="o-label">{props.label}</span>}
            <div>
                <Cron
                    locale={getCronLocale(graphic.currentLanguage as Languages)}
                    value={props.value}
                    setValue={(val: any) => props.onValueChange(val)}
                    onError={() => {
                        true
                    }}
                    defaultPeriod={'day'}
                />
            </div>
        </div>
    )
}

export default CronInput
