import React, { useMemo } from 'react'
import GridItem from './AppGridItem'

export interface RadioItem {
    id: number
    title: string
    weight: number
    image: {
        url: string
        video_url?: string
    }
    sponsored: boolean
}

interface Props {
    data: (RadioItem | undefined)[][]
    nColum: number
    nRow: number
}

const AppGrid: React.FC<Props> = (props) => {
    const dimenRow = 68
    // Dato un Array ti crea una tabella di 5 righe e 4 colonne

    const createWeightTable = (mapData: any[]) => {
        const renderTable = mapData.map((rowItems, rowIndex) => {
            const renderColumns = rowItems.map((column: RadioItem, columnIndex: number) => {
                if (!column) {
                    return (
                        <div key={`${rowIndex.toString()}-${columnIndex.toString()}`} style={{ flex: 1, margin: 5 }} />
                    )
                }

                return (
                    <GridItem
                        key={`${rowIndex.toString()}-${columnIndex.toString()}`}
                        item={column}
                        dimenRow={dimenRow}
                    />
                )
            })
            return (
                <div key={`${rowIndex.toString()}-Row`} style={{ flex: 1, display: 'flex' }}>
                    <div style={{ flex: 1, flexDirection: 'row', display: 'flex' }}>{renderColumns}</div>
                </div>
            )
        })

        return renderTable
    }

    return useMemo(
        () => (
            <div
                style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'hidden',
                    display: 'flex',
                    maxWidth: '100%',
                }}
            >
                <div style={{ flex: 1, display: 'flex', flexWrap: 'wrap' }}>{createWeightTable(props.data)}</div>
            </div>
        ),
        [props.data, dimenRow, props.nColum, props.nRow]
    )
}

export default AppGrid
