import React from 'react'
import './Modals.sass'
import Modal from '@mui/material/Modal'
import Button from '../Button/Button'
import { COLORS } from '../../resources/colors'

interface Props {
    isVisible: boolean
    onDelete: () => void
    onClose: () => void
    text: string
    title: string
}

const DeleteModal: React.FC<Props> = (props) => {
    return (
        <Modal
            open={props.isVisible}
            onClose={() => props.onClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className="modalBox deleteBox">
                <h2 className="modalBox-title">{props.title}</h2>
                <p className="deleteBox-text">{props.text}</p>
                <div className="modalBox-buttonsRow">
                    <div style={{ flex: '1 1 100%' }} />
                    <Button
                        label="Annulla"
                        border={'1px solid rgba(0,0,0,0.07)'}
                        onPress={() => props.onClose()}
                        type="secondary"
                        color={COLORS.darkGrey}
                        background="#fff"
                    />
                    <Button
                        label="Elimina"
                        onPress={() => props.onDelete()}
                        type="submit"
                        color="#fff"
                        background={COLORS.redDelete}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default DeleteModal
