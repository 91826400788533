import { getAuth, User, GoogleAuthProvider, signInWithPopup, OAuthProvider } from 'firebase/auth'

export enum AUTH_PROVIDER {
    GOOGLE = 'google',
    APPLE = 'apple',
}

export const performSocialLogin = async (provider: AUTH_PROVIDER): Promise<User | null> => {
    let prov = new GoogleAuthProvider()
    if (provider === AUTH_PROVIDER.APPLE) {
        prov = new OAuthProvider('apple.com')
    }
    const auth = getAuth()
    try {
        const response = await signInWithPopup(auth, prov)
        const credential = GoogleAuthProvider.credentialFromResult(response)
        if (credential === null) {
            return null
        }
        // const token = credential.accessToken
        const user = response.user
        return user
    } catch (error) {
        console.log(error)
        return null
    }
}
