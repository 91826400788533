import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Button from '../../../components/Button/Button'
import MuxFrequenciesFilter, { FilterMuxFrequencies } from '../../../components/Filters/MuxFrequenciesFilter'
import Loader from '../../../components/Loader/Loader'
import ActionsModal from '../../../components/Modals/ActionsModal'
import DabFrequencyModal from '../../../components/Modals/DabFrequencyModal'
import DeleteModal from '../../../components/Modals/DeleteModal'
import LoadingModal from '../../../components/Modals/LoadingModal'
import DabFrequenciesTable from '../../../components/Tables/DabFrequenciesTable'
import TextInput from '../../../components/TextInput/TextInput'
import { getDabCityAssoc, getDabFrequencies } from '../../../resources/api-constants'
import { COLORS } from '../../../resources/colors'
import { DabFrequency } from '../../../types/data'
import { Reducers, ReducerGraphic, ReducerUser } from '../../../types/reducers'
import FmWorldAxios from '../../../utility/FmWorldAxios'
import { voidDabFrequency, VoidDabFrequency } from '../../../utility/voidConstants'

interface Props {
    idMux: number
}

const initialFilter = {
    query: '',
    provinces: [],
}

const DabFrequncyTab: React.FC<Props> = (props) => {
    const graphic = useSelector<Reducers, ReducerGraphic>((state) => state.graphic)
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const [filter, setFilter] = useState<FilterMuxFrequencies>(
        graphic.lastMuxFrequenciesSearch ? graphic.lastMuxFrequenciesSearch : initialFilter
    )
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const [selectedFrequency, setSelectedFrequency] = useState<DabFrequency | null>(null)
    const [frequencyToDelete, setFrequencyToDelete] = useState<DabFrequency | null>(null)
    const [frequencyToEdit, setFrequencyToEdit] = useState<DabFrequency | VoidDabFrequency | null>(null)
    const [isLoading, setIsLoading] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)
    const [isCreating, setIsCreating] = useState(false)
    const [error, setError] = useState('')
    const [frequencies, setFrequencies] = useState<DabFrequency[]>([])
    const [filteredFrequencies, setFilteredFrequencies] = useState<DabFrequency[]>([])
    const { t } = useTranslation()

    const fetchFrequenciesLocal = async () => {
        setError('')
        setIsLoading(true)
        try {
            const params: any = {}
            if (filter.provinces.length > 0) {
                params.provinces = filter.provinces
                    .map((p) => {
                        return p.id
                    })
                    .toString()
            }

            const frequenciesFetch = await FmWorldAxios.get(getDabFrequencies(props.idMux), {
                params: { ...params },
            }).then((res) => {
                return res.data.items
            })
            setFrequencies(frequenciesFetch)
        } catch (error: any) {
            console.log('ERROR', error)
            setError(error.toString())
        }
        setIsLoading(false)
    }

    useEffect(() => {
        if (filter.query) {
            const filtered = frequencies.filter(
                (s) => s.frequency.includes(filter.query) || s.name.toLowerCase().includes(filter.query.toLowerCase())
            )
            setFilteredFrequencies(filtered)
        } else {
            setFilteredFrequencies(frequencies)
        }
    }, [filter.query, frequencies])

    useEffect(() => {
        void fetchFrequenciesLocal()
    }, [user.user])

    const handleDeleteFrequency = async () => {
        if (frequencyToDelete) {
            setError('')
            setIsLoading(true)
            try {
                await FmWorldAxios.delete(getDabCityAssoc(props.idMux, frequencyToDelete.id))
                setFrequencyToDelete(null)
                setSelectedFrequency(null)
                await fetchFrequenciesLocal()
            } catch (error: any) {
                console.log('ERROR', error)
                setError(error.toString())
                setIsLoading(false)
            }
        }
    }

    const handleUpdateFrequency = async (newFrequency: DabFrequency) => {
        if (frequencyToEdit) {
            setError('')
            setIsUpdating(true)
            try {
                const freq = {
                    frequency: newFrequency.frequency,
                }
                await FmWorldAxios.put(getDabCityAssoc(props.idMux, newFrequency.id), freq)
                setIsUpdating(false)
                setFrequencyToEdit(null)
                setSelectedFrequency(null)
                await fetchFrequenciesLocal()
            } catch (error: any) {
                console.log('ERROR', error)
                setError(error.toString())
                setIsUpdating(false)
            }
        }
    }

    const handleCreateFrequency = async (newFrequency: VoidDabFrequency) => {
        if (frequencyToEdit) {
            setError('')
            setIsCreating(true)
            try {
                const freq = {
                    frequency: newFrequency.frequency,
                }
                await FmWorldAxios.post(getDabCityAssoc(props.idMux, newFrequency.idCity), freq)
                setIsCreating(false)
                setFrequencyToEdit(null)
                await fetchFrequenciesLocal()
            } catch (error: any) {
                console.log('ERROR', error)
                setError(error.toString())
            }
        }
    }

    const actionsModal = [
        {
            text: t('frequencies.edit_frequency'),
            color: COLORS.purple,
            onClick: () => setFrequencyToEdit(selectedFrequency),
        },
        {
            text: t('frequencies.delete_frequency'),
            color: COLORS.redDelete,
            onClick: () => setFrequencyToDelete(selectedFrequency),
        },
    ]

    const handleFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    return (
        <div>
            <div className="titleRow">
                <div style={{ flex: 1 }}>
                    <h2 className="title">{t('frequencies.your_frequencies')}</h2>
                    <h3 className="subtitle" style={{ fontWeight: 400 }}>
                        {t('frequencies.manage_frequencies')}
                    </h3>
                    {error && <p className="u-normal-text u-bold-text u-error-text">{error}</p>}
                </div>
                <div style={{ display: 'flex', flex: 2, gap: 10, alignItems: 'center', justifyContent: 'flex-end' }}>
                    <div style={{ flex: 2 }}>
                        <TextInput
                            value={filter.query}
                            placeholder={t('general.search')}
                            icon="search"
                            iconPosition="right"
                            onTextChange={(v) => setFilter({ ...filter, query: v })}
                        />
                    </div>
                    <div style={{ flex: 1 }}>
                        <Button
                            label={t('general.filter')}
                            onPress={(e) => {
                                handleFilter(e)
                            }}
                            type="submit"
                            background="#fff"
                            border={`2px solid ${
                                JSON.stringify(filter) !== JSON.stringify(initialFilter) ? COLORS.green : COLORS.purple
                            }`}
                            color={
                                JSON.stringify(filter) !== JSON.stringify(initialFilter) ? COLORS.green : COLORS.purple
                            }
                        />
                        <MuxFrequenciesFilter
                            filter={filter}
                            anchorEl={anchorEl}
                            onClose={() => setAnchorEl(null)}
                            onReset={() => setFilter(initialFilter)}
                            onUpdate={(f) =>
                                setFilter({
                                    ...filter,
                                    provinces: f.provinces,
                                })
                            }
                        />
                    </div>
                    <div style={{ flex: 1 }}>
                        <Button
                            label={t('general.refresh')}
                            onPress={() => {
                                void fetchFrequenciesLocal()
                            }}
                            type="submit"
                            background="rgba(100,38,119,0.14)"
                            border={`2px solid ${COLORS.purple}`}
                            color={COLORS.purple}
                        />
                    </div>
                    <div style={{ flex: 1 }}>
                        <Button
                            label={t('general.add')}
                            onPress={() => {
                                setFrequencyToEdit(voidDabFrequency as VoidDabFrequency)
                            }}
                            type="submit"
                        />
                    </div>
                </div>
            </div>
            {isLoading ? (
                <div className="loadingGrid">
                    <Loader />
                </div>
            ) : (
                <div className="u-elements-row" style={{ alignItems: 'flex-start', marginTop: 20 }}>
                    <div style={{ flex: 1 }}>
                        <DabFrequenciesTable
                            data={filteredFrequencies}
                            selectFrequency={(f) => setSelectedFrequency(f)}
                        />
                    </div>
                </div>
            )}

            <ActionsModal
                isVisible={selectedFrequency !== null}
                title={t('general.what_to_do')}
                onClose={() => setSelectedFrequency(null)}
                actions={actionsModal}
            />
            <LoadingModal
                isVisible={isCreating || isUpdating}
                text={isUpdating ? t('loadings.updating_frequency') : t('loadings.creating_frequency')}
            />
            <DabFrequencyModal
                isVisible={frequencyToEdit !== null}
                onClose={() => setFrequencyToEdit(null)}
                onSave={(freq) => {
                    if ((freq as DabFrequency).id) {
                        void handleUpdateFrequency(freq as DabFrequency)
                    } else {
                        void handleCreateFrequency(freq as VoidDabFrequency)
                    }
                }}
                frequency={frequencyToEdit}
            />
            <DeleteModal
                onDelete={() => {
                    void handleDeleteFrequency()
                }}
                title={`Elimina la frequenza ${frequencyToDelete?.frequency} a ${frequencyToDelete?.name}`}
                text={`Vuoi eliminare la frequenza ${frequencyToDelete?.frequency} a ${frequencyToDelete?.name}?`}
                onClose={() => {
                    setFrequencyToDelete(null)
                }}
                isVisible={frequencyToDelete !== null}
            />
        </div>
    )
}

export default DabFrequncyTab
