import React from 'react'
import './Modals.sass'
import Modal from '@mui/material/Modal'

interface ActionModal {
    text: string
    color: string
    onClick: () => void
}

interface Props {
    isVisible: boolean
    onClose: () => void
    title: string
    actions: ActionModal[]
}

const ActionsModal: React.FC<Props> = (props) => {
    return (
        <Modal
            open={props.isVisible}
            onClose={() => props.onClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className="modalBox actionBox">
                <h2 className="modalBox-title">{props.title}</h2>
                <div className="actionBox-actions">
                    {props.actions.map((a) => {
                        return (
                            <p
                                className="actionBox-actions--action"
                                key={a.text}
                                style={{ backgroundColor: `${a.color}4D`, color: a.color, padding: 10 }}
                                onClick={() => a.onClick()}
                            >
                                {a.text}
                            </p>
                        )
                    })}
                </div>
            </div>
        </Modal>
    )
}

export default ActionsModal
