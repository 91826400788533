import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createXperiData, getXperiData } from '../../resources/api-constants'
import { COLORS } from '../../resources/colors'
import { XperiData } from '../../types/data'
import FmWorldAxios from '../../utility/FmWorldAxios'
import { usePrevious } from '../../utility/hooks/common'
import Button from '../Button/Button'
import LoadingModal from '../Modals/LoadingModal'
import TextInput from '../TextInput/TextInput'
import './ServiceTabs.sass'

interface Props {
    idRadio: number
    disabled?: boolean
}

const voidXperiData = (id: number) => {
    return {
        ac: '',
        active: false,
        apiKey: '',
        broadcastIds: '',
        idRadio: id,
        idXperi: -1,
        lastPush: '',
        lastUpdate: '',
        password: '',
        username: '',
    }
}

const Xperi: React.FC<Props> = (props) => {
    const [xperiData, setXperiData] = useState<XperiData | null>(null)
    const [error, setError] = useState('')
    const [isUpdating, setIsUpdating] = useState(false)
    const previousXperiData = usePrevious(xperiData)
    const [isNew, setIsNew] = useState(false)
    const { t } = useTranslation()

    const fetchXperiData = async () => {
        setError('')
        try {
            const data = await FmWorldAxios.get(getXperiData(props.idRadio)).then((res) => {
                return res.data
            })
            if (data) {
                setXperiData(data)
                setIsNew(false)
            } else {
                setXperiData(voidXperiData(props.idRadio))
                setIsNew(true)
            }
        } catch (error: any) {
            console.log('ERROR', error)
            if (!error.toString().includes('404')) {
                setError(error.toString())
            }
            setXperiData(voidXperiData(props.idRadio))
            setIsNew(true)
        }
    }

    const createXperi = async () => {
        if (xperiData && isNew) {
            setError('')
            setIsUpdating(true)
            try {
                const update = {
                    idXperi: xperiData.idXperi,
                    idRadio: props.idRadio,
                    username: xperiData.username,
                    password: xperiData.password,
                    apiKey: xperiData.apiKey,
                    broadcastIds: xperiData.broadcastIds,
                    active: true,
                }
                await FmWorldAxios.post(createXperiData(), update)
                await fetchXperiData()
            } catch (error: any) {
                console.log('ERROR', error)
                setError(error.toString())
            }
            setIsUpdating(false)
        }
    }

    const updateXperi = async (changeState?: boolean) => {
        if (xperiData) {
            setError('')
            setIsUpdating(true)
            try {
                const update = {
                    idXperi: xperiData.idXperi,
                    username: xperiData.username,
                    password: xperiData.password,
                    apiKey: xperiData.apiKey,
                    broadcastIds: xperiData.broadcastIds,
                    active: changeState ? !xperiData?.active : xperiData?.active,
                }
                await FmWorldAxios.put(getXperiData(props.idRadio), update)
                await fetchXperiData()
            } catch (error: any) {
                console.log('ERROR', error)
                setError(error.toString())
            }
            setIsUpdating(false)
        }
    }

    useEffect(() => {
        setXperiData(null)
        void fetchXperiData()
    }, [props.idRadio])

    if (xperiData) {
        return (
            <div className="serviceTab">
                <h2 className="serviceTab-title">{t('radios.xperi')}</h2>
                {error && <p className="u-normal-text u-bold-text u-error-text">{error}</p>}
                <TextInput
                    value={xperiData.username}
                    onTextChange={(e) => {
                        setXperiData({
                            ...xperiData,
                            username: e,
                        })
                    }}
                    label={t('auth.username')}
                    disabled={props.disabled}
                />
                <div style={{ margin: '20px 0' }}>
                    <TextInput
                        value={xperiData.password}
                        onTextChange={(e) => {
                            setXperiData({
                                ...xperiData,
                                password: e,
                            })
                        }}
                        type="password"
                        label={t('auth.password')}
                        disabled={props.disabled}
                    />
                </div>
                <div style={{ margin: '20px 0' }}>
                    <TextInput
                        value={xperiData.idXperi !== -1 ? xperiData.idXperi.toString() : ''}
                        onTextChange={(e) => {
                            setXperiData({
                                ...xperiData,
                                idXperi: parseInt(e, 10),
                            })
                        }}
                        label={t('radios.xperi_id')}
                        disabled={props.disabled}
                    />
                </div>
                <div style={{ margin: '20px 0' }}>
                    <TextInput
                        value={xperiData.apiKey}
                        onTextChange={(e) => {
                            setXperiData({
                                ...xperiData,
                                apiKey: e,
                            })
                        }}
                        label={t('auth.api_key')}
                        disabled={props.disabled}
                    />
                </div>
                <div className="serviceTab-buttons">
                    <div style={{ flex: 1, marginRight: 10 }}>
                        {!isNew ? (
                            <Button
                                disabled={
                                    !previousXperiData ||
                                    previousXperiData === null ||
                                    JSON.stringify(previousXperiData) === JSON.stringify(xperiData) ||
                                    props.disabled
                                }
                                label={t('general.update')}
                                onPress={() => {
                                    void updateXperi()
                                }}
                                type="submit"
                                background={COLORS.purple}
                                border={`2px solid ${COLORS.purple}`}
                                color={'#fff'}
                            />
                        ) : null}
                    </div>
                    <div style={{ flex: 1 }}>
                        <Button
                            label={xperiData.active ? t('general.unlink') : t('general.link')}
                            onPress={() => {
                                if (isNew) {
                                    void createXperi()
                                } else {
                                    void updateXperi(true)
                                }
                            }}
                            type="submit"
                            background={xperiData.active ? 'rgba(100,38,119,0.14)' : COLORS.purple}
                            border={`2px solid ${COLORS.purple}`}
                            color={xperiData.active ? COLORS.purple : '#fff'}
                            disabled={props.disabled}
                        />
                    </div>
                </div>
                <LoadingModal isVisible={isUpdating} text="Sto aggiornando" />
            </div>
        )
    }
    return null
}

export default Xperi
