import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useQueryParamsFromURL = <T>(objectStructure: T): T => {
    const [queryParamsFromURL] = useSearchParams()
    const [routeParams, setRouteParams] = useState<T>(objectStructure)

    useEffect(() => {
        const temporaryParams: any = {}
        Object.keys(routeParams).map((param) => {
            const singleParam = queryParamsFromURL.get(param)
            temporaryParams[param] = singleParam !== null ? singleParam : ''
            return
        })
        setRouteParams(temporaryParams)
        // eslint-disable-next-line
    }, [queryParamsFromURL])

    return routeParams
}

// Checks if the current device of the user is a touch one (smartphone, tablet, ...)
export const useCheckIsTouchDevice = () => {
    const [isTouchDevice, setIsTouchDevice] = useState(false)

    useEffect(() => {
        setIsTouchDevice(

            // Check if the touch events are supported
            !!(typeof window !== 'undefined' && ('ontouchstart' in window ||
                ((window as any).DocumentTouch &&
                    typeof document !== 'undefined' &&
                    document instanceof (window as any).DocumentTouch))) ||

            // Check if touch contact points are supported by the current device
            !!(typeof navigator !== 'undefined' &&
                (navigator.maxTouchPoints || (navigator as any).msMaxTouchPoints))
        )
    }, [])

    return isTouchDevice
}