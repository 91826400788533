import React from 'react'
import { InteractiveMuxItemConfiguration } from '../../types/data'
import { Paper } from '@mui/material'
export interface Props {
    configuration: InteractiveMuxItemConfiguration
    logo: string
}

const MUXPreview: React.FC<Props> = (props: Props) => {
    console.log('logo', props.logo)

    return (
        <Paper
            elevation={3}
            style={{
                height: 460,
                width: 640,
                backgroundColor: props.configuration.OverloadLayoutData.BackgroundColor ?? 'black',
                position: 'relative',
                overflow: 'hidden',
            }}
        >
            <img
                style={{
                    top: props.configuration.OverloadLayoutData.CoverTop ?? 10,
                    left: props.configuration.OverloadLayoutData.CoverLeft ?? 10,
                    height: props.configuration.OverloadLayoutData.CoverHeight ?? 440,
                    width: props.configuration.OverloadLayoutData.CoverWidth ?? 620,
                    position: 'absolute',
                    display: 'block',
                    overflow: 'hidden',
                    objectFit: 'cover',
                }}
                src={
                    props.configuration.OverloadLayoutData.CoverEnabled
                        ? 'https://fmworld-resources.s3.eu-west-2.amazonaws.com/cover_sample.jpg'
                        : props.logo
                }
                alt=""
            />

            <div
                style={{
                    position: 'absolute',
                    top: props.configuration.OverloadLayoutData.QRTop ?? 20,
                    left: props.configuration.OverloadLayoutData.QRLeft ?? 520,
                    height: props.configuration.OverloadLayoutData.QRHeight ?? 80,
                    width: props.configuration.OverloadLayoutData.QRWidth ?? 80,
                    display: 'block',
                    overflow: 'hidden',
                    objectFit: 'contain',
                    backgroundColor: 'white',
                }}
            >
                <img
                    style={{
                        width: props.configuration.OverloadLayoutData.QRWidth - 10 ?? 70,
                        height: props.configuration.OverloadLayoutData.QRHeight - 10 ?? 70,
                        margin: 5,
                        objectFit: 'contain',
                    }}
                    src="https://fmworld-resources.s3.eu-west-2.amazonaws.com/qrcode.png"
                    alt=""
                />
                {props.configuration.UseCenterImage ? (
                    <div
                        style={{
                            position: 'absolute',
                            height: props.configuration.OverloadLayoutData.QRHeight * 0.5,
                            width: props.configuration.OverloadLayoutData.QRWidth * 0.5,
                            top: props.configuration.OverloadLayoutData.QRHeight * 0.25,
                            left: props.configuration.OverloadLayoutData.QRWidth * 0.25,
                            overflow: 'hidden',
                        }}
                    >
                        <img
                            style={{
                                height: props.configuration.OverloadLayoutData.QRHeight * 0.5,
                                width: props.configuration.OverloadLayoutData.QRWidth * 0.5,
                                objectFit: 'contain',
                            }}
                            src={props.configuration.CenterImageUrl}
                            alt=""
                        />
                    </div>
                ) : null}
            </div>

            {props.configuration.OverloadLayoutData.TextEnabled ? (
                <div
                    style={{
                        position: 'absolute',
                        top: props.configuration.OverloadLayoutData.TextTop ?? 400,
                        left: props.configuration.OverloadLayoutData.TextLeft ?? 25,
                        width: props.configuration.OverloadLayoutData.TextWidth ?? 600,
                        height: props.configuration.OverloadLayoutData.TextHeight ?? 20,
                        color: props.configuration.OverloadLayoutData.TextColor ?? 'white',
                        fontSize: 25,
                        fontWeight: 50,
                    }}
                >
                    <p>Artista - Brano</p>
                </div>
            ) : null}
        </Paper>
    )
}

export default MUXPreview
