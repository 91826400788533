import React from 'react'
import { ThemeProvider, createTheme } from '@mui/material'

interface Props {
    children: any
}

const FixedTableTheme: React.FC<Props> = (props) => {
    const defaultMaterialTheme = createTheme()
    return (
        <div>
            <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
            <ThemeProvider theme={defaultMaterialTheme}>{props.children}</ThemeProvider>
        </div>
    )
}

export default FixedTableTheme
